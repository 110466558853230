<template>
  <StageLogin
    :headline="headline"
    :inputPlaceholder="inputPlaceholder"
    :buttonText="buttonText"
    :image="newImage"
    :fontColor="fontColor"
    :imageLink="imageLink"
    :buttonColorVariant="buttonColorVariant"
  />
</template>

<script>
  import StageLogin from '@/components/Stage/StageLogin.vue';
  export default {
    components: { StageLogin },
    data() {
      return {
        newImage: {}
      };
    },
    props: {
      headline: {
        type: String,
        default: ''
      },
      inputPlaceholder: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      image: {
        type: Object,
        default: {}
      },
      imageLink: {
        type: String,
        default: null
      },
      fontColor: {
        type: String,
        default: ''
      },
      buttonColorVariant: {
        type: String,
        default: ''
      }
    },
    mounted() {
      this.newImage = {
        link: process.env.VUE_APP_MGNL_HOST + '/dam' + this.image['@path'],
        name: this.image['@name']
      };
    }
  };
</script>
