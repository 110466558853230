export default [
  {
    "text": "global.navigation_items.basket",
    "name": "basket",
    "to": "/basket",
    "active": false,
    "step": 1
  },
  {
    "text": "global.navigation_items.payment",
    "name": "checkout-payment",
    "to": "/checkout/payment",
    "active": false,
    "step": 2
  },
  {
    "text": "global.navigation_items.address",
    "name": "checkout-address",
    "to": "/checkout/address",
    "active": false,
    "step": 3
  },
  {
    "text": "global.navigation_items.confirm",
    "name": "checkout-confirm",
    "to": "/checkout/confirm",
    "active": false,
    "step": 4
  }
]