<template>
  <div role="radiogroup" tabindex="-1" class="bv-no-focus-ring">
    <div v-for="(option, index) in options" :key="option.value" class="form-radio custom-control custom-radio custom-control-inline separator">
      <input
        :id="`${name}-${index}`"
        v-model="selected"
        :name="name"
        :value="option.value"
        :disabled="disabled"
        type="radio"
        class="custom-control-input"
      />
      <label class="custom-control-label" :for="`${name}-${index}`">{{ $t(option.text) }}</label>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Array,
        default() {
          return {
            options: [{ text: '', value: '' }]
          }
        }
      },
      name: {
        type: String,
        default: 'input-radio'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      modelValue: {
        type: String,
        default: ''
      }
    },
    computed: {
      selected: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
          this.$emit('inputed', value)
          this.$emit('changed', value)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form-radio {
    padding: 1rem 2rem;
  }

  .separator {
    border-bottom: 1px solid $border-color;
    &:last-child {
      border: none;
    }
  }
</style>
