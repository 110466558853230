<template>
  <div>
    <div role="group" class="form-group">
      <label v-if="!hideLabel" label="userNameInputField">
        {{ label }}
      </label>
      <input
        id="userNameInputField"
        v-model.trim="inputValue.user_name"
        type="text"
        :placeholder="userNamePlaceholder"
        :disabled="disabled"
        :readonly="readonly"
        class="form-control"
        :class="{ 'user-name': userName, 'is-invalid': errorName || apiError }"
        :user-name="userName"
        :aria-invalid="errorName"
        @change="change"
        @blur="blur"
      />
      <span class="invalid-feedback">{{ errorName }}</span>
    </div>
    <div class="position-relative">
      <div role="group" class="form-group">
        <label v-if="!hideLabel" label="passwordInputField">
          {{ label }}
        </label>
        <input
          id="passwordInputField"
          v-model.trim="inputValue.password"
          :placeholder="passwordPlaceholder"
          :disabled="disabled"
          :readonly="readonly"
          class="form-control"
          :class="{ 'password': password, 'is-invalid': errorPassword || apiError }"
          :password="password"
          :aria-invalid="errorPassword"
          @change="change"
          @blur="blur"
          type="password"
          ref="password"
        />
        <b-icon class="password_icon" @click="showPassword" :icon="isClicked !== true ? 'hide-password-icon' : 'show-password-icon'" />
        <span class="invalid-feedback">{{ errorPassword }}</span>
        <span class="invalid-feedback">{{ apiError }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';
  export default {
    components: {
      BIcon
    },
    props: {
      hideLabel: {
        type: [Boolean, String],
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      groupId: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: 'Placeholder'
      },
      state: {
        type: Boolean,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      },
      errorPassword: {
        type: String,
        default: ''
      },
      errorName: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      modelValue: {
        type: Object,
        default: {}
      },
      userName: {
        type: Boolean,
        default: false
      },
      password: {
        type: Boolean,
        default: false
      },
      userNamePlaceholder: {
        type: String,
        default: ''
      },
      passwordPlaceholder: {
        type: String,
        default: ''
      },
      apiError: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        isClicked: false
      }
    },
    computed: {
      inputValue: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
          this.$emit('inputed', value);
        }
      }
    },
    methods: {
      change() {
        this.$emit('changed', this.modelValue);
      },
      blur() {
        this.$emit('blured', this.modelValue);
      },
      showPassword() {
        let input = this.$refs.password
        this.isClicked = !this.isClicked;
        input.type = input.type === 'password' ? 'text' : 'password';
      }
    }
  };
</script>
<style lang="scss" scoped>
  input.form-control {
    &.user-name,
    &.password {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      text-align: center;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &::placeholder {
        color: var(--transparent-light-medium);
      }
      &:hover,
      &:active,
      &:focus {
        border-top: none;
        border-left: none;
        border-right: none;
      }
      &:hover {
        border-color: var(--transparent-light-medium);
      }
      &:active {
        border-color: var(--primary);
      }
      &:focus {
        border-bottom-color: var(--primary);
        box-shadow: none;
        box-shadow: 0 4px 2px -2px rgba(var(--primary), 0.25);
      }
    }
    &.is-invalid.user-name,
    &.is-invalid.password {
      border-bottom-color: $danger;
      box-shadow: 0 4px 2px -2px rgba($danger, 0.25);
      background-image: none;
    }
  }
  label {
    font-size: $h6-font-size;
  }
  .password_icon {
    position: absolute;
    right: 10px;
    top: 1.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
</style>
