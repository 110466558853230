<template>
  <div v-if="product" class="basket-sidebar__wrap">
    <div class="basket-sidebar__item">
      <div class="basket-sidebar__item-img">
        <img
          v-if="product.gift_card_image"
          :src="imageServerUrl + product.gift_card_image"
          :alt="product.name"
          class="basket-sidebar__item-img--tvo"
        />
        <img v-else-if="product.big_images[0]" :src="imageServerUrl + product.big_images[0]" :alt="product.name" />
        <img v-else :src="placeholder" alt="Placeholder image" />
      </div>
      <h3 class="basket-sidebar__item-title">"{{ product.name }}" {{ this.$t('global.basket_sidebar.product_added') }}</h3>
      <div class="basket-sidebar__item-btns">
        <DefaultButton type="link" :link="minimalMode ? '/welcome' : '/product-overview'" variant="light" class="basket-sidebar__item-btn">{{this.$t('global.basket_sidebar.back_button')}}</DefaultButton>
        <DefaultButton type="link" :link="'/basket'" variant="primary" data-test="sidebar-go-to-basket">{{this.$t('global.basket_sidebar.basket_button')}}</DefaultButton>
      </div>
    </div>
    <div v-if="recommendedProducts && recommendedProducts.length > 0" class="basket-sidebar__products-wrap">
      <h4 class="basket-sidebar__products-title">{{ this.$t('global.basket_sidebar.recommended_products') }}</h4>
      <div class="basket-sidebar__products">
        <ProductItemSmall v-for="product in recommendedProducts" :key="product.id" :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
  import Fuse from 'fuse.js';
  import ProductItemSmall from '@/components/Product/ProductItemSmall';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { mapGetters, mapState } from 'vuex';
  import { sortByPriceDiscount } from '@/helpers/sortingFunctions.js';
  export default {
    data() {
      return {
        imageServerUrl: 'https://images.cadooz.com/',
        recommendedProducts: [],
        searchOptions: {
          keys: [
            {
              name: 'name',
              weight: 4
            },
            {
              name: 'description',
              weight: 1
            }
          ],
          minMatchCharLength: 2,
          includeScore: true,
          includeMatches: true,
          threshold: 0.3,
          ignoreLocation: true
        }
      };
    },
    props: {
      placeholder: {
        type: String,
        default: require('@/assets/images/placeholder.png')
      }
    },
    mounted() {
      this.setRecommendedProducts();
    },
    components: { ProductItemSmall, DefaultButton },
    computed: {
      ...mapGetters({
        lastProduct: 'basket/getLastProductInBasket',
        allRecommendedProducts: 'product/getRecommendedProducts',
        minimalMode: 'pages/minimalMode'
      }),
      ...mapState({
        products: (state) => state.basket.positions,
        relatedProduct: (state) => state.filter.relatedProduct,
        catalogProducts: (state) => state.product.catalogProducts
      }),

      product() {
        return this.$store.getters['product/getProduct'](this.lastProduct.product_id);
      }
    },
    methods: {
      setRecommendedProducts() {
        this.recommendedProducts = [];
        if (this.relatedProduct && this.relatedProduct.categoryName) {
          const relatedProducts = [];
          let categories = this.relatedProduct.categoryName.replace('/\s/g', '').replace('&', '').split(' ');
          categories = categories.filter(e => e);
  
          categories.forEach(el => {
            const arr = new Fuse(this.catalogProducts, this.searchOptions).search(el);
            arr.forEach(el => {
              if (relatedProducts.indexOf(el.item) === -1) {
                relatedProducts.push(el.item)
              };
            })
          });

          let filteredProducts = relatedProducts.filter(el => el.number !== this.relatedProduct.productId).slice(0, 15);
          filteredProducts = sortByPriceDiscount(filteredProducts);
          this.recommendedProducts = filteredProducts;
          return;
        }

        const defaultProducts = this.allRecommendedProducts.filter(el => el.number !== this.relatedProduct.productId).slice(0, 7);
        this.recommendedProducts = sortByPriceDiscount(defaultProducts);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .basket-sidebar {
    &__item {
      padding: 0 3rem;
      margin-bottom: 2rem;

      @include media-breakpoint-down(xs) {
        padding: 0 1rem;
      }

      &-img {
        max-width: 10rem;
        width: 100%;
        margin-bottom: 1.5rem;
        position: relative;
        padding-top: 30%;

        img {
          border-radius: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          overflow: hidden;
        }
        &--tvo {
          box-shadow: $box-shadow;
        }
      }

      &-title {
        margin-bottom: 2rem;
        font-weight: bold;
      }

      &-btn {
        border: 1px solid var(--primary) !important;
        color: var(--dark);
        margin-right: 1rem;

        &:hover {
          color: var(--light);
        }

        @include media-breakpoint-down(xs) {
          margin-right: 0;
          margin-bottom: 0.5rem;
        }
      }

      &-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        @include media-breakpoint-down(xs) {
          flex-direction: column;
        }

        button {
          max-width: 100%;
          width: 100%;
          border: 1px solid transparent;
        }
      }
    }

    &__products-wrap {
      background-color: $gray-100;
      padding: 1rem 3rem;

      @include media-breakpoint-down(xs) {
        padding: 1rem;
      }
    }

    &__products {
      &-title {
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }
</style>
