<template>
  <VeeForm ref="form" @submit="submitForm" :validation-schema="validationSchema">
    <div v-for="(field, i) in formFields" :key="i" class="form-field" :class="{'mt-3' : i > 0}">
      <label :for="field.name">{{ field.label }}</label>
      <VeeField :name="field.name" :type="field.type" :placeholder="field.placeholder" class="form-control" />
      <VeeErrorMessage :name="field.name" />
    </div>
    <DefaultButton class="mt-4" type="submit" block :button-text="'Submit'" variant="primary" />
  </VeeForm>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

  export default {
    name: 'TestForm',
    props: {
      // formFields: {
      //   type: Array,
      //   default: [
      //     {
      //       name: 'email',
      //       type: 'email',
      //       placeholder: 'Enter email',
      //       label: 'Email',
      //       validation: this.$yup.string().required().email()
      //     }
      //   ]
      // }
    },
    components: {
      DefaultButton
    },
    data() {
      return {};
    },
    computed: {
      validationSchema() {
        const obj = {};
        this.formFields.forEach(field => obj[field.name] = field.validation);
        return this.$yup.object(obj);
      },  
      formFields() {
        return [
          {
            name: 'email',
            type: 'email',
            placeholder: this.$t('form.field.email.placeholder'),
            label: this.$t('form.field.email.label'),
            size: 6,
            fullRow: true, 
            validation: this.$yup.string().required().email().label(this.$t('form.field.email.label'))
          },
          {
            name: 'password',
            type: 'password',
            placeholder: this.$t('form.field.password.placeholder'),
            label: this.$t('form.field.password.label'),
            validation: this.$yup.string().required().min(8).label(this.$t('form.field.password.label'))
          },
          {
            name: 'address',
            type: 'text',
            placeholder: this.$t('form.field.address.placeholder'),
            label: this.$t('form.field.address.label'),
            validation: this.$yup.string().required().min(8).label(this.$t('form.field.address.label'))
          }
        ]
      }
    },
    mounted() {
      
    },
    methods: {
      async submitForm(e) {
        this.$emit('submit', e);
        console.log('form submitted', e)
      }
    }
  };
</script>

<style lang="scss">

</style>
