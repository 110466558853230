<template>
  <section class="mt-4 mb-5 newsletter__container container-xl">
    <div class="shadow-sm newsletter__wrapper d-flex bg-primary text-light">
      <div class="newsletter pr-xl-0 col-xl-8">
        <h2 class="" v-if="!showFeedback" v-html="headline"></h2>
        <h2 class="" v-else>{{ $t('form.newsletter.feedback_headline') }}</h2>
        <VeeForm @submit="submitForm" class="mt-4 row" v-if="!showFeedback" ref="newsletterForm">
          <div class="col-xl-8">
            <VeeField v-model="email" name="email" type="email" :placeholder="placeholder" class="form-control" :rules="emailRules"/>
            <div class="error-message">
              <VeeErrorMessage name="email" />
            </div>
          </div>
          <div class="col-md-5 col-xl-4">
            <DefaultButton class="mt-4 mt-xl-0" :disabled="buttonDisabled()" type="submit" block :button-text="buttonText" :variant="buttonColorVariant || 'light'" />
          </div>
          <div class="custom-control custom-checkbox cb-container mt-4 col-md-7 col-xl-12 pl-5 mb-0">
            <input v-model="isChecked" type="checkbox" class="custom-control-input" id="newsletter"
               name="gdpr" :value="true" />
            <label class="custom-control-label" for="newsletter">
              <span v-html="description"></span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--primary)" class="check-icon"
                viewBox="0 0 16 16">
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
              </svg>
            </label>      
          </div>         
        </VeeForm>
        <div class="newsletter__feedback" v-show="showFeedback" ref="newsletterFeedback">
          <h3>{{ $t('form.newsletter.feedback_subheadline') }}</h3>
          <p>{{ $t('form.newsletter.feedback_text') }}</p>
        </div>
      </div>
      <img :src="newsletterImage.link" :alt="newsletterImage.alt" class="d-none d-xl-block">
    </div>
  </section>
</template>

<script>
import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

export default {
  props: {
    image: {
      type: Object,
      default: {}
    },
    headline: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    buttonColorVariant: {
      type: String,
      default: ''
    },
  },
  components: {
    DefaultButton
  },
  data() {
    return {
      isChecked: false,
      email: '',
      showFeedback: false,
      emailRules: this.$yup.string().email(),
    };
  },
  computed: {
    newsletterList() {
      return this.$store.state.pages.additionalOptions.newsletterList;
    },
    newsletterImage () {
      return this.image === null ? {
        link: require('@/assets/images/new-newsletter-image.png'),
        name: 'Newsletter Image'
      } : this.image;
    }
  },
  methods: {
    async submitForm() {
      const data = {
        listId: Number(this.newsletterList),
        email: this.email,
        source: window.location.href + ' - component'
      }

      try {
        await this.$store.dispatch('user/newsletterSubscribe', data);

        this.$nextTick(() => {
          this.$refs.newsletterForm?.classList?.add('form--hide');
        });
        this.showFeedback = true;
        setTimeout(() => {
          this.$refs.newsletterFeedback.classList.add('form--show');
        }, 1);
      }
      catch (e) {
        console.error('Error newsletter: ', e);
        this.isChecked = false;
        this.email = '';
      }
    },
    buttonDisabled() {
      const validEmail = this.email.length > 1  && this.$refs.newsletterForm?.getMeta().valid;
      return !this.isChecked || !validEmail;
    }
  }
};
</script>
<style lang="scss" scoped>
.newsletter__container {
  @include media-breakpoint-down(xs) {
    padding-right: 0;
    padding-left: 0;
  }
}

.newsletter__wrapper {
  @include media-breakpoint-up(sm) {
    border-radius: 0.5rem;
  }
  @include media-breakpoint-up(lg) {
    position: relative;
    align-items: center;
    overflow: hidden;
    min-height: 21.25rem;
  }
}

.newsletter {
  position: relative;
  z-index: 2;
  padding: 2rem 1.8rem 2rem 1.8rem;
  @include media-breakpoint-up(xl) {
    padding-right: 0;
  }
}
.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  content: none;
}

.custom-control-label::after {
  background-color: white;
  border-radius: 0.2rem;
}

.custom-control-label {
  :deep(p) {
     margin-bottom: 0;
    }
}

.check-icon {
  background-color: white;
  position: absolute;
  top: 0rem;
  left: -2rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
}

.custom-checkbox {
  font-size: 0.75rem;
  margin-bottom: 0px;
}

img {
  position: absolute;
  width: auto;
  height: 100%;
  right: 0;
  top: 0;
  // opacity: 0.15;
}
.newsImg {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.error-message {
  font-size: 0.75rem;
  position: absolute;

}

input.form-control {
  transition: background .2s;
  caret-color: var(--light);
  &:focus {
    border-color: var(--light);
    caret-color: var(--light) !important;
  }

  &:hover {
    border-color: var(--light);
    background-color: var(--primary);
  }
}


.btn-light {
  border: 1px solid var(--light);
  &:hover {
    background-color: var(--gray-100);
    color: var(--primary);
    border-color: var(--gray-100);
  }
  &:active {
    background-color: var(--gray-200) !important;
    color: var(--primary) !important;
    border-color: var(--gray-200) !important;
  }
  &:focus {
    background-color: var(--light);
    color: var(--primary);
    border-color: var(--light);
  }
  &:disabled {
    background-color: var(--light) !important;
    color: var(--primary) !important;
    border-color: var(--light) !important;
  }
}

.bg--primary {
  background-color: var(--primary);
}

.bg--light {
  background-color: var(--light);
}

.text-light {
  :deep(a) {
    color: var(--light);
    text-decoration: underline;
  }

  :deep(input) {
    background-color: var(--primary-dark);
    color: var(--light) !important;
    border-color: var(--light);

    &::placeholder,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      color: var(--light);
      -webkit-text-fill-color: var(--light);
    }
  }
}

.text-dark {
  :deep(a) {
    color: $dark;
    text-decoration: underline;
  }
}

.newsletter__feedback {
    transition: $dur $ease;
    transform: scale(0);
  }

.form {
  &--hide {
    transform: scale(0);
    transition: $dur $ease;
  }

  &--show {
    transform: scale(1);
    transition: $dur $ease;
  }
}

</style>