<template>
  <StageDefault
    :overline="overline"
    :headline="headline"
    :description="description"
    :layout="layout?.field"
    :cardTitle="layout?.cardTitle"
    :cardDesc="layout?.cardDesc"
    :cardCtaLabel="layout?.button?.label"
    :cardCtaType="layout?.button?.type?.field"
    :cardCtaLink="ctaLink"
    :image="img"
    :imagePosition="layout?.position?.field"
    :buttonType="layout?.button?.type?.field"
    :buttonText="layout?.button?.label"
    :buttonIcon="iconButton(layout?.button)"
    :buttonLink="getButtonHref(layout?.button)"
    :buttonColorVariant="layout?.button?.buttonColorVariant"
  />
</template>

<script>
  import StageDefault from '@/components/Stage/StageDefault.vue';
  export default {
    components: { StageDefault },
    props: {
      headline: {
        type: String,
        default: ''
      },
      overline: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      layout: {
        type: Object,
        default: {}
      },
      button: {
        type: Object,
        default: {}
      }
    },
    computed: {
      ctaLink() {
        if (this.$store.state.app.mgnlMode) return '';

        switch (this.layout?.button?.type?.field) {
          case 'url':
            return this.layout?.button?.type?.url;
          case 'link':
            return this.removeSiteName(this.layout.button.type.link);
          case 'anchor':
            return this.layout?.button?.type?.scrollTo;
          default:
        }
      },
      img() {
        if (this.layout.image) {
          return process.env.VUE_APP_MGNL_HOST + '/dam' + this.layout.image['@path'];
        } else {
          return '';
        }
      }
    },
    methods: {
      iconButton(button) {
        return button?.btnIcon ? button.btnIcon[0] == 'showIcon' : false;
      },
      getButtonHref(button) {
        return button?.type?.field === 'url' ? button?.type?.url : (button?.type?.field === 'link' ? this.removeSiteName(button?.type?.link) : null);
      },
      removeSiteName(link) {
        if (link) {
          const siteName = link.substr(0, link.lastIndexOf("/"));
          return link.replace(siteName, '');
        }
      }
    }
  };
</script>
