<template>
  <div class="stage__shape" :class="imagePosition === 'left' ? 'mr-auto' : 'ml-auto'">
    <svg id="stageShape" class="shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
      <defs>
        <linearGradient id="shapeGradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" :stop-color="front" />
          <stop offset="100%" :stop-color="frontlight" stop-opacity="100" />
        </linearGradient>
      </defs>
      <rect id="shapeBack" :fill="back" width="570" height="570" rx="45" transform="rotate(30 95 560)" />
      <rect id="shapeFront" width="570" height="570" rx="45" transform="rotate(45 190 480)" fill="url(#shapeGradient)" />
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      front: {
        type: String,
        default: '#f39100'
      },
      frontlight: {
        type: String,
        default: '#ffb03b'
      },
      back: {
        type: String,
        default: 'rgba(0,0,0,.05)'
      },
      imagePosition: {
        type: String,
        default: 'right'
      }
    }
  }
</script>
<style lang="scss" scoped>
  .stage {
    &__shape {
      position: relative;
      width: 13rem;
      z-index: $z-index-lowest; // Fallback for mobile devices, which do not understand negative z-index
      z-index: $z-index-negative; // -1 is nassessary for scrolbar side effect
    }
    &__image {
      @include media-breakpoint-down(md) {
        top: 2rem;
      }
    }
    &__text {
      width: 11rem;
      right: 1rem;
      top: 0;
    }
  }
</style>
