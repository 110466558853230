import i18n from '../plugins/i18n.js';
import store from '../store/index.js';

const image = ((url) => {
  return require(`@/${url}`);
});

const routeHandler = ((url) => {
  if (typeof (url) != 'string') return url;
  if (store.state.translation.locales.length > 1) return `/${i18n.global.locale}${url}`;
  return `${url}`;
});

/**
 * Converts a currency string to use comma instead of a dot and appends a euro sign € afterwards.
 * Example: $currency(123.45)
 * Ressult: '123,45 €'
 * @returns String
 */
const currency = ((value, currencySymbol = store.state.app.currency.symbol) => {
  const price = value % 1 !== 0 ? value.toFixed(2).replace('.', ',') : value;
  return `${price} ${currencySymbol}`;
});

// Scroll to function
const scrollTo = (selector, offset = 0) => {
  const el = document.querySelector(selector);
  if (el) {
    window.scrollTo({ left: 0, top: el.offsetTop + offset, behavior: 'smooth' })
  }
}

const mgnlToJson = (obj) => {
  return obj['@nodes'].map(key => obj[key])
}

const toHexColorCode = (negDecimal) => {
  const colorCodeInDec = 16777216 + negDecimal;
  const colorCodeInHex = colorCodeInDec.toString(16).padStart(6, '0');
  return `#${colorCodeInHex}`;
}

const colorGenerator = (color, shade, percent) => {
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  if (shade === 'dark') {
    r = Math.round((r * (100 - percent)) / 100);
    g = Math.round((g * (100 - percent)) / 100);
    b = Math.round((b * (100 - percent)) / 100);
  } else {
    r = Math.round((255 - r) * (percent / 100)) + r;
    g = Math.round((255 - g) * (percent / 100)) + g;
    b = Math.round((255 - b) * (percent / 100)) + b;
  }

  r = r.toString(16).padStart(2, '0');
  g = g.toString(16).padStart(2, '0');
  b = b.toString(16).padStart(2, '0');

  return `#${r}${g}${b}`;
}

const setFavicon = (img) => {
  const icon = document.getElementById("favicon");
  if (!img) return icon.href = '/favicon.ico';
  return icon.href = process.env.VUE_APP_MGNL_HOST + '/dam' + img['@path'];
}

const setColors = (primary, secondary) => {
  const cssRoot = document.querySelector(':root');
  let colorVariables = [];

  if (primary) {
    colorVariables = [...colorVariables, ...generateColorVars('primary', primary)];
  }

  if (secondary) {
    colorVariables = [...colorVariables, ...generateColorVars('secondary', secondary)];
  }

  function generateColorVars(name, color) {
    return [
      { name: `--${name}`, value: color },
      { name: `--${name}-dark`, value: colorGenerator(color, 'dark', 10) },
      { name: `--${name}-bright`, value: colorGenerator(color, 'bright', 10) },
      { name: `--${name}-light`, value: colorGenerator(color, 'light', 40) },
      { name: `--${name}-lightest`, value: colorGenerator(color, 'lightest', 50) },
    ]
  }

  for (const colorVariable of colorVariables) {
    cssRoot.style.setProperty(colorVariable.name, colorVariable.value);
  }
}

const isAppleDevice = () => {
  const expression = /(Mac|iPhone|iPod|iPad)/i;
  const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  return expression.test(platform);
}

export { currency, image, scrollTo, mgnlToJson, toHexColorCode, setColors, setFavicon, routeHandler, isAppleDevice }