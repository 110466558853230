<template>
  <div class="card-body p-0">
    <div v-if="websiteSettings.wob_account_login && websiteSettings.wob_coupon_login" class="mb-2">
      <nav>
        <ul class="nav nav-tabs nav-fill">
          <li :class="activeTab === 1 ? 'active-tab--right' : 'inactive-tab'">
            <span class="nav-item nav-link"
              :class="{ active: activeTab === 1 }" 
              @click="setActiveTab(1)"
            >  
              {{ $t('global.login_form.tab_1') }}
          </span>
          </li>
          <li :class="activeTab === 2 ? 'active-tab--left' : 'inactive-tab'">
            <span class="nav-item nav-link"
              :class="{ active: activeTab === 2 }" 
              @click="setActiveTab(2)"
            >
              {{ $t('global.login_form.tab_2') }}
          </span>
          </li>
        </ul>
      </nav>
    </div>
    <div class="row card-body">
      <div class="col">
        <form @submit.prevent="submitVoucherCode">
          <p class="hide-element">
            <label name="bot-field">
              <input type="hidden" />
            </label>
          </p>
          <DefaultLoginInput 
            v-if="websiteSettings.wob_account_login && activeTab === 1"
            v-model="loginInput"
            hide-label
            :userNamePlaceholder="userNamePlaceholder || $t('global.login_form.user_name')"
            :passwordPlaceholder="passwordPlaceholder || $t('global.login_form.password')"
            :error-name="userNameError"
            :error-password="passwordError"
            :apiError="errorMessage"
            user-name
            password
            class="voucher-input mb-2"
            data-test="login-input-field"
          />
          <DefaultInput
            v-if="websiteSettings.wob_coupon_login && activeTab === 2"
            v-model="codeInput"
            hide-label
            :state="inputFieldState"
            :error-text="errorText"
            promo-code
            :placeholder="codePlaceholder || $t('global.login_form.code')"
            group-id="codeInputField"
            class="voucher-input mb-2"
            data-test="code-input-field"
          />
          <div v-if="!websiteSettings.deny_captcha" class="hcaptcha-container">
            <VueHcaptcha ref="hcaptcha" :sitekey="hcaptchaKey" :language="$store.state.translation.locale" @error="onError" @verify="onSuccess" @expired="onExpired" />
          </div>
          <DefaultButton
            :class="[!websiteSettings.deny_captcha ? 'mt-2' : 'mt-5', fontColor === 'dark' ? 'text-dark' : 'text-light']"
            type="submit"
            block
            variant="primary"
            :button-text="buttonText || $t('global.login_form.button')"
            :loading="loading"
            data-test="code-input-button"
          />
        </form>

        <div class="text-center mt-3">
          <button v-if="activeTab === 1" class="btn btn-link btn-link--primary" @click="$emit('handlePage')">{{ $t('global.login_form.forgot_password_link') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
  import { mapGetters, mapState } from 'vuex';
  import DefaultInput from 'building-blocks/components/DefaultElements/DefaultInput.vue';
  import DefaultLoginInput from 'building-blocks/components/DefaultElements/DefaultLoginInput.vue'
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { loadRiskIdent } from '@/helpers/riskIdent.js';

  export default {
    name: 'CodeInputForm',
    props: {
      redirection: {
        type: String,
        default: ''
      },
      codePlaceholder: {
        type: String,
        default: ''
      },
      userNamePlaceholder: {
        type: String,
        default: ''
      },
      passwordPlaceholder: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      fontColor: {
        type: String,
        default: ''
      }
    },
    components: {
      VueHcaptcha,
      DefaultInput,
      DefaultLoginInput,
      DefaultButton
    },
    data() {
      return {
        inputFieldState: null,
        codeInput: '',
        loginInput: {
          user_name: '',
          password: ''
        },
        captchaToken: '',
        errorMessage: '',
        userNameErrorMessage: '',
        passwordErrorMessage: '',
        pxRatio: 0,
        activeTab: 1,
      };
    },
    computed: {
      ...mapGetters({
        postPaymentType: 'basket/getPostPaymentType',
        riskIdentId: 'app/getRiskIdentId'
      }),
      ...mapState({
        basketGrossPrice: (state) => state.basket.grossPrice,
        loading: (state) => state.app.isLoading,
        websiteSettings: (state) => state.app.websiteSettings,
        prefilledVoucherCode: (state) => state.app.prefilledVoucherCode,
        prepaidValue: (state) => state.basket.prepaidValue,
        isUserLoggedIn: (state) => state.user.loggedIn,
        basketNumber: (state) => state.basket.basketNumber,
        isOpenShop: (state) => state.basket.isOpenShop
      }),

      errorText() {
        return this.errorMessage;
      },
      userNameError() {
        return this.userNameErrorMessage;
      },
      passwordError() {
        return this.passwordErrorMessage;
      },
      isCodeInputEmtpy() {
        return this.codeInput === '';
      },
      isLoginInputUsernameEmpty() {
        return this.loginInput.user_name === '';
      },
      isLoginInputPasswordEmpty() {
        return this.loginInput.password === '';
      },
      hcaptchaKey() {
        return process.env.VUE_APP_HCAPTCHA_SITE_KEY;
      }
    },
    mounted() {
      if (this.prefilledVoucherCode) {
        this.codeInput = this.prefilledVoucherCode;
      };
      this.activeTab = this.websiteSettings.wob_account_login ? 1 : 2;
      this.$emit('setActiveTab', this.activeTab);
    },
    watch: {
      prefilledVoucherCode() {
        this.codeInput = this.prefilledVoucherCode;
      }
    },
    methods: {
      setActiveTab(value) {
        this.$emit('setActiveTab', value);
        this.activeTab = value;
        this.userNameErrorMessage = '';
        this.passwordErrorMessage = '';
        this.errorMessage = '';
        this.inputFieldState = null;
        this.loginInput.user_name = '';
        this.loginInput.password = '';
        this.codeInput = '';
      },
      /**
       * Sets Focus on CodeInput
       * Get´s triggered from Parent Component, if Dropdown is visible
       */
      setFocusToInput() {
        document.getElementById('codeInputField').focus();
      },
      /**
       * Resets the InputField
       * Get´s triggered from Parent Component, if Dropdown is hidden
       */
      resetInputField() {
        this.inputFieldState = null;
        this.errorMessage = '';
        this.userNameErrorMessage = '';
        this.passwordErrorMessage = '';
        this.codeInput = '';
        this.$store.commit('app/SET_PREFILLED_VOUCHERCODE', null);
      },
      setLoading(state) {
        this.$store.commit('app/SET_LOADINGSTATE', state);
      },
      toast(message, error) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      },
      onError(error) {
        if (!error.handled) {
          this.toast(this.$t('form.error.hcaptcha', { error }));
        }
      },
      onSuccess(captchaToken) {
        this.captchaToken = captchaToken;
      },
      async onExpired() {
        await this.$refs.hcaptcha.reset();
      },
      submitVoucherCode() {
        this.inputFieldState = null;
        if(this.activeTab === 2 && this.isCodeInputEmtpy) {
          this.inputFieldState = false;
          this.errorMessage = this.$t('api-errors.voucher.empty');
        } 
        if(this.activeTab === 1 && (this.isLoginInputUsernameEmpty || this.isLoginInputPasswordEmpty)) {
          this.inputFieldState = false;
          if (this.isLoginInputUsernameEmpty) this.userNameErrorMessage = this.$t('api-errors.login.user_name_empty');
          if (this.isLoginInputPasswordEmpty) this.passwordErrorMessage = this.$t('api-errors.login.password_empty');
        }
        if(this.isUserLoggedIn || this.websiteSettings.deny_captcha) {
          this.validateVoucherInput();
        } else {
          this.verifyHcaptcha();
        }
      },
      async validateVoucherInput() {
        try {
          this.setLoading(true);
          await this.$store.dispatch('basket/createBasket');
          if(this.activeTab !== 1) {
            await this.$store.dispatch('basket/verifyLoginCode', this.codeInput)
          } else {
            await this.$store.dispatch('basket/verifyUserCredentials', this.loginInput)
          }

          await this.$store.dispatch('user/logIn');
          await this.$store.dispatch('product/fetchProducts');
          
          const allProducts = await this.$store.getters['product/getAllProducts'];
          await this.$store.dispatch('filter/setPriceRangeAndProducts', allProducts);
          await this.$store.commit('filter/SET_ALL_PRODUCTS', allProducts);
          await this.$store.commit('filter/SET_LOADED_PRODUCTS', allProducts);
          
          this.resetInputField();
          this.checkPaymentmethods();
          loadRiskIdent({token: this.basketNumber, apiId: this.riskIdentId});
          this.setLoading(false);
          if (this.redirection !== '') this.$router.push(this.$routeHandler(this.redirection));
          this.$emit('success');
        } catch (error) {
            this.setLoading(false);

            
            // If the Voucher is valid for another Shop the ErrorObj will contain a redirection_url, which we use to redirect the user.
            // If the User is logged in and just adds another invalid Voucher he will not be redirected
            if (!this.isUserLoggedIn && error.response.data.error_fields.redirection_url) {
              window.location.href = error.response.data.error_fields.redirection_url
            }
            // We just show the Input Error if the User is not getting redirected
            else if (error.displayInComponent) {
              this.inputFieldState = false;
              this.errorMessage = error.message;
            }
          }
      },
      async verifyHcaptcha() {
        try {
          this.setLoading(true);
          const response = await this.$api.verifyCaptchaToken(this.captchaToken);
          const tokenValid = response.success;
          if (tokenValid) {
            await this.$refs.hcaptcha.reset();
            this.validateVoucherInput();
          } else {
            this.setLoading(false);
            throw this.toast(this.$t('form.error.check_hcaptcha'));
          }
        } catch (error) {
          this.setLoading(false);
          await this.$refs.hcaptcha.reset();
        }
      },
      /**
       * Check if other payment methodes besides prepaidvalue are needed after codeinput, if not then they get removed
       */
      checkPaymentmethods() {
        if (this.basketGrossPrice <= this.prepaidValue) {
          this.deleteCurrentPostPaymentOptionFromBasket();
        }
      },
      deleteCurrentPostPaymentOptionFromBasket() {
        const currentPostPayment = this.postPaymentType;
        if (currentPostPayment) {
          const currentPostPaymentType = currentPostPayment.payment_type_id;
          this.$store.dispatch('basket/removePaymentType', currentPostPaymentType);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .hide-element {
    display: none;
  }
  .voucher-input {
    .input {
      transition: $dur $ease;
    }
    .invalid-feedback {
      text-align: center;
      font-size: $font-size-tiny;
    }
  }
  .hcaptcha-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    transform: scale(0.77);
  }

  .nav {
    justify-content: space-evenly;
  }
  .nav li {
    flex: 1;
  }
  .nav-link {
    padding: 1rem;
  }
  .nav-tabs .nav-item {
    cursor: pointer;
    border: none !important;
    color: var(--gray-500);
    transition: $dur $ease;
    font-family: $font-semibold;
  }
  .nav-tabs .nav-link.active {
    border: none !important;
    border-radius: .5rem;
  }
  .nav-tabs .nav-link:hover {
    color: var(--primary);
  }
  .nav-tabs {
    border: none !important;
  }

  .active-tab {
    &--right {
      border-right: 1px solid var(--gray-200); 
      border-bottom: none !important;
    }
    &--left {
      border-left: 1px solid var(--gray-200); 
      border-bottom: none !important;
    }
  }
  .inactive-tab {
    border-bottom: 1px solid #E4E4E4;
  }
</style>
