import { createStore } from 'vuex';
import app from './modules/app';
import basket from './modules/basket';
import filter from './modules/filter';
import order from './modules/order';
import product from './modules/product';
import user from './modules/user';
import pages from './modules/pages';
import punchout from './modules/punchout';
import translation from './modules/translation';
import etracker from './modules/etracker';
import pagination from './modules/pagination';

const store = createStore({
  modules: {
    app,
    basket,
    filter,
    order,
    product,
    user,
    pages,
    punchout,
    translation,
    etracker,
    pagination
  }
});

export default store;
