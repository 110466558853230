<template>
  <div class="price-info detail-card detail-card--no-border">
    <h1 class="mb-0">{{ title }}</h1>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      isAvailable: {
        type: Boolean,
        default: false
      },
      isReward: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      badgeText() {
        return this.isAvailable === false ? 'view.product.state.unavailable' : 'view.product.state.available'
      }
    }
  }
</script>

<style lang="scss" scoped>
  h1 {
    font-family: $font-family-base;
  }
</style>
