<template>
  <div class="basket">
    <div class="container-xl mb-5">
      <div v-if="basketCounter" class="row">
        <div class="col-md-8 col-xl-7">
          <CheckoutNavigation v-if="!isPunchout" />
          <div class="h1 mb-5">
            {{ $t('view.checkout.cart.headline') }}
          </div>
          <div v-show="$device.isMobile && basketCounter > 0" class="h1 mb-5">
          
            <a @click="$scrollTo('#checkoutConfirmation', 350)" href="javascript:void(0)" target="_self" class="btn btn-primary btn-block">{{
              $t('view.checkout.cart.summary.button.checkout')
            }}</a>
          </div>
          <div v-if="basketItemsWithDeliveryTypePost.length > 0" class="shoppingcart-variant mb-5">
            <h3 class="mb-3">{{ $t('view.product.shipping.post.title') }}</h3>
            <BasketItemGroup :products="basketItemsWithDeliveryTypePost" :is-editable="!isPunchoutInspect" />
          </div>
          <div v-if="basketItemsWithDeliveryTypeEmail.length > 0" class="shoppingcart-variant mb-5">
            <h3 class="mb-3">{{ $t('view.product.shipping.mail.title') }}</h3>
            <BasketItemGroup :products="basketItemsWithDeliveryTypeEmail" :is-editable="!isPunchoutInspect" />
          </div>
          <div v-if="basketItemsWithDeliveryTypeSMS.length > 0" class="shoppingcart-variant mb-5">
            <h3 class="mb-3">{{ $t('view.product.shipping.SMS.title') }}</h3>
            <BasketItemGroup :products="basketItemsWithDeliveryTypeSMS" :is-editable="!isPunchoutInspect" />
          </div>
        </div>
        <div class="col-md-4 col-xl-4 offset-xl-1">
          <BasketSummary :button-text="$t('view.checkout.cart.summary.button.checkout' + (isPunchout ? '_punchout' : ''))" @button-click="navigateToCheckout" />
        </div>
      </div>
      <div v-if="!basketCounter" class="row">
        <InfoBox
          :headline="$t('view.checkout.cart.info_box.title')"
          :button-text="$t('view.checkout.cart.info_box.button')"
          button-link="/product-overview"
          button-type="link"
          data-test="basket-empty-msg"
        />
      </div>
    </div>
    <ProductSlider
      v-if="!minimalMode"
      :headline="$t('global.product_slider.headline')"
      :productLinkPath="'/product-overview'"
      :productLinkQuery="{ recommended: true }"
      :productLinkText="$t('global.product_slider.link')"
      :products="shopItems.slice(0, 20)"
      class="swiper-spacer-top"
    />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import CheckoutNavigation from '@/components/CheckoutNavigation/CheckoutNavigation.vue';
  import BasketItemGroup from '@/components/Basket/BasketItemGroup.vue';
  import BasketSummary from '@/components/Basket/BasketSummary.vue';
  import InfoBox from '@/components/InfoBox/InfoBox.vue';
  import ProductSlider from '@/components/Product/ProductSlider.vue';

  export default {
    components: {
      CheckoutNavigation,
      BasketItemGroup,
      BasketSummary,
      InfoBox,
      ProductSlider
    },
    computed: {
      ...mapGetters({
        basketCounter: 'basket/positionsCounter',
        basketItemsWithDeliveryTypePost: 'basket/getPositionsWithDeliveryTypePost',
        basketItemsWithDeliveryTypeEmail: 'basket/getPositionsWithDeliveryTypeEmail',
        basketItemsWithDeliveryTypeSMS: 'basket/getPositionsWithDeliveryTypeSMS',
        isPunchout: 'punchout/isPunchout',
        isPunchoutInspect: 'punchout/isPunchoutInspect',
        minimalMode: 'pages/minimalMode'
      }),
      ...mapState({
        positions: (state) => state.basket.positions
      }),
      shopItems() {
        return this.$store.state.product.catalogProducts;
      }
    },
    methods: {
      async navigateToCheckout() {
        if(!this.isPunchout) {
          this.$router.push({ path: this.$routeHandler('/checkout/payment') });
        } else {
          const checkoutPassed = await this.$store.dispatch('punchout/checkout', this.isPunchoutInspect);
          if(!checkoutPassed) {
            this.$store.commit('app/SET_TOAST', {
              message: this.$t('view.error.general'),
              duration: 7
            });
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .basket {
    margin-top: 3rem;
  }
  .shipping-hint {
    margin-top: 0.5rem;
    font-size: $font-size-md;
    color: $gray-400;
  }
</style>
