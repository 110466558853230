<template>
  <Default>
    <template v-if="indexPage && indexPage.header" v-slot:header>
      <EditableArea :content="indexPage.header" :pageTemplateId="indexPage['mgnl:template']" />
    </template>
    <EditableArea v-if="before" :content="before" />
    <p>Here is content from static page</p>
    <EditableArea v-if="after" :content="after" />
    <template v-if="indexPage && indexPage.footer" v-slot:footer>
      <EditableArea :content="indexPage.footer" :pageTemplateId="indexPage['mgnl:template']" />
    </template>
  </Default>
</template>

<script>
  import { EditableArea } from '@magnolia/vue-editor';
  import Default from '@/layouts/Default.vue';
  export default {
    components: {
      EditableArea,
      Default
    },
    props: ['title', 'headline', 'metadata', 'before', 'after'],
    computed: {
      indexPage() {
        return this.$store.state.pages.indexPage;
      }
    }
  };
</script>
