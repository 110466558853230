<template>
  <section class="login">
    <div class="container-xl">
      <div class="login__wrap row">
        <div class="col-md-12 col-lg-6">
          <div class="card login__form">
            <div class="loading" v-if="loader">
              <div v-for="(item, index) in 3" :key="index"></div>
            </div>
            <form v-if="!forgotSubmit && !loader" class="card__form" :class="fontColor === 'dark' ? 'text-dark' : 'text-light'">
              <h1 v-if="headlineText" class="card-title">{{ headlineText }}</h1>
              <div v-if="descriptionText" class="card-subtitle">{{ descriptionText }}</div>
              <div class="card">
                <div class="">
                  <LoginInputForm
                    v-if="displayForm"
                    :button-text="buttonText" 
                    :codePlaceholder="codePlaceholder"
                    :userNamePlaceholder="userNamePlaceholder"
                    :passwordPlaceholder="passwordPlaceholder" 
                    redirection="/welcome"
                    :fontColor="fontColor"
                    @handlePage="handlePage"
                    @setActiveTab="setActiveTab"
                  />

                  <ForgotPasswordForm
                    v-else-if="forgotPassword"
                    @handleSubmit="handleSubmit"
                    @backToLogin="backToLogin"
                  />
                </div>
              </div>
            </form>

            <PasswordSuccessForm
              v-if="forgotSubmit && !loader"
              :fontColor="fontColor"
              @handleReturn="handleReturn"
              @backToLogin="backToLogin"
            />
          </div>
        </div>
      </div>
      <img v-if="image.name" class="login__image" :src="image.link" alt="image">
    </div>
    <div class="login__background" :style="'background-color: ' + bgColor + ''"></div>
  </section>
</template>

<script>
  import LoginInputForm from '@/components/LoginInputForm.vue';
  import ForgotPasswordForm from '@/components/ForgotPasswordForm.vue';
  import NewPasswordForm from '@/components/NewPasswordForm.vue';
  import PasswordSuccessForm from '@/components/PasswordSuccessForm.vue';
  import { mapState } from 'vuex';

  export default {
    components: {
      LoginInputForm,
      ForgotPasswordForm,
      PasswordSuccessForm,
      NewPasswordForm,
    },
    props: {
      bgColor: {
        type: String
      },
      codeHeadline: {
        type: String,
        default: ''
      },
      codeDescription: {
        type: String,
        default: ''
      },
      loginHeadline: {
        type: String,
        default: ''
      },
      loginDescription: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      codePlaceholder: {
        type: String,
        default: ''
      },
      userNamePlaceholder: {
        type: String,
        default: ''
      },
      passwordPlaceholder: {
        type: String,
        default: ''
      },
      image: {
        type: Object,
        default: {}
      },
      fontColor: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        forgotPassword: false,
        forgotSubmit: false,
        loader: false,
        activeTab: null
      }
    },

    computed: {
      ...mapState({
        websiteSettings: (state) => state.app.websiteSettings,
      }),

      displayForm() {
        if(!this.forgotPassword) return true;
      },

      headlineText() {
        if(!this.forgotPassword) {
          if(this.activeTab === 1) {
            return this.loginHeadline !== '' ? this.loginHeadline : this.$t('global.login_form.loginHeadline');
          }
          return this.codeHeadline !== '' ? this.codeHeadline : this.$t('global.login_form.codeHeadline');
        };
        if(this.forgotPassword) return this.$t('global.forgot_password_form.title');
      },

      descriptionText() {
        if(!this.forgotPassword) {
          if(this.activeTab === 1) {
            return this.loginDescription !== '' ? this.loginDescription : this.$t('global.login_form.loginDescription');
          }
          return this.codeDescription !== '' ? this.codeDescription : this.$t('global.login_form.codeDescription');
        };
        if(this.forgotPassword) return this.$t('global.forgot_password_form.description');
      }
    },

    methods: {
      setLoader() {
        this.loader = true;
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      },
      setActiveTab(value) {
        this.activeTab = value;
      },
      handlePage() {
        this.forgotPassword = true;
        this.setLoader()
      },
      handleSubmit() {
        this.forgotSubmit = true;
        this.setLoader()
      },
      backToLogin() {
        this.forgotSubmit = false;
        this.forgotPassword = false;
        this.setLoader();
      },
      handleReturn() {
        this.forgotSubmit = false;
        this.forgotPassword = true;
        this.setLoader();
      }
    },
    mounted() {
      this.setLoader();
      this.setActiveTab();
    }
  };
</script>

<style lang="scss" scoped>
  .login {
    position: relative;
    overflow: hidden;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-down(xs) {
      padding: 1rem 0;
    }

    &__background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__image {
      position: absolute;
      bottom: -3rem;
      right: -3rem;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .container-xl {
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }
  .login__wrap {
    color: white;

    @include media-breakpoint-down(md) {
      max-width: 500px;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }

    .card-title {
      font-family: $font-semibold;
      margin-bottom: 2rem;
    }

    .card-subtitle {
      margin-bottom: 1rem;
    }

    & .login__form {
      border-radius: 10px;
      z-index: 2;
      background: var(--primary);
      padding: 2rem;
      width: 100%;

      min-height: 500px;
      display: flex;
      justify-content: center;
      
      @include media-breakpoint-up(md) {
        align-items: center;
      }

      @media screen and (max-width: 410px) {
        padding: 1rem;
      }

      & .card {
        border-radius: $border-radius;

        &-body {
          @media screen and (max-width: 410px) {
            padding: 1rem;
          }
        }
      }
    }

    .loading {
      display: flex;
      justify-content: center;

      div {
        width: 1rem;
        height: 1rem;
        margin: 2rem 0.3rem;
        background: var(--light);
        border-radius: 50%;
        animation: 0.9s bounce infinite alternate;

        &:nth-child(2) {
          animation-delay: 0.3s;
        }

        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }

    @keyframes bounce {
      to {
        opacity: 0.3;
        transform: translate3d(0, -1rem, 0);
      }
    }
  }
</style>
