<template>
  <WelcomeStage
    :bgColor="toHexColorCode(parseInt(bgColor))"
    :image="newImage"
    :headline="headline"
    :description="description"
    :buttonType="button.type.field"
    :buttonText="button.label"
    :buttonIcon="iconButton(button)"
    :buttonLink="getButtonHref(button)"
    :productsHeadline="productsHeadline"
    :showProducts="products"
    :fontColor="fontColor"
    :buttonColorVariant="button.buttonColorVariant"
    />
</template>

<script>
import WelcomeStage from '@/components/WelcomeStage/WelcomeStage.vue'
import { mgnlToJson } from '../helpers/utils.js';
export default {
  data() {
    return {
      newImage: {}
    };
  },
  components: { WelcomeStage },
  props: {
    bgColor: {
      type: [String, Number],
      default: '-814848'
    },
    image: {
      type: Object,
      default: {}
    },
    headline: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    button: {
      type: Object,
      default: {}
    },
    showProducts: {
      type: Object,
      default: {}
    },
    productsHeadline: {
      type: String,
      default: ''
    },
    fontColor: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.newImage = {
      link: process.env.VUE_APP_MGNL_HOST + '/dam' + this.image['@path'],
      name: this.image['@name']
    };
  },

  computed: {
    products() {
      if (this.showProducts.field === 'custom-product-list') {
        const productIDs = [];
        const mgnlProducts = mgnlToJson(this.showProducts.products);
        mgnlProducts.forEach(product => {
          productIDs.push(product.id);
        });
        return productIDs;
      } else if (this.showProducts.field === 'highest-discount') {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    getButtonHref(button) {
      return button.type.field === 'url' ? button.type.url : (button.type.field === 'link' ? this.removeSiteName(button.type.link) : null);
    },
    // Example of link /ace/product-overview. With this method we removing '/ace'.
    removeSiteName(link) {
      if (link) {
        const siteName = link.substr(0, link.lastIndexOf("/"));
        return link.replace(siteName, '');
      }
    },
    iconButton(button) {
      return button?.btnIcon ? button.btnIcon[0] == 'showIcon' : false;
    },
    toHexColorCode(negDecimal) {
      const colorCodeInDec = 16777216 + negDecimal;
      const colorCodeInHex = colorCodeInDec.toString(16).padStart(6, '0');
      return `#${colorCodeInHex}`;
    }
  }
  
};
</script>
