<template>
  <div class="basket-itemgroup">
    <BasketItem v-for="(product, index) in products" :key="index" :product="product" :is-editable="isEditable" />
  </div>
</template>

<script>
  import BasketItem from './BasketItem.vue';

  export default {
    components: {
      BasketItem
    },
    props: {
      products: {
        type: Array,
        default: null
      },
      isEditable: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .basket-itemgroup {
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    div:last-child {
      border: none;
    }
  }
</style>
