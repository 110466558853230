const { routeHandler } = require("@/helpers/utils");

module.exports = {
  computed: {
    isPunchout() {
      return this.$store.getters['punchout/isPunchout'];
    },
    isHandshake() {
      return this.$store.state.app.isHandshake;
    },
    websiteSettings() {
      return this.$store.state.app.websiteSettings;
    }
  },
  methods: {
    async logout() {
      if (this.isPunchout) {
        const logoutPassed = await this.$store.dispatch('punchout/checkout', true);
        if(!logoutPassed) {
          this.$store.commit('app/SET_TOAST', {
            message: this.$t('view.error.general'),
            duration: 7
          });
        }
        return;
      }
      
      this.$store.dispatch('app/loadingAnimation', true);

      this.$store.dispatch('etracker/etCommerceRemoveAll');

      setTimeout(async () => {
        await this.$store.dispatch('user/logOut');
        await this.$api.deleteUserSession();
        if (this.isHandshake && !this.websiteSettings?.wob_account_login && !this.websiteSettings?.wob_coupon_login) {
          await this.$router.push({ path: routeHandler('/handshake-logout') });
          this.$store.commit('app/SET_HEADER_LOGGEDIN', false);
          this.$store.dispatch('app/loadingAnimation', false);
        } else {
          window.location.href = routeHandler('/');
        }
      }, 0);  
    }
  }
}