// =======================================================================
// State
// =======================================================================
const state = () => ({
  setNewPassword: false,
  loggedIn: false,
  userSessionDuration: null,
  isUserSessionExpired: false
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_LOGGED_IN(state, payload) {
    state.loggedIn = payload;
  },

  SET_SESSION_DURATION(state, payload) {
    state.userSessionDuration = payload;
  },

  SET_SESSION_EXPIRED(state, payload) {
    state.isUserSessionExpired = payload;
  },

  SET_NEW_PASSWORD_SESSION(state, payload) {
    state.setNewPassword = payload;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  /**
   * @param {*} context
   */
  newPassword(context) {
    context.commit('SET_NEW_PASSWORD_SESSION', true);
  }, 
  /**
   * @param {*} context
   */
  async handShakeLogin(context, handshakeId, isValidCoupon) {
    const loginData = await this.$api.handShakeLogin(handshakeId, isValidCoupon);
    await context.commit('SET_LOGGED_IN', true);
    return loginData;
  },
    /**
   * @param {*} context
   */
  async handShakeGenerator(context, url) {
    return await this.$api.handShakeGenerator(url);
  },
  /**
   * @param {*} context
   */
  logIn(context) {
    context.commit('SET_LOGGED_IN', true);
  },
  /**
   * @param {*} context
   */
  logOut(context) {
    context.commit('SET_LOGGED_IN', false);
  },
  /**
   * @param {*} context
   * @returns {Boolean}
   */
  checkIfUserIsLoggedIn() {
    return this.$api.checkLoginState().then((responseData) => {
      return responseData.logged_in;
    });
  },

  /**
   * Stores how long the Usersession is still valid (s)
   * Logs out the User and sets Flag if Session is not valid anymore
   * @param {object} - Destructed Context Object
   */
  async checkUserSessionDuration(context) {
    if (!context.state.isUserSessionExpired && context.state.loggedIn) {
      const duration = await this.$api.getSessionDuration();
      context.commit('SET_SESSION_DURATION', duration);
      if (duration < 0) {
        context.dispatch('etracker/etCommerceRemoveAll');
        setTimeout(() => {
          context.commit('SET_LOGGED_IN', false);
          context.commit('SET_SESSION_EXPIRED', true);
        }, 0);
      }
    }
  },
  /**
   * Extend User Session by configured Time
   */ extendUserSession({ state }) {
    if (state.loggedIn) {
      this.$api.heartbeat();
    }
  },

  async newsletterSubscribe(_context, data) {
    const subData = await this.$api.newsletterSubscribe(data);
    return subData;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
