<template>
  <div class="list-group">
    <div v-for="(listItem, index) in list" :key="index" class="list-group-item">
      <div class="list-group-item--title">
        <span>
          {{ listItem.title }}
        </span>
        <span>
          <router-link :to="$routeHandler(listItem.linkTo)"> {{ $t('global.edit') }} </router-link>
        </span>
      </div>
      <slot> </slot>
      <div class="list-group-item--body">
        <slot>
          <div>{{ listItem.firm_name }}</div>
        </slot>
        <slot>
          <div>{{ listItem.firm_department }}</div>
        </slot>
        <div>{{ listItem.salutation }} {{ listItem.first_name }} {{ listItem.last_name }}</div>
        <slot>
          <div>{{ listItem.address }}</div>
        </slot>
        <slot>
          <div>{{ listItem.address_2 }}</div>
        </slot>
        <slot>
          <div>{{ listItem.zip_code }} {{ listItem.city }}</div>
        </slot>
        <slot>
          <div>{{ listItem.phone_number }}</div>
        </slot>
        <div>{{ listItem.email }}</div>
      </div>
      <div class="list-group-item--body">
        <slot>
          {{ listItem.payment_methods }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-group {
    margin: 2rem 0;
    border-radius: 0.5rem;
  }
  .list-group-item {
    padding: 1.5rem;
    @include media-breakpoint-down(xs) {
      padding: 1rem;
    }

    &--title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0.5rem;
      font-family: $font-semibold;
      @extend %font-h6;
    }
    &--body {
      @extend %font-md;
    }
  }
</style>
