export default [
  {
    id: 0,
    type: 'OFFLINE',
    title: 'view.product.shipping.post.title', 
    badge: 'view.product.shipping.post.badge'
  },
  {
    id: 1,
    type: 'ONLINE',
    title: 'view.product.shipping.mail.title', 
    badge: 'view.product.shipping.mail.badge'
  },
  {
    id: 2,
    type: 'SMS',
    title: 'view.product.shipping.sms.title', 
    badge: 'view.product.shipping.sms.badge'
  },
  {
    id: 3,
    type: 'CSV_CODES',
    title: 'view.product.shipping.csv.title', 
    badge: 'view.product.shipping.csv.badge'
  },
  {
    id: 4,
    type: 'CSV_ECARDS',
    title: 'view.product.shipping.csv.title', 
    badge: 'view.product.shipping.csv.badge'
  },
  {
    id: 7,
    type: 'DOWNLOAD_PDF',
    title: 'view.product.shipping.pdf.title', 
    badge: 'view.product.shipping.pdf.badge'
  },
  {
    id: 8,
    type: 'DOWNLOAD_LIST',
    title: 'view.product.shipping.csv.title', 
    badge: 'view.product.shipping.csv.badge'
  },
  {
    id: 9,
    type: 'BULK_VIA_ECARD',
    title: 'view.product.shipping.bulk.title', 
    badge: 'view.product.shipping.bulk.badge'
  }
]