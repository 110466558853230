<template>
  <div class="search__box">
    <div class="container-xl">
      <div class="row no-gutters">
        <div class="col-md-8 offset-md-2 col-10">
          <input
            type="search"
            maxlength="30"
            class="search__input"
            id="search-input"
            :placeholder="$t('view.search.placeholder')"
            @input="debounceInput"
          />
          <div v-if="hasNoSearchResults" class="text-center mt-5">
            <p>{{ $t('view.search.no_results.headline', { search: searchInput }) }}</p>
          </div>
        </div>
        <div class="col-md-2 col-2 text-right">
          <button type="button" class="btn btn-primary search__close my-2" @click="closeSearch">
            <b-icon icon="x" variant="white" font-scale="2"></b-icon>
          </button>
        </div>
      </div>
      <DefaultDivider v-if="searchResults.length > 0" />
      <div v-if="searchResults.length > 0" class="search-results">
        <div v-for="product in resultsPreview" :key="product.number" @click="closeSearch">
          <ProductItem :product="product" />
        </div>
      </div>
      <div v-if="searchResults.length > previewLimit" class="col-12 search-results__more mt-3 text-center">
        <DefaultButton variant="primary" :button-text="$t('view.search.all_button_text')" data-test="show-all-results" @click="showAllResults" />
      </div>
    </div>
    <ProductSlider
      v-if="hasNoSearchResults"
      :isSearchBar="true"
      :headline="$t('global.product_slider.headline')"
      :productLinkPath="'/product-overview'"
      :productLinkText="$t('global.product_slider.headline')"
      :productLinkQuery="{ recommended: true }"
      :products="allProducts"
      :close-overlay="closeSearch"
      bgColor="white"
    />
  </div>
</template>

<script>
  import Fuse from 'fuse.js';
  import DefaultDivider from 'building-blocks/components/DefaultElements/DefaultDivider.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import ProductItem from './ProductItem.vue';
  import ProductSlider from './ProductSlider.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    name: 'App',
    components: {
      DefaultDivider,
      DefaultButton,
      ProductItem,
      ProductSlider,
      BIcon
    },
    data() {
      return {
        searchInput: '',
        searchResults: [],
        previewLimit: 8,
        debounce: null,
        searchOptions: {
          keys: [
            {
              name: 'name',
              weight: 4
            },
            {
              name: 'description',
              weight: 1
            }
          ],
          minMatchCharLength: 2,
          includeScore: true,
          includeMatches: true,
          threshold: 0.3,
          ignoreLocation: true
        }
      };
    },
    computed: {
      allProducts() {
        return this.$store.state.product.catalogProducts;
      },
      resultsPreview() {
        return this.searchResults.length > this.previewLimit ? this.searchResults.slice(0, this.previewLimit) : this.searchResults;
      },
      emptySearchInput() {
        return this.searchInput.trim() == '';
      },
      hasNoSearchResults() {
        return this.searchResults.length === 0 && !this.emptySearchInput;
      }
    },
    mounted() {
      document.getElementById('search-input').focus();
    },
    methods: {
      debounceInput(event) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.searchInput = event.target.value;
          this.handleResults();
          const cc_attributes = { 
            'etcc_med_onsite': 'Internal search', 
            'etcc_cmp_onsite': this.emptySearchInput ? 'Without result' : 'With result', 
            'etcc_cu': 'onsite', 
            'etcc_st_onsite': this.searchInput
          };
          this.$store.dispatch('etracker/et_eC_Wrapper', {et_et: this.$store.state.etracker.etrackerId, cc_attributes: cc_attributes});
        }, 500);
      },

      handleResults() {
        if (this.emptySearchInput) {
          this.searchResults = [];
        } else {
          const arr = new Fuse(this.allProducts, this.searchOptions).search(this.searchInput);
          this.searchResults = arr.map((el) => el.item);
        }
      },
      closeSearch() {
        this.searchResults = [];
        this.searchInput = '';
        this.$emit('close');
      },
      showAllResults() {
        this.$store.commit('filter/SET_LOADED_PRODUCTS', this.searchResults);
        this.$store.commit('filter/SET_SORTOPTION', 'default');
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: { search: this.searchInput } });
        this.closeSearch();
      }
    }
  };
</script>

<style lang="scss" scoped>
  // maybe global for all input type search?
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search {
    &__box {
      padding: 2rem 0;
      background: var(--light);
      box-shadow: $box-shadow-lg;
      transition: transform 0.3s ease-out;
      @include media-breakpoint-up(lg) {
        padding: 3rem 0;
      }
      input {
        padding: $btn-padding-x $btn-padding-y-lg;
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 5rem;
        -webkit-box-shadow: 0px 0px 5px 1px var(--primary);
        -moz-box-shadow: 0px 0px 5px 1px var(--primary);
        box-shadow: 0px 0px 5px 1px var(--primary);

        &:focus {
          outline: none;
        }
      }
      .product-swiper {
        background: var(--light);
        padding-top: 0;
        &:last-child {
          margin-top: 0;
        }
      }
    }
    &__close {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 50%;
      .b-icon {
        font-size: 200% !important;
      }
    }
  }

  .search-results {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__empty-message {
      padding: 4rem;
    }
  }

  .search-results > div {
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex-basis: calc(50% - 0.5rem);
    min-width: 0;
    @include media-breakpoint-down(xs) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(sm) {
      flex-basis: calc(100% / 3 - 2rem / 3);
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 2rem;
      flex-basis: calc(25% - 1.5rem);
      &:nth-child(3n) {
        margin-right: 2rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .search-results > div {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
</style>
