<template>
  <div>
    <div v-if="visible" data-show="true" role="dialog" class="modal fade show" tabindex="-1">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <DefaultHeadline is-headline heading-size="h5" class="title" :headline-text="title" />
          </div>
          <div class="modal-body">
            <p>{{ text }}</p>
          </div>
          <div class="modal-footer">
            <DefaultButton v-if="button" :button-text="buttonText" variant="primary" @click="handleButtonClick" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="visible" class="modal-backdrop" @click="clickBackdrop" @scroll="scrollBackdrop"></div>
  </div>
</template>

<script>
  import DefaultHeadline from './DefaultHeadline.vue';
  import DefaultButton from './DefaultButton.vue';

  export default {
    name: 'Modal',
    components: {
      DefaultHeadline,
      DefaultButton
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      button: {
        type: Boolean,
        default: false
      },
      buttonText: {
        type: String,
        default: ''
      }
    },
    mounted() {
      if (this.visible) {
        this.disableBackgroundScrolling();
      }
    },
    destroyed() {
      this.enableBackgroundScrolling();
    },
    methods: {
      handleButtonClick() {
        this.enableBackgroundScrolling();
        this.$emit('button-click');
      },
      enableBackgroundScrolling() {
        document.body.classList.remove('stop-scrolling');
      },
      disableBackgroundScrolling() {
        document.body.classList.add('stop-scrolling');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    display: block;
  }
  .modal-backdrop {
    background-color: rgba($dark, 0.5);
  }
  .title {
    font-family: $font-semibold;
  }
</style>
