<template>
  <PromotionBanner :promotions="mgnlPromotions" />
</template>

<script>
  import PromotionBanner from '@/components/Promotion/PromotionBanner.vue';
  import { mapState } from 'vuex';
  import mgnlConfig from '../config/mgnl';

  export default {
    components: { PromotionBanner },
    data() {
      return {
        promotionsUrl: mgnlConfig.promotions,
        promotions: []
      };
    },

    computed: {
      ...mapState({
        indexPage: (state) => state.pages.indexPage,
        currentPage: (state) => state.pages.currentPage
      }),

      websitePromotionQuery() {
        return `websites=${this.indexPage['@id']}`;
      },
      pagePromotionQuery() {
        return `pages=${this.currentPage['@id']}`;
      },
      mgnlPromotions() {
        if (this.promotions == null) return [];

        const promos = this.promotions.map((p) => this.createPromotionObject(p));
        return this.sortByWeight(promos);
      }
    },
    async mounted() {
      //Fetch Website Promotions and filter for active ones
      const websitePromotionsData = await this.fetchPromotions(this.websitePromotionQuery);
      const websitePromotions = websitePromotionsData.length === 0 ? [] : this.filterActivePromotionsByDate(websitePromotionsData.results);

      //Fetch Page Promotions and filter for active ones
      const pagePromotionsData = await this.fetchPromotions(this.pagePromotionQuery);
      const pagePromotions = pagePromotionsData.length === 0 ? [] : this.filterActivePromotionsByDate(pagePromotionsData.results);

      // Merge Website and Page Promotions and filter out Duplicates
      const websitePromotionIDs = new Set(websitePromotions.map((obj) => obj['@id']));
      this.promotions = [...websitePromotions, ...pagePromotions.filter((p) => !websitePromotionIDs.has(p['@id']))];
    },

    methods: {
      async fetchPromotions(query) {
        try {
          const response = await fetch(`${this.promotionsUrl}?${query}`, { options: { method: 'GET' } });
          return response.json();
        } catch (err) {
          console.error(err);
          return [];
        }
      },

      createPromotionObject(promotion) {
        return {
          name: promotion.name,
          headlineText: promotion.headline_text,
          textColor: this.toHexColorCode(parseInt(promotion.text_color)),
          buttonText: promotion.button_text,
          buttonVariant: promotion.button_variant,
          productImageUrl: this.createImageUrl(promotion.product_image),
          bannerImageUrl: this.createImageUrl(promotion.banner_image),
          backgroundColor: this.toHexColorCode(parseInt(promotion.background_color)),
          linkTo: this.createLink(promotion),
          linkToAce: this.checkForLinkToAce(promotion),
          externalLink: this.checkForExternalLink(promotion),
          weight: promotion.weight ? parseInt(promotion.weight) : 0
        };
      },
      sortByWeight(promotions) {
        return promotions.slice().sort((a, b) => b.weight - a.weight);
      },

      toHexColorCode(negDecimal) {
        const colorCodeInDec = 16777216 + negDecimal;
        const colorCodeInHex = colorCodeInDec.toString(16).padStart(6, '0');
        return `#${colorCodeInHex}`;
      },

      isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
      },

      createImageUrl(imageObj) {
        if (imageObj == null) return '';
        const imagePath = imageObj['@path'];
        return imagePath ? process.env.VUE_APP_MGNL_HOST + '/dam' + imagePath : '';
      },

      createLink(promotion) {
        if (this.$store.state.app.mgnlMode) return '';
        if (promotion.link_type === 'none' || promotion.link_type == null) return '';

        const linkObj = promotion.link_type;
        switch (linkObj.field) {
          case 'category':
            return this.$routeHandler(`/product-overview?category=${linkObj.categoryId.trim()}`);
          case 'product':
            return this.$routeHandler(`/product-details/${linkObj.productId.trim()}`);
          case 'external':
            const trimmedUrl = linkObj.externalUrl.trim()
            return  trimmedUrl !== '' ? `https://${trimmedUrl}` : ''
          case 'internal':
            const siteName = linkObj.internalLink.substr(1, linkObj.internalLink.substring(1).indexOf("/") + 1);
            return  linkObj.internalLink.replace(siteName, '');
          case 'allproducts':
            return `/product-overview`;
          default:
            return '';
        }
      },

      checkForLinkToAce(promotion) {
        return this.isObject(promotion.link_type) && promotion.link_type.field === 'linkToAce'
      },
      
      checkForExternalLink(promotion) {
        return this.isObject(promotion.link_type) && promotion.link_type.field === 'external' && promotion.link_type.externalUrl.trim() !== ''
      },

      filterActivePromotionsByDate(promotions) {
        const currentDateInMs = Date.now();

        return promotions.filter((p) => {
          // Adding timezone Offset to the Magnolia Data to make sure we get the correct Date
          const startDateInMs = p.start_date ? new Date(p.start_date).getTime() : currentDateInMs;
          const endDateInMs = p.end_date ? new Date(p.end_date).getTime() : currentDateInMs;

          // Set Start/End Date to the Start and the End of the Day
          const modifiedStartDateInMs = new Date(startDateInMs).setHours(0, 0, 0, 0);
          const modifiedEndDateInMs = new Date(endDateInMs).setHours(23, 59, 59, 999);

          return currentDateInMs >= modifiedStartDateInMs && currentDateInMs <= modifiedEndDateInMs;
        });
      }
    }
  };
</script>
