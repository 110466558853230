import mgnlConfig from '../config/mgnl';

const defaultLang = 'de';

export function getLanguages() {
  return ['de', 'en', 'fr', 'pl', 'nl', 'es', 'it'];
}

export function getCurrentLanguage(url) {
  const languages = getLanguages();
  const path = url || window.location.pathname;
  const langMatches = path.match(`\/(${languages.join('|')})(|_[A-Z]{2})\/`);

  if (langMatches && languages.includes(langMatches[1])) {
    return langMatches[1];
  }

  return defaultLang;
}

export function removeCurrentLanguage(string, currentLanguage = getCurrentLanguage()) {
  return string.replace(new RegExp('/' + currentLanguage + '($|/)'), '/');
}

export function getVersion(path) {
  return new URLSearchParams(path).get('mgnlVersion');
}

// **********************************
// Used for Page Loader MGNL
// **********************************

export const getMgnlPath = (path, nodeName, lang) => {
  const currentLanguage = lang || getCurrentLanguage();
  let mgnlPath = path || window.location.pathname;
  
  mgnlPath = nodeName + mgnlPath.replace(new RegExp('(.*' + nodeName + '|.html)', 'g'), '');
  
  mgnlPath = removeCurrentLanguage(mgnlPath, currentLanguage);
  mgnlPath += '?lang=' + currentLanguage;
  
  return mgnlPath;
};

export const getContentUrl = (path) => {
  const version = getVersion(window.location.href);

  if (version) {
    path += path.indexOf('?') > -1 ? '&version=' + version : '?version=' + version;
  }

  return `${version ? mgnlConfig.pages_preview : mgnlConfig.pages}${path}`;
};

// Load nav routes from Magnolia API
export const loadNavigation = async (nodeName, lang) => {
  let content;

  try {
    const contentResponse = await fetch(mgnlConfig.nav + getMgnlPath('/', nodeName, lang)).then(data => data)
    .catch((error) => {
      console.log("CMS is down!!"); 
      return false;
    });
    content = await contentResponse.json();
  } catch (error) {
    return false;
  }

  return content;
};

export const loadPageContent = async (path, nodeName, lang) => {
  const mgnlPath = getMgnlPath(path, nodeName, lang);
  const contentUrl = getContentUrl(mgnlPath);
  let content;

  try {
    const contentResponse = await fetch(contentUrl);
    content = await contentResponse.json();
  } catch (error) {
    return false;
  }

  const templateId = content['mgnl:template'];
  if (!templateId) {
    return false;
  }

  // Get Template Annotations
  const templateEndpointUrl = mgnlConfig.templates + mgnlPath;

  // Handle CORS exceptions
  try {
    const templateResponse = await fetch(templateEndpointUrl);
    const templateAnnotations = await templateResponse.json();
    content.templateAnnotations = templateAnnotations;
  } catch (error) {
    return false;
  }

  return content;
};