<template>
  <div>
    <div v-for="(elementGroup, index) in formElements" :key="index" class="row">
      <div v-for="element in elementGroup" :key="element.name" :class="[element.md ? `col-md-${element.md}` : null]">
        <DefaultInputSelect
          v-if="element.type !== false && element.type === 'form-select'"
          v-model="order[element.name]"
          :label="$t(`form.field.${element.name}.label`)"
          :name="`${element.name}`"
          :options="element.selectOptions"
          :state="!validations[element.name].valid || serverErrors[element.name] ? false : null"
          :error-text="$t(`form.error.${element.name}`)"
          :readonly="element.readonly"
          :disabled="element.readonly || element.selectOptions?.length === 1"
          :data-test="`address-input-${element.name}`"
          :placeholder="element.placeholder"
          :hintText="hintText"
          :hintLocation="hintLocation"
          @changed="element.handleChange"
          @blured="element.handleChange"
        />
        <DefaultInput
          v-else-if="element.type !== false && element.type === 'form-input'"
          v-model.trim="order[element.name]"
          :placeholder="$t(`form.field.${element.name}.placeholder`)"
          :label="$t(`form.field.${element.name}.label`)"
          :state="!validations[element.name].valid || serverErrors[element.name] ? false : null"
          :error-text="$t(`form.error.${element.name}`)"
          :readonly="element.readonly"
          :data-test="`address-input-${element.name}`"
          @changed="element.handleChange"
          @inputed="element.handleInput"
          @blured="element.handleChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import salutations from '@/config/salutations.js';
  import DefaultInputSelect from 'building-blocks/components/DefaultElements/DefaultInputSelect.vue';
  import DefaultInput from 'building-blocks/components/DefaultElements/DefaultInput.vue';
  import { mapState } from 'vuex';

  export default {
    components: {
      DefaultInputSelect,
      DefaultInput
    },
    props: {
      // Contains all necessary fields. Needs to be an Observer object, i.e. Vue object
      order: {
        type: Object,
        default: () => ({})
      },
      // Contains all fields with a boolean variable which indicates whether the field can be changed or not
      readonly: {
        type: Object,
        default: () => ({})
      },
      // Contains all fields with a boolean variable which indicates whether the field is erroneous, i.e. rejected by the api
      serverErrors: {
        type: Object,
        default: () => ({})
      },
      // Indicates if this is a short form
      // e.g. voucher codes are sent by email and need no complete address.
      useShortForm: {
        type: Boolean,
        default: false
      },
      allCountries: {
        type: Boolean,
        default: false
      },
      translateBlocker: {
        type: Boolean
      }
    },
    data() {
      return {
        // These are the fields that are displayed in short forms. All others are excluded.
        shortFormFields: ['salutation', 'firm_name', 'first_name', 'last_name', 'email', 'email_repeat'],
        calledChangeOnce: [],
        validations: null, // Gets filled in created
        salutations: salutations,
        hintLocation: '',
        hintText: ''
      };
    },
    // Uses vuelidate to automatically check the options for each field
    computed: {
      ...mapState({
        positions: (state) => state.basket.positions,
        websiteSettings: (state) => state.app.websiteSettings,
      }),
      countries() {
        const allCountries = [
          { value: 'DE', text: this.$t('form.field.country_id.germany') },
          { value: 'AT', text: this.$t('form.field.country_id.austria') },
          { value: 'CH', text: this.$t('form.field.country_id.swiss') },
          { value: 'FR', text: this.$t('form.field.country_id.france') },
          { value: 'SE', text: this.$t('form.field.country_id.sweden') },
          { value: 'BE', text: this.$t('form.field.country_id.belgium') },
          { value: 'GB', text: this.$t('form.field.country_id.britain') },
          { value: 'NL', text: this.$t('form.field.country_id.netherlands') },
          { value: 'ES', text: this.$t('form.field.country_id.spain') },
          { value: 'IT', text: this.$t('form.field.country_id.italy') },
          { value: 'PL', text: this.$t('form.field.country_id.poland') }
        ];
        const allowedCountryList = this.websiteSettings.shipping_country_list?.split(',') || ['DE'];
        const reducedCountries = allCountries.filter(el =>  allowedCountryList.includes(el.value?.toUpperCase()));
        if(!this.allCountries){
          return reducedCountries;
        } else {
          return allCountries;
        }
      },
      /**
       * Creates the list of form elements that are iterated through above and displayed per row and column.
       * Uses the helper function extendDefaultForm.
       */
      formElements() {
        let formElements = [
          [this.extendDefaultForm('salutation', { md: 6, type: 'form-select', selectOptions: this.getSalutations(this.salutations), placeholder: this.$t('form.field.salutation.placeholder') })],
          [this.extendDefaultForm('first_name', { md: 6, noBreak: true }), this.extendDefaultForm('last_name', { md: 6 })],
          [this.extendDefaultForm('firm_name', { md: 6 })],
          [this.extendDefaultForm('address')],
          [this.extendDefaultForm('address_2')],
          [this.extendDefaultForm('zip_code', { md: 4 }), this.extendDefaultForm('city', { md: 8 })],
          [this.extendDefaultForm('country_id', { type: 'form-select', selectOptions: this.countries, placeholder: this.$t('form.field.country_id.placeholder') })],
          [this.extendDefaultForm('email')],
          [this.extendDefaultForm('email_repeat', { readonly: this.readonly.email })]
        ];        
        formElements = formElements.filter((els) => els.filter((el) => el !== false).length > 0);
        return formElements;
      }
    },
    created() {
      let validations = {
        salutation: { fns: [[this.freeField]], valid: true },
        first_name: { fns: [[this.required]], valid: true },
        last_name: { fns: [[this.required]], valid: true },
        firm_name: { fns: [[this.maxLength, 50]], valid: true },
        address: { fns: [[this.required]], valid: true },
        address_2: { fns: [[this.maxLength, 50]], valid: true },
        zip_code: { fns: [[this.required], [this.numeric], [this.minLength, 4], [this.maxLength, 5]], valid: true },
        city: { fns: [[this.required]], valid: true },
        country_id: { fns: [[this.required]], valid: true },
        email: { fns: [[this.required], [this.email], [this.minLength, 6], [this.maxLength, 150]], valid: true },
        email_repeat: { fns: [[this.required], [this.email], [this.sameAs, 'email']], valid: true }
      };
      // When using the short form, all but the fields from this.shortFormFields must be excluded.
      // Otherwise, vuelidate will try and check them and subsequently fail.
      const finalValidations = { ...validations };
      if (this.useShortForm) {
        Object.keys(validations).forEach((key) => {
          if (!this.shortFormFields.includes(key)) {
            delete finalValidations[key];
          }
        });
      }
      this.validations = finalValidations;
    },
    methods: {
      // Translate salutations if needed depending on the props and emit that info back in parent component
      getSalutations(salutations) {
        if (!this.translateBlocker) {
          salutations.forEach((el) => {
            el.text = this.$t(el.text);
          });
        }
        this.$emit('translated');
        return salutations;
      },
      freeField() {
        return true;
      },
      required(value) {
        return !!value;
      },
      minLength(value, min) {
        return !this.required(value) || value.length >= min;
      },
      maxLength(value, max) {
        return !this.required(value) || value.length <= max;
      },
      numeric(value) {
        return value && value.match && value.match(/^\d*(\.\d+)?$/);
      },
      phoneNumber(value) {
        if (value === null || value === '') {
          return true;
        } else {
          return value.match(/^(\+|0([/ -()]*0)?)[/ -()]*[1-9][\d / \-()]+$/g);
        }
      },
      sameAs(value, fieldName) {
        return value === this.order[fieldName];
      },
      email(value) {
        return (
          value &&
          value.match &&
          value.match(
            // eslint-disable-next-line no-control-regex
            /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])$/i
          )
        );
      },
      validateField([fieldName, value]) {
        const v = this.validations[fieldName];
        // Short form, we filtered these before
        if (!v) {
          return;
        }
        v.valid = v.fns.reduce((cur, [method, args]) => {
          return cur && method(value, args);
        }, true);
      },
      areAllFieldsValid() {
        // Revalidate all fields
        Object.entries(this.order).map(this.validateField);
        // Check if any are invalid
        const valid = Object.entries(this.validations).reduce((cur, [key, { valid }]) => cur && valid, true);
        return valid;
      },
      /**
       * Only used as a shortcut function to create the formElements structure
       * as most inputs share the same properties when rendered.
       * Return false if short form should be used and the requested field is not a short form field.
       * @param {String} name
       * @param {Object} extension Properties that overwrite the default
       * @return Object | false
       */
      extendDefaultForm(name, extension) {
        if (this.useShortForm && !this.shortFormFields.includes(name)) {
          return false;
        }
        return {
          ...{
            name,
            type: 'form-input',
            readonly: this.readonly[name],
            noBreak: false,
            md: 12,
            // Do not show the user the error message immediately upon starting typing in a field but
            // only after he clicked outside of it once.
            handleChange: (value) => {
              this.validateField([name, value]);
              this.calledChangeOnce[name] = true;
            },
            handleInput: (value) => {
              if (this.calledChangeOnce[name]) {
                this.validateField([name, value]);
              }
            }
          },
          ...extension
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .server-error {
    font-size: 80%;
    color: #dc3545;
    display: block;
    background: white;
    margin-top: -1rem;
    z-index: $z-index-highest;
    height: 1rem;
    position: absolute;
  }
</style>
