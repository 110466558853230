import sortingOptions from '@/config/sortingOptions.js';
// =======================================================================
// State
// =======================================================================
const state = () => ({
  catalogProducts: [],
  catalog: [],
  sortingOptions: sortingOptions
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  /**
   * @param {*} state
   * @param {*} products
   */
  ADD_CATALOG_PRODUCTS(state, products) {
    if (!state.catalogProducts.length) {
      state.catalogProducts = products;
    } else {
      state.catalogProducts = [...state.catalogProducts, ...products];
      const ids = state.catalogProducts.map(el => el.number);
      const updatedCatalogProducts = state.catalogProducts.filter(({number}, index) => !ids.includes(number, index + 1));
      state.catalogProducts = updatedCatalogProducts;
    }
  },
  /**
   * @param {*} state
   * @param {String} catalogId
   */
  SET_CATALOG(state, catalog) {
    state.catalog.push(catalog);
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  /**
   * @param {*} context
   * @param {*} products
   */
  async addCatalogProducts(context, products) {
    if (!products) return;

    const productsWithVoucherDiscount = await context.dispatch('calculateVoucherDiscountPrice', products);
    context.commit('ADD_CATALOG_PRODUCTS', productsWithVoucherDiscount);
  },
  /**
   * @param {*} context
   * @param {String} catalogId
   */
  addCatalog(context, { catalogId, loaded }) {
    if (catalogId && !(context.state.catalog.some(cat => cat.id === catalogId))) {
      const catalog = { id: catalogId, loaded: loaded };
      context.commit('SET_CATALOG', catalog);
    }
  },
  /**
   * Fetches only the catalog products that are not yet loaded.
   */
  async fetchProducts(context) {
    const shopUrl = context.rootState.app.shopUrl;

    for (const cat of context.state.catalog) {
      if (!cat.loaded) {
        await this.$api.getCatalogProducts({ catalogId: cat.id, shopUrl: shopUrl }).then((products) => {
          context.dispatch('addCatalogProducts', products);
          cat.loaded = true;
        });
      }
    }
  },

  /**
   * Returns a new Product Array with discounted Face-Values for Vouchers
   */
  calculateVoucherDiscountPrice(context, products) {
    const targetVoucher = 'TVO';
    const universalVoucher = 'UVO';

    return products.map((product) => {
      if ((product.discount !== null && product.type_group === targetVoucher) || product.type_group === universalVoucher) {
        const newVariations = product.variations.map((variation) => {
          const varDiscount = (variation.selling_price / 100) * product.discount;
          const varNetDiscount = (variation.net_selling_price / 100) * product.discount;
          const newVarPrice = variation.selling_price - varDiscount;
          const newVarNetPrice = variation.net_selling_price - varNetDiscount;
          const savings = variation.retail_value - newVarPrice;
          const netSavings = variation.net_retail_value - newVarNetPrice;
          return { ...variation, selling_price: newVarPrice, net_selling_price: newVarNetPrice, savings: savings, net_savings: netSavings};
        });
        return { ...product, variations: newVariations };
      } else {
        return product;
      }
    });
  }
};

// =======================================================================
// Getters
// =======================================================================
const getters = {
  getProduct: (state) => (productNumber) => {
    return state.catalogProducts.find((product) => product.number === productNumber);
  },
  /**
   * @param {*} state
   * @param {*} getters
   * @returns {function(String): String}
   */
  getProductTypReward: (state, getters) => (productNumber) => {
    const product = getters.getProduct(productNumber);
    if (product) {
      const type = product.type_group === 'PHY';
      return type;
    }
  },
  /**
   *
   * @param {*} state
   * @param {*} getters
   * @returns {function(String): String}
   */
  getCatalogImageUrl: (state, getters) => (productNumber) => {
    const imageServerUrl = 'https://images.cadooz.com/';
    const product = getters.getProduct(productNumber);
    if (product) {
      const imageUrl = product ? product.catalog_images[0] : null;
      return imageUrl ? `${imageServerUrl}${imageUrl}` : null;
    }
    return null;
  },
  /**
   *
   * @param {*} state
   * @param {*} getters
   * @returns {function(String): String}
   */
  getBasketImageUrl: (state, getters) => (product) => {
    const imageServerUrl = 'https://images.cadooz.com/';
    const imageUrl = product?.bakset_images ? product?.bakset_images[0] : null;
    return imageUrl ? `${imageServerUrl}${imageUrl}` : null;
  },
  getRecommendedProducts(state, getters) {
    const amountOfRecommendations = 20;
    const recommendationID = 2;
    const recommendedProducts = state.catalogProducts.filter((a) => a.sublists.includes(recommendationID));
    // If no recommended Products over sublists are available we just slice some of the whole Products
    if (Array.isArray(recommendedProducts) && !recommendedProducts.length) {
      return getters.isCatalogLoaded ? state.catalogProducts.slice(0, amountOfRecommendations) : [];
    }
    return recommendedProducts;
  },

  isCatalogLoaded: (state) => {
    return state.catalogProducts.length > 0;
  },

  getAllSublistIds(state) {
    const sublists = state.catalogProducts.map((product) => product.sublists);
    const flattenSublists = sublists.flat();
    const noDuplicates = [...new Set(flattenSublists)];
    return noDuplicates;
  },

  getAllProducts(state) {
    return state.catalogProducts;
  },

  getSortingOptions(state) {
    if(state.catalogProducts.some(product => product.discount !== null)) {
      return state.sortingOptions;
    } else {
      return state.sortingOptions.filter(option => option.value !== 'byDiscount');
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
