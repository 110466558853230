<template>
  <component
    :is="type === 'url' ? 'a' : 'button'"
    :type="type === 'url' ? null : type === 'submit' ? 'submit' : 'button'"
    :href="type === 'url' ? link : null"
    :target="type === 'url' ? '_blank' : null"
    :rel="type === 'url' ? 'noreferrer nofollow' : null"
    :disabled="disabled || loading || null"
    class="btn"
    :class="[
      {
        pill,
        'btn-block': block,
        reverse: isReverse,
        'rounded-circle': isCircle,
        'btn--flex': iconButton,
        'btn--shadowed': shadowedButton
      },
      size ? `btn-${size}` : '',
      variant ? `btn-${variant}` : ''
    ]"
    @click="handleClick"
  >
    <span v-if="loading" aria-hidden="true" class="spinner-border"></span>
    <b-icon v-if="iconButton && !loading" :icon="icon" :scale="scale" aria-hidden="true" :class="{ nestedIcon, 'mr-2': buttonText }"></b-icon>
    <slot v-if="!loading">{{ buttonText }}</slot>
    <span
      v-if="nestedBadge && !loading"
      class="badge mx-1"
      :class="[{ 'nested-badge': nestedBadge, 'rounded-pill': pill }, variant ? `badge-${variant}` : '']"
      >{{ badgeText }}</span
    >
  </component>
</template>

<script>
  import BIcon from '../BIcon.vue';
  export default {
    components: {
      BIcon
    },
    props: {
      variant: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: ''
      },
      type: {
        // url (external link), link (internal link), anchor (scrollto), submit
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      },
      shadowedButton: {
        type: Boolean,
        default: false
      },
      iconButton: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: 'gear-fill'
      },
      nestedBadge: {
        type: Boolean,
        default: false
      },
      badgeText: {
        type: String,
        default: ''
      },
      scale: {
        type: String,
        default: ''
      },
      isReverse: {
        type: Boolean,
        default: false
      },
      isCircle: {
        type: Boolean,
        default: false
      },
      pill: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      block: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      scrollto: {
        type: String,
        default: ''
      }
    },
    computed: {
      nestedIcon() {
        return {
          'mx-2': this.isCircle === false && this.buttonText !== ''
        };
      }
    },
    methods: {
      handleClick() {
        if (!this.loading) {
          if (this.type === 'anchor' && this.scrollto !== '') this.$scrollTo(this.scrollto, -30);
          if (this.type === 'link') this.$router.push(this.$routeHandler(this.link));
        }
      }
    }
  };
</script>
<style lang="scss">
  .reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .spinner-border {
    margin: -0.5rem 0;
  }
</style>
