<template>
  <div class="promotion_wrapper" v-if="getPromotions && getPromotions.length >= 1" data-track-content>
    <div class="promotion_carousel container-xl">
      <swiper :options="swiperOptions" :modules="swiperModules">
        <div class="swiper-slide" v-for="(item, index) in promotions" :key="index">
          <div class="promotion_carousel__content" :style="{ backgroundColor: item.backgroundColor }">
            <div class="promotion_carousel__body" :style="{ color: item.textColor }">
              <a
                :href="`${item.linkTo}`"
                @click="(e) => onBannerClick(e, item)"
                target="_blank"
                rel="noreferrer noopener"
                class="product-img__container media-wrapper"
                :class="{'product-img__container--empty': !item.productImageUrl}"
                data-content-piece="Banner Link"
                data-track-content
              >
                <img v-if="item.productImageUrl" :src="item.productImageUrl" />
              </a>  
              <div class="product-title__container">
                <h2 class="product-title" v-html="item.headlineText"></h2>
              </div>
              <DefaultButton
                :class="{ 'invisible': !item.buttonText }"
                @click.native="(e) => onBannerClick(e, item)"
                :button-text="item.buttonText"
                :variant="item.buttonVariant"
                :link="item.linkTo"
                :type="item.externalLink || item.linkToAce ? 'url' : 'link'"
                data-content-piece="Banner Link"
                data-track-content
              />
            </div>
            <div class="promotion_carousel__banner">
              <a
                @click="(e) => onBannerClick(e, item)"
                :href="item.linkTo"
                target="_blank"
                rel="noreferrer noopener"
                data-content-piece="Banner Link"
                data-track-content
              >
                <div class="carousel__item" :style="{ backgroundImage: `url(${item.bannerImageUrl})` }"></div>
              </a>
            </div>
          </div>
        </div>
        <template v-slot:navigation>
          <DefaultButton 
            variant="primary" 
            size="sm" 
            type="button" 
            button-text="" 
            shadowed-button 
            icon-button 
            icon="arrow-left" 
            scale="1" 
            is-circle 
            class="swiper-btn swiper-btn--prev"
          />
          <DefaultButton 
            variant="primary" 
            size="sm" 
            type="button" 
            button-text="" 
            shadowed-button 
            icon-button icon="arrow-right" 
            scale="1" 
            is-circle 
            class="swiper-btn swiper-btn--next"
          />
        </template>
        <template v-slot:pagination>
          <div class="swiper-pagination"></div>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { EffectCards } from 'swiper/modules';

  export default {
    data (){
      return {
        swiperModules: [EffectCards],
        swiperOptions: {
          slidesPerView :1,
          loop:true,
          centeredSlides: true,
          effect: "cards",
          autoplay: {
            delay: 4000,
            pauseOnMouseEnter: true
          }
        },
      }
    },
    components: {
      DefaultButton
    },

    props: {
      promotions: {
        type: Array
      }
    },

    computed: {
      getPromotions() {
        return this.promotions;
      }
    },

    methods: {
      async onBannerClick(event, promo) {
        if(promo.linkToAce) {
          if (!event.target.classList.contains('linkToAce')) {
            event.preventDefault();
            event.target.classList.add('linkToAce');
            const handshakeURL = await this.$store.dispatch('user/handShakeGenerator', 'aktion.ace-vorteilswelt.de');
            event.target.href = handshakeURL;
            event.target.click();
          } else {
            this.eTrackerClick(promo);
          }
        } else {
          this.eTrackerClick(promo);
          if(!promo.externalLink) {
            event.preventDefault();
            this.$router.push(this.$routeHandler(promo.linkTo));
          }
        }
      },

      eTrackerClick(promo) {
        this.$store.dispatch('etracker/etClick', {name: promo.name, type: 'Banner Click'});
      }
    }
  };
</script>

<style lang="scss" scoped>
  .promotion_wrapper {
    z-index: 1;
    padding: 30px 20px 60px;
    margin: 20px auto;
    min-height: 350px;
    overflow: hidden;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      min-height: 200px;
    }
    &:hover .swiper-btn {
      opacity: 1;
    }
  }
  .promotion_carousel {
    position: relative;
    .swiper {
      border-radius: $border-radius;
      &-slide {
        border-radius: $border-radius;
        // overflow: hidden;
        &-active {
          box-shadow: $box-shadow-lg;
        }
      }
    }

    &__content {
      display: flex;
      width: 100%;
      height: 350px;
      border-radius: $border-radius;

      @media (max-width: 600px) {
        flex-direction: column-reverse;
        height: 100%;
        justify-content: start;
      }
    }

    &__body {
      max-width: 350px;
      width: 100%;
      padding: 20px;
      padding-left: 30px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      @media (max-width: 600px) {
        flex-grow: 1;
      }
      .product-title__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 150%;
        margin: 0.5rem 0;

        @media (max-width: 992px) {
          width: 120%;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .product-title {
        @extend %font-h2;
        margin: 0;
        @media (max-width: 600px) {
          margin-bottom: 1rem;
          max-width: 100%;
        }
      }

      .product-img__container {
        width: 9rem;
        border-radius: $border-radius;
        box-shadow: $box-shadow-lg;
        
        &:before {
          padding-bottom: 65%;
        }

        @media (max-width: 600px) {
          display: none;
        }

        @media (max-width: 992px) {
          width: 8rem;
        }

        &--empty {
          pointer-events: none !important;
          box-shadow: none !important;
        }
      }

      @media (max-width: 600px) {
        max-width: none;
        margin-bottom: 2rem;
        max-height: 20rem;
        padding: 1rem;
      }
    }
    &__banner {
      width: 100%;
    }
  }
  .carousel__item {
    pointer-events: none;
    min-height: 200px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .swiper {
    .swiper-btn{
      position: absolute;
      top: 50%;
      z-index: 3;

      @media (max-width: 600px) {
        display: none;
      }

      @media (hover: hover) {
        transition: all 0.5s ease;
        opacity: 0;
        
      }
      &--next{
        right: 0;
        transform: translate(50%, -50%);

        @media (max-width: 1200px) {
          transform: translate(40%, -50%);
        }
      }
      &--prev{
        left: 0;
        transform: translate(-50%, -50%);

        @media (max-width: 1200px) {
          transform: translate(-40%, -50%);
        }
      }
    }
  }
  .swiper-slide {
    @media (max-width: 768px) {
      height: auto !important;
    }
  }
  .swiper-pagination {
    position: absolute !important;
    bottom: .75rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    margin: 0 !important;
    @media (min-width: 601px) {
      display: none;
    }
  }
</style>