<template>
  <article>
    <router-link :to="$routeHandler(`/product-details/${product.number}`)" class="product-link">
      <div class="card product animated-border">
        <div class="card-body">
          <div class="card-body--top">
            <div class="product__image-container media-wrapper">
              <img
                :src="imageUrl"
                :alt="product.name"
                class="product__image-container__image"
              />
            </div>
          </div>
          <div class="card-body--down">
            <div class="product__type">{{ getProductType(product.type_group) }}</div>
            <div class="product__name">{{ product.name }}</div>
            <div class="price-area">
              <div class="product__price-wrap">
                <div v-if="isReward" class="product__price">{{ $currency(firstVarSellingPrice) }}</div>
                <div v-if="isReward && hasDiscount" class="product__old-price">{{ $currency(retailPrice) }}</div>
                <div v-else-if="product.variations.length && firstVarSellingPrice < lastVarSellingPrice" class="product__price">
                  {{ $currency(firstVarSellingPrice, getCurrencySymbol(product)) }} -
                  {{ $currency(lastVarSellingPrice, getCurrencySymbol(product)) }}
                </div>
                <div v-else-if="!isReward" class="product__price">{{ $currency(firstVarSellingPrice, getCurrencySymbol(product)) }}</div>
              </div>
            </div>
          </div>
          <div class="product__badge--fixed" v-if="hasDiscount">
            <span class="badge badge-secondary product__discount">-{{ product.displayed_discount }}%</span>
          </div>
          <div class="product__badge--fixed" v-if="product.discount_text && !hasDiscount">
            <span class="badge badge-secondary product__discount">{{ product.discount_text }}</span>
          </div>
        </div>
      </div>
    </router-link>
  </article>
</template>
<script>
  export default {
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        imageServerUrl: 'https://images.cadooz.com/',
        firstVarSellingPrice: 0,
        lastVarSellingPrice: 0,
        retailPrice: 0
      };
    },
    watch: {
      product: {
        handler: function (newProduct, oldProduct) {
          this.firstVarSellingPrice = this.getVariationSellingPrice(newProduct, 0);
          this.lastVarSellingPrice = this.getVariationSellingPrice(newProduct, newProduct.variations.length - 1);
          this.retailPrice = this.isB2B ? newProduct.net_recommended_retail_price : newProduct.recommended_retail_price;
        },
        deep: true
      },
    },
    computed: {
      isReward() {
        return this.product.type_group === 'PHY';
      },
      isB2B() {
        return this.$store.state.app.isB2B;
      },
      imageUrl() {
        const { product, imageServerUrl } = this;
        let imgUrl = product.big_images[0];
        if (!imgUrl) {
          imgUrl = product.gift_card_image;
        }
        return `${imageServerUrl}${imgUrl}`;
      },
      hasDiscount() {        
        return this.product.displayed_discount && this.product.displayed_discount > 0;
      },
    },
    methods: {
      getProductType(type) {
        switch(type) {
          case 'TVO':
            return this.$t('filter.group.type.option_vouchers');
          case 'PHY':
            return this.$t('filter.group.type.option_brand_products');
          case 'UVO':
            return this.$t('filter.group.type.option_theme_vouchers');
          default:
            return '';
        }
      },
      getVariationSellingPrice(product, varIndex) {
        let price = product.variations[varIndex][(this.isB2B ? 'net_' : '') + 'selling_price'];
        if(!this.isReward && product.external_product_value_type_id !== 'HID' || product.variations.length > 1) {
          price = product.variations[varIndex].face_value.amount;
        }
        return price;
      },
      getCurrencySymbol(product) {
        return product.variations[0]?.face_value?.currency?.short_symbol || product.variations[0]?.face_value?.currency?.symbol;
      }
    },
    created() {
      this.firstVarSellingPrice = this.getVariationSellingPrice(this.product, 0);
      this.lastVarSellingPrice = this.getVariationSellingPrice(this.product, this.product.variations.length - 1);
      this.retailPrice = this.isB2B ? this.product.net_recommended_retail_price : this.product.recommended_retail_price;
    }
  };
</script>

<style lang="scss" scoped>
  .flag-icon {
    transform: scale(2);
  }
  .product-link:hover .product {
    box-shadow: $box-shadow;
  }
  .product {
    width: 100%;
    border-radius: $border-radius;

    .card-body {
      position: relative;
      padding: 0;
      box-shadow: $box-shadow-sm;
      border-radius: $border-radius;

      &--down {
        padding: 0 .5rem .5rem;
      }
    }
    .card-footer {
      height: 1rem;
      margin-bottom: 1rem;
    }
    &__promotion,
    &__name {
      margin-bottom: 0.5rem;
    }
    &__image-container {
      width: 100%;
      margin-bottom: .5rem;
      border-radius: $border-radius;
      
      &:before {
        padding-bottom: 62.5%; /* 8:5 Aspect Ratio (divide 5 by 8 = 0.625) */
      }
    }
    &__name {
      height: ($h5-font-size * $headings-line-height) * 2;
      color: $body-color;
      line-height: $headings-line-height;
      overflow: hidden;
      font-family: $font-semibold;
    }
    &__type {
      margin-bottom: .25rem;
      font-weight: bold;
      color: var(--primary);
      font-size: .8rem;
    }
    &__badge {
      margin-bottom: 0.5rem;
      height: 1.5rem;
    }
    &__price-wrap {
      display: table;
      width: 100%;
      height: ($h5-font-size * $headings-line-height) * 1.5;
      overflow: hidden;
    }
    &__price,
    &__old-price {
      @extend %font-h4;
      font-family: $font-semibold;
      display: table-cell;
    }
    &__price {
      color: var(--secondary);
    }
    &__old-price {
      color: $gray_700;
      text-decoration: line-through;
      text-align: right;
    }
  }

  .product__badge--fixed {
    position: absolute;
    top: -.5rem;
    left: -.5rem;
    z-index: 3;

    .badge {
      padding: .5rem;
      font-size: 1rem;
    }
  }
</style>
