<template>
  <div no-body class="card filterbox" :class="{ 'filterbox--in-sidebar': additionalOptions.filterVariant === 'sidebar-filter' }">
    <div class="card-header p-0" role="tab">
      <button class="btn btn-light btn-block filterbox__header p-3" :class="{ 'not-collapsed': !isCollapsed }" @click="toogleCollapse">
        <span>{{ title }}</span>
        <span class="filterbox__icon"><b-icon icon="caret-down-fill" h-flip /></span>
      </button>
    </div>

    <div class="collapse" :class="{ show: !isCollapsed }">
      <div class="card-body pt-0 px-3 pb-2">
        <div role="group" class="bv-no-focus-ring">
          <div v-for="option of options" :key="option.text" class="mb-2">
            <div class="custom-control custom-control-inline custom-checkbox">
              <input :id="option.text" v-model="selectedFilter" type="checkbox" class="custom-control-input" :value="option" @change="handleInput" />
              <label :for="option.text" class="custom-control-label checked">
                <span>
                  {{ option.text }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: null
      },
      activeFilter: {
        type: Array,
        default: null
      }
    },
    data() {
      return {
        selectedFilter: this.activeFilter,
        isCollapsed: false
      };
    },
    computed: {
      additionalOptions() {
        return this.$store.state.pages.additionalOptions;
      }
    },
    watch: {
      activeFilter() {
        this.selectedFilter = this.activeFilter;
      }
    },

    methods: {
      handleInput() {
        this.$emit('filterInput', this.selectedFilter);
      },
      toogleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filterbox {
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;

    &--in-sidebar {
      margin: 0 auto 2rem auto;
      max-width: 380px;
    }

    .card-body {
      @extend %font-md;
    }
  }
</style>
