<template>
  <nav ref="nav" class="navigation">
    <div class="container-xl">
      <div class="mobile-nav d-flex d-lg-none" :class="!links.length ? 'mobile-nav--empty' : ''" v-if="loggedIn">
        <HeaderLogoContainer :logo="logo" :loggedIn="loggedIn" />
        <DefaultButton v-if="!minimalMode && links.length" variant="primary" icon="list" icon-button is-reverse @click="showSidebar">
          <span class="btn-text mr-2">{{ $t('global.navigation_items.menu') }}</span>
        </DefaultButton>
        <Sidebar ref="sidebar" v-if="links.length">
          <template #body="{ hideSidebar }">
            <div class="list-group">
              <template v-for="(item, index) in links">
                <router-link
                  v-if="!item.href"
                  :key="'0' + index"
                  :to="{ path: $routeHandler(item.path), query: item.query, hash: item.hash }"
                  @click="hideSidebar()"
                  class="list-group-item list-group-item-action"
                  >{{ item.name }}</router-link
                >
                <a
                  v-else
                  :key="index"
                  :href="item.href"
                  :target="item.target"
                  @click="hideSidebar()"
                  class="list-group-item list-group-item-action"
                  >{{ item.name }}</a
                >
              </template>
            </div>
            <DefaultDivider />
            <div>
              <LanguageSwitch :sidebar="true" />
            </div>
            <div class="navigation__logout" @click="logout()" v-if="!isOpenShop">
              <b-icon icon="logout" />
              <span>{{this.$t('global.navigation_items.logout')}}</span>
            </div>
          </template>
        </Sidebar>
      </div>
      <ul v-if="!minimalMode && links.length" class="nav main-nav" :class="loggedIn ? ['d-none', 'd-lg-flex'] : ''">
        <li v-for="(item, index) in links" :key="index" class="nav-item">
          <router-link v-if="!item.href" :to="{ path: $routeHandler(item.hash ? '/' : item.path), query: item.query, hash: item.hash }" class="nav-link">{{ item.name }}</router-link>
          <a v-else :href="item.href" :target="item.target" class="nav-link">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import HeaderLogoContainer from './HeaderLogoContainer.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import Sidebar from 'building-blocks/components/Sidebar.vue';
  import DefaultDivider from 'building-blocks/components/DefaultElements/DefaultDivider.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import headerMixin from '@/plugins/mixins/headerMixin';
  import LanguageSwitch from '../LanguageSwitch.vue';

  export default {
    components: {
      HeaderLogoContainer,
      DefaultButton,
      Sidebar,
      DefaultDivider,
      BIcon,
      LanguageSwitch
    },
    data() {
      return {
        hideSidebar: true
      };
    },
    mixins: [headerMixin],
    props: {
      loggedIn: {
        type: Boolean,
        default: false
      },
      logo: {
        type: String
      },
      links: {
        type: Array,
        default: []
      }
    },

    computed: {
      minimalMode() {
        return this.$store.getters['pages/minimalMode'];
      },
      isOpenShop() {
        return this.$store.state.app.isOpenShop;
      }
    },
    methods: {
      showSidebar() {
        this.$refs.sidebar.showSidebar();
      }
    },
  };
</script>

<style lang="scss" scoped>
  .navigation {
    position: relative;
    width: 100%;
    border-top: 1px solid $border-color;
    // overflow-x: auto;

    &:hover {
      /* Handle on hover */
      &::-webkit-scrollbar-thumb {
        background: $border-color;
        border-radius: $border-radius;
      }
    }
    &::-webkit-scrollbar {
      height: 3px;
      background: var(--light);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--light);
      border-radius: $border-radius;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--light);
      border-radius: $border-radius;
    }

    &__logout {
      padding: 0 1rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: .3rem;
      }
    }
  }
  .main-nav {
    font-family: $font-semibold;
    height: 3rem;
    margin: 0 -1rem;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
      height: 3.5rem;
    }

    .nav-link {
      position: relative;
      height: 100%;
      border: 0;
      display: inline-flex;
      align-items: center;

      &:hover {
        border: 0;
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 1rem;
        width: calc(100% - 2rem);
        border-bottom: 2px solid var(--primary);
      }

      &.active {
        color: var(--primary);
        &:after {
          content: '';
        }
      }
    }
  }
  .mobile-nav {
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;

    @include media-breakpoint-up(md) {
      height: 5rem;

      &.mobile-nav--empty {
        height: 3.5rem;
      }
    }

    .btn {
      @include media-breakpoint-down(sm) {
        padding: 0.75rem 0.75rem;
        font-size: 0.8125rem;
        line-height: 1rem;
      }
    }
    .btn-text {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  .list-group {
    padding: 0.75rem 1rem;
    .list-group-item,
    a.list-group-item {
      padding-left: 0;
      border: 0;
      border-bottom: 1px solid $border-color;
      font-family: $font-semibold;
      &--light {
        font-size: $h6-font-size;
        font-family: $font-regular;
        color: var(--gray-800);
      }
    }
    .disabled {
      font-family: $font-regular;
    }
  }
</style>
