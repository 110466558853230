<template>
  <TextEditor :headline="headline" :content="mutatedContent"/>
</template>

<script>
  import TextEditor from 'building-blocks/components/TextEditor.vue';
  export default {
    components: { TextEditor },
    props: {
      headline: {
        type: String,
        default: ''
      },
      content: {
        type: String,
        default: ''
      },
    },
    computed: {
      websiteSettings() {
        return this.$store.state.app.websiteSettings
      },  
      mutatedContent() {
        // INFO: &lt; - left arrow (<) / &gt; - right arrow (>)
        let newContent = this.content;
        
        // <--site_url--> -> Returns URL of current site
        newContent = newContent.replace(/&lt;--site_url--&gt;/g, this.siteUrl);

        // <--payment_methods--> -> Returns available payments methods of current site
        newContent = newContent.replace(/&lt;--payment_methods--&gt;/g, this.availablePayments);

        // <--etracker_optout--> -> Returns eTracker Opt Out Checkbox
        newContent = newContent.replace(/&lt;--etracker_optout--&gt;/g, this.websiteSettings?.website_etracker_id ? this.eTrackerOptOut : '');

        return newContent;
      },
      siteUrl() {
        return window.location.origin;
      },
      availablePayments() {
        const paymentsArr = this.$store.getters['app/getAvailablePaymentMethods']();
        const translatedArr = paymentsArr.map(el => {
          return this.$t(el.title);
        });

        if (translatedArr) {
          const paymentStr = translatedArr.join(', ') + '.';
          return paymentStr;
        }
      },
      eTrackerOptOut() {
        return `
          <div class="etracker-optout">
            <div class="custom-control custom-checkbox cb-container">
              <input type="checkbox" id="etracker-optout" class="custom-control-input" data-tld="${this.siteUrl}"/>
              <label for="etracker-optout" class="custom-control-label">${this.$t('global.etracker.optout.label')}</label>
            </div>
            <p>${this.$t('global.etracker.optout.text')} <a href="https://www.etracker.com/datenschutz" target="_blank" rel="noopener">${this.$t('global.etracker.optout.linkText')}</a>.</p>
          </div>
        `;
      }
    },
    methods: {
      initTrackingCheckbox(checkbox, label) {
        const uncheckedText = this.$t('global.etracker.optout.label');
        const checkedText = this.$t('global.etracker.optout.label-checked');

        window._etrackerOnReady.push(function() {
          checkbox.checked = _etracker.isTrackingEnabled();
          if(checkbox.checked) label.innerHTML = checkedText;
          
          checkbox.onclick = function() {
            const tld = this.getAttribute('data-tld');
            if(this.checked) {
              _etracker.enableTracking(tld);
              label.innerHTML = checkedText;
            } else {
              _etracker.disableTracking(tld);
              label.innerHTML = uncheckedText;
            }
          };
        });
      }
    },
    mounted() {
      const checkbox = document.getElementById('etracker-optout');
      if(this.websiteSettings?.website_etracker_id && checkbox) {
        const label = checkbox.nextElementSibling;
        this.initTrackingCheckbox(checkbox, label);
      }
    }
  }
</script>

<style lang="scss">
  .etracker-optout {
    margin: 2rem 0;
    
    p {
      margin-top: .25em;
    }
  }
</style>