/**
 * create risk ident scripts 
 */
 function createScript(obj) {
  let shouldAppend = false;
  let el = document.querySelector(`${obj.tag}[${obj.atr}="${obj.path}"]`);
  if (!el) {
    el = document.createElement(obj.tag);
    if (obj.type) el.type = obj.type;
    el[obj.atr] = obj.path;
    if (obj.additional) el[obj.additional[0]] = obj.additional[1]
    if (obj.text) el.innerHTML = obj.text
    shouldAppend = true;
  }
  if (shouldAppend) document.head.appendChild(el);
}

export function loadRiskIdent(riskIdentData) {
  if(!riskIdentData.apiId) return;
  let di = { t: riskIdentData.token, v: riskIdentData.apiId, l: 'Order' };
  di.va = `cId=''`;
  const src = `https://sub.cadooz.com/${riskIdentData.apiId}/di.js`;
  const href = `https://sub.cadooz.com/di.css?t=${riskIdentData.token}&v=${riskIdentData.apiId}&l=Order&va=cId=${riskIdentData.username}`;

  createScript({
    tag: 'script',
    atr: "data-risk-ident",
    path: "riskident",
    text: `var di=${JSON.stringify(di)}`
  });

  createScript({
    tag: 'script',
    atr: "src",
    path: src,
    type: "text/javascript"
  });

  createScript({
    tag: 'link',
    atr: "href",
    path: href,
    type: "text/css",
    additional: ['rel', 'stylesheet']
  })
}