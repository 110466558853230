<template>
  <PromotionTeaser :promotions="mgnlPromotions" />
</template>

<script>
  import PromotionTeaser from '@/components/Promotion/PromotionTeaser.vue';
  import mgnlConfig from '../config/mgnl';
  export default {
    components: { PromotionTeaser },
    data() {
      return {
        promotionsUrl: mgnlConfig.promotions_teaser,
        promotions: []
      };
    },

    methods: {
      async fetchPromotions() {
        try {
          const response = await fetch(this.promotionsUrl, { options: { method: 'GET' } });
          return response.json();
        } catch (err) {
          console.error(err);
          return [];
        }
      },

      filterActivePromotions(promotions) {
        return promotions.filter((p) => p.active === 'true');
      },

      sortByWeight(promotions) {
        return promotions.slice().sort((a, b) => b.weight - a.weight);
      },

      createImageUrl(imageObj) {
        if (imageObj) {
          return process.env.VUE_APP_MGNL_HOST + '/dam' + imageObj['@path'];
        }
        return '';
      },

      createLink(linkType, link) {
        switch (linkType) {
          case 'none':
            return '';
          case 'category':
            return this.$routeHandler(`/product-overview?category=${link}`);
          case 'product':
            return this.$routeHandler(`/product-details/${link}`);
        }
      },

      createPromotionObject(promotion) {
        return {
          name: promotion.name,
          headlineText: promotion.headline_text,
          image: this.createImageUrl(promotion.background_image),
          linkTo: this.createLink(promotion.link_type, promotion.linkId),
          weight: promotion.weight ? parseInt(promotion.weight) : 0
        };
      }
    },

    computed: {
      mgnlPromotions() {
        if (this.promotions == null) return [];

        const promos = this.promotions.map((p) => this.createPromotionObject(p));
        const promosByWeight = this.sortByWeight(promos);
        return promosByWeight.slice(0, 2);
      }
    },

    async mounted() {
      const data = await this.fetchPromotions();
      if (data) this.promotions = this.filterActivePromotions(data.results);
    }
  };
</script>
