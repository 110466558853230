<template>
  <div class="card accordion-card mb-3" :class="isOpen ? 'not-collapsed' : 'collapsed'">
    <header class="card-header" role="tab">
      <button class="btn btn-light btn-block card-header-btn" :class="{ 'not-collapsed': isOpen }" @click="toogleCollapse">
        <span class="card-header-btn__text">{{ cardContent.header }}</span>
        <span class="card-header-btn__icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
          </svg>
        </span>
      </button>
    </header>
    <div class="collapse" :class="{ show: isOpen }">
      <div class="card-body" v-html="cardContent.text" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      cardContent: {
        type: Object,
        default: () => ({})
      },
      isOpen: {
        type: Boolean
      }
    },
    methods: {
      toogleCollapse() {
        if (this.isOpen) {
          this.$emit('toggle', null);
        } else {
          this.$emit('toggle', this.cardContent.header);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .accordion-card {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow-anchor: none;

    &.not-collapsed {
      box-shadow: $box-shadow-sm;
      border: 0;
      & .card-header-btn {
        color: var(--primary);
      }
      & .card-header-btn__icon {
        & svg {
          transform: rotate(180deg);
        }
      }
    }
    &.collapsed {
      & .card-header-btn {
        color: $body-color;
      }
    }
    .card-header {
      padding: 0;
      &:first-child {
        border-radius: $border-radius;
      }
      & .card-header-btn,
      .btn-light {
        display: flex;
        justify-content: space-between;
        transition: transform 3s;
        border-radius: $border-radius;
        padding: 1rem;
        background-color: var(--light);
        @include media-breakpoint-up(xl) {
          padding: 1.5rem 2rem;
        }
        &:active:focus {
          box-shadow: none;
          background-color: var(--light);
        }
        &__text {
          text-align: left;
          margin-right: 1rem;
        }
      }
    }
    & .card-body {
      padding: 0 1rem 1rem 1rem;
      color: $body-color;
      @include media-breakpoint-up(xl) {
        padding: 0 2rem 1.5rem 2rem;
      }
    }
  }
</style>
