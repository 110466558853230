<template>
  <div class="loader__overlay">
    <div class="loader">
      <div class="inner"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .loader__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 9999999;
    background-color: #fff;
  }

  .loader {
    width: 100px;
    height: 100px;
    border: 3px solid var(--primary);
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    animation: spin 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      display: none;
    }

    .inner {
      width: 50px;
      height: 50px;
      border: 3px solid transparent;
      border-top: 3px solid var(--primary);
      border-radius: 50%;
      animation: spinInner 1s linear infinite;
    }
  }

  /*--------------------------------------------------------------
## Loader animation
--------------------------------------------------------------*/
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes spinInner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-720deg);
    }
  }
</style>
