import initServices from '@/services/init.js';
import basketServices from '@/services/basket.js';
import paymentServices from '@/services/payment.js';
import orderServices from '@/services/order.js';
import userServices from '../services/user.js';
import punchoutServices from '../services/punchout.js';
import passwordServices from '../services/password.js';

const api = {
  ...initServices,

  ...basketServices,

  ...paymentServices,

  ...orderServices,

  ...userServices,

  ...punchoutServices,

  ...passwordServices
};

export default api;
