<template>
  <div class="row card-body">
    <div class="col">
      <form @submit.prevent="submitEmail">
        <div role="group" class="form-group">
          <label label="forgotPassword" />
          <input
            id="forgotPassword"
            type="text"
            :placeholder="$t('global.forgot_password_form.placeholder')"
            :class="{ 'input-field': inputField, 'is-invalid': inputFieldState === false }"
            class="form-control"
            :aria-invalid="!inputFieldState"
            v-model="inputField"
          />
          <div id="forgotPassword" class="invalid-feedback">{{ inputError }}</div>
        </div>
        <div v-if="!websiteSettings.deny_captcha" class="hcaptcha-container">
          <VueHcaptcha ref="hcaptcha" :sitekey="hcaptchaKey" :language="$store.state.translation.locale" @error="onError" @verify="onSuccess" @expired="onExpired" />
        </div>
        <DefaultButton
          class="mt-2"
          type="submit"
          block
          variant="primary"
          :button-text="$t('global.forgot_password_form.button')"
          :loading="loading"
          :disabled="buttonDisabled"
          data-test="password-forgot-input-button"
        />
        <div class="text-center mt-3">
          <button class="btn btn-link btn-link--primary" @click="$emit('backToLogin')">{{ $t('global.forgot_password_form.back_button') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { mapState } from 'vuex';
  export default {
    data(){ 
      return {
        inputField: '',
        inputErrorMessage: '',
        inputFieldState: null,
        captchaToken: ''
      }
    },
    props: {
      groupId: {
        type: String,
        default: ''
      },
    },
    components: {
      VueHcaptcha,
      DefaultButton
    },
    computed: {
      ...mapState({
        loading: (state) => state.app.isLoading,
        websiteSettings: (state) => state.app.websiteSettings,
      }),
      hcaptchaKey() {
        return process.env.VUE_APP_HCAPTCHA_SITE_KEY;
      },
      inputError() {
        return this.inputErrorMessage;
      },
      isInputEmpty() {
        return this.inputField === ''; 
      },
      buttonDisabled() {
        return this.websiteSettings.deny_captcha ? false : !(this.captchaToken && !this.isInputEmpty)
      }
    },
    methods: {
      setLoading(state) {
        this.$store.commit('app/SET_LOADINGSTATE', state);
      },
      toast(message, error) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      },

      submitEmail() {
        this.setLoading(true);
        if(this.isInputEmpty) {
          this.inputFieldState = false;
          this.inputErrorMessage = this.$t('api-errors.forgot_password.empty');
          this.setLoading(false);
        } else {
          this.resetSubmit();
        }
      },

      async resetSubmit() {
        const passwordData = {
          user_name: this.inputField.includes('@') ? "" : this.inputField,
          email: this.inputField.includes('@') ? this.inputField : "",
          captcha: this.websiteSettings.deny_captcha ? '' : this.captchaToken,
          external_identifier: this.websiteSettings.external_identifier
        }
        try {
          const response = await this.$api.handleReset(passwordData);
          if(response === 204) {
            this.$emit('handleSubmit');
          }
          this.setLoading(false);
        } catch (error) {
          console.log(error)
          this.setLoading(false);
        }
      },

      onError(error) {
        if (!error.handled) {
          this.toast(this.$t('form.error.hcaptcha', { error }));
        }
      },
      onSuccess(captchaToken) {
        this.captchaToken = captchaToken;
      },
      async onExpired() {
        await this.$refs.hcaptcha.reset();
      },
      async verifyHcaptcha() {
        try {
          this.setLoading(true);
          const response = await this.$api.verifyCaptchaToken(this.captchaToken);
          const tokenValid = response.success;
          if (tokenValid) {
            await this.$refs.hcaptcha.reset();
            this.resetSubmit();
          } else {
            this.setLoading(false);
            throw this.toast(this.$t('form.error.check_hcaptcha'));
          }
        } catch (error) {
          this.setLoading(false);
          await this.$refs.hcaptcha.reset();
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .invalid-feedback {
    text-align: left;
    font-size: $font-size-md;
  }
</style>