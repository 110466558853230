<template>
  <CategoriesBar :headline="headline" />
</template>

<script>
  import CategoriesBar from '@/components/Categories/CategoriesBar.vue';
  export default {
    components: { CategoriesBar },
    props: {
      headline: {
        type: String,
        default: ''
      }
    }
  };
</script>
