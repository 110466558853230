/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function sortByPriceDiscount(productArray) {
  const sortByName = productArray.sort((a, b) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1))
  return sortByName.sort((a, b) => b.displayed_discount - a.displayed_discount)
}

/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function sortByPriceAscending(productArray) {
  return productArray.sort((a, b) => a.variations[0].selling_price - b.variations[0].selling_price)
}

/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function sortByPriceDescending(productArray) {
  return productArray.sort((a, b) => b.variations[b.variations.length - 1].selling_price - a.variations[a.variations.length - 1].selling_price)
}

/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function sortByNameAscending(productArray) {
  return productArray.sort((a, b) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1))
}

/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function sortByNameDescending(productArray) {
  return productArray.sort((a, b) => (a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? 1 : -1))
}

/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function sortByRecommendation(productArray) {
  const recommendationID = 2
  const recommendedProducts = productArray.filter((product) => product.sublists.includes(recommendationID))
  const notRecommendedProducts = productArray.filter((product) => !product.sublists.includes(recommendationID))
  const sortedProducts = [...recommendedProducts, ...notRecommendedProducts]
  return sortedProducts
}
