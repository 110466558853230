<template>
  <div class="page page--error">
    <StageDefault textstage :headline="$t('view.error.page.payment_in_process.headline')" />
  </div>
</template>

<script>
  import StageDefault from '@/components/Stage/StageDefault.vue';
  export default {
    components: { StageDefault }
  };
</script>
