import paymentTypes from '@/config/paymentTypes.js';

// =======================================================================
// State
// =======================================================================
const state = () => ({
  orderNumber: null,
  receiptPosition: [],
  grossPrice: 0,
  amountToPay: null,
  payments: [],
  invoiceAddress: null,
  containsOnlineProducts: null,
  receipt_positions: [],
  trackingValue: 0
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_ORDER(state, responseData) {
    state.orderNumber = responseData.order_number;
    state.receiptPosition = responseData.receipt_positions;
    state.grossPrice = responseData.gross_price;
    state.amountToPay = responseData.amount_to_pay;
    state.payments = responseData.payments;
    state.invoiceAddress = responseData.invoice_address;
    state.containsOnlineProducts = responseData.contains_online_product;
    state.receipt_positions = responseData.receipt_positions;
    state.trackingValue = responseData.tracking_value;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  /**
   * @param {*} context
   * @param {*} basketNumber
   * @throws ApiError
   */
  async createOrder(context, basketNumber) {
    const responseData = await this.$api.createOrder(basketNumber);
    if (responseData.order) {
      context.commit('SET_ORDER', responseData.order);
    } else {
      return responseData.redirection_url;
    }
  },
  /**
   * Calls the C*Vision API for the current basket and checks if the user can create an order, or if some limits are violated, etc ...
   * @param {*} context
   * @throws ApiError
   */
  async checkOrderCreation(context) {
    const basketNumber = context.rootState.basket.basketNumber;
    return await this.$api.checkOrderCreation(basketNumber);
  }
};

// =======================================================================
// Getters
// =======================================================================
const getters = {
  getInvoiceEmail: (state) => {
    return state.invoiceAddress.email;
  },

  getPrepaidPayments: (state) => {
    const prepaidPaymentTypeIds = paymentTypes.filter((payment) => payment.prepaid).map((paymentType) => paymentType.id);
    return state.payments.filter((payment) => prepaidPaymentTypeIds.includes(payment.payment_type_id));
  },

  containsPostPayment: (state) => {
    return state.payments.some((payment) => payment.payment_type_id !== 2);
  },

  getSaferPayMethod: (state) => {
    return state.payments.some((payment) => payment.payment_type_id === 4);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
