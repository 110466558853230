<template>
  <div class="card card__theme" :class="[variant]" @click="handleInput">
      <img :src="image" class="card__theme__image"/>
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <DefaultButton
        class="theme-btn"
        v-if="button"
        variant="light"
        size="md"
        type="link"
        shadowed-button
        icon-button
        icon="arrow-right"
        scale="1"
        is-circle
      />
    </div>
  </div>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

  export default {
    components: {
      DefaultButton
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      image: {
        type: String,
        default: ''
      },
      variant: {
        type: String,
        default: ''
      },
      productType: {
        type: String,
        default: ''
      },
      activeFilter: {
        type: Array,
        default: null
      },
      button: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      filterProductType() {
        return {
          data: [
            {
              groupId: 'productType',
              text: this.title,
              value: this.productType
            }
          ]
        };
      }
    },
    methods: {
      handleInput() {
        this.$emit('filterCategory', this.filterProductType.data);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    border-radius: $border-radius;
    &__theme {
      cursor: pointer;
      box-shadow: $box-shadow-sm;
      transition: all 0.15s ease-in;
      &:hover {
        box-shadow: $box-shadow;
      }
      &__image {
        border-radius: $border-radius $border-radius 0 0;
        object-fit: cover;
        width: auto;
        & img {
          width: auto;
          object-fit: cover;
        }
      }
      & .card-body {
        padding: 3rem 1.5rem 1.5rem;
        @include media-breakpoint-up(lg) {
          padding: 1.5rem;
        }
        background-color: white;
        border-radius: $border-radius;
        & .theme-btn {
          padding: 0;
        }
      }
      & .card-title {
        margin: 0;
        color: var(--primary);
      }
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>
