import axiosInstance from 'axios';
import handleError from '@/helpers/apiErrorHandler.js';

const baseUrlFromWindow = process.env.VUE_APP_BACKEND_URL;

const baseUrl = baseUrlFromWindow;

const axios = axiosInstance.create({
  baseURL: baseUrl,
  withCredentials: true,
  timeout: 0,

  headers: {
    'Content-Type': 'application/json'
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError = handleError(error);
    return Promise.reject(customError);
  }
);

export default axios;
