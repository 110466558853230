<template>
  <div class="search__box">
    <div class="search">
      <b-icon icon="search" class="search__icon"></b-icon>
      <input
        type="search"
        maxlength="30"
        class="search__input"
        id="search-input"
        :placeholder="$t('view.search.placeholder')"
        v-model="searchText"
      />
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    data() {
      return {
        debounce: null,
        searchText: ''
      };
    },
    watch: {
      searchText() {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.$emit('search', this.searchText);
        }, 500);
      }
    },
    methods: {
      clear() {
        this.searchText = '';
      }
    }
  };
</script>

<style lang="scss" scoped>
  // maybe global for all input type search?
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .search__box {
    max-width: 100%;
    width: 100%;
    margin-right: 2rem;

    @include media-breakpoint-down(sm) {
      order: 4;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      margin-right: 0;
    }

    .search {
      position: relative;

      &__icon {
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: $gray-300;
      }
    }

    .search__input {
      padding: 1rem 1.5rem 1rem 3.5rem;
      width: 100%;
      max-width: 100%;
      border: none;
      border-radius: 5px;
      background-color: $gray-100;

      @include media-breakpoint-down(md) {
        padding: 0.8rem 1.5rem 0.8rem 3.5rem;
      }

      &:focus {
        outline: none;
      }
    }
  }
</style>
