import axios from '@/plugins/axios';

const basketServices = {
  // creates a new basket object on cvision side
  createBasket() {
    return axios.post(`/basket`).then((resp) => resp.data);
  },

  // retrieve basket from cvision side
  loadBasket() {
    return axios.get(`/basket`).then((resp) => resp.data);
  },

  // updates the given basket on cvision side an returns the new one
  updateBasket(updateData) {
    return axios.put(`/basket`, updateData).then((resp) => resp.data.basket);
  },

  // mark product to enable adding it to basket
  setImportantHintCheckbox(productNumber) {
    return axios
      .post(`user/important-hints`, {
        product_number: productNumber
      })
      .then((resp) => {
        if (!resp.data.result) {
          console.error('error'); // missing error handling
        }
        return resp.data;
      });
  },
  // calls C*Vision api to set addresses to the basket object
  setAddressToBasket(updateData) {
    return axios.post(`/address`, updateData).then((resp) => resp.data);
  },

  // calls C*Vision api to validate addresses
  validateBasketAddresses() {
    return axios.post(`/address/validate`).then((resp) => resp.data);
  },

  //
  setTermsAndConditions(value) {
    return axios
      .post('user/terms-and-conditions', {
        TnC_status: value
      })
      .then((resp) => resp.data);
  }
};
export default basketServices;
