<template>
  <section class="address">
    <div class="container-xl mb-5">
      <div class="row">
        <div class="col-md-8 col-xl-7">
          <CheckoutNavigation />
          <DefaultHeadline heading-size="h1" :headline-text="$t('view.checkout.address.headline')" is-headline />
          <div class="address__container">
            <AddressForm
              ref="deliveryAddressForm"
              :use-short-form="useShortAddressForm"
              :order="deliveryAddress"
              :server-errors="serverErrorsDelivery"
              :readonly="readonlyFields"
              :translateBlocker="translateBlocker"
              @translated="translated"
            />
          </div>
          <div class="address__container address__container-bottom">
            <DefaultInputRadio v-model="selectedAddressOption" :options="deliveryOptions" @changed="handleAddressOptionSelection" />
            <div v-show="selectedAddressOption === 'different_invoice_address'" class="mt-4">
              <AddressForm
                ref="invoiceAddressForm"
                :translateBlocker="translateBlocker"
                @translated="translated"
                :order="invoiceAddress"
                :server-errors="serverErrorsInvoice"
                :allCountries="true"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xl-4 offset-xl-1">
          <div class="basket-summary__wrapper">
            <BasketSummary :button-text="$t('view.checkout.cart.summary.button.order')" class="basket-summary" @button-click="handleFormValidation" />
            <div class="required_hint">* {{ $t('form.required') }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import CheckoutNavigation from '@/components/CheckoutNavigation/CheckoutNavigation.vue';
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import DefaultInputRadio from 'building-blocks/components/DefaultElements/DefaultInputRadio.vue';
  import AddressForm from '@/components/AdressForm/AddressForm.vue';
  import BasketSummary from '@/components/Basket/BasketSummary.vue';
  import { mapGetters, mapState } from 'vuex';

  const emptyFieldsStructure = {
    salutation: null,
    first_name: null,
    last_name: null,
    firm_name: null,
    firm_department: null,
    address: null,
    address_2: null,
    zip_code: null,
    city: null,
    country_id: null,
    phone_number: null,
    email: null,
    email_repeat: null
  };
  const fieldNames = Object.keys(emptyFieldsStructure);

  export default {
    components: {
      CheckoutNavigation,
      DefaultHeadline,
      DefaultInputRadio,
      AddressForm,
      BasketSummary
    },

    data() {
      return {
        deliveryAddressNotEditable: false,
        invoiceAddressNotEditable: false,
        serverErrorsDelivery: { ...emptyFieldsStructure },
        serverErrorsInvoice: { ...emptyFieldsStructure },
        deliveryAddress: { ...emptyFieldsStructure },
        invoiceAddress: { ...emptyFieldsStructure },
        readonlyFields: {},
        selectedAddressOption: 'same_invoice_address',
        translateBlocker: false
      };
    },
    computed: {
      ...mapGetters({
        isShortAddressSufficent: 'basket/isShortAddressSufficent'
      }),
      ...mapState({
        existingDeliveryAddress: (state) => state.basket.deliveryAddress,
        existingInvoiceAddress: (state) => state.basket.invoiceAddress,
        deliveryAddressWithoutEmailEditable: (state) => state.basket.deliveryAddressWithoutEmailEditable,
        deliveryAddressEmailEditable: (state) => state.basket.deliveryAddressEmailEditable
      }),
      useShortAddressForm() {
        // Check whether the address contains one of the keys above and if it is empty or not.
        if (this.existingDeliveryAddress) {
          const longAddressKeys = ['address', 'address_2', 'zip_code', 'city', 'phone_number'];
          const containsLongAddressData =
            Object.entries(this.existingDeliveryAddress).find(([key, val]) => {
              return longAddressKeys.includes(key) && val.length !== 0;
            }) !== undefined;
          if (containsLongAddressData) {
            return false;
          }
        }
        return this.isShortAddressSufficent;
      },
      deliveryOptions() {
        return [
          { text: 'view.checkout.address.invoice.identical', value: 'same_invoice_address' },
          { text: 'view.checkout.address.invoice.different', value: 'different_invoice_address' }
        ];
      }
    },
    mounted() {
      this.initForm();
    },
    methods: {
      // Blocker for translate inside AddressForm for salutations
      translated() {
        this.translateBlocker = true;
      },
      /**
       * Initializes the form by prefilling any fields that are alredy existing.
       */
      async initForm() {
        // When we have an already existing delivery address, we need to prefill the input ...
        if (this.existingDeliveryAddress) {
          this.deliveryAddress = { ...this.existingDeliveryAddress, email_repeat: this.existingDeliveryAddress.email };
          // ... and lock the input fields

          await this.validatePresetAddress();
          this.lockDeliveryAddressInputFields();
        }
        // When we have an already existing invoice address, which is different to the delivery,then we need to prefill the input
        if (this.existingInvoiceAddress) {
          if (!this.isAddressEqual(this.existingInvoiceAddress, this.existingDeliveryAddress)) {
            this.invoiceAddress = { ...this.existingInvoiceAddress, email_repeat: this.existingInvoiceAddress.email };
            this.selectedAddressOption = 'different_invoice_address';
          }
        }
      },
      /**
       * Checks Frontend Validation and scrolls to invalid input or submits.
       */
      handleFormValidation() {
        let allInputsValid;

        if (this.selectedAddressOption === 'same_invoice_address') {
          this.invoiceAddress = { ...this.deliveryAddress };
          allInputsValid = this.$refs.deliveryAddressForm.areAllFieldsValid();
        }

        if (this.selectedAddressOption === 'different_invoice_address') {
          allInputsValid = this.$refs.deliveryAddressForm.areAllFieldsValid() && this.$refs.invoiceAddressForm.areAllFieldsValid();
        }

        if (allInputsValid) {
          this.submit();
        } else {
          this.scrollToInvalidInput();
        }
      },
      /**
       * Validate the input and send the user to the confirm page or display error messages.
       */
      async submit() {
        const { errors, generalError } = await this.setEditableAddressToBasket();

        if (errors.length === 0 && generalError === null) {
          // The provided address is okay, so move forward to the payment
          this.$router.push({ path: this.$routeHandler('/checkout/confirm') });
        } else {
          // We need to re-init the form so the new basket from the api will be applied
          // TODO when we have already a valid address and try to enter a invalid one, then the initForm will refill it with the last valid one from the api. Is this a problem?
          // this.initForm()

          // We have to display the errors for the user
          this.displayErrorMessages(errors);
          // And finally show a toast with a general message
          // TODO check ErrorHandling for general Errors
          // generalError.toast()
        }
      },
      /**
       * Commits the final address to the basket store.
       */
      async setEditableAddressToBasket() {
        const errors = [];

        let deliveryAddressForSet = null;
        let invoiceAddressForSet = null;

        //  we send only the addresses to the api which can be changed
        if (!this.deliveryAddressNotEditable) {
          deliveryAddressForSet = this.deliveryAddress;
        }
        if (!this.invoiceAddressNotEditable) {
          invoiceAddressForSet = this.invoiceAddress;
        }

        const addressData = this.createAddressData(deliveryAddressForSet, invoiceAddressForSet);
        const setAddressResult = await this.$store.dispatch('basket/setAddressesToBasket', addressData);
        const deliveryAddressErrors = setAddressResult.deliveryAddressErrors;
        const invoiceAddressErrors = setAddressResult.invoiceAddressErrors;
        const deliveryUpdateError = setAddressResult.deliveryUpdateError;
        const invoiceUpdateError = setAddressResult.invoiceUpdateError;
        const generalError = setAddressResult.error;

        if (deliveryAddressErrors) {
          deliveryAddressErrors.forEach((errorField) => {
            errors.push({ address: 'DEL', type: errorField.field_name, message: errorField.error_message });
          });
        }

        if (invoiceAddressErrors) {
          invoiceAddressErrors.forEach((errorField) => {
            errors.push({ address: 'INV', type: errorField.field_name, message: errorField.error_message });
          });
        }

        if (deliveryUpdateError) {
          errors.push({ address: 'DEL', type: 'GENERAL', message: deliveryUpdateError });
        }
        if (invoiceUpdateError) {
          errors.push({ address: 'INV', type: 'GENERAL', message: invoiceUpdateError });
        }

        return { errors, generalError };
      },
      /**
       * Helper function to make the form uneditable.
       */
      lockDeliveryAddressInputFields() {
        // when we have an existing address we need to check if we can edit it and which input fields need to be locked
        fieldNames.forEach((key) => this.readonlyFields[key] = !['', null, undefined, false].includes(this.deliveryAddress[key]) && !this.deliveryAddressWithoutEmailEditable);
        this.readonlyFields = {
          ...this.readonlyFields,
          email: !this.deliveryAddressEmailEditable,
          email_repeat: !this.deliveryAddressEmailEditable
        };
      },
      /**
       * @param {Object} a1 First Address
       * @param {Object} a2 Second Address
       * @return {Boolean}
       */
      isAddressEqual(a1, a2) {
        return !Object.keys(a1)
          .map((k) => a1[k] === a2[k] || k === 'address_type')
          .includes(false);
      },
      /**
       * Updates the selected address option.
       */
      handleAddressOptionSelection(value) {
        this.selectedAddressOption = value;
      },
      /**
       * Create a combined objects from the addresses, which can be send to C*vision.
       * @param {Object} deliveryAddress
       * @param {Object} invoiceAddress
       * @return {Object}
       */
      createAddressData(deliveryAddress, invoiceAddress) {
        const combinedAddressData = { delivery_address: null, invoice_address: null };
        if (deliveryAddress) {
          const deliveryAddressData = this.createAddressDataSingle(deliveryAddress, 'DEL');
          combinedAddressData.delivery_address = deliveryAddressData;
        }
        if (invoiceAddress) {
          const invoiceAddressData = this.createAddressDataSingle(invoiceAddress, 'INV');
          combinedAddressData.invoice_address = invoiceAddressData;
        }
        return combinedAddressData;
      },
      /**
       * Creates an object containing the address without the repeated email and
       * with its address type, so it can be send to C*vision.
       * @param {Object} address
       * @param {String} addressType
       * @return {Object}
       */
      createAddressDataSingle(address, addressType) {
        const finalAddress = { ...{ ...address }, address_type: addressType };
        return finalAddress;
      },

      /**
       * Validates any preset address, i.e. the address has already been added before and still remains in memory.
       */
      async validatePresetAddress() {
        if (this.deliveryAddressWithoutEmailEditable || this.deliveryAddressEmailEditable) {
          return [];
        }
        // When we have addresses which cannot be edited, we only have to check if they are still valid for the basket
        const validateAddressResult = await this.$store.dispatch('basket/validateBasketAddresses');
        const deliveryAddressValidationResult = validateAddressResult.deliveryAddressValidationResult;
        if (deliveryAddressValidationResult.addressIsValid) {
          return [];
        }
        const errors = [];
        const addressError = deliveryAddressValidationResult.address_error;
        if (addressError) {
          errors.push({ address: 'DEL', type: 'GENERAL', message: addressError });
        }
        const addressFieldErrors = deliveryAddressValidationResult.address_field_errors;
        if (addressFieldErrors) {
          addressFieldErrors.forEach((errorField) => {
            errors.push({ address: 'DEL', type: errorField.field_name, message: errorField.error_message });
          });
        }
        return errors;
      },

      /**
       * Constructs and displays any field specific error messages.
       */
      displayErrorMessages(errors) {
        const deliveryFieldErrors = errors.filter((item) => item.type !== 'GENERAL' && item.address === 'DEL');
        const deliveryFieldErrorNames = deliveryFieldErrors.map((item) => item.type);
        const invoiceFieldErrors = errors.filter((item) => item.type !== 'GENERAL' && item.address === 'INV');
        const invoiceFieldErrorNames = invoiceFieldErrors.map((item) => item.type);
        fieldNames.forEach((fieldName) => {
          if (deliveryFieldErrorNames.includes(fieldName)) {
            this.serverErrorsDelivery[fieldName] = true;
          }
          if (invoiceFieldErrorNames.includes(fieldName)) {
            this.serverErrorsInvoice[fieldName] = true;
          }
        });
      },
      /**
       * Scrolls to the topmost invalid Input Field
       */
      scrollToInvalidInput() {
        setTimeout(() => {
          if (document.getElementsByClassName('is-invalid').length >= 1) {
            document.getElementsByClassName('is-invalid')[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
        }, 500);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .error-message {
    bottom: 2rem;
    right: 2rem;
    position: fixed;
    z-index: $z-index-high;
    min-width: 10rem;
    max-width: 40rem;
    overflow: hidden;
  }
  .address {
    margin-top: 3rem;
    &__container {
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 1.5rem;
      margin-top: 2rem;

      &-bottom {
        margin-bottom: 2rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  .basket-summary__wrapper {
    @include media-breakpoint-up(md) {
      position: -webkit-sticky;
      position: sticky;
      top: 12rem;
    }
  }
  .required_hint {
    @extend %font-md;
    margin-top: 2rem;
  }
</style>
