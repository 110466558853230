<template>
  <Accordion :headline="headline" :groups="mgnlGroups" />
</template>

<script>
  import { mgnlToJson } from '../helpers/utils.js';
  import Accordion from 'building-blocks/components/Accordion/Accordion.vue';

  export default {
    components: { Accordion },
    props: {
      headline: {
        type: String
      },
      groups: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      mgnlGroups() {
        return mgnlToJson(this.groups).map((group) => {
          return {
            headline: group.headline,
            cards: mgnlToJson(group.cards)
          };
        });
      }
    }
  };
</script>
