<template>
  <div class="container login__container" :class="bgColor">
    <div class="row">
      <div class="col-sm-12 col-md-7 col-lg-5">
        <div class="login__box">
          <div id="login" class="card login__card" :class="[bgColor, textColor]">
            <div class="row no-gutters">
              <div class="col">
                <div class="card-body">
                  <h4 v-if="headline" class="card-title" :class="fontColor === 'dark' ? 'text-dark' : 'text-light'">{{ headline }}</h4>
                  <form class="login__card__form">
                    <CodeInputForm :buttonFontColor="fontColor" :button-text="buttonText" :inputPlaceholder="inputPlaceholder" redirection="/welcome" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CodeInputForm from '@/components/CodeInputForm.vue';

  export default {
    components: {
      CodeInputForm
    },
    props: {
      bgVariant: {
        type: String,
        default: 'primary'
      },
      textVariant: {
        type: String,
        default: 'white'
      },
      headline: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      redirection: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      inputPlaceholder: {
        type: String,
        default: ''
      },
      fontColor: {
        type: String,
        default: ''
      },
      buttonColorVariant: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        bgColor: 'bg-' + this.bgVariant,
        textColor: 'text-' + this.textVariant
      };
    }
  };
</script>

<style lang="scss" scoped>
  .login {
    &__container {
      position: relative;
      width: 100%;
      height: 100%;
      .row {
        height: 100%;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: none;
      }
      @include media-breakpoint-up(md) {
        background: transparent !important;
      }
    }
    &__box {
      z-index: $z-index-middle;
      position: relative;
      width: 100%;
      margin-top: 15rem;
      @include media-breakpoint-up(md) {
        width: 360px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        background: transparent;
        z-index: $z-index-low;
      }
      @include media-breakpoint-up(xl) {
        width: 420px;
      }
    }
    &__card {
      color: var(--light);
      box-shadow: none;
      & .card-body {
        padding: 1rem;
      }
      & .card-title,
      & .card-text {
        padding: 0 1.5rem;
      }
      & .card-title {
        @extend %font-h1;
        margin-bottom: 1rem;
      }
      @include media-breakpoint-down(sm) {
        background: transparent !important;
      }
      @include media-breakpoint-up(md) {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        text-align: center;
        & .card-body {
          padding: 1.5rem;
        }
        & .card-title,
        & .card-text {
          padding: 0;
        }
      }
      @include media-breakpoint-up(xl) {
        & .card-body {
          padding: 2rem;
        }
      }
      &__form {
        border-radius: $border-radius;
        background: var(--light);
        padding: 1rem;
        margin-top: 1rem;
        box-shadow: $box-shadow-sm;
        @include media-breakpoint-up(md) {
          margin-top: 1.5rem;
          padding: 1.5rem;
        }
        @include media-breakpoint-up(lg) {
          margin-top: 2rem;
          padding: 2rem;
        }
      }
    }
  }
</style>
