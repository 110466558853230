<template>
  <div class="product-description">
    <div id="description" class="detail-card">
      <div v-if="isReward">
        <div class="product-description__headline mb-3">{{ $t('view.product.info.title_description') }}</div>
        <div class="product-description__text" v-html="product.description" />
      </div>
      <div v-if="!isReward" class="description-tabnav">
        <nav>
          <ul class="nav nav-tabs nav-fill">
            <li v-if="product.redemption_conditions">
              <a class="nav-item nav-link" :class="{ active: activeTab === 1 }" @click="setActiveTab(1)">{{ $t('view.product.info.title_conditions') }}</a>
            </li>
            <li v-if="product.description">
              <a class="nav-item nav-link" :class="{ active: activeTab === 2 }" @click="setActiveTab(2)">{{ product.name }}</a>
            </li>
            <li v-if="product.info_url">
              <a class="nav-item nav-link" :class="{ active: activeTab === 3 }" @click="setActiveTab(3)">{{ $t('view.product.info.title_links') }}</a>
            </li>
          </ul>
        </nav>
        <div class="tab-content mt-4">
            <div
              v-show="product.redemption_conditions && activeTab === 1"
              key="1"
              class="tab-pane tab-nav-content"
              :class="{ 'show active': activeTab === 1 }"
              v-html="product.redemption_conditions"
            ></div>
            <div
              v-show="product.description && activeTab === 2"
              key="2"
              class="tab-pane tab-nav-content"
              :class="{ 'show active': activeTab === 2 }"
              v-html="product.description"
            ></div>
            <div v-show="product.info_url && activeTab === 3" key="3" class="tab-pane fade tab-nav-content" :class="{ 'show active': activeTab === 3 }">
              <a :href="product.info_url" rel="noopener" target="_blank">{{ product.info_url }}</a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      },
      isReward: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeTab: 1
      };
    },
    created() {
      let defaultActiveTab;
      if (this.product.redemption_conditions) {
        defaultActiveTab = 1;
      } else if (this.product.description) {
        defaultActiveTab = 2;
      } else if (this.product.info_url) {
        defaultActiveTab = 3;
      }
      this.activeTab = defaultActiveTab;
    },
    methods: {
      setActiveTab(value) {
        this.activeTab = value;
      }
    }
  };
</script>

<style lang="scss">
  .product-description {
    &__headline {
      @extend %font-h5;
    }
    &__text {
      margin-bottom: 0.5rem;
      @extend %font-md;
    }
  }
  .description-tabnav {
    .nav-tabs {
      display: flex;
      justify-content: flex-start;
      flex-flow: nowrap;
      width: 100%;
      overflow-y: hidden !important;
      overflow: scroll;
      overflow-x: scroll;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      padding-bottom: 6px;
      @include media-breakpoint-up(md) {
        flex-wrap: wrap;
        width: auto;
        overflow-x: hidden !important;
        overflow-y: none;
      }
      .nav-link {
        @extend %font-md;
        padding: 0.5rem;
        margin-right: 1rem !important;
        border-bottom: 2px solid transparent;
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        max-width: 12rem;
        min-width: 6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        cursor: pointer;
        outline: none;
        @include media-breakpoint-up(md) {
          width: auto;
          max-width: none;
          min-width: 0;
          padding: 0 0 0.5rem 0;
          margin-right: 1.5rem;
          white-space: none;
          overflow: none;
          text-overflow: none;
        }
        &:hover,
        &.active:hover,
        &.active {
          border-bottom: 2px solid var(--primary);
          color: var(--primary);
          border-top: transparent;
          border-left: transparent;
          border-right: transparent;

          @media (max-width: 768px) {
            border-bottom: 2px solid transparent;
          }
        }
      }
    }
    .tab-nav-content img {
      max-width: 100%;
      height: auto;
    }
    .tab-pane.tab-nav-content.active {
      @extend %font-md;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
