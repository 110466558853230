export function createOptions(maxQuantity, productQuantity) {
  const options = []
  if (productQuantity > maxQuantity) {
    maxQuantity = productQuantity
  }
  for (let i = 1; i <= maxQuantity; i++) {
    options.push(`${i}`)
  }
  return options
}
