<template>
  <div :class="{ centered: isCentered }">
    <div v-if="overlineText" :class="{ overline: overlineText }">{{ overlineText }}</div>
    <div :class="headingClass">{{ headlineText }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      overlineText: {
        type: String,
        default: ''
      },
      headlineText: {
        type: String,
        default: ''
      },
      headingSize: {
        type: String,
        default: 'h1'
      },
      isHeadline: {
        type: Boolean,
        default: false
      },
      isDisplay: {
        type: Boolean,
        default: false
      },
      isCentered: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        h1: false,
        h2: false,
        h3: false,
        h4: false,
        h5: false,
        h6: false,
        d1: false,
        d2: false,
        d3: false,
        d4: false
      }
    },
    computed: {
      headingClass() {
        return {
          h1: this.headingSize === 'h1' && this.isHeadline,
          h2: this.headingSize === 'h2' && this.isHeadline,
          h3: this.headingSize === 'h3' && this.isHeadline,
          h4: this.headingSize === 'h4' && this.isHeadline,
          h5: this.headingSize === 'h5' && this.isHeadline,
          h6: this.headingSize === 'h6' && this.isHeadline,
          d1: this.headingSize === 'd1' && this.isDisplay,
          d2: this.headingSize === 'd2' && this.isDisplay,
          d3: this.headingSize === 'd3' && this.isDisplay,
          d4: this.headingSize === 'd4' && this.isDisplay
        }
      }
    }
  }
</script>
