<template>
  <div class="card">
    <b-icon :icon="icon" class="card-icon" />
    <div class="card-body">
      <h5 class="card-title"><span>{{ title }}</span></h5>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    box-shadow: $box-shadow-sm;
    height: 9rem;
    width: calc(50% - 1rem / 2);
    margin-bottom: 1rem;
    transition: $dur $ease;
    &:hover {
      box-shadow: $box-shadow;
      cursor: pointer;

      svg {
        fill: var(--primary);
        transition: $dur $ease;
      }

      .card-title {
        span {
          background-size: 100% 2px;
        }
      }
    }
    &:not(:nth-child(2n)) {
      margin-right: 1rem;
    }
    @include media-breakpoint-up(md) {
      width: calc(100% / 3 - 2rem / 3);
      &:nth-child(2n) {
        margin-right: 1rem;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      width: calc(20% - 4rem / 5);
      &:nth-child(3n) {
        margin-right: 1rem;
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(xl) {
      &:not(:nth-child(6n)) {
        margin-right: 2rem !important;
      }
      &:nth-child(6n) {
        margin-right: 0;
      }
      width: calc(17% - 11.5rem / 6);
      margin-bottom: 2rem;
    }
    & svg {
      fill: var(--primary-lightest);
      font-size: 300% !important;
      margin: 1.5rem auto 0;
      transition: $dur $ease;
    }
    &-body {
      padding: 1rem 1rem 2rem;
      text-align: center;
    }
    &-title {
      margin-bottom: 0;

      span {
        text-decoration: none;
        background-image: linear-gradient(var(--primary), var(--primary));
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 0% 2px;
        transition: background-size 500ms ease-in-out;
      }

      @media screen and (max-width: 410px) {
        font-size: 14px;
      }
    }

    &-icon {
      width: auto !important;
    }
  }
</style>
