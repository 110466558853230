import axios from '@/plugins/axios';

const orderServices = {
  // creates a new order object on cvision side
  createOrder() {
    return axios.post(`/order/create`).then((resp) => resp.data);
  },

  // check order object on cvision side
  checkOrderCreation() {
    return axios.get(`/order/check`).then((resp) => resp.data);
  }
};

export default orderServices;
