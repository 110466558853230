<template>
  <Newsletter
    :headline="headline"
    :placeholder="placeholder"
    :buttonText="buttonText"
    :description="description"
    :buttonColorVariant="buttonColorVariant"
    :image="parsedImage"
  />
</template>

<script>
  import Newsletter from 'building-blocks/components/Newsletter.vue';

  export default {
    components: { Newsletter },
    props: {
      variant: {
        type: String,
        default: ''
      },
      headline: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      fontColor: {
        type: String,
        default: ''
      },
      buttonColorVariant: {
        type: String,
        default: ''
      },
      image: {
        type: Object,
        default: {}
      },
    },
    computed: {
      parsedImage () {
        const { '@name': name, '@path': path } = this.image;
        return name && path ? {
          link: `${process.env.VUE_APP_MGNL_HOST}/dam${path}`,
          name
        } : null;
      }
    }
  };
</script>
