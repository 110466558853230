<template>
  <div class="app-container">
    <main class="main">
      <slot />
    </main>
    <Cookie />
  </div>
</template>

<script>
  import Cookie from 'building-blocks/components/Cookie/Cookie.vue';

  export default {
    components: {
      Cookie
    },
    mounted() {
      if (window.location.hash) {
        this.$nextTick(() => {
          const el = document.querySelector(window.location.hash);
          if (el) el.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  };
</script>
