<template>
  <section id="categoryBar">
      <div class="container-xl container-xl__category">
        <h3 v-if="headline">{{headline}}</h3>
        <div class="categories">
          <CategoryItem 
            v-for="category in mutatedCategories" 
            :key="category.id"            
            :title="category.name"
            :icon="setIcon(category.id)"
            @click="updateCategoryFilter(category)"
          />
        </div>
          <div class="card-all" v-if="categories.length > catLength">
            <div class="card-body" @click="showAll">
              <h5 class="card-title">Alle Kategorien anzeigen</h5>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CategoryItem from './CategoryItem.vue';
export default {
  name:'CategoriesBar',
  props: {
    headline: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      catLength: this.catCalculation()
    };
  },
  components: { 
    CategoryItem, 
  },
  computed: {
    ...mapGetters({
      categories: "filter/getCategories",
      composedQuery: 'filter/composedQuery'
      }),
    mutatedCategories() {
      return this.getCategories.filter((cat, i) => i < this.catLength)
    },
    getCategories(){
      if(this.$store.state.app.mgnlMode) {
        return [ { "id": 1573, "name": " Technik & Entertainment"}, { "id": 1563, "name": "Beauty & Lifestyle" }, { "id": 1488, "name": "Fashion & Accessoires"}, { "id": 1568, "name": "Freizeit & Reisen"}, { "id": 1558, "name": "Haus & Garten"} ]
      } else {
        return this.categories
      }
    }
  },
  methods: {
    updateCategoryFilter(category) {
      this.$store.commit('filter/SET_CATEGORY', category);
      this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
    },
    showAll() {
      this.catLength = this.categories.length;
    },
    setIcon(catId){
      return this.$store.getters['app/getCategoryIcon'](catId);
    },
    catCalculation() {
      if (window.innerWidth > 1200) {
        return this.getCategories > 6 ? 5 : 6;
      } else if (window.innerWidth > 990 && window.innerWidth < 1200) {
        return this.getCategories > 5 ? 4 : 5;
      } else {
        return this.getCategories > 6 ? 5 : 6;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-xl__category {
  padding: 3rem 1rem 1rem;
  z-index: 1;
}
.categories {
  display: flex;
  flex-wrap: wrap;
}
.card-all{
  border-radius: 5px;
  background-color: var(--primary);
  box-shadow: $box-shadow-sm;
  transition: $dur $ease;
  max-width: 300px;
  margin: 1rem auto;

  &:hover {
    box-shadow: $box-shadow;
    cursor: pointer;
    transition: $dur $ease;
    background-color: var(--primary-dark);
  }

  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .card-title {
    margin-bottom: 0;
    text-align: center;
    color: $white;
    font-family: $font-semibold;
  }
}
</style>