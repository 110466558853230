<template>
  <div class="custom-header">
    <div class="container-xl">
      <div class="custom-header__wrap" :class="{ 'logged-out': !loggedIn }">
        <HeaderLogoContainer :logo="logo" :loggedIn="loggedIn" :class="loggedIn ? ['d-none', 'd-lg-block'] : ''" />
        <div class="nav-wrapper" v-if="loggedIn">
          <ul class="nav custom-nav">
            <li v-if="!minimalMode" class="nav-item">
              <button class="btn btn-white global-search-button" @click="showSearch()">
                <b-icon icon="search" class="search-icon"></b-icon> {{ $t('global.navigation_items.search') }}
              </button>
            </li>
            <li class="nav-item nav__logout" :class="isRedemptionShop ? 'nav__logout--hidden' : ''" v-if="!isOpenShop">
              <span class="nav-link" @click="logout()">
                <b-icon icon="logout" /> 
                <span>{{ $t('global.navigation_items.logout') }}</span>
              </span>
            </li>
            <li ref="dropdownButton" class="nav-item dropdown account-dropdown" v-if="isRedemptionShop">
              <a
                id="navbarDropdownMenuLink"
                href="javascript:void(0)"
                class="nav-link dropdown-toggle dropdown-toggle-no-caret"
                @click="toggleDropdown"
              >
                <b-icon icon="person"></b-icon>
                {{ $currency(prepaidValue) }}
              </a>
              <div ref="dropdown" class="dropdown-menu" :class="{ show: isDropdownVisible }">
                <div class="account-dropdown-menu">
                  <router-link :to="$routeHandler('/voucher-overview')" @click="toggleDropdown">
                    <DefaultButton class="mt-2 btn btn-primary btn-block" block :button-text="$t('global.header.credits.button')" :type="'button'" />
                  </router-link>
                  <section class="py-4">
                    <div class="row">
                      <div class="col"><hr class="hr" /></div>
                      <div class="col-auto text">oder weiteren Code hinzufügen</div>
                      <div class="col"><hr class="hr" /></div>
                    </div>
                  </section>
                  <CodeInputForm
                    ref="codeInputForm"
                    :inputPlaceholder="$t('global.voucher_form.placeholder')"
                    :buttonText="$t('global.voucher_form.button')"
                    @success="toggleDropdown"
                  />
                </div>
              </div>
            </li>
            <li class="nav-item language-item">
              <LanguageSwitch />
            </li>
            <li class="nav-item">
              <router-link :to="$routeHandler('/basket')" class="nav-link">
                <b-icon icon="cart3" />
                {{ itemsCounter }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isSearchVisible" class="search">
        <ProductSearch @close="closeSearch" />
      </div>
    </transition>
  </div>
</template>
<script>
  import HeaderLogoContainer from './HeaderLogoContainer.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import CodeInputForm from '@/components/CodeInputForm.vue';
  import ProductSearch from '@/components/Product/ProductSearch.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import headerMixin from '@/plugins/mixins/headerMixin';
  import LanguageSwitch from '@/components/LanguageSwitch.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      HeaderLogoContainer,
      DefaultButton,
      CodeInputForm,
      ProductSearch,
      LanguageSwitch,
      BIcon
    },
    data() {
      return {
        isSearchVisible: false,
        isDropdownVisible: false
      };
    },
    mixins: [headerMixin],
    props: {
      loggedIn: {
        type: Boolean,
        default: false
      },
      logo: {
        type: String
      }
    },
    computed: {
      ...mapGetters({
        itemsCounter: 'basket/positionsCounter',
        minimalMode: 'pages/minimalMode',
        isPunchout: 'punchout/isPunchout',
        isRedemptionShop: 'app/isRedemptionShop'
      }),
      prepaidValue() {
        return this.$store.state.basket.prepaidValue;
      },
      isOpenShop() {
        return this.$store.state.app.isOpenShop;
      }
    },
    methods: {
      showSearch() {
        this.isSearchVisible = true;
      },
      closeSearch() {
        this.isSearchVisible = false;
      },
      resetCodeInputField() {
        this.$refs.codeInputForm.resetInputField();
      },
      setFocusToCodeInputField() {
        this.$refs.codeInputForm.setFocusToInput();
      },
      toggleDropdown() {
        if (this.isDropdownVisible) {
          this.resetCodeInputField();
          window.removeEventListener('click', this.hideDropdownOnOutsideClick);
        } else {
          window.addEventListener('click', this.hideDropdownOnOutsideClick);
          this.setFocusToCodeInputField();
        }
        this.isDropdownVisible = !this.isDropdownVisible;
      },
      hideDropdownOnOutsideClick(event) {
        if (this.isDropdownVisible) {
          if (!this.$refs.dropdown.contains(event.target) && !this.$refs.dropdownButton.contains(event.target)) {
            this.isDropdownVisible = false;
            window.removeEventListener('click', this.hideDropdownOnOutsideClick);
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .text {
    color: #999;
    font-size: 0.8rem;
  }
  .hr {
    margin: 0.5rem 0;
  }
  .custom-header {
    position: relative;
  }
  .custom-header__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;

    &.logged-out {
      height: 3.5rem;
    }

    @include media-breakpoint-up(md) {
      &.logged-out {
        padding: 1rem 0;
        height: 5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      &,
      &.logged-out {
        padding: 1rem 0;
        height: 5rem;
      }
    }
    .nav {
      justify-content: space-between;
      align-items: center;

      &,
      &-wrapper {
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: max-content;
        }
      }

      &-item {
        svg {
          margin-right: 5px;
        }

        &:hover {
          svg {
            transition: $dur $ease;
            color: var(--primary);
          }
        }
      }

      &-link {
        cursor: pointer;
        color: $body-color;
        border-radius: $border-radius;
        border-bottom: 0 !important;
        font-family: $font-semibold;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(sm) {
          padding: 0.8rem 1rem;
        }

        &:hover {
          transition: $dur $ease;
          color: var(--primary);
          background-color: var(--transparent-light-lowest);
        }
        &.active {
          border: 0;
          outline: 0;
        }
      }

      &__logout {
        &--hidden {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }
  }
  .account-dropdown .dropdown-menu {
    right: 0;
    left: auto;
    border: 0;
    @include media-breakpoint-down(md) {
      right: -100%;
    }
    .account-dropdown-menu {
      width: 20.5rem;
      padding: 0.5rem 1.5rem 0.75rem;
      border: 0;

      & .b-dropdown-form {
        padding: 0;
        & .form-control {
          margin-bottom: 0.5rem;
          padding: 0.81rem 1.5rem;
          height: max-content;
          text-align: center;
        }
      }
    }
  }
  .search {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--transparent-light-medium);
    overflow: auto;
    z-index: 1;
  }
  .btn.global-search-button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      font-size: .9rem;
    }

    .b-icon.bi {
      vertical-align: inherit;
    }
    &:hover {
      background-color: var(--transparent-light-lowest);
      color: var(--primary);
      transition: $dur $ease;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .language-item {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
</style>
