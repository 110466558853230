/**
 * @param {*} array
 * @returns [*]
 */
const removeDuplicates = (array) => [...new Set(array)]

/**
 * @param {*} productArray
 * @param {*} filterArray
 * @returns [{*}]
 */
export function filterProductsByType(productArray, filterArray) {
  let filteredProducts = []
  filterArray.forEach((filter) => {
    const products = productArray.filter((product) => Array.isArray(filter.value) ? filter.value.includes(product.type_group) : product.type_group === filter.value)
    filteredProducts = filteredProducts.concat(products)
  })
  return [...new Set(filteredProducts)];
}

/**
 * @param {*} productArray
 * @param {*} minValue
 * @param {*} maxValue
 * @returns [{*}]
 */
export function filterProductsByPriceRange(productArray, minValue, maxValue) {
  return productArray.filter((product) =>
    product.variations.some((variation) => variation.selling_price >= minValue && variation.selling_price <= maxValue)
  )
}

/**
 * @param {*} productArray
 * @returns [{*}]
 */
export function filterProductsByDiscount(productArray) {
  return productArray.filter((product) => {
    const isReward = product.type_group === "PHY"
    if (isReward) {
      const retailPrice = product.recommended_retail_price
      const sellingPrice = product.variations[0].selling_price
      return retailPrice && retailPrice > sellingPrice
    } else {
      return Boolean(product.discount);
    }
  })
}

/**
 * @param {*} productArray
 * @param {*} brands
 * @returns [{*}]
 */
export function filterProductsByBrand(productArray, selectedBrands) {
  const ret = productArray.filter((product) => {
    return selectedBrands.includes(product.brand)
  })
  return ret
}

/**
 * @param {*} productArray
 * @param {*} category
 * @returns [{*}]
 */
export function filterProductsByCategory(productArray, category) {
  const ret = productArray.filter((product) => {
    return product.categories.length > 0 && product.categories.find((categoryId) => categoryId === category.id)
  })
  return ret
}

/**
 * @param {*} productArray
 * @param {*} filterArray
 * @returns [{*}]
 */
export function filterProductsByShippingMethod(productArray, filterArray) {
  let filteredProducts = []
  filterArray.forEach((filter) => {
    const filterValue = typeof filter.value === 'string' ? parseInt(filter.value) : filter.value
    const products = productArray.filter((product) => product.delivery_options.some((option) => option.id === filterValue))
    filteredProducts = filteredProducts.concat(products)
  })
  return removeDuplicates(filteredProducts)
}

/**
 * @param {*} productArray
 * @param {*} filterArray
 * @returns [{*}]
 */
// The filter criteria "in der Filiale ausdrucken" should always exclude physical products || ARIANE-621
export function filterProductsByRedemption(productArray, filterArray) {
  let filteredProducts = []
  filterArray.forEach((filter) => {
    const products = productArray.filter((product) => product[filter.value] && product.type_group !== 'PHY')
    filteredProducts = filteredProducts.concat(products)
  })
  return removeDuplicates(filteredProducts)
}
