<template>
  <section class="confirm">
    <div class="container-xl mb-5">
      <div class="row">
        <div class="col-md-7 col-xl-7">
          <CheckoutNavigation />
          <ConfirmListGroup :list="formattedConfirmListArray" />
          <div v-if="basketItemsWithDeliveryTypePost.length > 0" class="shoppingcart-variant mb-5">
            <h3 class="mb-3">{{ $t('view.product.shipping.post.title') }}</h3>
            <BasketItemGroup :products="basketItemsWithDeliveryTypePost" />
          </div>
          <div v-if="basketItemsWithDeliveryTypeEmail.length > 0" class="shoppingcart-variant mb-5">
            <h3 class="mb-3">{{ $t('view.product.shipping.mail.title') }}</h3>
            <BasketItemGroup :products="basketItemsWithDeliveryTypeEmail" />
          </div>
          <div v-if="basketItemsWithDeliveryTypeSMS.length > 0" class="shoppingcart-variant mb-5">
            <h3 class="mb-3">{{ $t('view.product.shipping.SMS.title') }}</h3>
            <BasketItemGroup :products="basketItemsWithDeliveryTypeSMS" />
          </div>
        </div>
        <div class="col-md-4 col-xl-4 offset-xl-1">
          <BasketSummary :confirmPage="true" :button-disabled="checker()"
            ref="basketSummaryRef"
            :button-text="$t('view.checkout.cart.summary.button.buy')"
            @button-click="isOpenShop ? verifyHcaptcha() : createOrder()" @captchaToken="e => captchaToken = e"
            @toggleTermsAndCond="e => confirmedTnC = e" @newsletterConfirm="e => newsletterConfirm = e">
          </BasketSummary>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import salutations from '@/config/salutations.js';
import paymentTypes from '@/config/paymentTypes.js';
import CheckoutNavigation from '@/components/CheckoutNavigation/CheckoutNavigation.vue';
import ConfirmListGroup from '@/components/ConfirmListGroup.vue';
import BasketItemGroup from '@/components/Basket/BasketItemGroup.vue';
import BasketSummary from '@/components/Basket/BasketSummary.vue';

export default {
  components: {
    CheckoutNavigation,
    ConfirmListGroup,
    BasketItemGroup,
    BasketSummary
  },
  async created() {
    try {
      const response = await this.$store.dispatch('order/checkOrderCreation');
      if (!response.can_create_order) {
        throw this.$store.commit('app/SET_TOAST', {
          message: this.$t('view.error.general'),
          colorVariant: 'danger',
          duration: 7
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  data() {
    return {
      salutations: salutations,
      paymentTypes: paymentTypes,
      confirmedTnC: false,
      newsletterConfirm: false,
      captchaToken: ''
    };
  },

  computed: {
    ...mapGetters({
      basketItemsWithDeliveryTypePost: 'basket/getPositionsWithDeliveryTypePost',
      basketItemsWithDeliveryTypeEmail: 'basket/getPositionsWithDeliveryTypeEmail',
      basketItemsWithDeliveryTypeSMS: 'basket/getPositionsWithDeliveryTypeSMS',
      postPayment: 'basket/getPostPaymentType',
      newsletterData: 'basket/getNewsletterData'
    }),
    ...mapState({
      basketNumber: (state) => state.basket.basketNumber,
      deliveryAddress: (state) => state.basket.deliveryAddress,
      invoiceAddress: (state) => state.basket.invoiceAddress,
      basketPayments: (state) => state.basket.payments
    }),

    isOpenShop() {
      return this.$store.state.app.isOpenShop;
    },

    formattedConfirmListArray() {
      const deliveryAdressObject = this.createFormattedAddressObject(this.deliveryAddress);
      const invoiceAddressObject = this.createFormattedAddressObject(this.invoiceAddress);
      const paymentObject = this.createFormattedPaymentObject(this.basketPayments);

      return [deliveryAdressObject, invoiceAddressObject, paymentObject];
    },

    newsletterList() {
      return this.$store.state.pages.additionalOptions.newsletterList;
    },

    newsletterCheckout() {
      return this.$store.getters['pages/newsletterCheckout'];
    }
  },
  methods: {
    /**
     * Creates an formatted Object including Payment Methods as Comma separated List
     * @param { Array }  basketPayments
     * @returns {Object}
     */
    createFormattedPaymentObject(basketPayments) {
      const paymentObj = {
        title: this.$t('view.checkout.summary.payment_method'),
        linkTo: '/checkout/payment',
        payment_methods: ''
      };

      const paymentArray = [];
      basketPayments.forEach((basketPayment) => {
        paymentArray.push(this.paymentTypes.find((paymentType) => paymentType.id === basketPayment.payment_type_id).title);
      });

      const paymentArrayWithoutDuplicates = paymentArray.filter((item, index) => paymentArray.indexOf(item) === index);
      const paymentArrayTranslated = paymentArrayWithoutDuplicates.map((payment) => this.$t(payment));
      const paymentArrayStringified = String(paymentArrayTranslated.join(', '));

      paymentObj.payment_methods = paymentArrayStringified;

      return paymentObj;
    },

    /**
    *Creates an formatted Object from Basket Address with additional Information
    @param {Object} basketAddress
    @return {Object}
    */
    createFormattedAddressObject(basketAddress) {
      const formattedAddress = {
        ...basketAddress,
        linkTo: '/checkout/address',
        title: '',
        salutation: ''
      };
      if (basketAddress.address_type === 'DEL') {
        formattedAddress.title = this.$t('view.checkout.summary.shipping_address');
      } else if (basketAddress.address_type === 'INV') {
        formattedAddress.title = this.$t('view.checkout.summary.invoice_address');
      }
      if (basketAddress.salutation === 2 || basketAddress.salutation === 3 || basketAddress.salutation === 5) {
        this.salutations.forEach((item) => {
          if (item.value === basketAddress.salutation) {
            formattedAddress.salutation = this.$t(item.text);
          }
        });
      } else {
        formattedAddress.salutation = '';
      }
      return formattedAddress;
    },

    checker() {
      if (this.isOpenShop) {
        if (this.confirmedTnC) {
          if (this.captchaToken.length !== 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return !this.confirmedTnC;
      }
    },

    async createOrder() {
      this.confirmedTnC = false;

      if (this.newsletterConfirm && this.newsletterCheckout) {
        const data = {
          listId: Number(this.newsletterList),
          email: this.newsletterData.email,
          source: window.location.origin + ' - checkout',
          attributes: {
            Vorname: this.newsletterData.firstName,
            Nachname: this.newsletterData.lastName
          }
        }
        try {
          await this.$store.dispatch('user/newsletterSubscribe', data);
        } catch (e) {
          console.error('Error newsletter: ', e);
        }
      }

      if (this.postPayment) {
        const paymentType = this.paymentTypes.find(({ id }) => id === this.postPayment.payment_type_id);
        if (!paymentType.saferpay) {
          await this.$store.dispatch('order/createOrder', this.basketNumber);
          await this.$store.dispatch('user/logOut');
          await this.$store.dispatch('basket/resetBasket');
          this.$store.commit('app/SET_HEADER_LOGGEDIN', false);
          this.$router.push({ path: this.$routeHandler('/checkout/confirmation') });
        } else {
          const orderUrl = await this.$store.dispatch('order/createOrder', this.basketNumber);
          if (orderUrl) {
            await this.$store.dispatch('basket/paymentInProcess', 'true');
            window.open(orderUrl, '_self');
          } else {
            this.$store.commit('app/SET_TOAST', {
              message: this.$t('view.error.general'),
              duration: 7
            });
          }
        }
      } else {
        await this.$store.dispatch('order/createOrder', this.basketNumber);
        await this.$store.dispatch('user/logOut');
        await this.$store.dispatch('basket/resetBasket');
        this.$store.commit('app/SET_HEADER_LOGGEDIN', false);
        this.$router.push({ path: this.$routeHandler('/checkout/confirmation') });
      }
    },
    async verifyHcaptcha() {
      try {
        this.setLoading(true);
        const response = await this.$api.verifyCaptchaToken(this.captchaToken);
        const tokenValid = response.success;
        if (tokenValid) {
          await this.$refs.basketSummaryRef.resetHcaptcha();
          this.createOrder();
        } else {
          this.setLoading(false);
          this.$store.commit('app/SET_TOAST', {
              message: this.$t('view.error.general'),
              duration: 7
            });
        }
      } catch (error) {
        this.setLoading(false);
        await this.$refs.basketSummaryRef.resetHcaptcha();
      }
    },
    setLoading(state) {
      this.$store.commit('app/SET_LOADINGSTATE', state);
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm {
  margin-top: 3rem;

  &__container {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 1.5rem;
  }
}
</style>
