<template>
  <div
    class="sidebar__outer"
    :class="{
      'sidebar__outer--alt': additionalOptions.filterVariant === 'sidebar-filter' || additionalOptions.addToCartVariant === 'add-cart-sidebar',
      'hidden': !isSidebarVisible
    }"
  >
    <transition name="slide">
      <div ref="sidebar" v-if="isSidebarVisible" class="sidebar" :title="title">
        <div class="sidebar__header" :class="{ 'sidebar__header--right-align': !title }">
          <slot>
            <h4 class="ml-2 mb-0" v-if="title">{{ $t(title) }}</h4>
          </slot>
          <DefaultButton
            v-if="additionalOptions.filterVariant !== 'sidebar-filter' && additionalOptions.addToCartVariant !== 'add-cart-sidebar'"
            icon-button
            icon="x"
            class="sidebar__close"
            variant="white"
            @click="hideSidebar"
          />
        </div>
        <span
          class="sidebar__close"
          v-if="additionalOptions.filterVariant === 'sidebar-filter' || additionalOptions.addToCartVariant === 'add-cart-sidebar'"
          @click="hideSidebar()"
        ></span>
        <slot name="body" :hideSidebar="hideSidebar"> </slot>
      </div>
    </transition>

    <div v-if="isSidebarVisible" class="sidebar__backdrop" @click="hideSidebar" />
  </div>
</template>

<script>
  import DefaultButton from '../components/DefaultElements/DefaultButton.vue';

  export default {
    components: {
      DefaultButton
    },
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isSidebarVisible: false
      };
    },
    mounted() {
      window.addEventListener('resize', this.enableScrollingOnResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.enableScrollingOnResize);
    },
    computed: {
      additionalOptions() {
        return this.$store.state.pages.additionalOptions;
      }
    },  
    methods: {
      hideSidebar() {
        this.isSidebarVisible = false;
        this.enableBackgroundScrolling();
      },

      showSidebar() {
        this.isSidebarVisible = true;
        setTimeout(() => {
          this.$refs.sidebar.scrollTo(0, 0);
        }, 10);
        this.disableBackgroundScrolling();
      },

      enableBackgroundScrolling() {
        document.body.classList.remove('stop-scrolling'); // ..assets/styles/global.scss
      },
      disableBackgroundScrolling() {
        document.body.classList.add('stop-scrolling'); // ..assets/styles/global.scss
      },
      enableScrollingOnResize() {
        if (window.innerWidth >= 992) {
          this.hideSidebar();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    height: 3rem;
    font-family: $font-semibold;
    &--right-align {
      justify-content: flex-end;
      overflow: hidden;
      height: auto;
      margin-bottom: 0;
    }
  }

  .hidden {
    pointer-events: none;
    display: none !important;
  }
  .sidebar__outer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $z-index-highest;
    display: block;
  }
  .sidebar {
    height: 100%;
    width: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
    overscroll-behavior: contain;
    margin-left: auto;
  }
  .sidebar__close {
    transform: scale(1.5);
    z-index: 9999999;
  }

  .sidebar__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }

  .sidebar__outer--alt {
    .sidebar {
      width: 480px;
      padding-top: 3rem;

      @include media-breakpoint-down(xs) {
        padding-top: 3.25rem;
        width: 100%;
      }
    }

    .sidebar__close {
      position: absolute;
      padding: 1rem;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
      display: block;
      height: 20px;
      width: 20px;
      transform: scale(1);

      @include media-breakpoint-down(xs) {
        top: 1rem;
        right: 1rem;
      }

      &:hover {
        &::before,
        &::after {
          transition: $dur $ease;
          transform: translate(-50%, -50%) rotate(0);
        }
      }

      &::before,
      &::after {
        content: '';
        height: 2px;
        width: 20px;
        background-color: var(--primary);
        transition: $dur $ease;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        position: absolute;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &-wrap {
        position: relative;
      }
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(100%);
  }
</style>