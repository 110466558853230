<template>
  <div ref="swiper" class="swiper">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <slot v-if="!isLocked" name="navigation"></slot>
    <slot v-if="!isLocked" name="pagination"></slot>
  </div>
</template>

<script>
  import Swiper from 'swiper';
  import { Pagination, Navigation, Autoplay } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';

  export default {
    name: 'Swiper',
    props: {
      options: {
        type: Object,
        default: {}
      },
      modules: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        swiper: null,
        isLocked: false
      };
    },
    computed: {
      swiperModules(){
        const defaultModules = [ Pagination, Navigation, Autoplay ];
        return [...new Set(defaultModules.concat(this.modules))];
      },
      optionsDefault() {
        return {
          loop: false,
          slidesPerView: 'auto',
          speed: 600,
          slidesPerGroup: 1,
          grabCursor: true,
          navigation: {
            nextEl: this.$refs.swiper.querySelector('.swiper-btn--next'),
            prevEl: this.$refs.swiper.querySelector('.swiper-btn--prev')
          },
          pagination: {
            el: this.$refs.swiper.querySelector('.swiper-pagination'),
            type: 'bullets'
          }
        }
      },
      swiperOptions() {
        return Object.assign(this.optionsDefault, this.options, {modules: this.swiperModules});
      }
    },
    mounted() {
      this.swiper = new Swiper(this.$refs.swiper, this.swiperOptions);
      if(this.swiper.isLocked) {
        this.swiper.disable();
        this.isLocked = true;
      }
    }
  };
</script>
