<template>
  <StageLoginImage
    :bgColor="toHexColorCode(parseInt(bgColor))"
    :codeHeadline="codeHeadline" 
    :codeDescription="codeDescription"
    :loginHeadline="loginHeadline" 
    :loginDescription="loginDescription"
    :codePlaceholder="codePlaceholder"
    :userNamePlaceholder="userNamePlaceholder"
    :passwordPlaceholder="passwordPlaceholder" 
    :buttonText="buttonText" 
    :image="newImage"
    :fontColor="fontColor"
  />
</template>

<script>
  import StageLoginImage from '@/components/Stage/StageLoginImage.vue';
  export default {
    components: { StageLoginImage },
    data() {
      return {
        newImage: {}
      };
    },
    props: {
      bgColor: {
        type: String,
        default: '-814848'
      },
      codeHeadline: {
        type: String,
        default: ''
      },
      codeDescription: {
        type: String,
        default: ''
      },
      loginHeadline: {
        type: String,
        default: ''
      },
      loginDescription: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      codePlaceholder: {
        type: String,
        default: ''
      },
      userNamePlaceholder: {
        type: String,
        default: ''
      },
      passwordPlaceholder: {
        type: String,
        default: ''
      },
      image: {
        type: Object,
        default: {}
      },
      fontColor: {
        type: String,
        default: ''
      }
    },
    mounted() {
      this.newImage = {
        link: process.env.VUE_APP_MGNL_HOST + '/dam' + this.image['@path'],
        name: this.image['@name']
      };
    },
    methods: {
      toHexColorCode(negDecimal) {
        const colorCodeInDec = 16777216 + negDecimal;
        const colorCodeInHex = colorCodeInDec.toString(16).padStart(6, '0');
        return `#${colorCodeInHex}`;
      }
    }
  };
</script>
