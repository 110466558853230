import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/plugins/i18n';

const $t = i18n.global.t;
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])$/i;

yup.addMethod(yup.string, 'email', function validateEmail() {
  return this.matches(emailRegex, {
    message: (ctx) => {
      return $t('form.validation.string.email', {path: ctx.label || ctx.path || $t('form.field.email.label')});
    },
    name: 'email',
    excludeEmptyString: true,
  });
});
export default { Field, Form, ErrorMessage, yup, configure };