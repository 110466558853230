<template>
  <div class="page page--error">
    <StageDefault 
      textstage 
      :overline="error !== 404 ? ( $t('view.error.page.500.subheadline') + error) : $t('view.error.page.404.subheadline')" 
      :headline="error !== 404 ? $t('view.error.page.500.headline') : $t('view.error.page.404.headline')" 
      :errorPage="true" />
  </div>
</template>

<script>
  import StageDefault from '@/components/Stage/StageDefault.vue';
  export default {
    components: { StageDefault },
    computed: {
      error() {
        return this.$route.meta.error;
      }
    }
  };
</script>
