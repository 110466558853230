<template>
  <div class="promotion__wrap" v-if="getPromotions && getPromotions.length >= 1" data-track-content>
    <div class="container-xl">
      <div class="row">
        <div class="col-sm-6 col-xs-12" v-for="(promo, index) in getPromotions" :key="index">
          <div class="promotion__item"
            data-track-content
            :data-content-name="promo.name"
            :data-content-target="promo.linkTo"
            data-content-piece="Promotion item"
          >
            <router-link class="promotion__item-link" :to="$routeHandler(promo.linkTo)" data-track-content data-content-piece="Banner Link">
              <div class="promotion__item-img">
                <img :src="promo.image" alt="Promotion image">
              </div>
              <h2 class="promotion__item-headline">{{promo.headlineText}}</h2>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      promotions: {
        type: Array
      }
    },

    computed: {
      getPromotions() {
        return this.promotions;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .promotion__wrap {
    z-index: 1;
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
      padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
      padding: 2rem 0;
    }
  }

  .promotion__item {
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
    }

    &-link {
      color: $dark;
    }

    &-img {
      margin-bottom: 1rem;
      height: 22rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(md) {
        height: 14rem;
      }
    }

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
</style>