<template>
  <div class="app-container">
    <DefaultAlert :show="alert.show" :text="alert.text" :color-variant="alert.colorVariant" />

    <slot name="header">
      <Header />
    </slot>
    <main class="main">
      <slot name="before" />
      <slot />
      <slot name="after" />
    </main>
    <slot name="footer">
      <Footer />
    </slot>

    <DefaultModal
      button
      :visible="isUserSessionExpired"
      @button-click="redirectToLoginPage"
      :button-text="$t('view.error.modal.inactivity.button')"
      :text="$t('view.error.modal.inactivity.text')"
      />

    <Cookie />
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import DefaultAlert from 'building-blocks/components/DefaultElements/DefaultAlert.vue';
  import DefaultModal from 'building-blocks/components/DefaultElements/DefaultModal.vue';
  import Cookie from 'building-blocks/components/Cookie/Cookie.vue';
  import Header from '@/components/Header/Header.vue';
  import Footer from '@/components/Footer/Footer.vue';

  export default {
    components: {
      DefaultAlert,
      DefaultModal,
      Cookie,
      Header,
      Footer
    },

    methods: {
      updatePrefillCode(prefillCode) {
        const action = this.$route.query.action;

        if (action === 'check_encrypted_code') {
          this.$store.dispatch('app/decryptAndPrefillVoucherCode', prefillCode);
        } else {
          this.$store.commit('app/SET_PREFILLED_VOUCHERCODE', prefillCode);
        }
      },
      redirectToLoginPage() {
        window.location.href = '/';
      }
    },
    computed: {
      ...mapState({
        isUserSessionExpired: (state) => state.user.isUserSessionExpired,
        alert: (state) => state.app.alert,
        isOpenShop: (state) => state.app.isOpenShop
      })
    },
    mounted() {
      if (window.location.hash) {
        this.$nextTick(() => {
          const el = document.querySelector(window.location.hash);
          if (el) el.scrollIntoView({ behavior: 'smooth' });
        });
      }

      // Checks queryParams for Codes to prefill
      const prefillCode = this.$route.query.code ? this.$route.query.code : this.$route.query.enteredCode;
      if (this.$route.path == '/' && prefillCode) {
        this.updatePrefillCode(prefillCode);
      }
    }
  };
</script>
