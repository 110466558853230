<template>
  <form class="card__form text-center" :class="fontColor === 'dark' ? 'text-dark' : 'text-light'">
    <b-icon class="mb-2" style="width: 80px; height: 80px;" :icon="'forgot-password-icon'" />
    <h1 class="card-title mb-3">{{ $t('global.reset_password_success_form.title') }}</h1>
    <div class="card-subtitle">{{ $t('global.reset_password_success_form.subtitle') }}</div>

    <DefaultButton 
      class="mt-5"
      block
      variant="light"
      :button-text="$t('global.reset_password_success_form.button')"
      :loading="loading"
      @click="$emit('backToLogin')"
      data-test="password-forgot-success-input-button"
    />

    <div class="mt-3">
      <button class="btn btn-link btn-link--light" @click="$emit('handleReturn')">{{ $t('global.reset_password_success_form.link') }}</button>
    </div>
  </form>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { mapState } from 'vuex';

  export default {
    props: {
      fontColor: {
        type: String,
        default: ''
      },
    },
    components: {
      DefaultButton,
      BIcon
    },
    computed: {
      ...mapState({
        loading: (state) => state.app.isLoading,
      }),
    },
}
</script>