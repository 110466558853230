<template>
  <div :id="id" class="detail-card">
    <slot :content="headlineText">
      <DefaultHeadline is-headline heading-size="h3" :headline-text="headlineText" />
    </slot>
    <slot v-if="subHeadline" :content="subHeadline">
      <p>{{ subHeadline }}</p>
    </slot>
    <fieldset class="form-group mb-0">
      <div role="group" class="bv-no-focus-ring">
        <div role="radiogroup" class="bv-no-focus-ring">
          <div v-for="paymentOption in devicePaymentOptions" :key="paymentOption.id" class="row no-gutters custom-radio-wrapper">
            <div class="col-10">
              <div class="custom-control custom-control-inline custom-radio">
                <input
                  :id="paymentOption.id"
                  v-model="selected"
                  type="radio"
                  :value="paymentOption.id"
                  class="custom-control-input"
                  @input="changePaymentOption(paymentOption.id)"
                />
                <label :for="paymentOption.id" class="custom-control-label">
                  {{ $t(paymentOption.title) }}
                </label>
              </div>
            </div>
            <div class="additionals col-2">
              <img v-if="paymentOption.icon_url.includes('http')" :src="paymentOption.icon_url" :alt="$t(paymentOption.title) + ' Logo'" />
              <img v-else :src="$image(paymentOption.icon_url)" :alt="$t(paymentOption.title) + ' Logo'" />
            </div>
            <div v-show="paymentOption.id === selected" class="font-md pt-2 col-12">
              <slot>{{ $t(paymentOption.description) }} </slot>
              <div v-if="paymentOption.id === bestChoicePaymentId" class="detail-card code-form mt-3 mb-0">
                <CodeInputForm :button-text="buttonText" :input-placeholder="$t('global.voucher_form.placeholder')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import CodeInputForm from '@/components/CodeInputForm.vue';

  export default {
    components: {
      DefaultHeadline,
      CodeInputForm
    },
    props: {
      paymentOptions: {
        type: Array,
        default: () => []
      },
      selectedPaymentOptionType: {
        type: Number,
        default: 2
      },
      headlineText: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: 'radio-option-group'
      },
      buttonText: {
        type: String,
        default: ''
      },
      subHeadline: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        selected: this.selectedPaymentOptionType,
        bestChoicePaymentId: 2
      };
    },
    computed: {
      devicePaymentOptions() {
        if(this.$isAppleDevice()) {
          return this.paymentOptions
        }
        return this.paymentOptions.filter(opt => opt.id !== 107) // ID 107 is apple pay option
      }
    },
    watch: {
      selectedPaymentOptionType(newValue) {
        this.selected = newValue;
      }
    },
    methods: {
      changePaymentOption(selectedPaymentTypeId) {
        this.$emit('selection', selectedPaymentTypeId);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .custom-radio-wrapper {
    margin: 1rem 0;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $border-color;
    & .additionals {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & img {
        max-width: 100%;
        height: 1rem;
      }
    }
    & + .custom-radio-wrapper {
      margin-top: 1.5rem;
    }
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .bv-no-focus-ring :last-child {
    border-bottom: 0;
  }
  .code-form {
    box-shadow: $box-shadow-sm;
  }
</style>
