<template>
  <transition name="fade">
    <div
      v-if="text.length > 0"
      class="alert alert-dismissible position-fixed rounded-3 p-2"
      :class="[{ 'fixed-bottom': showBottom, 'fixed-top': !showBottom, show }, colorVariant ? `alert-${colorVariant}` : '']"
    >
      <div class="alert__content">
        {{ text }}
        <button type="button" class="close" @click="handleDismiss">&times;</button>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'DefaultAlert',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        default: ''
      },
      colorVariant: {
        type: String,
        default: 'primary'
      },
      showBottom: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleDismiss() {
        const alertData = {
          show: false,
          text: '',
          colorVariant: 'primary'
        };
        this.$store.commit('app/SET_ALERT', alertData);
      }
    },
    updated() {
      setTimeout(() => {
        this.handleDismiss();
      }, 5000);
    }
  };
</script>

<style lang="scss">
  .alert {
    margin: 1em 10em;
    text-align: center;
    border: 0;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.12);
    z-index: $z-index-highest;
    @include media-breakpoint-down(md) {
      margin: 0;
    }

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: .5rem;

      .close {
        padding-left: .5rem;
      }
    }
  }

  // Color Stylings
  .alert-primary {
    @include alert(primary);
  }
  .alert-secondary {
    @include alert(secondary);
  }
  .alert-danger {
    @include alert(danger);
  }
  .alert-warning {
    @include alert(warning);
  }
  .alert-success {
    @include alert(success);
  }
  .alert-info {
    @include alert(info);
  }
  .alert-light {
    background: var(--light);
    color: var(--dark);
    border: $border-width solid var(--transparent-light-lower);
    .close {
      color: var(--dark);
    }
  }
  .alert-dark {
    background-color: var(--dark) !important;
    color: var(--light) !important;
    border: $border-width solid var(--transparent-light-lower);
    .close {
      color: var(--light) !important;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
