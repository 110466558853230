<template>
  <div role="group" class="form-group">
    <label v-if="!hideLabel" :label-for="groupId">
      {{ label }}
    </label>
    <input
      :id="groupId"
      v-model.trim="inputValue"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      class="form-control"
      :class="{ 'promo-code': promoCode, 'is-invalid': state === false }"
      :promo-code="promoCode"
      :aria-invalid="!state"
      @change="change"
      @blur="blur"
    />
    <span class="invalid-feedback">{{ errorText }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      hideLabel: {
        type: [Boolean, String],
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      groupId: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: 'Placeholder'
      },
      state: {
        type: Boolean,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      },
      errorText: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      modelValue: {
        type: String,
        default: ''
      },
      promoCode: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      inputValue: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
          this.$emit('inputed', value);
        }
      }
    },
    methods: {
      change() {
        this.$emit('changed', this.modelValue);
      },
      blur() {
        this.$emit('blured', this.modelValue);
      }
    }
  };
</script>
<style lang="scss" scoped>
  input.form-control {
    &.promo-code {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      text-align: center;
      &::placeholder {
        color: var(--transparent-light-medium);
      }
      &:hover,
      &:active,
      &:focus {
        border-top: none;
        border-left: none;
        border-right: none;
      }
      &:hover {
        border-color: var(--transparent-light-medium);
      }
      &:active {
        border-color: var(--primary);
      }
      &:focus {
        border-bottom-color: var(--primary);
        box-shadow: none;
        box-shadow: 0 4px 2px -2px rgba(var(--primary), 0.25);
      }
    }
    &.is-invalid.promo-code {
      border-bottom-color: $danger;
      box-shadow: 0 4px 2px -2px rgba($danger, 0.25);
      background-image: none;
    }
  }
  label {
    font-size: $h6-font-size;
  }
</style>
