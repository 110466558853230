<template>
  <div class="code-overview">
    <StageDefault textstage :headline="headline" :description="$t('view.voucher_overview.stage.text')" />
    <div class="container-xl">
      <div class="row">
        <div class="col-sm-12 col-md-8 p-0">
          <div class="col-xl-10 col-md-12">
            <DefaultHeadline
              is-headline
              heading-size="h2"
              class="title"
              :headline-text="$t('view.voucher_overview.list.headline')"
              :badge="vouchers.length + ''"
              badge-variant="soft-dark"
            />
            <div v-for="voucher in vouchers" :key="voucher.partial_payment_id" class="voucher_card card my-3 text-dark card--soft">
              <div class="card-body" v-if="voucher.code">
                <div class="container-xl">
                  <div class="row voucher-item">
                    <div class="col-lg-2 col-6">
                      <span class="badge voucher-item__value badge-secondary"> {{ $currency(voucher.value) }} </span>
                    </div>
                    <div class="voucher-item__code col-6 col-lg-4">{{ voucher.code }}</div>
                    <div class="col-md-12 d-lg-none">
                      <hr class="my-2" />
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="badge voucher-item__expires badge-soft-dark">
                        {{ $t('view.voucher_overview.validation_date', { date: voucher.expiration_date }) }}
                      </span>
                    </div>
                    <div class="voucher-item__remove-container col-lg-3 col-6">
                      <span v-if="hasMultipleVouchers" id="tooltip-target" class="voucher-item__remove-link" @click="remove(voucher)">{{
                        $t('global.remove')
                      }}</span>
                      <span v-else id="tooltip-target" class="voucher-item__remove-link--disabled">{{ $t('global.remove') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DefaultCard shadowed shadow-s-m class="card-code-input mt-5">
              <DefaultHeadline is-headline heading-size="h3" class="title" :headline-text="$t('view.voucher_overview.voucher-form.title')" />
              <p>{{ $t('view.voucher_overview.voucher-form.text') }}</p>
              <DefaultCard shadowed shadow-s-m>
                <div class="px-3">
                  <CodeInputForm :inputPlaceholder="$t('global.voucher_form.placeholder')" :buttonText="$t('global.voucher_form.button')" />
                </div>
              </DefaultCard>
            </DefaultCard>
          </div>
        </div>
        <div class="info-cards col-sm-12 col-md-4 mt-5">
          <DefaultCard soft-color shadowed shadow-s-m>
            <DefaultHeadline is-headline heading-size="h3" :headline-text="$t('view.voucher_overview.recommendations.title')" />
            <p>{{ $t('view.voucher_overview.recommendations.text') }}</p>
            <router-link :to="$routeHandler('/product-overview?recommended=true&nav=true')" class="card-explanation__link">{{
              $t('view.voucher_overview.recommendations.link')
            }}</router-link>
          </DefaultCard>

          <DefaultCard soft-color shadowed shadow-s-m class="mt-4">
            <DefaultHeadline is-headline heading-size="h3" :headline-text="$t('view.voucher_overview.help.title')" />
            <p>{{ $t('view.voucher_overview.help.text') }}</p>
            <router-link :to="$routeHandler('/faq')" class="card-explanation__link">{{ $t('view.voucher_overview.help.link') }}</router-link>
          </DefaultCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import StageDefault from '@/components/Stage/StageDefault.vue';
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import DefaultCard from 'building-blocks/components/DefaultElements/DefaultCard.vue';
  import CodeInputForm from '@/components/CodeInputForm.vue';

  export default {
    components: {
      StageDefault,
      DefaultHeadline,
      DefaultCard,
      CodeInputForm
    },

    computed: {
      ...mapState({
        vouchers: (state) => state.basket.payments,
        prepaidValue: (state) => state.basket.prepaidValue
      }),

      hasMultipleVouchers() {
        return this.vouchers.length > 1;
      },
      headline() {
        return `${this.$t('view.voucher_overview.stage.headline')}: ${this.$currency(this.prepaidValue)}`;
      }
    },
    methods: {
      remove(voucher) {
        this.$store.dispatch('basket/removePaymentType', voucher);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .voucher_card {
    box-shadow: $box-shadow;
  }

  .code-overview {
    padding-bottom: 6rem;
    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .info-cards {
    @extend %font-sm;
  }

  .voucher-item {
    align-items: center;

    &__value {
      @extend %font-lg;
      text-align: left;
    }
    &__code {
      letter-spacing: 1pt;
      text-align: left;
      @extend %font-base;
      @include media-breakpoint-down(lg) {
        text-align: right;
      }
    }
    &__expires {
      @extend %font-tiny-sm;
    }
    &__remove-container {
      font-size: 0.69rem;
      text-align: right;
    }
    &__remove-link {
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
      &--disabled {
        color: var(--gray-200);
      }
    }
  }
</style>
