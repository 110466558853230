<template>
  <HeaderSearchMenu
    v-if="variant === 'header-search-menu'"
    :logo="getImageUrl(logo)"
    :loggedOutLinks="parseLinks(loggedOutLinks)"
    :loggedInLinks="parseLinks(loggedInLinks)"
  />
  <Header v-else :logo="getImageUrl(logo)" :loggedOutLinks="parseLinks(loggedOutLinks)" :loggedInLinks="parseLinks(loggedInLinks)" />
</template>

<script>
  import { mgnlToJson } from '../helpers/utils.js';
  import Header from '@/components/Header/Header.vue';
  import HeaderSearchMenu from '@/components/HeaderSearchMenu/Header.vue';

  export default {
    components: { Header, HeaderSearchMenu },
    props: {
      variant: {
        type: String,
        default: 'classic'
      },
      logo: {
        type: Object,
        default: undefined
      },
      loggedInLinks: {
        type: Object,
        default: undefined
      },
      loggedOutLinks: {
        type: Object,
        default: undefined
      }
    },
    mounted() {
      const header = document.querySelector('#header');
      if (header) {
        const parent = header.parentElement;
        if (this.$store.state.app.mgnlMode) {
          header.classList.remove('sticky-header');
        }
        const id = header.getAttribute('id');
        const cssClass = header.getAttribute('class');
        parent.setAttribute('id', id);
        parent.setAttribute('class', cssClass);
        header.removeAttribute('id');
        header.removeAttribute('class');
      }
    },
    methods: {
      parseLinks(links) {
        return mgnlToJson(links)
          .filter((link) => link.show === 'true' || link.show)
          .map((link) => {
            if (link.type.field == 'link') return this.createLinkObject(link);

            if (link.type.field == 'url') return this.createUrlObject(link);
          });
      },

      createUrlObject(link) {
        return {
          name: link.name,
          href: link.type.href,
          target: link.type.target === 'true' ? '_blank' : ''
        };
      },
      createLinkObject(link) {
        return {
          name: link.name,
          path: link.type.path ? this.createPath(link.type.path) : '',
          hash: link.type.additional.field === 'hash' ? this.createAnchorLink(link.type.additional.hash) : '',
          query: link.type.additional.field === 'query' ? this.createQuery(link.type.additional.query) : ''
        };
      },

      createPath(path) {
        const siteName = path.substr(1, path.substring(1).indexOf("/") + 1);
        return path.replace(siteName, '');
      },

      createAnchorLink(hash) {
        if (hash.trim() === '') return '';

        return hash.includes('#') ? hash : '#' + hash;
      },
      createQuery(query) {
        if (query.trim() === '') return '';

        try {
          const jsonStr = query
            .replace(/(\w+:)|(\w+ :)/g, function (s) {
              return '"' + s.substring(0, s.length - 1) + '":';
            })
            .replace(/\'/g, '"');
          return JSON.parse(jsonStr);
        } catch (error) {
          console.error(`Provided Query String "${query}" was not in the expected Format`);
          return '';
        }
      },

      getImageUrl(img) {
        if (!img) return;
        return process.env.VUE_APP_MGNL_HOST + '/dam' + img['@path'];
      }
    }
  };
</script>
