<template>
  <div class="detail-card">
    <fieldset class="form-group" @change="handleSelectedMethod">
      <legend v-if="hasMultipleOptions" class="bv-no-focus-ring col-form-label pt-0">
        {{ title }}
      </legend>
      <div role="group" class="bv-no-focus-ring">
        <div role="radiogroup" class="bv-no-focus-ring">
          <template v-for="option in radioOptions" :key="option.id">
            <div v-if="getDeliveryType(option.id)" class="row no-gutters custom-radio-wrapper custom-row">
              <div class="col-lg-6">
                <div class="custom-control custom-control-inline custom-radio" :class="{ 'hidden-radio': !hasMultipleOptions }">
                  <input :id="option.id" v-model="selected" :value="option.id" type="radio" name="radio-option-group" class="custom-control-input" />
                  <label :for="option.id" class="custom-control-label">
                    {{ $t(getDeliveryType(option.id).title) }}
                  </label>
                </div>
              </div>
              <div class="text-right col-lg mt-1 mt-lg-0">
                <span class="badge badge-soft-dark" v-html="$t(getDeliveryType(option.id).badge)"></span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import deliveryTypes from '@/config/deliveryTypes';

export default {
  props: {
    radioOptions: {
      type: Array,
      default() {
        return {
          options: []
        }
      }
    },
    selectedOptionType: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: 'radio-option-group'
    }
  },
  data() {
    return {
      selected: this.selectedOptionType
    }
  },
  computed: {
    hasMultipleOptions() {
      return this.radioOptions.length > 1;
    },
    isPunchout() {
      return this.$store.getters['punchout/isPunchout'];
    }
  },
  watch: {
    selectedOptionType(newValue) {
      this.selected = newValue;
    }
  },
  methods: {
    handleSelectedMethod() {
      this.$emit('select', this.selected);
    },
    getDeliveryType(id) {
      return deliveryTypes.find(type => type.id === id);
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-group {
    margin-bottom: 0;
  }
  .badge {
    white-space: normal;
  }
  .custom-radio-wrapper + .custom-radio-wrapper {
    margin-top: 1rem;
    border-top: 1px solid $border-color;
    padding-top: 1rem;
  }
  .custom-control-label {
    font-size: $h5-font-size;
    padding-left: 1rem;
  }
  legend {
    font-family: $font-semibold, sans-serif;
  }
  .quoted {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    &.hidden-radio {
      margin-left: 0;
    }
  }
  .text-right {
    display: flex;
    align-items: center;

    span {
      font-size: .8rem;
      display: block;
      width: 100%;
      max-width: 100%;
      text-align: right;

      @include media-breakpoint-down(md) {
        text-align: left;
        padding-left: 0;
      }
    }
  }

  .custom-row {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
</style>
