<template>
  <section class="categorie-swiper">
    <div class="swiper">
      <div ref="swiperContainer" class="row swiper__container">
        <div
          class="swiper__item col-lg-4 col-xl-3"
          v-for="category in mutatedCategories"
          :key="category.id"
          @click="updateCategoryFilter(category)"
          :class="{ 'swiper__item--active': activeCategory === category.id }"
          :data-test="category.name"
        >
          <div class="card">
            <b-icon :icon="setIcon(category.id)" />
            <div class="card-body">
              <h5 class="card-title">{{ category.name }}</h5>
            </div>
          </div>
        </div>

        <div class="swiper__item col-lg-4 col-xl-3" v-if="categories.length >= catLength">
          <div class="card card--all" @click="toggleCard">
            <div class="card-body">
              <h5 class="card-title" v-if="categories.length > catLength">Alle Kategorien anzeigen</h5>
              <h5 class="card-title" v-if="categories.length === catLength">Weniger Kategorien anzeigen</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    name: 'CategoriesBarSwiper',
    components: {
      BIcon
    },
    data() {
      return {
        catLength: window.innerWidth < 1140 && window.innerWidth > 992 ? 5 : 7,
        innerViewportWidth: null
      };
    },
    computed: {
      ...mapGetters({
        categories: 'filter/getCategories',
        composedQuery: 'filter/composedQuery'
      }),
      ...mapState({
        activeCategory: (state) => state.filter.activeCategory
      }),
      mutatedCategories() {
        const arr = [];
        this.categories.forEach((el) => {
          arr.push(el);
        });
        return arr.slice(0, this.catLength);
      }
    },
    mounted() {
      this.innerViewportWidth = window.innerWidth;
    },
    methods: {
      scrollSwiperLeft() {
        const swiperContainer = this.$refs.swiperContainer;
        swiperContainer.scrollBy(-this.innerViewportWidth, 0);
      },
      scrollSwiperRight() {
        const swiperContainer = this.$refs.swiperContainer;
        swiperContainer.scrollBy(this.innerViewportWidth, 0);
      },
      updateCategoryFilter(category) {
        category.id === this.activeCategory ? this.$store.commit('filter/SET_CATEGORY', null) : this.$store.commit('filter/SET_CATEGORY', category);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      toggleCard() {
        if (this.categories.length > this.catLength) {
          this.catLength = this.categories.length;
        } else {
          this.catLength = window.innerWidth < 1140 && window.innerWidth > 992 ? 5 : 7;
        }
      },
      setIcon(catId) {
        return this.$store.getters['app/getCategoryIcon'](catId);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .categorie-swiper {
    background: transparent;
    overflow: hidden;
    margin: 0 -1rem;
    padding: 0 1rem;
    z-index: 1;
    &__headline {
      flex-wrap: wrap;
    }
    @include media-breakpoint-up(lg) {
      overflow: inherit;
      margin: auto;
      padding: 0;
    }
  }
  .swiper {
    padding-top: 1rem;
    margin: 0 -0.75rem 2rem -1.5rem;
    padding-bottom: 0 !important;
    @include scrollbar(primary, md, 992px);
    &__container {
      width: 100%;
      padding: 1rem;
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      height: auto;
      margin: auto;
      @include media-breakpoint-up(lg) {
        padding-bottom: 1rem;
        flex-wrap: wrap;
      }
    }
    &:hover .swiper__btn {
      opacity: 1;
    }
    &__item {
      padding: 0.5rem;
      height: max-content;
      order: 1;
      
      & .card {
        box-shadow: $box-shadow-sm;
        border: 1px solid transparent;
        transition: all 0.15s ease-in;
        &:hover {
          box-shadow: $box-shadow;
        }
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: $border-radius;
        &--all {
          background: var(--primary);
          height: 5rem;
        }
        @include media-breakpoint-up(lg) {
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
        }
        & svg {
          margin: 0.5rem;
          color: var(--primary);
          fill: var(--primary);
          height: 2rem;
          width: 2rem;
        }
        & .card-body {
          padding: 0;
          max-width: 100%;
          & .card-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            margin-bottom: 0;
          }
        }
      }
      &--active {
        order: 0;
        & .card {
          border: 1px solid var(--primary);
          color: var(--primary);
          box-shadow: $box-shadow;
        }
      }
    }
    .card--all {
      background: var(--primary);
      border-radius: $border-radius;
      margin: 0;
      height: 5.35rem;
      @include media-breakpoint-up(lg) {
        height: 4rem;
      }
      .card-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card-title {
        margin-bottom: 0;
        text-align: center;
        color: $white;
        font-family: $font-semibold;
        &.hide {
          display: none;
        }
      }
    }

    &__btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: $z-index-low;
      transition: all 0.5s ease;
      opacity: 0;
      &.right {
        right: 4rem;
        @include media-breakpoint-up(xl) {
          right: calc(10%);
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
        @media (min-width: 1700px) {
          right: calc(20%);
        }
        @media (min-width: 1900px) {
          right: calc(28%);
        }
      }
      &.left {
        left: 4rem;
        @include media-breakpoint-up(xl) {
          left: calc(10%);
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
        @media (min-width: 1700px) {
          left: calc(20%);
        }
        @media (min-width: 1900px) {
          left: calc(28%);
        }
      }
    }
  }
</style>
