<template>
  <div class="login__shade">
    <component :is="imageLink ? 'a' : 'div'" :href="imageLink" :target="imageLink ? '_blank' : null" class="container-xl">
      <svg id="stage" class="login__shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 1080">
        <defs>
          <pattern id="bgimage" x="0" y="0" patternUnits="userSpaceOnUse" height="100%" width="100%">
            <image x="0" y="0" width="100%" :href="image.link" :alt="image.name" />
          </pattern>
        </defs>
        <path
          id="shade"
          d="M350.736,0,230.289,449.514c-17.082,63.752,21.1,129.888,84.853,146.97l1631.449,437.145c63.751,17.082,129.887-21.1,146.969-84.853L2347.784,0Z"
        />
        <path
          id="image"
          fill="url(#bgimage)"
          d="M282.027,0l-122.7,337.113c-22.573,62.02,9.7,131.232,71.721,153.805l1587.14,577.673c62.02,22.573,131.232-9.7,153.806-71.721L2334.826,0Z"
        />
        <rect id="area" class="invis-rect" width="2400" height="1080" />
      </svg>
    </component>
  </div>
</template>

<script>
  export default {
    props: {
      image: {
        type: Object,
        default: {}
      },
      imageLink: {
        type: String,
        default: null
      }
    }
  };
</script>
<style>
  .invis-rect {
    fill: none;
  }
</style>

<style lang="scss" scoped>
  .login {
    &__shade {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: $z-index-low;
      @include media-breakpoint-up(md) {
        background: transparent;
      }
    }
    &__shape {
      position: absolute;
      height: 15rem;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      @include media-breakpoint-up(md) {
        height: 30rem;
      }
      @include media-breakpoint-up(lg) {
        height: 40rem;
        top: 0;
        bottom: 0;
      }
      @include media-breakpoint-up(xl) {
        height: 40rem;
      }
    }
    #shade {
      fill: var(--transparent-light-lowest);
    }
  }
</style>
