<template>
  <div class="filter-row pb-3">
    <div class="row align-items-start justify-content-between">
      <div class="col-7">
        <div class="filtertags">
          <button class="btn filtertag__btn btn-soft-dark btn-sm mr-2 p-2" @click="resetSorting()" v-if="sortOption !== 'default'">
            {{ $t(sortingText.text) }} <b-icon icon="x" shift-v="6" />
          </button>
          <button v-for="tag in filterTags" :key="tag.text" class="btn filtertag__btn btn-soft-dark btn-sm mr-2 p-2" @click="removeFilterByTag(tag)">
            {{ tag.text }} <b-icon icon="x" shift-v="6" />
          </button>
          <button v-if="filterTags.length > 0 || sortOption !== 'default'" variant="light" class="filtertags__reset btn btn-sm btn-light p-2" @click="$emit('reset')">
            {{ $t('filter.reset') }}
          </button>
        </div>
      </div>
      <div class="col-md-5">
        <div class="sorting">
          <div class="sorting__count">{{ $t('filter.amount', { product_count: productCount }) }}</div>
          <div class="sorting__select">
            <select v-model="selectedSorting" class="custom-select" @change="updateSorting" data-test="filter-sort">
              <option v-for="option in sortingOptions" :key="option.value" :value="option.value">{{ $t(option.text) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    data() {
      return {
        selectedSorting: this.sortOption
      };
    },
    computed: {
      ...mapGetters({
        productCount: 'filter/getFilteredProductsCount',
        filterTags: 'filter/getFilterTags',
        composedQuery: 'filter/composedQuery',
        sortingOptions: 'product/getSortingOptions'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType,
        redemptionTypeFilter: (state) => state.filter.redemptionType,
        shippingMethodFilter: (state) => state.filter.shippingMethod,
        sortOption: (state) => state.filter.sortOption,
        selectedBrands: (state) => state.filter.selectedBrands
      }),
      sortingText() {
        return this.sortingOptions.find(el => el.value === this.sortOption);
      }
    },
    watch: {
      sortOption() {
        this.selectedSorting = this.sortOption;
      }
    },
    mounted() {
      this.selectedSorting = this.sortOption;
    },
    methods: {
      updateSorting() {
        this.$store.commit('filter/SET_SORTOPTION', this.selectedSorting);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      resetSorting() {
        this.$store.commit('filter/SET_SORTOPTION', 'default');
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      removeFilterByTag(tag) {
        const groupId = tag.groupId;
        let oldFilterGroup;
        let newFilterGroup;

        switch (groupId) {
          case 'productType':
            oldFilterGroup = this.productTypeFilter;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag);
            this.$store.commit('filter/SET_PRODUCTTYPE', newFilterGroup);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'redemptionType':
            oldFilterGroup = this.redemptionTypeFilter;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag);
            this.$store.commit('filter/SET_REDEMPTIONTYPE', newFilterGroup);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'shippingMethod':
            oldFilterGroup = this.shippingMethodFilter;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag);
            this.$store.commit('filter/SET_SHIPPINGMETHOD', newFilterGroup);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'discount':
            this.$store.commit('filter/SET_DISCOUNT', false);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'price':
            this.$store.dispatch('filter/resetPriceFilter');
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'brand':
            oldFilterGroup = this.selectedBrands;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag.text);
            this.$store.commit('filter/SET_SELECTED_BRANDS', newFilterGroup);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'category':
            this.$store.commit('filter/SET_CATEGORY', null);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter-row {
    border-bottom: $border-width solid $border-color;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .filtertags {
    @extend %font-sm;
    min-height: 41px;

    &__reset {
      margin-bottom: .5rem;
      @extend %font-sm;
    }
  }
  .sorting {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    &__select {
      text-align: right;
      .custom-select {
        font-size: $font-size-sm;
        height: auto;
        width: 160px;
      }
    }
  }

  .filtertag__btn {
    margin-bottom: .5rem;
    svg {
      font-size: 127% !important;
    }
  }
</style>
