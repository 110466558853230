<template>
  <div>
    <EditableArea v-if="productPage?.before" :content="productPage.before" />
    <section v-if="selectedProduct" class="product" ref="product">
      <div class="container-xl">
        <span class="product__back" @click="goBack()">
          <b-icon icon="arrow-left" class="product__back-icon"/>
          {{$t('global.back')}}
        </span>
        <div class="row">
          <div class="col-md-5">
            <ul v-if="isReward" class="gallery gallery--sticky">
              <div v-if="!$device.isMobile">
                <div class="gallery__item">
                  <img v-if="selectedProduct.big_images[0]" :src="imageServerUrl + selectedProduct.big_images[0]" :alt="selectedProduct.name" class="gallery__image" />
                </div>
              </div>
              <img
                v-if="$device.isMobile"
                :src="imageServerUrl + selectedProduct.big_images[0]"
                class="gallery__image img-fluid w-100"
                :alt="selectedProduct.name"
              />
            </ul>
            <div v-if="!isReward" class="gallery gallery--voucher">
              <img
                :src="imageServerUrl + `${selectedProduct.gift_card_image}`"
                :alt="selectedProduct.name"
                class="gallery__image gallery__image--voucher img-fluid w-100"
              />
            </div>
          </div>
          <div class="col-md-7">
            <form @submit.prevent="addProductToBasket">
              <ProductDetailTitle :title="selectedProduct.name" :is-reward="isReward" />
              <ProductDetailAmount
                :product="selectedProduct"
                :is-reward="isReward"
                :is-available="true"
                :selected-variation="productData.selectedVariationId"
                @select="updateProductVariation"
              />
              <ProductDetailDescriptionTeaser v-if="isReward" :description="selectedProduct.description" />
              <ProductDetailDeliverySelect
                :title="$t('view.product.shipping.title')"
                :radio-options="selectedProduct.delivery_options"
                :selected-option-type="productData.selectedDeliveryOption"
                @select="updateDeliveryOption"
              />
              <ProductDetailHintConfirmation
                v-if="!isReward"
                :is-reward="isReward"
                :has-error="confirmationError"
                :product="selectedProduct"
                @toggleCheckbox="updateConfirmation"
                ref="detailConfirm"
              />
              <ProductDetailBrandSurvey
                v-if="!isReward && websiteQuestionnaire && !questionnaireAnswered"
                :product="selectedProduct"
                :survey-options="surveyOptions"
                v-model="selectedSurvey"
              />
              <ProductDetailOrderBar :product="selectedProduct" @select="updateProductQuantity" ref="detailOrder" />
              <ProductDetailDescription :product="selectedProduct" :isReward="isReward" />
            </form>
          </div>
        </div>
      </div>
    </section>
    <EditableArea v-if="productPage?.after" :content="productPage.after" />
    <Sidebar id="basketSidebar" ref="basketSidebar">
      <template #body="{ hideSidebar }">
        <BasketSideBar @button-click="hideBasketSidebar(hideSidebar)" />
      </template>
    </Sidebar>
  </div>
</template>

<script>
  import BasketSideBar from '@/components/Basket/BasketSideBar';
  import ProductDetailBrandSurvey from '@/components/ProductDetail/ProductDetailBrandSurvey.vue';
  import ProductDetailTitle from '@/components/ProductDetail/ProductDetailTitle.vue';
  import ProductDetailAmount from '@/components/ProductDetail/ProductDetailAmount.vue';
  import ProductDetailDescriptionTeaser from '@/components/ProductDetail/ProductDetailDescriptionTeaser.vue';
  import ProductDetailDeliverySelect from '@/components/ProductDetail/ProductDetailDeliverySelect.vue';
  import ProductDetailHintConfirmation from '@/components/ProductDetail/ProductDetailHintConfirmation.vue';
  import ProductDetailOrderBar from '@/components/ProductDetail/ProductDetailOrderBar.vue';
  import ProductDetailDescription from '@/components/ProductDetail/ProductDetailDescription.vue';
  import DefaultDivider from 'building-blocks/components/DefaultElements/DefaultDivider.vue';
  import Sidebar from 'building-blocks/components/Sidebar.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import { EditableArea } from '@magnolia/vue-editor';

  export default {
    data() {
      return {
        imageServerUrl: 'https://images.cadooz.com/',
        productNumber: this.$route.params.product_number,
        isConfirmed: false,
        confirmationError: false,
        productData: {
          productQuantity: 1,
          selectedDeliveryOption: null,
          selectedVariationId: ''
        },
        selectedSurvey: null,
        surveyOptions:[
          { text: this.$t('view.product.survey.checkbox_no'),  value: 1 },
          { text: this.$t('view.product.survey.checkbox_yes'),  value: 0 }
        ]
      };
    },
    components: {
      ProductDetailBrandSurvey,
      BasketSideBar,
      ProductDetailTitle,
      ProductDetailAmount,
      ProductDetailDescriptionTeaser,
      ProductDetailDeliverySelect,
      ProductDetailHintConfirmation,
      ProductDetailOrderBar,
      ProductDetailDescription,
      DefaultDivider,
      Sidebar,
      BIcon,
      EditableArea
    },
    watch: {
      $route(to) {
        if (to.params.product_number) {
          this.productNumber = to.params.product_number;
          this.eTrackerCommerce('viewProduct');
        }
        if (this.additionalOptions.addToCartVariant === 'add-cart-sidebar') {
          this.resetProduct();
          this.$refs.basketSidebar.hideSidebar();
        }
      }
    },
    computed: {
      questionnaireAnswered() {
        return this.$store.state.basket.questionnaireAnswered.includes(this.selectedProduct?.number);
      },
      websiteQuestionnaire() {
        return this.$store.state.app.websiteSettings?.website_questionnaire && this.selectedProduct?.product_questionnaire && this.$store.state.basket.debitorQuestionnaire;
      },
      additionalOptions() {
        return this.$store.state.pages.additionalOptions;
      },
      selectedProduct() {
        return this.$store.getters['product/getProduct'](this.productNumber);
      },
      selectedVariation() {
        return this.selectedProduct?.variations.find((variation) => variation.number === this.productData.selectedVariationId);
      },
      isReward() {
        return this.selectedProduct?.type_group === 'PHY';
      },
      activeGiftomatic() {
        return this.$store.getters['pages/activeGiftomatic'];
      },
      mgnlMode() {
        return this.$store.state.app.mgnlMode;
      },
      productPage() {
        return this.$store.state.pages.productPage;
      }
    },
    created() {
      if (!this.selectedProduct && !this.mgnlMode) {
        this.$router.push({ path: this.$routeHandler('/not-found') });
      }

      this.productData.selectedVariationId = this.selectedProduct?.variations[0].number;
      this.productData.selectedDeliveryOption = this.selectedProduct?.delivery_options[0].id;
      this.eTrackerCommerce('viewProduct');
    },
    mounted() {
      if (this.activeGiftomatic && !this.mgnlMode) {
        this.giftomaticOptimizer();
      }
    },
    methods: {
      /**
       * Creates a Product Object from given Inputs and adds it to the Basket
       */
      async addProductToBasket() {
        if(this.isReward) await this.updateConfirmation(true);
        if (this.isConfirmed) {
          this.$store.dispatch('filter/setRelatedProducts', this.selectedProduct);
          this.$store.commit('app/SET_LOADINGSTATE', true);
          const productData = {
            variation_id: this.productData.selectedVariationId,
            quantity: this.productData.productQuantity,
            number: this.selectedProduct.number,
            face_value: this.selectedVariation.face_value.amount,
            delivery_options: this.productData.selectedDeliveryOption
          };
          if(this.websiteQuestionnaire) {
            productData['selected_answer'] = this.selectedSurvey;
          }
          try {
            await this.$store.dispatch('basket/addProductToBasket', productData);
            this.eTrackerCommerce('insertToBasket');
            this.additionalOptions.addToCartVariant === 'add-cart-sidebar'
              ? this.$refs.basketSidebar.showSidebar()
              : this.$router.push({ path: this.$routeHandler('/basket') });
          } catch (error) {
            this.displayErrorToast(error.message);
          }

          this.$store.commit('app/SET_LOADINGSTATE', false);
        } else {
          this.confirmationError = true;
        }
      },
      resetProduct() {
        this.$refs.detailConfirm?.reset();
        this.$refs.detailOrder?.reset();
        this.productData.selectedVariationId = this.selectedProduct?.variations[0].number;
        this.productData.selectedDeliveryOption = this.selectedProduct?.delivery_options[0].id;
        this.productData.productQuantity = 1;
      },
      displayErrorToast(message) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      },
      updateProductQuantity(value) {
        this.productData.productQuantity = value;
      },
      updateDeliveryOption(value) {
        this.productData.selectedDeliveryOption = value;
      },
      updateProductVariation(value) {
        this.productData.selectedVariationId = value;
        this.eTrackerCommerce('viewProduct');
      },
      async updateConfirmation(value) {
        if (value === true) {
          await this.$store.dispatch('basket/setImportantHintCheckbox', this.productNumber);
        }
        this.isConfirmed = value;
        this.confirmationError = !value;
      },
      hideBasketSidebar(hideSidebar) {
        hideSidebar();
      },
      showBasketSidebar() {
        this.$refs.basketSidebar.showSidebar();
      },
      goBack() {
        this.$router.go(-1);
      },
      giftomaticOptimizer() {
        const giftomaticLinks = this.$refs.product?.querySelectorAll('.description-tabnav a[target="_blank"]');
        giftomaticLinks?.forEach(el => {
          el.addEventListener('click', element => {
            if (!element.target.classList.contains('link-optmz')) {
              element.preventDefault();
              element.target.href = 'https://www.giftomatic.io/out?dataset=CADOOZ&url=' + encodeURIComponent(giftomaticLinks[0].href);
              element.target.classList.add('link-optmz');
              element.target.click();
            }
          })
        });
      },
      eTrackerCommerce(eventName) {
        if(!this.$store.state.etracker.etrackerId || !this.selectedProduct || !this.selectedVariation) return;
        const categories = this.$store.getters['etracker/getProductHierarchy'](this.selectedProduct.number);
        const price = this.selectedVariation.tracking_value;
        const discount = this.selectedProduct.discount ? price*(this.selectedProduct.discount/100) : 0;
        this.$store.dispatch('etracker/etCommerce', {eventName, payload: {
          id: this.selectedProduct.number,
          name: this.selectedProduct.tracking_name,
          price: price.toFixed(2).toString(),
          discount: discount.toFixed(2).toString(),
          currency: 'EUR',
          category: categories,
          variants: this.isReward ? {} : {'var1': this.selectedVariation.face_value.amount.toFixed(2).toString()}
        }, number: +this.productData.productQuantity});
      }
    }
  };
</script>
<style lang="scss" scoped>
  .product {
    margin-top: 2rem;

    &__back {
      display: inline-block;
      cursor: pointer;
      font-size: 0.875rem;
      color: var(--dark);
      transition: $dur $ease;
      margin-bottom: 1.5rem;
      font-weight: bold;

      &:hover {
        color: var(--primary);

        .product__back-icon {
          color: var(--primary);
        }
      }

      &-icon {
        margin-right: 5px;
        color: var(--dark);
        transition: $dur $ease;
      }
    }
  }
  .gallery {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin-right: 0;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 2rem;
    }
    &__item {
      margin-bottom: 3rem;
    }
    &__image {
      width: 100%;
      height: auto;
      &--voucher {
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: $box-shadow;
      }
    }
    &--sticky {
      position: sticky;
      top: 10rem;
    }
    &--voucher {
      position: sticky;
      top: 10rem;
    }
  }
</style>
