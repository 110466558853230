<template>
  <div class="container picture-card-group">
    <div id="howto" class="row pt-5">
      <div class="col-md-6">
        <div class="picture-card-group__headline">
          <p class="overline" v-if="overline">{{ overline }}</p>
          <h1 v-if="headline">{{ headline }}</h1>
        </div>
      </div>
      <div class="col-md-6">
        <PictureCard
          class="light"
          v-for="story in cardStories"
          :key="story.title"
          :title="story.title"
          :text="story.text"
          :image="story.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import PictureCard from './PictureCard.vue';
  export default {
    components: { PictureCard },
    props: {
      overline: {
        type: String,
        default: ''
      },
      headline: {
        type: String,
        default: ''
      },
      cardStories: {
        type: Array,
        default: []
      }
    }
  };
</script>

<style lang="scss" scoped>
  .picture-card-group {
    z-index: 1;
    @extend %spacer-bottom;
    &__headline {
      margin-bottom: 3rem;
      @include media-breakpoint-up(md) {
        position: sticky;
        top: 10rem;
      }
    }
  }
</style>