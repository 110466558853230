<template>
  <div class="card filterbox" :class="[{ 'filterbox--mobile': $device.isMobile }, { 'filterbox--in-sidebar': additionalOptions.filterVariant === 'sidebar-filter' }]">
    <div class="card-header p-0" role="tab">
      <button class="btn btn-light btn-block filterbox__header p-3" :class="{ 'not-collapsed': !isCollapsed }" @click="toogleCollapse">
        <span>{{ title }}</span>
        <span class="filterbox__icon"><b-icon icon="caret-down-fill" h-flip /></span>
      </button>
    </div>
    <div class="collapse" :class="{ show: !isCollapsed }">
      <div class="card-body pt-0 px-3 pb-2">
        <div class="bv-no-focus-ring" role="radiogroup">
          <div v-for="option of options" :key="option.text" class="mb-2">
            <div class="custom-control custom-control-inline custom-radio">
              <input
                :id="option.value"
                v-model="selectedFilter"
                :value="option.value"
                type="radio"
                class="custom-control-input"
                @change="handleInput"
              />
              <label :for="option.value" class="custom-control-label">
                <span> {{ option.text }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: null
      },
      activeFilter: {
        type: String,
        default: 'default'
      }
    },
    data() {
      return {
        selectedFilter: this.activeFilter,
        isCollapsed: false
      };
    },
    watch: {
      activeFilter() {
        this.selectedFilter = this.activeFilter;
      }
    },
    computed: {
      additionalOptions() {
        return this.$store.state.pages.additionalOptions;
      }
    },
    methods: {
      handleInput() {
        this.$emit('filterRadio', this.selectedFilter);
      },
      toogleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  };
</script>

<style lang="scss" scoped>
  /** Overridung Global Styles from  _custom-forms.scss */

  .custom-control-label {
    span {
      font-family: $font-regular;
      margin-left: -0.5rem;
    }
  }

  .filterbox {
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;

    &--in-sidebar {
      margin: 0 auto 2rem auto;
      max-width: 380px;
    }

    .card-body {
      @extend %font-md;
    }
  }
</style>
