import axios from '@/plugins/axios';

const paymentServices = {
  retrieveAvailablePayments() {
    return axios.get(`/payment/types`).then((resp) => resp.data.payment_types);
  },

  // adds a payment to the basket
  addBankTransferPayment(paymentData) {
    return axios.post(`/payment/banktransfer`, paymentData).then((resp) => resp.data.basket);
  },

  // adds a saferpay payment to the basket
  addSaferpayPayment(params) {
    return axios.post(`/payment/saferpay`, params).then((resp) => resp.data.basket);
  },

  // verify saferpay payment
  verifySaferpay(params) {
    return axios.post(`/payment/post/verify?saferpay_status=${params.saferpay_status}&query=${params.query}`).then((resp) => resp.data.valid_payment);
  },

  // payment in process
  paymentInProcess(param) {
    return axios.post(`/payment/payment-in-process?paymentInProcessParam=${param}`).then((resp) => resp.data.payment_in_process);
  },

  // Reset Serialized basket
  resetSerialized() {
    return axios.put('/reset').then((resp) => resp.data);
  }
};

export default paymentServices;
