<template>
  <section class="info-box container-xl">
    <div class="row no-gutters">
      <div class="col">
        <div class="info-box__content" :class="[`bg-${colorVariant}`, colorVariant === 'primary' ? 'text-light' : 'text-dark']">
          <div class="card-body">
            <h4 v-if="headline" class="card-title">{{ headline }}</h4>
            <p v-if="description" class="card-text">{{ description }}</p>
            <DefaultButton
              :variant="buttonColorVariant || (colorVariant === 'primary' ? 'light' : 'primary')"
              :button-text="buttonText"
              :link="buttonLink"
              :type="buttonType"
              :scrollto="scrollTo"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

  export default {
    components: {
      DefaultButton
    },
    props: {
      headline: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      buttonType: {
        type: String,
        default: 'default'
      },
      buttonLink: {
        type: String,
        default: '#'
      },
      scrollTo: {
        type: String,
        default: ''
      },
      colorVariant: {
        type: String,
        default: ''
      },
      buttonColorVariant: {
        type: String,
        default: ''
      }
    }
  };
</script>
<style lang="scss" scoped>
  .info-box {
    @extend %spacer-bottom;

    &__content {
      padding: 2rem 1em;
      box-shadow: $box-shadow-lg;
      text-align: center;
      border-radius: $border-radius;

      @include media-breakpoint-up(md) {
        padding: 3rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 4rem;
      }

      .card-title {
        @extend %font-h2;
      }

      .card-body {
        padding: 0;
      }
    }
  }
</style>
