<template>
  <header ref="header" id="header" class="header header--alt bg-white sticky-header" :class="{ 'logged-out': !headerLoggedIn }">
    <div class="container-xl">
      <div class="header__wrap">
        <div class="header__logo">
          <router-link :to="$routeHandler(headerLoggedIn ? '/welcome' : '/')" class="header__logo-link">
            <img :src="logo" />
          </router-link>
        </div>

        <div class="header__menu" @click="triggerMenu()" v-if="headerLoggedIn && !minimalMode" data-test="header-menu">
          <span class="header__menu-line"></span>
          <span class="header__menu-text">Menu</span>
        </div>

        <ProductSearchAlt v-if="headerLoggedIn && !minimalMode" @search="search" ref="searchInput" />

        <div class="header__icons">
          <span class="header__logout" @click="logout()" v-if="headerLoggedIn">
            <b-icon icon="logout" />
          </span>

          <router-link :to="$routeHandler('/basket')" class="header__cart" v-if="headerLoggedIn">
            <b-icon icon="cart3" />
            <span class="cart-counter"> {{ itemsCounter }}</span>
          </router-link>
        </div>
      </div>
      <div class="header__nav" v-if="!headerLoggedIn">
        <ul class="nav main-nav" v-if="loggedOutLinks.length">
          <li v-for="(item, index) in loggedOutLinks" :key="index" class="nav-item">
            <router-link v-if="!item.href" :to="{ path: $routeHandler(item.path), query: item.query, hash: item.hash }" class="nav-link">{{item.name}}</router-link>
            <a v-else :href="item.href" :target="item.target" class="nav-link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <ProductSearchAltResults v-if="searchText.length > 0" :search="searchText" @clearSearch="clearSearch" />
    <template v-if="headerLoggedIn">
      <transition name="slide-fade">
        <HeaderMenu v-if="showMenu" @closeMenu="closeMenu" :logo="logo" :links="loggedInLinks" :loggedIn="headerLoggedIn" />
      </transition>
    </template>
  </header>
</template>

<script>
  import ProductSearchAlt from '@/components/Product/ProductSearchAlt.vue';
  import ProductSearchAltResults from '@/components/Product/ProductSearchAltResults.vue';
  import HeaderMenu from './HeaderMenu.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import headerMixin from '@/plugins/mixins/headerMixin';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      ProductSearchAlt,
      ProductSearchAltResults,
      HeaderMenu,
      BIcon
    },
    data() {
      return {
        showMenu: false,
        searchText: ''
      };
    },
    mixins: [headerMixin],
    props: {
      logo: {
        type: String,
        default: require('@/assets/images/logo.png')
      },
      languageLogo: {
        type: String,
        default: require('@/assets/images/de.png')
      },
      loggedInLinks: {
        type: Array,
        default: undefined
      },
      loggedOutLinks: {
        type: Array,
        default: undefined
      }
    },
    computed: {
      ...mapGetters({
        itemsCounter: 'basket/positionsCounter',
        minimalMode: 'pages/minimalMode',
        isPunchout: 'punchout/isPunchout'
      }),
      headerLoggedIn() {
        return this.$store.state.app.headerLoggedIn;
      }
    },
    watch: {
      $route() {
        this.clearSearch()
      }
    },
    mounted() {
      if (this.$store.state.app.mgnlMode) return;

      let lastPos = 0;
      const header = this.$refs.header;

      setTimeout(() => {
        const links = header.querySelectorAll('nav a');
        const anchors = [...links]
          .filter((link) => link.hash && link.hash !== '#app' && document.querySelector(link.hash))
          .map((link) => {
            const section = document.querySelector(link.hash);
            return { el: link, yMin: section.offsetTop - 30, yMax: section.offsetTop - 30 + section.offsetHeight };
          });

        window.addEventListener('scroll', () => {
          const scrollPos = window.scrollY || document.documentElement.scrollTop;

          // detects new state and compares it with the new one
          if (scrollPos < lastPos) {
            document.getElementById('header').setAttribute('data-scroll-direction', 'up');
          } else {
            document.getElementById('header').setAttribute('data-scroll-direction', 'down');
          }
          lastPos = scrollPos <= 0 ? 0 : scrollPos;

          anchors.forEach((anchor) => {
            anchor.el.classList.remove('active');
            if (scrollPos >= anchor.yMin && scrollPos < anchor.yMax) anchor.el.classList.add('active');
          });
        });
      }, 500);
    },
    methods: {
      triggerMenu() {
        this.showMenu = !this.showMenu;
      },
      closeMenu() {
        this.showMenu = false;
      },
      search(searchText) {
        this.searchText = searchText;
      },
      clearSearch() {
        this.$refs.searchInput?.clear();
      }
    }
  };
</script>

<style lang="scss">
  .header--alt {
    padding: 1.5rem 0;
    border-bottom: none;

    &.sticky-header[data-scroll-direction='up'] {
      top: 0 !important;
    }

    &.sticky-header[data-scroll-direction='down'] {
      top: 0 !important;
    }

    &.logged-out {
      overflow: hidden;
      padding: 0.5rem 0 0 !important;
      border-bottom: 0 !important;

      &.sticky-header[data-scroll-direction='up'] {
        top: 0 !important;
      }

      &.sticky-header[data-scroll-direction='down'] {
        top: -5rem !important;
      }

      .header__wrap {
        justify-content: space-between;
        padding-bottom: 1rem;

        &::before {
          content: '';
          background-color: $gray-100;
          position: absolute;
          bottom: 0;
          left: calc(-50vw + 50%);
          width: 100vw;
          height: 1px;
        }
      }

      .header__language,
      .header__logout {
        @include media-breakpoint-down(md) {
          display: flex !important;
        }
      }
    }

    .header__logo {
      display: flex;
      align-items: center;
      padding: 1rem 0;

      @include media-breakpoint-down(md) {
        margin-right: 2rem;
      }

      @include media-breakpoint-down(sm) {
        margin-right: auto;
      }

      &-link {
        height: 40px;

        @include media-breakpoint-down(md) {
          height: 32px;
        }
      }

      img {
        height: 100%;
        width: auto;
      }
    }

    .header__nav {
      width: 100%;
      overflow-x: auto;

      .nav {
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      .nav-link {
        padding: 0.8rem 0;
        margin-right: 2rem;
        font-weight: bold;
      }
    }

    .header__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      position: relative;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }

    .header__menu {
      margin-left: 4rem;
      margin-right: 2rem;
      padding: 1rem 1.5rem;
      background-color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      transition: $dur $ease;

      &:hover {
        background: var(--primary-dark);
      }

      @include media-breakpoint-down(lg) {
        margin-left: 2rem;
      }

      @include media-breakpoint-down(md) {
        order: 4;
        margin-right: 0;
        padding: 0.9rem;
        width: 3rem;
        height: 3rem;
      }

      @include media-breakpoint-down(sm) {
        order: 3;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.6rem;
      }

      @media (max-width: 350px) {
        margin-left: 1rem;
      }

      &-line {
        display: block;
        height: 2px;
        width: 1.25rem;
        background-color: $light;
        position: relative;
        margin-right: 8px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
          width: 1.5rem;
        }

        &::before,
        &::after {
          content: '';
          height: 2px;
          width: 20px;
          background-color: $light;
          position: absolute;
          left: 0;
        }

        &::before {
          top: 6px;
        }

        &::after {
          bottom: 6px;
        }
      }

      &-text {
        color: $white;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .header__icons {
      display: flex;
    }

    .header__language,
    .header__logout {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      margin-right: 2rem;
      padding: 1rem;
      border-radius: 5px;
      transition: $dur $ease;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
      }

      @include media-breakpoint-down(lg) {
        margin-right: 1rem;
        width: 3rem;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .header__cart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      color: $dark;
      font-size: 1.2rem;
      padding: 1rem;
      border-radius: 5px;
      transition: $dur $ease;

      .cart-counter {
        font-size: 0.9rem;
        padding: 0.2rem;
      }

      &:hover {
        background-color: $gray-100;
        color: $dark;
      }

      @include media-breakpoint-down(lg) {
        width: 3rem;
      }

      @include media-breakpoint-down(md) {
        padding: 0.75rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 0.5rem;
        width: 2.5rem;
      }

      b-icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }
</style>
