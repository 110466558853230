<template>
  <article>
    <div class="card product">
      <div class="card-body">
        <div class="product-content">
          <div class="product__type">{{ getProductType(product.type_group) }}</div>
          <div class="product__name">{{ product.name }}</div>
          <div class="product_desc" v-html="truncateText(product.description, 150)" />
          <div class="price-area">
            <div class="product__price-wrap">
              <div v-if="isReward" class="product__price">
                {{ $currency(firstVarSellingPrice) }}
              </div>
              <div v-if="isReward && hasDiscount" class="product__old-price">
                {{ $currency(retailPrice) }}
              </div>
              <div v-else-if="product.variations.length && firstVarSellingPrice < lastVarSellingPrice" class="product__price">
                {{ $currency(firstVarSellingPrice, getCurrencySymbol(product)) }} -
                {{ $currency(lastVarSellingPrice, getCurrencySymbol(product)) }}
              </div>
              <div v-else-if="!isReward" class="product__price">
                {{ $currency(firstVarSellingPrice, getCurrencySymbol(product)) }}
              </div>
            </div>
          </div>
          <DefaultButton 
            class="product-btn"
            buttonText="Jetzt shoppen!"
            type="link"
            :link="`/product-details/${product.number}`"
            variant="primary"
            size=""
          />
        </div>
        <div class="product__image-container">
          <img
            :src="imageUrl"
            :alt="product.name"
            class="product__image-container__image"
            :class="{ 'product__image-container__image--voucher': !isReward, rounded: !isReward }"
          />
        </div>
        <div class="product__badge--fixed" v-if="hasDiscount">
          <span class="badge badge-secondary product__discount">-{{ discountPercentage }}%</span>
        </div>
        <div class="product__badge--fixed" v-if="product.discount_text && !hasDiscount">
          <span class="badge badge-secondary product__discount">{{ product.discount_text }}</span>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  export default {
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    components: { DefaultButton },
    data() {
      return {
        imageServerUrl: "https://images.cadooz.com/",
        firstVarSellingPrice: 0,
        lastVarSellingPrice: 0,
        retailPrice: 0
      };
    },
    computed: {
      isReward() {
        return this.product.type_group === "PHY";
      },
      isB2B() {
        return this.$store.state.app.isB2B;
      },
      imageUrl() {
        const { product, imageServerUrl } = this;
        let imgUrl = product.big_images[0];
        if (!imgUrl) {
            imgUrl = product.gift_card_image;
        }
        return `${imageServerUrl}${imgUrl}`;
      },
      discountPercentage() {
        return this.product.displayed_discount;
      },
      hasDiscount() {
        return this.discountPercentage > 0;
      }
    },
    methods: {
      truncateText(text, length) {
        if (text) {
          return text.slice(0, length) + " " + "...";
        }
      },
      getProductType(type) {
        switch(type) {
          case 'TVO':
            return this.$t('filter.group.type.option_vouchers');
          case 'PHY':
            return this.$t('filter.group.type.option_brand_products');
          case 'UVO':
            return this.$t('filter.group.type.option_theme_vouchers');
          default:
            return '';
        }
      },
      getVariationSellingPrice(product, varIndex) {
        let price = product.variations[varIndex][(this.isB2B ? 'net_' : '') + 'selling_price'];
        if(!this.isReward && product.external_product_value_type_id !== 'HID' || product.variations.length > 1) {
          price = product.variations[varIndex].face_value.amount;
        }
        return price;
      },
      getCurrencySymbol(product) {
        return product.variations[0]?.face_value?.currency?.short_symbol || product.variations[0]?.face_value?.currency?.symbol;
      }
    },
    created() {
      this.firstVarSellingPrice = this.getVariationSellingPrice(this.product, 0);
      this.lastVarSellingPrice = this.getVariationSellingPrice(this.product, this.product.variations.length - 1);
      this.retailPrice = this.isB2B ? this.product.net_recommended_retail_price : this.product.recommended_retail_price;
    }
  }
</script>

<style lang="scss" scoped>
  .flag-icon {
    transform: scale(2);
  }
  .product-link:hover .product {
    box-shadow: $box-shadow;
  }
  .product {
    box-shadow: $box-shadow-sm;
    width: 100%;
    border-radius: $border-radius;
    transition: all 0.15s ease-in;
    border-left: 8px solid var(--primary);
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 100%;
      padding-top: 1rem;
      @include media-breakpoint-up(md) {
        padding-top: 0;
        max-width: calc(60% - 1rem);
      }
    }
    &-link {
      color: $body-color;
    }
    &-btn {
      width: max-content;
      padding: 0.75rem 1.5rem;
    }

    .card-body {
      position: relative;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      @include media-breakpoint-up(md){
        flex-direction: row;
      }
      @include media-breakpoint-up(lg){
        padding: 2rem 3rem;
      }
    }
    .card-footer {
      height: 1rem;
      margin-bottom: 1rem;
    }
    &__badges{
      margin-bottom: .75rem;
    }
    &__promotion {
      margin-right: .5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__discount {
      max-width: max-content;
    }
    &__image-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(md){
        width: calc(40% - 1rem);
      }
      &__image {
        max-width: 100%;
        max-height: 100%;
      }
      &__image--voucher {
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius;
      }
    }
    &__type {
      margin-bottom: .25rem;
      font-weight: bold;
      color: var(--primary);
      font-size: .8rem;
    }
    &__name {
      margin-bottom: 0.5rem;
      font-size: 1.75rem;
      color: $body-color;
      line-height: $headings-line-height;
      font-family: $font-semibold;
      @include media-breakpoint-up(md){
        height: ($h5-font-size * $headings-line-height) * 2;
        overflow: hidden;
      }
    }
    &__price-wrap {
      display: flex;
      align-items: baseline;
      width: 100%;
      height: ($h1-font-size * $headings-line-height) * 1.5;
      overflow: hidden;
    }
    &__price,
    &__old-price {
      @extend %font-h1;
      font-family: $font-semibold;
      display: table-cell;
    }
    &__price {
      color: var(--secondary);
      margin-right: .5rem;
    }
    &__old-price {
      color: $gray_700;
      text-decoration: line-through;
      text-align: right;
      font-size: $font-size-sm;
    }
  }
  .product__badge--fixed {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    z-index: 3;

    .badge {
      padding: 1rem;
      font-size: 1rem;
    }
  }
</style>
