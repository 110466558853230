<template>
  <div class="summary">
    <div v-if="basketSaving != 0" class="summary__row summary__row--basket-saving pb-3">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.saving') }}</div>
      <div class="summary__value">{{ $currency(basketSaving) }}</div>
    </div>
    <div class="summary__row summary__row--basket-value pb-3">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.cart') }}</div>
      <div class="summary__value">{{ $currency(basketPriceNoFees) }}</div>
    </div>
    <div class="summary__row summary__row--voucher-value pb-3" v-if="useCredit">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.credits_used') }}</div>
      <div class="summary__value">{{ $currency(usedCreditAmount) }}</div>
    </div>
    <div v-if="postPaymentValue > 0 && useCredit" class="summary__row summary__row--post-payment-value pb-3">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.post_payment_value') }}</div>
      <div class="summary__value">{{ $currency(postPaymentValue) }}</div>
    </div>
    <div v-for="(fee, i) in impersonalAccounts" :key="i" class="summary__row summary__row--post-payment-value pb-3">
      <div class="summary__title">{{ $t(getFee(fee.identifier)?.title) }}</div>
      <div class="summary__value">{{ $currency(isB2B ? fee.net_price.amount : fee.gross_price.amount) }}</div>
    </div>
    <div class="summary__row summary__row--remaining-credit py-3" v-if="useCredit">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.credits_rest') }}</div>
      <div class="summary__value">{{ $currency(remainingBalance) }}</div>
    </div>
    <div v-if="isB2B && tax" class="summary__row summary__row--vat pt-3 pb-2">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.tax') }}</div>
      <div class="summary__value">{{ $currency(tax) }}</div>
    </div>
    <div class="summary__row summary__row--total pt-3 pb-2">
      <div class="summary__title">{{ $t('view.checkout.cart.summary.sum') }}</div>
      <div class="summary__value">{{ $currency(sum) }}</div>
    </div>
    <div class="summary__row summary__row--vat">
      <small v-for="vat in vatObject" :key="vat.index"><sup>{{ vat.index }}</sup> {{ vat.desc }} <br /></small>
    </div>
    <div class="mt-4">
      <DefaultButton id="checkoutConfirmation" :disabled="buttonDisabled"  :loading="isLoading" variant="primary" block class="mb-1" @click="handleButtonClick">
        {{ buttonText }}
      </DefaultButton>
      <DefaultButton
        v-if="this.$route.name.includes('basket')"
        :disabled="buttonDisabled"
        variant="primary"
        block
        @click="handleContinueShopping"
      >
        {{ $t('view.checkout.cart.summary.button.shopping') }}
      </DefaultButton>
    </div>
    <template v-if="confirmPage">
      <div v-if="isOpenShop" class="hcaptcha-container">
        <VueHcaptcha ref="hcaptcha" :sitekey="hcaptchaKey" :language="$store.state.translation.locale"  @error="onError" @verify="onSuccess" @expired="resetHcaptcha" />
      </div>
      <div class="legal-text" v-if="newsletterCheckout">
        <div class="custom-control custom-checkbox cb-container">
          <input v-model="newsletterConfirm" @input="e => $emit('newsletterConfirm', e.target.checked)" type="checkbox" class="custom-control-input" id="newsletterCheckbox"/>
          <label class="custom-control-label" for="newsletterCheckbox"></label>
        </div>
        <p class="legal-text-p">{{ $t('view.checkout.cart.summary.newsletter') }}</p>
      </div>
      <BasketSummaryLegalText :class="!isOpenShop ? 'mt-4' : ''" @toggleCheckbox="updateTermsAndConditions" />
    </template>
  </div>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { mapGetters, mapState } from 'vuex';
  import vatIndexMap from '@/config/vatIndexMap';
  import serviceFees from '@/config/serviceFees';
  import BasketSummaryLegalText from '@/components/Basket/BasketSummaryLegalText.vue';
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

  export default {
    components: {
      VueHcaptcha,
      DefaultButton,
      BasketSummaryLegalText
    },
    props: {
      buttonText: {
        type: String,
        default: ''
      },
      buttonDisabled: {
        type: Boolean,
        default: false
      },
      product: {
        type: Object,
        default: null
      },
      confirmPage: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        newsletterConfirm: false,
        captchaToken: ''
      };
    },
    computed: {
      ...mapGetters({
        usedCreditAmount: 'basket/getUsedCreditAmount',
        postPaymentValue: 'basket/getPostPaymentAmount',
        remainingBalance: 'basket/getRemainingPrepaidValue',
        vatTypes: 'basket/getVatTypesInBasket',
        minimalMode: 'pages/minimalMode',
        prepaidPayments: 'basket/getPrepaidPayments'
      }),
      ...mapState({
        basketGrossPrice: (state) => state.basket.grossPrice,
        basketNetPrice: (state) => state.basket.netPrice,
        basketDiscount: (state) => state.basket.discount,
        impersonalAccounts: (state) => state.basket.impersonalAccounts,
        tax: (state) => state.basket.tax,
        missingAmount: (state) => state.basket.missingAmountOfMoney,
        basketPositions: (state) => state.basket.positions,
        isLoading: (state) => state.app.isLoading,
        isB2B: (state) => state.app.isB2B
      }),

      hcaptchaKey() {
        return process.env.VUE_APP_HCAPTCHA_SITE_KEY;
      },

      isOpenShop() {
        return this.$store.state.app.isOpenShop;
      },

      newsletterCheckout() {
        return this.$store.getters['pages/newsletterCheckout'];
      },

      basketPriceNoFees() {
        let price = this.isB2B ? this.basketNetPrice : this.basketGrossPrice;
        this.impersonalAccounts?.forEach(fee => price -= this.isB2B ? fee.net_price.amount : fee.gross_price.amount);
        return price;
      },

      sum() {
        return this.postPaymentValue > 0 ? this.postPaymentValue : this.missingAmount;
      },

      useCredit() {
        return this.prepaidPayments.length > 0;
      },

      vatObject() {
        return this.vatTypes.map((vatType) => {
          return {
            index: vatIndexMap[vatType],
            desc: this.$t('view.checkout.cart.summary.vat', { vatType })
          };
        });
      },
      basketSaving() {
        let saving = 0;
        this.basketPositions.forEach(position => {
          const product = this.$store.getters['product/getProduct'](position.product_number)
          const variation = Array.isArray(product?.variations) 
                            ? product.variations.find(variation => variation.number === position?.variation_number) 
                            : undefined;
          if (product?.displayed_discount > 0 && variation) {
            saving += ((variation.retail_value*position.quantity) - position.gross_price)
          }
        });
        return saving;
      }
    },
    methods: {
      handleButtonClick(event) {
        this.$emit('button-click', event);
      },
      handleContinueShopping() {
        const path = this.minimalMode ? '/welcome' : '/product-overview';
        this.$router.push({ path: this.$routeHandler(path) });
      },
      async updateTermsAndConditions(value) {
        await this.$store.dispatch('basket/setTermsAndConditions', value);
        this.$emit('toggleTermsAndCond', value);
      },
      onError(error) {
        if (!error.handled) {
          this.toast(this.$t('error.hcaptcha', { error }));
        }
      },
      onSuccess(captchaToken) {
        this.captchaToken = captchaToken;
        this.$emit('captchaToken', captchaToken);
      },
      getFee(id) {
        return serviceFees.find(fee => fee.id === id);
      },
      async resetHcaptcha() {
        await this.$refs.hcaptcha.reset();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .legal-text {
    @extend %font-sm;
    margin-top: 1rem;

    &-p {
      margin-bottom: 0;
      display: inline;
    }
  }
  .cb-container {
    display: inline;
    width: 1.25rem;
    height: 1.25rem;
  }
  .custom-control {
    padding-left: 0;
    margin-right: 1.5rem;
  }
  .custom-control-label{
    padding: 0;
    
    &::before,
    &::after {
      left: 0;
    }
  }
  .custom-control-label::before,
  .custom-control-label::after {
    width: 1.25rem;
    height: 1.25rem;
  }

  .hcaptcha-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    transform: scale(0.8);

    @media screen and (min-width: 768px) and (max-width: 900px) {
      transform: scale(0.67);
    }
  }
  .summary {
    padding: 1rem;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
    @include media-breakpoint-up(md) {
      margin: 0;
      align-self: flex-start;
    }
    @include media-breakpoint-up(lg) {
      padding: 2rem;
    }
    &__row {
      display: table;
      width: 100%;
      &--basket-value,
      &--voucher-value,
      &--post-payment-value,
      &--remaining-credit,
      &--vat,
      &--basket-saving {
        @extend %font-h6;
      }
      &--basket-saving {
        color: var(--primary)
      }
      &--basket-value,
      &--total {
        font-family: $font-semibold;
      }
      &--remaining-credit {
        border-top: $border-width solid $border-color;
        border-bottom: $border-width solid $border-color;
      }
      &--total {
        @extend %font-h5;
      }
      &--vat {
        color: $gray-400;
      }
    }
    &__title,
    &__value {
      display: table-cell;
      vertical-align: top;
    }
    &__value {
      text-align: right;
    }
  }
</style>
