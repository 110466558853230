<template>
  <div class="px-1 pb-2 pt-4">
    <div v-if="localMaxRange > 0" id="slider-distance" :class="{'mg-down' : additionalOptions.filterVariant === 'sidebar-filter'}" slider style="position: relative">
      <div style="position: relative">
        <div inverse-left :style="{ width: inverseLeftWidth }"></div>
        <div inverse-right :style="{ width: inverseRightWidth }"></div>
        <div range :style="{ left: rangeLeft, right: rangeRight }"></div>
        <span thumb :style="{ left: thumbLeft__left }"></span>
        <span thumb :style="{ left: thumbRight__left }"></span>
      </div>
      <input
        id="input-min"
        :value="minValue"
        type="range"
        tabindex="0"
        :max="localMaxRange"
        :min="localMinRange"
        step="1"
        @input="moveSliderLeft($event)"
      />

      <input
        id="input-max"
        :value="maxValue"
        type="range"
        tabindex="0"
        :max="localMaxRange"
        :min="localMinRange"
        step="1"
        @input="moveSliderRight($event)"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      minRange: {
        type: String,
        default: '0'
      },
      maxRange: {
        type: String,
        default: '100'
      },

      priceMinFilter: {
        type: String,
        default: ''
      },
      priceMaxFilter: {
        type: String,
        default: ''
      }
    },
    computed: {
      additionalOptions() {
        return this.$store.state.pages.additionalOptions;
      }
    },
    data() {
      return {
        minValue: this.priceMinFilter,
        maxValue: this.priceMaxFilter,
        localMinRange: this.minRange,
        localMaxRange: this.maxRange,
        inverseLeftWidth: '100%',
        inverseRightWidth: '0%',
        rangeLeft: '0%',
        rangeRight: '0%',
        thumbLeft__left: '0%',
        thumbRight__left: '100%'
      };
    },
    watch: {
      priceMinFilter() {
        this.minValue = this.priceMinFilter;
        this.updateSliderRight();
        this.updateSliderLeft();
      },
      priceMaxFilter() {
        this.maxValue = this.priceMaxFilter;
        this.updateSliderRight();
        this.updateSliderLeft();
      },
      minRange() {
        this.localMinRange = this.minRange;
        this.localMaxRange = this.maxRange;
        this.updateSliderRight();
        this.updateSliderLeft();
      },
      maxRange() {
        this.localMinRange = this.minRange;
        this.localMaxRange = this.maxRange;
        this.updateSliderRight();
        this.updateSliderLeft();
      }
    },
    mounted() {
      this.updateSliderLeft();
      this.updateSliderRight();
    },
    methods: {
      drawCustomRangeLeft(value) {
        this.inverseLeftWidth = value + '%';
        this.rangeLeft = value + '%';
        this.thumbLeft__left = value + '%';
      },

      drawCustomRangeRight(value) {
        this.inverseRightWidth = 100 - value + '%';
        this.rangeRight = 100 - value + '%';
        this.thumbRight__left = value + '%';
      },

      calculatePercentage(minRange, maxRange, currentValue) {
        let value =
          (100 / (parseInt(maxRange) - parseInt(minRange))) * parseInt(currentValue) -
          (100 / (parseInt(maxRange) - parseInt(minRange))) * parseInt(minRange);
        if (value > 100) {
          value = 100;
        }
        if (value < 0) {
          value = 0;
        }
        return value;
      },

      moveSliderLeft(event) {
        const target = event.target;
        target.value = Math.min(target.value, this.maxValue - 1);
        this.minValue = target.value;
        const value = this.calculatePercentage(target.min, target.max, target.value);
        this.drawCustomRangeLeft(value);
        this.$emit('slided', { newMin: this.minValue, newMax: this.maxValue });
      },

      moveSliderRight(event) {
        const target = event.target;
        target.value = Math.max(target.value, this.minValue - -1);
        this.maxValue = target.value;
        const value = this.calculatePercentage(target.min, target.max, target.value);
        this.drawCustomRangeRight(value);
        this.$emit('slided', { newMin: this.minValue, newMax: this.maxValue });
      },
      updateSliderRight() {
        this.localMaxRange = this.maxRange;
        this.localMinRange = this.minRange;
        this.maxValue = Math.max(this.priceMaxFilter, this.minValue - -1);
        const value = this.calculatePercentage(this.localMinRange, this.localMaxRange, this.priceMaxFilter);
        this.drawCustomRangeRight(value);
      },
      updateSliderLeft() {
        this.localMaxRange = this.maxRange;
        this.localMinRange = this.minRange;
        this.minValue = Math.min(this.priceMinFilter, this.maxValue - 1);
        const value = this.calculatePercentage(this.localMinRange, this.localMaxRange, this.priceMinFilter);
        this.drawCustomRangeLeft(value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  [slider] {
    pointer-events: none;
  }
  [slider] > div > [inverse-left] {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 4px;
    background-color: var(--primary);
    opacity: 0.3;
    margin: 0;
  }

  [slider] > div > [inverse-right] {
    position: absolute;
    right: 0;
    height: 4px;
    border-radius: 4px;
    background-color: var(--primary);
    opacity: 0.3;
    margin: 0;
  }

  [slider] > div > [range] {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 4px;
    background-color: var(--primary);
  }

  [slider] > div > [thumb] {
    position: absolute;
    top: -7px;
    z-index: 2;
    height: 18px;
    width: 18px;
    margin-left: -9px;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    background-color: var(--primary);
    border-radius: 50%;
    outline: none;
    transition: 0.3s transform;
  }

  div[slider] > input[type='range']::-ms-thumb {
    cursor: pointer;
    margin-left: 0;
    pointer-events: all;
    width: 26px;
    height: 26px;
    border-radius: 0px;
    border: 0 none;
    background: red;
  }

  div[slider] > #input-min::-ms-thumb {
    margin-left: -6px;
  }

  div[slider] > input[type='range']::-moz-range-thumb {
    cursor: pointer;
    padding-top: 0;
    margin-left: 0;
    pointer-events: all;
    width: 26px;
    height: 26px;
    border-radius: 0px;
    border: 0 none;
    background: red;
  }

  div[slider] > #input-min::-moz-range-thumb {
    padding-left: 10px;
  }

  div[slider] > input[type='range']::-webkit-slider-thumb {
    cursor: pointer;
    margin-left: 0px;
    pointer-events: all;
    width: 26px;
    height: 26px;
    border-radius: 0px;
    border: 0 none;
    background: red;
    -webkit-appearance: none;
  }

  div[slider] > #input-min::-webkit-slider-thumb {
    margin-left: -6px;
  }

  div[slider] > input[type='range']::-ms-fill-lower {
    background: transparent;
    border: 0 none;
  }

  div[slider] > input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0 none;
  }

  [slider] > input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 14px;
    top: -2px;
    width: 110%;
    left: -5%;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0; //set 1 to see hitboxes of original thumbs
  }

  div[slider] > input[type='range']::-ms-track {
    -webkit-appearance: none;
    background: transparent;
    color: transparent;
  }

  div[slider] > input[type='range']::-moz-range-track {
    -moz-appearance: none;
    background: transparent;
    color: transparent;
  }

  div[slider] > input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
  }

  div[slider] > input[type='range']:focus {
    outline: none;
  }

  [slider]:hover > div > [thumb] {
    transform: scale(1.2);
  }

  .mg-down {
    margin-bottom: 1.5rem;
  }
</style>
