<template>
  <div
    :title="title"
    class="card"
    :class="[
      {
        'card--sm': cardSM,
        'card--lg': cardLG,
        card__bordered: bordered,
        card__shadow: shadowed,
        'card__shadow--sm': shadowSM,
        'card__shadow--lg': shadowLG,
        'card__no-padding': noPadding,
        'card--soft': softColor
      },
      textVariant ? `text-${textVariant}` : ''
    ]"
  >
    <div class="card-body">
      <h4 v-if="title" class="card-title">{{ title }}</h4>
      <p v-if="text" class="card-text">{{ text }}</p>
      <slot />
      <DefaultButton v-if="useButton" :variant="buttonVariant" :button-text="buttonText" :scrollto="scrollto" :link="buttonLink" :type="buttonType" />
    </div>
  </div>
</template>

<script>
  import DefaultButton from '../DefaultElements/DefaultButton.vue';

  export default {
    name: 'DefaultCard',
    components: {
      DefaultButton
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      textVariant: {
        type: String,
        default: 'dark'
      },
      text: {
        type: String,
        default: ''
      },
      useButton: {
        type: Boolean,
        default: false
      },
      buttonType: {
        type: String,
        default: 'default'
      },
      buttonVariant: {
        type: String,
        default: 'primary'
      },
      buttonText: {
        type: String,
        default: ''
      },
      buttonLink: {
        type: String,
        default: ''
      },
      scrollto: {
        type: String,
        default: '#'
      },
      cardSM: {
        type: Boolean,
        default: false
      },
      cardLG: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      shadowed: {
        type: Boolean,
        default: false
      },
      shadowSM: {
        type: Boolean,
        default: false
      },
      softColor: {
        type: Boolean,
        default: false
      },
      noPadding: {
        type: Boolean,
        default: false
      },
      shadowLG: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    border-radius: $border-radius;
    &--soft {
      &.bg-primary,
      &.text-primary {
        @include soft-card(primary); // assets/styles/mixins.scss
      }
      &.bg-secondary,
      &.text-secondary {
        @include soft-card(secondary);
      }
      &.bg-success,
      &.text-success {
        @include soft-card(success);
      }
      &.bg-info,
      &.text-info {
        @include soft-card(info);
      }
      &.bg-warning,
      &.text-warning {
        @include soft-card(primary);
      }
      &.bg-danger,
      &.text-danger {
        @include soft-card(danger);
      }
    }
    & .card-body {
      padding: 1.5rem;
    }
    & .card-title {
      @extend %font-h3;
      margin-bottom: 1rem;
    }
    & .card-text {
      @extend %font-base;
    }
    &__bordered {
      border: 1px solid $border-color;
      background: transparent;
    }
    &__shadow {
      box-shadow: $box-shadow;
      &--lg {
        box-shadow: $box-shadow-lg;
      }
      &--sm {
        box-shadow: $box-shadow-sm;
      }
    }
    &__no-padding {
      & .card-body {
        padding: 0;
      }
    }
    &--lg {
      & .card-body {
        padding: 2rem;
      }
      & .card-title {
        @extend %font-h2;
      }
      & .card-text {
        @extend %font-lg;
      }
    }
    &--sm {
      & .card-title {
        @extend %font-h4;
      }
      & .card-text {
        @extend %font-sm;
      }
    }
  }
</style>
