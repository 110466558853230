import { createI18n } from 'vue-i18n/index'
import de from '@/assets/i18n/de.json'
import en from '@/assets/i18n/en.json'
import es from '@/assets/i18n/es.json'
import fr from '@/assets/i18n/fr.json'
import it from '@/assets/i18n/it.json'
import nl from '@/assets/i18n/nl.json'
import pl from '@/assets/i18n/pl.json'

export default createI18n({
  legacy: true,
  warnHtmlMessage: false,
  warnHtmlInMessage: 'off',
  locale: 'de',
  globalInjection: true,
  messages: {
    de,
    en,
    es,
    fr,
    it,
    nl,
    pl
  }
})
