import axios from '@/plugins/axios';

const initServices = {
  // Returns the settings for this website and creates a new session if needed
  getWebsiteSettings({url, sessionId, language}) {
    const config = {};
    if(sessionId) {
      config.params = {
        sid: sessionId
      }
    }
    return axios
      .post("website/init", {
        url: `${url}`,
        language: `${language}`
      }, config)
      .then((resp) => resp.data);
  },

  // Request a verification for a Login over a Handshake
  verifyHandShake({ url, referrer, query}) {
    const config = {params: {
      query: query.handshakeQueryParam
    }};
    if(query.sessionId) {
      config.params['sid'] = query.sessionId;
    }
    const data = {
      url
    }
    if(referrer) data.referrer = referrer;
    return axios
      .post(`website/handshake`, data, config)
      .then((resp) => resp.data);
  },

  // Request the validation for the captcha token
  verifyCaptchaToken(token) {
    return axios
      .post('captcha/validate', {
        token
      })
      .then((resp) => resp.data);
  },

  // get the products for the given catalogs
  getCatalogProducts({ catalogId, shopUrl }) {
    return axios
      .post(`/catalog?id=${catalogId}`, {
        url: `${shopUrl}`
      })
      .then((resp) => resp.data.products);
  },

  decryptVoucherCode(payload) {
    return axios.post('/voucher/decrypt', payload).then((resp) => resp.data);
  }
};

export default initServices;
