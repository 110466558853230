import store from '@/store';
import {
  filterProductsByType,
  filterProductsByPriceRange,
  filterProductsByDiscount,
  filterProductsByBrand,
  filterProductsByCategory,
  filterProductsByShippingMethod,
  filterProductsByRedemption
} from '@/helpers/filterFunctions.js';
import { sortByPriceAscending, sortByPriceDescending, sortByNameAscending, sortByRecommendation, sortByPriceDiscount, sortByNameDescending } from '@/helpers/sortingFunctions.js';

// =======================================================================
// State
// =======================================================================
const state = () => ({
  productType: [],
  redemptionType: [],
  shippingMethod: [],
  priceMin: '',
  priceMax: '',
  discount: false,
  sortOption: 'default',
  loadedProducts: [],
  allProducts: [],
  highlightProducts: [],
  onlyRecommendations: false,
  relatedProduct: {},
  searchInput: '',
  brands: [],
  selectedBrands: [],
  category: null,
  activeCategory: -1,
  page: 1,
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_PRODUCTTYPE(state, data) {
    state.productType = data;
  },
  SET_REDEMPTIONTYPE(state, data) {
    state.redemptionType = data;
  },
  SET_SHIPPINGMETHOD(state, data) {
    state.shippingMethod = data;
  },
  SET_PRICE_MIN(state, data) {
    state.priceMin = data;
  },
  SET_PRICE_MAX(state, data) {
    state.priceMax = data;
  },
  SET_DISCOUNT(state, data) {
    state.discount = data;
  },
  SET_SORTOPTION(state, data) {
    state.sortOption = data;
  },
  SET_LOADED_PRODUCTS(state, data) {
    state.loadedProducts = data;
  },
  SET_ALL_PRODUCTS(state, data) {
    state.allProducts = data;
  },
  SET_HIGHLIGHT_PRODUCTS(state, data) {
    state.highlightProducts = data;
  },
  SET_ONLY_RECOMMENDATIONS(state, data) {
    state.onlyRecommendations = data;
  },
  SET_SEARCHINPUT(state, data) {
    state.searchInput = data;
  },
  SET_SELECTED_BRANDS(state, data) {
    state.selectedBrands = data;
  },
  SET_CATEGORY(state, data) {
    state.category = data;
    state.activeCategory = data ? data.id : -1;
  },
  SET_RELATED_PRODUCT(state, data) {
    state.relatedProduct = data;
  },
  SET_PAGE(state, data) {
    state.page = data;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  updatePriceFilter(context, priceObject) {
    context.commit('SET_PRICE_MIN', priceObject.min);
    context.commit('SET_PRICE_MAX', priceObject.max);
  },

  resetAllFilter({ commit }) {
    commit('SET_PRODUCTTYPE', []);
    commit('SET_REDEMPTIONTYPE', []);
    commit('SET_SHIPPINGMETHOD', []);
    commit('SET_DISCOUNT', false);
    commit('SET_SELECTED_BRANDS', []);
    commit('SET_CATEGORY', null);
    commit('SET_ONLY_RECOMMENDATIONS', false);
    commit('SET_SORTOPTION', 'default');
    commit('SET_PAGE', 1);
  },
  resetPriceFilter({ getters, dispatch }) {
    const priceMin = getters.getMinPriceOfAllProducts;
    const priceMax = getters.getMaxPriceOfAllProducts;
    const priceObject = { min: priceMin, max: priceMax };
    dispatch('updatePriceFilter', priceObject);
  },
  // Set min and max price before user come on product-overview page
  setPriceRangeAndProducts(context, data) {
    context.commit('SET_ALL_PRODUCTS', data);
    context.dispatch('resetPriceFilter');
    if (!context.state.highlightProducts.length) {
      context.dispatch('setHighlightProducts', data);
    }
  },
  setHighlightProducts(context, data) {
    const allProducts = JSON.parse(JSON.stringify(data))
    const sortedProducts = sortByPriceDiscount(allProducts);
    const finalProducts = sortedProducts.slice(0, 5);
    context.commit('SET_HIGHLIGHT_PRODUCTS', finalProducts);
  },
  setRelatedProducts(context, product) {
    const categories = context.getters.getCategories;
    const relatedCategory = categories.filter(element => product.categories.includes(element.id));
    let obj = {
      categoryId: null,
      categoryName: null,
      productId: product.number
    };

    if (relatedCategory.length) {
      obj = {
        categoryId: relatedCategory[0].id,
        categoryName: relatedCategory[0].name,
        productId: product.number
      }
    }

    context.commit('SET_RELATED_PRODUCT', obj);
  }
};

// =======================================================================
// Getters
// =======================================================================
const getters = {
  getBrands: (state) => {
    const allProducts = state.loadedProducts;
    const allBrands = allProducts.map((product) => {
      if (product.brand) return product.brand;
    });
    const allUniqueBrands = allBrands
      .sort(function (a, b) {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      })
      .filter((brand, index) => allBrands.indexOf(brand) === index);
    return allUniqueBrands.filter((brand) => brand !== null && brand !== undefined && brand !== 'no brand');
  },

  /**
   * Filters the loaded Products based on the Filter States (without Price Filter)
   * @param {*} state
   * @returns {Array}
   */
  getFilteredProductsWithoutPriceFilter: (state) => {
    let products = state.loadedProducts;
    if (state.productType.length > 0) {
      products = filterProductsByType(products, state.productType);
    }
    if (state.redemptionType.length > 0) {
      products = filterProductsByRedemption(products, state.redemptionType);
    }
    if (state.shippingMethod.length > 0) {
      products = filterProductsByShippingMethod(products, state.shippingMethod);
    }
    if (state.discount) {
      products = filterProductsByDiscount(products);
    }
    if (state.selectedBrands && state.selectedBrands.length >= 1) {
      if (state.productType.length === 1 && state.productType[0].value === 'TVO') {
        // if true, do nothing
      } else products = filterProductsByBrand(products, state.selectedBrands);
    }
    if (state.category) {
      products = filterProductsByCategory(products, state.category);
    }
    return products;
  },
  /**
   * Filters the already filtered Products by Price
   * @param {*} state
   * @param {*} getters
   * @returns {Array}
   */
  getFilteredProducts: (state, getters) => {
    let products = getters.getFilteredProductsWithoutPriceFilter;
    const minValue = parseInt(state.priceMin);
    const maxValue = parseInt(state.priceMax);

    if (minValue && maxValue) {
      products = filterProductsByPriceRange(products, minValue, maxValue);
    }

    return products;
  },
  /**
   * Sorts the filtered Products based on the sortOption State
   * @param {*} state
   * @param {*} getters
   * @returns {Array}
   */
  getFilteredAndSortedProducts: (state, getters) => {
    const filteredProducts = getters.getFilteredProducts;
    let sortedProducts;
    switch (state.sortOption) {
      case 'priceDescending':
        sortedProducts = sortByPriceDescending(filteredProducts);
        break;
      case 'priceAscending':
        sortedProducts = sortByPriceAscending(filteredProducts);
        break;
      case 'nameAscending':
        sortedProducts = sortByNameAscending(filteredProducts);
        break;
      case 'nameDescending':
        sortedProducts = sortByNameDescending(filteredProducts);
        break;
      case 'noSorting':
        sortedProducts = filteredProducts;
        break;
      case 'byDiscount':
        sortedProducts = sortByPriceDiscount(filteredProducts);
        break;
      default:
        sortedProducts = sortByRecommendation(filteredProducts);
    }
    return sortedProducts;
  },

  /**
   * Calculates and returns the Smallest Price of all filtered Products ( without Price Filter)
   * @param {*} state
   * @param {*} getters
   * @returns {String}
   */
  getMinPriceOfAllProducts: (state) => {
    if (Array.isArray(state.allProducts) == false) return;

    let minPriceTotal = 1000;
    const filteredProducts = state.allProducts;
    if (filteredProducts.length === 0) {
      minPriceTotal = 0;
    } else {
      filteredProducts.forEach((product) => {
        const minPriceProduct = product.variations[0].selling_price;
        if (minPriceProduct < minPriceTotal) {
          minPriceTotal = minPriceProduct;
        }
      });
    }
    return minPriceTotal.toFixed(0).toString();
  },
  /**
   * Calculates and returns the Highest Price of all filtered Products ( without Price Filter)
   * @param {*} state
   * @param {*} getters
   * @returns {String}
   */
  getMaxPriceOfAllProducts: (state) => {
    if (Array.isArray(state.allProducts) == false) return;

    let maxPriceTotal = 0;
    const filteredProducts = state.allProducts;
    filteredProducts.forEach((product) => {
      const maxPriceProduct = product.variations[product.variations.length - 1].selling_price;
      if (maxPriceProduct > maxPriceTotal) {
        maxPriceTotal = maxPriceProduct;
      }
    });
    return maxPriceTotal.toFixed(0).toString();
  },
  getFilteredProductsCount: (_state, getters) => {
    return getters.getFilteredProducts.length;
  },
  /**
   * Returns an Array of Filter Tags for all active Filter
   * @param {*} state
   * @param {*} getters
   * @returns {Array}
   */
  getFilterTags: (state, getters, rootState) => {
    const allFilters = [...state.productType, ...state.shippingMethod, ...state.redemptionType];
    const sameMin = state.priceMin === getters.getMinPriceOfAllProducts;
    const sameMax = state.priceMax === getters.getMaxPriceOfAllProducts;
    if (!sameMin || !sameMax) {
      allFilters.push({ groupId: 'price', text: `${state.priceMin}-${state.priceMax} ${rootState.app.currency.code} ` });
    }
    if (state.discount) {
      allFilters.push({ groupId: 'discount', text: 'reduziert' });
    }
    if (state.selectedBrands && state.selectedBrands.length > 0) {
      if (state.productType.length === 1 && state.productType[0].value === 'TVO') {
        // if true, do nothing
      } else
        state.selectedBrands.forEach((brand) => {
          allFilters.push({ groupId: 'brand', text: brand });
        });
    }
    if (state.category) {
      allFilters.push({ groupId: 'category', text: state.category.name });
    }
    return allFilters;
  },
  getFilterCount: (state, getters) => {
    if (store.state.pages.additionalOptions.filterVariant === 'sidebar-filter') {
      return state.activeCategory !== -1 ? getters.getFilterTags.length - 1 : getters.getFilterTags.length;
    } else {
      return getters.getFilterTags.length;
    }
  },
  isSearchActive: (state) => {
    return state.searchInput !== '';
  },
  getCategories: (_state, _getters, rootState) => {
    const websiteSettings = rootState.app.websiteSettings;
    const allProducts = rootState.product.catalogProducts;

    // Clone object
    const shopCategories = [...websiteSettings.shop_categories];

    // Filter out any category that is nonexistent in any product
    const allProductCategories = Array.from(new Set(allProducts.map((product) => product.categories).flat()));
    const filteredShopCategories = shopCategories.filter((category) => allProductCategories.includes(category.id));

    // Sorting by name ascending
    filteredShopCategories.sort((c1, c2) => (c1.name > c2.name ? 1 : -1));
    return filteredShopCategories;
  },
  composedQuery: (state, getters) => {
    return {
      ...(state.category !== null && { category: state.category.id }),
      ...(getters.isSearchActive && { search: state.searchInput }),
      ...(state.productType.length > 0 && { productType: state.productType }),
      ...(state.shippingMethod.length > 0 && { shippingMethod: state.shippingMethod }),
      ...(state.redemptionType.length > 0 && { redemptionType: state.redemptionType }),
      recommended: state.onlyRecommendations,
      sorting: state.sortOption,
      ...(state.discount && { discount: state.discount }),
      price: { min: state.priceMin, max: state.priceMax },
      ...(state.selectedBrands && state.selectedBrands.length > 0 && { brands: state.selectedBrands.toString() }),
      page: state.page
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
