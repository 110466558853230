<template>
  <div class="logo-wrapper">
    <router-link :to="$routeHandler(loggedIn ? '/welcome' : '/')" class="navbar-brand">
      <img :src="logoImage" />
    </router-link>

    <LanguageSwitch v-if="!loggedIn" />
    <LanguageSwitch v-else-if="!isRedemptionShop" class="lang-mobile-screen" />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import LanguageSwitch from '../LanguageSwitch.vue'
  
  export default {
  components: { LanguageSwitch },
    props: {
      loggedIn: {
        type: Boolean,
        default: false
      },
      logo: {
        type: String,
        default: require('@/assets/images/BestChoice_Logo_4c_pos_grau.svg').default
      }
    },
    computed: {
      ...mapState({
        shopCustomization: (state) => state.basket.shopCustomization
      }),
      ...mapGetters({
        isRedemptionShop: 'app/isRedemptionShop'
      }),
      logoImage() {
        return this.shopCustomization?.customer_logo || this.logo;
      }
    },

  }
</script>

<style lang="scss" scoped>
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 34px;
    @include media-breakpoint-up(md) {
      height: 45px;
    }
  }

  img {
    height: 100%;
    width: auto;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    width: max-content;
    &:first-child {
      height: 100%;
    }
  }

  .lang-mobile-screen {
    display: none;

    @include media-breakpoint-down(md) { 
      display: inline-block;
    }
  }
</style>
