<template>
  <div>
    <div v-if="visible" data-show="true" role="dialog" class="modal fade show" tabindex="-1">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center pr-0 pl-3 mb-3"> 
            <DefaultHeadline is-headline heading-size="h4" class="title bold" :headline-text="title" />
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" variant="primary" @click="handleButtonClick">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="items">
              <DefaultCard bordered :class="{'mt-3' : index > 0}" v-for="(item, index) in items" :key="index">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="affiliate__image-container mr-3">
                      <div class="affiliate__image">
                        <img class="product__image" :src="`https://images.cadooz.com/${item.bakset_images[0]}`" alt="">
                      </div>
                    </div>
                    <div>
                      <h5 class="bold">{{ item.product_name }}</h5>
                      <p class="m-0">{{ $t('view.checkout.confirmation.afiliate.value', { value: $currency(getProductPrice(item), getCurrencySymbol(item)) }) }}</p>
                    </div>
                  </div>

                  <div v-if="item.affiliate_link">
                    <a :href="item.affiliate_link" class="affiliate_modal_gift_links" target="_blank">{{ affiliate_link }}</a>
                  </div>
                </div>
              </DefaultCard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="visible" class="modal-backdrop" @click="clickBackdrop" @scroll="scrollBackdrop"></div>
  </div>
</template>

<script>
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import DefaultCard from '../../../building-blocks/components/DefaultElements/DefaultCard.vue';

  export default {
    name: 'Modal',
    components: {
      DefaultHeadline,
      DefaultCard
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      affiliate_link: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      button: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        default: []
      }
    },
    computed: {
      activeGiftomatic() {
        return this.$store.getters['pages/activeGiftomatic'];
      }
    },
    mounted() {
      if (this.visible) {
        this.disableBackgroundScrolling();
      }
      if(this.activeGiftomatic && this.visible) {
        this.optimizedModalLinks();
      }
    },
    destroyed() {
      this.enableBackgroundScrolling();
    },
    methods: {
      handleButtonClick() {
        this.enableBackgroundScrolling();
        this.$emit('button-click');
      },
      enableBackgroundScrolling() {
        document.body.classList.remove('stop-scrolling');
      },
      disableBackgroundScrolling() {
        document.body.classList.add('stop-scrolling');
      },
      optimizedModalLinks() {
        const giftomaticLinks = document.querySelectorAll('.affiliate_modal_gift_links');
        giftomaticLinks.forEach(el => {
          el.addEventListener('click', element => {
            if (!element.target.classList.contains('link-optmz')) {
              element.preventDefault();
              element.target.href = 'https://www.giftomatic.io/out?dataset=CADOOZ&url=' + encodeURIComponent(el.href);
              element.target.classList.add('link-optmz');
              element.target.click();
            }
          })
        });
      },
      getProductPrice(prod) {
        let price = prod.face_value.amount;
        const product = this.$store.getters['product/getProduct'](prod.product_number);
        const isReward = this.isReward(prod.product_number);
        if(isReward) {
          price = product.recommended_retail_price;
        } else if (product.external_product_value_type_id === 'HID' && product.variations.length === 1) {
          price = prod.gross_price;
        }
        return price;
      },
      isReward(product_number) {
        return this.$store.getters['product/getProductTypReward'](product_number);
      },
      getCurrencySymbol(productPosition) {
        return this.isReward(productPosition.product_number) ? this.$store.state.app.currency.symbol : productPosition.face_value.currency.short_symbol || productPosition.face_value.currency.symbol
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    display: block;
  }
  .modal-content {
    padding: 1.5rem
  }
  .modal-dialog {
    max-width: 720px;
    width: 100%;
  }
  .modal-body {
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    padding: 0 1rem;

    &::-webkit-scrollbar {
      height: 3px;
      width: 10px;
      background: var(--light);
      transition: all .2s;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--gray-200);
      border-radius: $border-radius;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--gray-600);
      border-radius: $border-radius;
    }
  }
  .modal-backdrop {
    background-color: rgba($dark, 0.5);
  }
  .title {
    font-family: $font-semibold;
  }
  .bold {
    font-family: $font-bold;
  }
  .affiliate__image-container,
    .affiliate__image {
      width: 5rem;
      height: 3.2rem;
      position: relative;
      @include media-breakpoint-down(xs) {
        width: 3rem;
      }
    }
    .affiliate__image {
      z-index: 1;
      position: absolute;
      height: 100%;
      overflow: hidden;
      cursor: pointer;
      .product__image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
      }
    }
</style>
