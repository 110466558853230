<template>
  <InfoBox
    :headline="headline"
    :description="description"
    :buttonType="button.type.field"
    :buttonText="button.label"
    :buttonLink="getButtonHref(button)"
    :scrollTo="button.type.scrollTo"
    :colorVariant="variant"
    :buttonColorVariant="button.buttonColorVariant"
  />
</template>

<script>
  import InfoBox from '@/components/InfoBox/InfoBox.vue';
  export default {
    components: { InfoBox },
    props: {
      headline: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      button: {
        type: Object,
        default: {
          label: 'Button Label'
        }
      },
      variant: {
        type: String,
        default: 'primary'
      }
    },

    methods: {
      getButtonHref(button) {
        return button.type.field === 'url' ? button.type.url : (button.type.field === 'link' ? this.removeSiteName(button.type.link) : null);
      },
      // Example of link /ace/product-overview. With this method we removing '/ace'.
      removeSiteName(link) {
        if (link) {
          const siteName = link.substr(0, link.lastIndexOf("/"));
          return link.replace(siteName, '');
        }
      }
    }
  };
</script>
