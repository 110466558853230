<template>
  <div class="categories">
    <div class="categories__entries d-none d-lg-block">
      <div
        v-for="(category, index) in categories"
        :key="category.id"
        :data-index="index"
        :class="{ categories__entry: true, 'categories__entry--active': activeCategory === category.id }"
        @click="handleClick"
        :data-test="category.name"
      >
        <b-icon :icon="setIcon(category.id)" class="category-icon" />
        {{ category.name }}
      </div>
    </div>
    <DefaultInputSelect
      class="categories__selector d-lg-none"
      :label="title"
      :modelValue="activeCategory"
      :options="selectorCategories"
      :placeholder="$t('filter.navigation.all_products')"
      @inputed="handleSelect"
    />
  </div>
</template>

<script>
  import DefaultInputSelect from 'building-blocks/components/DefaultElements/DefaultInputSelect.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      DefaultInputSelect,
      BIcon
    },
    props: {
      categories: {
        type: Array,
        default: null
      },
      activeCategory: {
        type: Number,
        default: -1,
      },
      title: {
        type: String,
        default: ''
      }
    },
    computed: {
      selectorCategories() {
        return this.categories.map((category) => {
          return {
            value: category.id,
            text: category.name
          };
        });
      }
    },
    methods: {
      handleClick(e) {
        const index = parseInt(e.currentTarget.getAttribute('data-index'));
        const category = this.categories[index];
        this.$emit('update', category);
      },
      handleSelect(itemId) {
        let category = this.categories.find((category) => category && category.id === itemId);
        category = !category ? null : category;
        this.$emit('update', category);
      },
      setIcon(catId){
        return this.$store.getters['app/getCategoryIcon'](catId);
      }
    },
    mounted() {
      this.activeCategory
    }
  };
</script>

<style lang="scss" scoped>
  .categories {
    &__headline {
      font-family: $font-semibold;
      font-size: 1rem;
      padding-bottom: 1rem;
    }
    &__entry {
      cursor: pointer;
      font-family: $font-semibold;
      font-size: 0.8125rem;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      &--active,
      &:hover {
        color: var(--primary);
      }
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 2rem;
    }
  }
  .category-icon {
    fill: var(--primary);
    margin-right: .5rem;
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
</style>
