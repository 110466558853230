<template>
  <div class="cookie-modal" @click="onBgClick">
    <div ref="cookieModal" class="cookie-modal__content">
      <div class="text-right">
        <DefaultButton variant="primary" @click="close()" :buttonText="$t('global.cookie.settings.close')" :type="'button'" class="mb-4" />
      </div>
      <div>
        <h3>{{ $t('global.cookie.settings.headline') }}</h3>
        <p>
          {{ $t('global.cookie.settings.text') }} <router-link :to="$routeHandler('/privacy')"> {{ $t('global.cookie.settings.data_protection') }} </router-link>
        </p>
      </div>

      <div class="mb-3 cookie-modal__details">
        <button @click="open_details = !open_details" class="cookie-modal__details_btn">
          {{ $t('global.cookie.settings.details') }}
          <b-icon class="details_icon" :style="open_details ? 'transform: rotate(180deg)' : ''" icon="dropdown-down"></b-icon>
        </button>
        <div v-if="open_details" :class="{ 'cookie-modal__details_table': open_details }">
          <div class="mt-2">
            <table class="mb-3">
              <tr>
                <th colspan="4">Notwendige Cookies</th>
              </tr>
              <tr>
                <th>Anbieter</th>
                <th>Zweck</th>
                <th>Ablauf</th>
                <th>Typ</th>
              </tr>
              <tr>
                <td>cadooz</td>
                <td>Behält die Zustände des Benutzers bei allen Seitenanfragen bei.</td>
                <td>Session</td>
                <td>http</td>
              </tr>
              <tr>
                <td>cadooz</td>
                <td>Behält die Zustände des Benutzers bei allen Seitenanfragen bei.</td>
                <td>1 Jahr</td>
                <td>http</td>
              </tr>
            </table>
            <table class="mb-2">
              <tr>
                <th colspan="4">Optionale Cookies</th>
              </tr>
              <tr>
                <th>Anbieter</th>
                <th>Zweck</th>
                <th>Ablauf</th>
                <th>Typ</th>
              </tr>
              <tr>
                <td>Matomo</td>
                <td>Wird genutzt, um Seitenabrufe des Besuchers während der Sitzung nachzuverfolgen.</td>
                <td>Session</td>
                <td>http</td>
              </tr>
              <tr>
                <td>Matomo</td>
                <td>
                  Erfasst Statistiken über Besuche des Benutzers auf der Website, wie z. B. die Anzahl der Besuche, durchschnittliche Verweildauer auf
                  der Website und welche Seiten gelesen wurden.
                </td>
                <td>1 Jahr</td>
                <td>http</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <hr />

      <div class="mb-5">
        <h3>{{ $t('global.cookie.settings.necessary') }}</h3>
        <div class="d-flex align-items-start">
          <label class="cookie-modal__switch mr-3">
            <input class="cookie-modal__switch_input" checked="checked" value="cadooz" disabled="disabled" type="checkbox" />
            <i class="cookie-modal__switch_icon"></i>
          </label>
          <span class="cookie-modal__switch_span">{{ $t('global.cookie.settings.necessary_text') }}</span>
        </div>
      </div>
      <div class="mb-5">
        <h3>{{ $t('global.cookie.settings.analysis') }}</h3>
        <div class="d-flex align-items-start gap-3">
          <label class="cookie-modal__switch mr-3">
            <input class="cookie-modal__switch_input" type="checkbox" value="matomo" />
            <i class="cookie-modal__switch_icon"></i>
          </label>
          <span class="cookie-modal__switch_span">{{ $t('global.cookie.settings.analysis_text') }}</span>
        </div>
      </div>
      <div class="cookie-modal__buttons">
        <DefaultButton variant="primary" @click="onSavePreferences" :buttonText="$t('global.cookie.settings.confirm')" :type="'button'" />
        <DefaultButton variant="primary" @click="close() && $emit('accept-all')" :buttonText="$t('global.cookie.settings.accept')" :type="'button'" />
        <DefaultButton variant="primary" @click="close() && $emit('remove-all')" :buttonText="$t('global.cookie.settings.remove')" :type="'button'" />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultButton from '../DefaultElements/DefaultButton.vue';
  import BIcon from '../BIcon.vue';
  export default {
    components: {
      DefaultButton,
      BIcon
    },
    data() {
      return {
        open_details: false
      };
    },
    mounted() {
      let pref = localStorage.getItem('cookie-acceptance');
      if (pref) {
        pref = JSON.parse(pref);
        pref.forEach((option) => {
          if (option !== 'cadooz') {
            const el = this.$refs.cookieModal.querySelector(`input[type="checkbox"][value="${option}"]`);
            if (el) el.checked = 'checked';
          }
        });
      } else {
        const checkboxes = this.$refs.cookieModal.querySelectorAll('input[type="checkbox"]:not([value="cadooz"])');
        [...checkboxes].forEach((el) => (el.checked = 'checked'));
      }
    },
    methods: {
      close() {
        this.$emit('close-modal');
        return true;
      },
      onBgClick(e) {
        if (e.target === e.currentTarget) {
          this.close();
        }
      },
      onSavePreferences() {
        const arr = [];
        const cookieOptions = this.$refs.cookieModal.querySelectorAll('input[type="checkbox"]');
        [...cookieOptions].forEach((option) => {
          if (option.checked) arr.push(option.value);
        });
        this.close();
        this.$emit('save-pref', arr);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .cookie-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fade-in 0.3s;
    background: var(--transparent-light-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999;
    font-size: small;

    &__content {
      background-color: $white;
      max-width: 550px;
      width: 100%;
      overflow-y: scroll;
      padding: 40px;
      max-height: 80vh;

      @media (max-width: 769px) {
        max-width: none;
        max-height: 100%;
      }
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      button:not(:first-child) {
        margin-left: 10px;
      }
    }

    &__details_btn {
      border: 0;
      outline: 0;
      padding: 0;
      box-shadow: none;
      background: none;
      overflow-anchor: none;
      font-size: medium;
      font-weight: bold;
    }

    &__switch {
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }

    &__switch_input {
      display: none;
      &:checked + .cookie-modal__switch_icon {
        background-color: var(--primary);
        &:before {
          transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
        }
        &:after {
          transform: translate3d(20px, 2px, 0);
        }
      }
      &:disabled + .cookie-modal__switch_icon {
        background-color: var(--gray-200);
        cursor: no-drop;
      }
    }

    &__switch_icon {
      position: relative;
      display: inline-block;
      max-width: 42px;
      min-width: 42px;
      min-height: 24px;
      max-height: 24px;
      background-color: #bebebe;
      border-radius: 22px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 38px;
        height: 20px;
        background-color: #bebebe;
        border-radius: 11px;
        transform: translate3d(2px, 2px, 0) scaleX(1);
        transition: all 0.25s linear;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 11px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
        transform: translate3d(2px, 2px, 0);
        transition: all 0.2s ease-in-out;
      }
    }

    button:not(.cookie-modal__details_btn) {
      font-size: 12px;
    }

    h3 {
      font-size: medium;
      font-weight: bold;

      @media (max-width: 769px) {
        font-size: 16px;
      }
    }

    p,
    span {
      @media (max-width: 769px) {
        font-size: 11.2px;
      }
    }
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
