<template>
  <section class="categorie-swiper">
    <div class="categorie-swiper__title container-xl">
      <DefaultHeadline :overlineText="overlineText" :headlineText="headlineText" isHeadline />
    </div>
    <div class="swiper container-xl">
      <div ref="swiperContainer" class="swiper__container">
        <div class="swiper__item--first" />
        <div v-for="(category, index) in categories" :key="index" class="swiper__item" @click="closeOverlay">
          <CategoryThemeItem
            button
            :title="category.title"
            :image="category.image.link"
            :productType="category.products"
            :activeFilter="productTypeFilter"
            @filterCategory="updateProductTypeFilter"
          />
        </div>
      </div>
      <div class="swiper-scrollbar"></div>
    </div>
  </section>
</template>

<script>
  import CategoryThemeItem from './CategoryThemeItem.vue';
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'CategorieThemeSwiper',
    components: { CategoryThemeItem, DefaultHeadline },
    props: {
      overlineText: {
        type: String,
        default: ''
      },
      headlineText: {
        type: String,
        default: ''
      },
      variant: {
        type: String,
        default: ''
      },
      categories: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        innerViewportWidth: null
      };
    },
    computed: {
      ...mapGetters({
        composedQuery: 'filter/composedQuery'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType
      })
    },
    mounted() {
      this.innerViewportWidth = window.innerWidth;
    },
    methods: {
      scrollSwiperLeft() {
        const swiperContainer = this.$refs.swiperContainer;
        swiperContainer.scrollBy(-this.innerViewportWidth, 0);
      },
      scrollSwiperRight() {
        const swiperContainer = this.$refs.swiperContainer;
        swiperContainer.scrollBy(this.innerViewportWidth, 0);
      },
      updateProductTypeFilter(filterArray) {
        this.$store.commit('filter/SET_PRODUCTTYPE', filterArray);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .background {
    padding: 0;
    @include media-breakpoint-up(xl) {
      padding: 0 1rem;
    }
  }
  .categorie-swiper {
    background: transparent;
    &__headline {
      flex-wrap: wrap;
    }
    &__title {
      position: relative;
      z-index: 1;
      padding: 1rem 1rem 0;
    }
  }
  .swiper {
    @include scrollbar(primary, sm, 768px);
    &__container {
      width: 100%;
      position: relative;
      @include media-breakpoint-up(lg) {
        justify-content: center;
        padding: 1.5rem 0 4rem;
      }
      height: auto;
      max-height: 30rem;
      display: flex;
    }
    &:hover .swiper__btn {
      opacity: 1;
    }
    &__item {
      scroll-snap-align: center;
      align-items: center;
      min-width: 54vw;
      width: 54vw;
      height: auto;
      margin: 0 0 3rem 1rem;
      @media screen and (min-width: 480px) {
        min-width: 40vw;
        width: 40vw;
      }
      @include media-breakpoint-up(md) {
        min-width: calc(33.333% - 2rem / 3);
        width: calc(33.333% - 2rem / 3);
      }
      @include media-breakpoint-up(lg) {
        margin: 0 0 1rem 2rem;
        min-width: calc(33.333% - 4rem / 3);
        width: calc(33.333% - 4rem / 3);
      }
      &:nth-child(2) {
        margin-left: 0;
      }
      &--first {
        @include media-breakpoint-up(xl) {
          display: none;
        }
        width: 1px;
        max-width: 1px;
        min-width: 1px;
        background: transparent;
      }
    }
    &__btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: $z-index-low;
      transition: all 0.5s ease;
      opacity: 0;
      &.right {
        right: 4rem;
        @include media-breakpoint-up(xl) {
          right: calc(10%);
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
        @media (min-width: 1700px) {
          right: calc(20%);
        }
        @media (min-width: 1900px) {
          right: calc(28%);
        }
      }
      &.left {
        left: 4rem;
        @include media-breakpoint-up(xl) {
          left: calc(10%);
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
        @media (min-width: 1700px) {
          left: calc(20%);
        }
        @media (min-width: 1900px) {
          left: calc(28%);
        }
      }
    }
  }
</style>
