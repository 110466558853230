import axios from '@/plugins/axios';

const passwordServices = {

  handleReset(data) {
    const requestData = {
      website_uuid: data.external_identifier,
      user_name: data.user_name,
      email: data.email,
      captcha_response: data.captcha
    }
    return axios.post('/password/forgot-password', requestData).then((resp) => resp.status);
  },

  handleTokenValidation(data) {
    return axios.get(`/password/validate-token?website_uuid=${data.external_identifier}&token=${data.token}`).then((resp) => resp.data);
  },

  handleNewPassword(data) {
    const requestData = {
      new_password: data.new_password,
      new_password_retype: data.new_password_retype,
      captcha_response: data.captcha,
      password_token: data.password_token,
      website_uuid: data.external_identifier,
    }
    return axios.put('password/new-password', requestData).then((resp) => resp);
  }
}

export default passwordServices;