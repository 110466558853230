<template>
  <div class="text-center mb-5">
    <button 
      class="btn btn-light pagination_btn mr-5"
      :disabled="activePage === 1" 
      @click="prevPage">
      <b-icon icon="left-arrow" />
    </button>
    <p class="pagination_text">{{ $t('filter.pagination.page_count', { activePage, pageCount }) }}</p>
    <button
      class="btn btn-light pagination_btn ml-5"
      :disabled="activePage >= pageCount" 
      @click="nextPage">
      <b-icon icon="right-arrow" />
    </button>
  </div>
</template>

<script>
import BIcon from './BIcon.vue'
import { mapGetters } from 'vuex';

export default {
  components: {
    BIcon
  },

  props: {
    products:{
      type:Array,
      required: true,
      default: []
    },
    activePage: {
      type: Number,
      default: 1,
    }
  },

  computed: {
    ...mapGetters({
      composedQuery: 'filter/composedQuery',
    }),
    pageCount(){
      return Math.ceil(this.products.length/this.$store.state.pagination.itemsPerPage);
    }
  },

  methods: {
    nextPage(){
      this.emitModel(this.activePage + 1);
    },
    prevPage(){
      this.emitModel(this.activePage - 1);
    },
    setFilteredProducts() {
      this.$store.dispatch('pagination/setDisplayedProducts', { products: this.products, activePage: this.activePage });
    },
    emitModel(index) {
      this.$emit("update", {
        pageCount: this.pageCount,
        activePage: index,
      });
      this.$store.commit('filter/SET_PAGE', index);
      this.$store.dispatch('pagination/setDisplayedProducts', { products: this.products, activePage: index });
      this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagination_btn {
    border: 1px solid var(--primary);
    max-width: 50px;
    width: 100%;
    padding: 5px;
    svg {
      vertical-align: middle !important;
    }

    &:disabled {
      border: 1px solid var(--transparent-light-low);
    }
    &:disabled:hover {
      border-color: var(--transparent-light-low);
      color: var(--transparent-light-low) !important;
      background-color: var(--transparent-light-low) !important;
    }
  }
  .btn-light:focus {
    color: var(--primary);
    background-color: var(--light);
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .btn-light:hover {
    color: var(--light) !important;
    background-color: var(--primary) !important;
    border-color: var(--primary);
  }

  .pagination_text {
    color: var(--dark);
    font-weight: bold;
    margin: 0;
    display: inline;
  }
</style>