<template>
  <div>
    <ProductFilterOnPage
      ref="allFilters"
      :recommendedProducts="recommendedProducts"
      :filteredProducts="filteredProducts"
      :filterCount="filterCount"
      :computedHeadline="computedHeadline"
      @showFilterSidebar="showFilterSidebar"
      @resetAllFilters="resetAllFilters"
      @hideFilterSidebar="hideFilterSidebar"
    />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import ProductFilterOnPage from '@/components/ProductFilter/ProductFilterOnPage.vue';

  export default {
    data() {
      return {
        headline: ''
      };
    },
    components: {
      ProductFilterOnPage
    },
    computed: {
      ...mapGetters({
        recommendedProducts: 'product/getRecommendedProducts',
        filteredProducts: 'filter/getFilteredAndSortedProducts',
        filterCount: 'filter/getFilterCount',
        composedQuery: 'filter/composedQuery'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType,
        showOnlyRecommendedProducts: (state) => state.filter.onlyRecommendations,
        searchInput: (state) => state.filter.searchInput,
        selectedCategory: (state) => state.filter.category,
        allProducts: (state) => state.product.catalogProducts
      }),
      websiteSettings() {
        return this.$store.state.app.websiteSettings;
      },
      additionalOptions() {
        return this.$store.state.pages.additionalOptions;
      },
      computedHeadline() {
        if (this.headline !== '') {
          return this.headline;
        }
        if (this.selectedCategory) {
          return this.selectedCategory.name;
        }
        if (this.showOnlyRecommendedProducts) {
          return this.$t('filter.navigation.recommended');
        } else if (this.productTypeFilter.length === 1) {
          return this.productTypeFilter[0].text;
        }
        
        return this.$t('filter.navigation.all_products');
      },
      filterLayout() {
        if (this.additionalOptions.filterVariant === 'on-page-filter') {
          return 'ProductFilterOnPage';
        }

        if (this.additionalOptions.filterVariant === 'sidebar-filter') {
          return 'ProductFilterSidebar';
        }
      }
    },
    watch: {
      $route() {
        if (this.$route.name === 'product-overview' && this.$route.query) {
          this.handleQuery();
        }
      }
    },
    mounted() {
      this.handleQuery();
    },

    methods: {
      /**
       * Access the current Query Params and works as first switch to process queries
       */
      handleQuery() {
        const query = this.$route.query;

        if (query.search) {
          this.handleSearch(query);
        } else {
          this.headline = '';
          this.loadProducts(query);
        }

        this.setFilterFromQuery(query);
      },
      /**
       * Get`s called if a searchterm is found in the Query Params. Updates the Headline and the Sorting
       * @param {Object} query
       */
      handleSearch(query) {
        this.$store.commit('filter/SET_SEARCHINPUT', query.search);
        this.headline = this.$t('view.search.all_headline') + ' ' + '"' + this.searchInput + '"';
      },
      /**
       * Loads the Products to Filter depending on the Query Param 'recommended'
       * @param {Object} query
       */
      loadProducts(query) {
        this.$store.commit('filter/SET_ALL_PRODUCTS', this.allProducts);
        if (query.recommended === true) {
          this.$store.commit('filter/SET_LOADED_PRODUCTS', this.recommendedProducts);
          this.$store.commit('filter/SET_ONLY_RECOMMENDATIONS', true);
        } else {
          this.$store.commit('filter/SET_LOADED_PRODUCTS', this.allProducts);
          this.$store.commit('filter/SET_ONLY_RECOMMENDATIONS', false);
        }
      },
      /**
       * Returns the category by id
       * @param {Integer} id
       * @return {Object}
       */
      getCategory(id) {
        return this.websiteSettings.shop_categories.find((category) => category.id === id);
      },
      /**
       * Checks for Filtersettings in QueryParams and updates them if needed
       *  @param {Object} query
       */

      // prettier-ignore
      setFilterFromQuery(query) {
        query.productType 
          ? this.$store.commit('filter/SET_PRODUCTTYPE', query.productType) 
          : this.$store.commit('filter/SET_PRODUCTTYPE', []);
        query.shippingMethod
          ? this.$store.commit('filter/SET_SHIPPINGMETHOD', query.shippingMethod)
          : this.$store.commit('filter/SET_SHIPPINGMETHOD', []);
        query.redemptionType
          ? this.$store.commit('filter/SET_REDEMPTIONTYPE', query.redemptionType)
          : this.$store.commit('filter/SET_REDEMPTIONTYPE', []);
        query.discount 
          ? this.$store.commit('filter/SET_DISCOUNT', query.discount) 
          : this.$store.commit('filter/SET_DISCOUNT', false);
        query.price 
          ? this.$store.dispatch('filter/updatePriceFilter', query.price) 
          : this.$store.dispatch('filter/resetPriceFilter');
        query.category
          ? this.$store.commit('filter/SET_CATEGORY', this.getCategory(parseInt(query.category)))
          : this.$store.commit('filter/SET_CATEGORY', null);
        query.brands
        ? this.$store.commit('filter/SET_SELECTED_BRANDS', query.brands.split(','))
        : this.$store.commit('filter/SET_SELECTED_BRANDS', []);
        
        let page = query.page * 1;
        if(!page) {
          page = 1;
          this.$router.replace({ query: {...this.$route.query, page} });
        }
        this.$store.dispatch('pagination/setPageItems');
        this.$store.commit('filter/SET_PAGE', page);
        this.$store.dispatch('pagination/setDisplayedProducts', {
          products: this.filteredProducts.length > 0 ? this.filteredProducts : this.recommendedProducts,
          activePage: page
        });
      },
      resetAllFilters() {
        this.$store.dispatch('filter/resetPriceFilter');
        this.$store.dispatch('filter/resetAllFilter');
        this.$store.commit('filter/SET_SEARCHINPUT', '');
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },

      hideFilterSidebar(hideSidebar) {
        hideSidebar();

        if (this.filteredProducts.length === 0) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      },
      showFilterSidebar() {
        this.$refs.allFilters.$refs.filterSidebar.showSidebar();
      }
    }
  };
</script>

<style lang="scss">
  .floating-button {
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    z-index: $z-index-middle;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      top: 0 !important;
      font-size: .6rem
    }

    @extend %font-md;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .filter-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }
    &__empty-message {
      padding: 2rem 4rem 4.5rem;
      @include media-breakpoint-up(sm) {
        padding: 4rem 4rem 6rem;
      }
      @include media-breakpoint-up(lg) {
        padding: 6rem 4rem;
      }
    }
  }

  .filter-results > article {
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex-basis: calc(50% - 0.5rem);
    min-width: 0;
    @include media-breakpoint-down(xs) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(sm) {
      flex-basis: calc(100% / 3 - 2rem / 3);
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(md) {
      flex-basis: calc(25% - 0.75rem);
      margin-bottom: 2rem;
      &:nth-child(3n) {
        margin-right: 1rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      margin-right: 2rem;
      flex-basis: calc(100% / 3 - 4rem / 3);
      &:nth-child(4n) {
        margin-right: 2rem;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(xl) {
      flex-basis: calc(25% - 1.5rem);
      &:nth-child(3n) {
        margin-right: 2rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .filter-results > article {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
</style>
