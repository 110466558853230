// =======================================================================
// State
// =======================================================================
import i18n from "../../plugins/i18n";

const state = () => ({
  locale: 'de',
  fallbackLocale: 'de',
  locales: ['de']
});

const actions = {
  changeLanguage ({ state }, lang) {
    let path = window.location.pathname;
    const search = window.location.search;
    const hash = window.location.hash;
    let routeLang = path.split('/')[1];
    if(!state.locales.includes(routeLang)) routeLang = null;
    path = routeLang ? path.replace(`/${routeLang}/`, `/${lang}/`) : `/${lang}${path}`;
    window.location.href = `${location.origin}${path}${search}${hash}`;
  },

  setAvailableLocales ({ commit, state }, payload) {
    if(!payload || payload === '') return;

    let locales = payload.replace(/\s+/g, '').split(",");
    locales = [...new Set(locales)];
    const finalLocales = [];
    locales.forEach(locale => {
      if(i18n.global.availableLocales.includes(locale)) {
        finalLocales.push(locale);
      }
    });
    
    commit('SET_AVAILABLE_LOCALES', finalLocales);
  },

  setFallbackLocale ({ state, commit }, lang) {
    let fallbackLang = lang;
    if(!fallbackLang) fallbackLang = state.locales[0];
    if(fallbackLang) {
      commit('SET_FALLBACK_LOCALE', fallbackLang);
    } else {
      i18n.global.fallbackLocale = state.fallbackLocale;
    }
  },

  setOverrideLocale ({ state, commit }, lang) {
    const locales = i18n.global.availableLocales;
    if(!locales.includes(lang)) return;
    commit('SET_AVAILABLE_LOCALES', [lang]);
    commit('SET_FALLBACK_LOCALE', lang);
    commit('SET_LOCALE', lang);
  },

  setYupLocale({state}) {
    if(!state.locale) return;
    const localeObj = Object.entries(i18n.global.messages).filter(([key]) => key.includes(state.locale))[0][1];
    this.$yup.setLocale(localeObj.form.validation);
  }
}

const mutations = {
  SET_LOCALE (state, locale) {
    i18n.global.locale = locale;
    state.locale = locale;
  },

  SET_AVAILABLE_LOCALES (state, locales) {
    state.locales = locales;
  },

  SET_FALLBACK_LOCALE (state, locale) {
    i18n.global.fallbackLocale = locale;
    state.fallbackLocale = locale;
  }
}

const getters = {
  getInitialLanguage: () => (path) => {
    let lang = i18n.global.fallbackLocale;
    const locales = i18n.global.availableLocales;
    const routeLang = path.split('/')[1];
    const browserLang = navigator.language;
    if(locales.includes(routeLang)) {
      lang = routeLang;
    } else if(locales.includes(browserLang)) {
      lang = browserLang;
    }
    return lang;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}