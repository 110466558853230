<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="container-sm">
        <div class="row footer__nav">
          <div v-if="serviceLinks && serviceLinks.length >= 1" class="footer__nav__block col-md-3 col-12">
            <h5 class="footer__nav__title">{{ $t('global.footer.group_2.headline') }}</h5>
            <div class="list-group">
              <div v-for="(item, i) in serviceLinks" :key="i" class="list-group-item">
                <router-link v-if="!item.href" :to="{ path: $routeHandler(item.path), query: item.query, hash: item.hash }" class="list-group-link">{{ item.name }}</router-link>
                <a v-else :href="item.href" :target="item.target" class="list-group-link">{{ item.name }}</a>
              </div>
              <div class="list-group-item" @click="openCookieModal()">
                <div class="cookie-link list-group-link">{{ $t('global.navigation_items.cookie_settings') }}</div>
              </div>
            </div>
          </div>
          <div v-if="availablePayments && availablePayments.length >= 1 && !isPunchout" class="footer__nav__block col-md-3 col-12">
            <h5 class="footer__nav__title">{{ $t('global.footer.group_3.headline') }}</h5>
            <div class="footer__nav__payment">
              <img v-for="payment in availablePayments" :key="payment.id" class="payment__icon" :src="payment.icon.indexOf('http') !== -1 ? payment.icon : $image(payment.icon)" />
            </div>
          </div>
          <div class="footer__nav__block col-md-3 col-12">
            <h5 class="footer__nav__title">{{ $t('global.footer.group_4.headline') }}</h5>
            <div class="footer__nav__certificates">
              <a class="image-link" :href="tuvUrl" rel="noopener" target="_blank">
                <img :src="$image(footerImgs.tuevLogo)" />
              </a>
              <a class="image-link" v-if="websiteSettings.website_etracker_id" href="https://www.etracker.com/datenschutz" rel="noopener" target="_blank">
                <img :src="$image(footerImgs.etrackerLogo)" alt="etracker" />
              </a>
            </div>
          </div>
          <div class="footer__nav__block col-md-3 col-12" v-if="showTrustpilot">
            <div>
              <h5 class="footer__nav__title">{{ $t('global.trustpilot.headline') }}</h5>
              <a href="https://de.trustpilot.com/review/cadooz.com" class="trustpilot__link" target="_blank" rel="noopener">
                <span>{{ $t('global.trustpilot.link') }}</span>
                <span>
                  <b-icon icon="trustpilotIcon" class="trustpilotIcon" />
                </span>
                <span>Trustpilot</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__wrapper">
      <div class="container-sm">
        <div class="row footer__legals">
          <div class="col-md-9 col-12">
            <div class="row">
              <div v-if="shopLinks && shopLinks.length >= 1" class="footer__legals__nav col-12">
                <div class="list-group list-group-horizontal">
                  <div v-for="(item, i) in shopLinks" :key="'0' + i" class="list-group-item">
                    <router-link v-if="!item.href" :to="{ path: $routeHandler(item.path), query: item.query, hash: item.hash }" class="list-group-link">{{ item.name }}</router-link>
                    <a v-else :href="item.href" :target="item.target" class="list-group-link">{{ item.name }}</a>
                  </div>
                </div>
              </div>
              <div class="footer__legals__copyright col-12">
                <div class="list-group list-group-horizontal">
                  <div class="list-group-item">{{ $t('global.footer.meta.copyright', { copyright_year: copyrightYear }) }}</div>
                  <div class="list-group-item">{{ $t('global.footer.meta.tax') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__legals__logo col-md-3 col-12">
            <a :href="url" target="_blank" rel="noopener">
              <img :src="logo" alt="Cadooz logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';
  export default {
    components: { BIcon },
    data() {
      return {
        copyrightYear: new Date().getFullYear()
      };
    },
    props: {
      shopLinks: {
        type: Array
      },
      serviceLinks: {
        type: Array
      },
      logo: {
        type: String,
        default: require('@/assets/images/cadooz-logo.png')
      },
      url: {
        type: String
      },
      tuvUrl: {
        type: String
      },
    },
    computed: {
      isUserLoggedIn() {
        return this.$store.state.user.loggedIn;
      },
      footerImgs() {
        return this.$store.state.app.footerImgs;
      },
      appLoaded() {
        return this.$store.state.app.appLoaded;
      },
      availablePayments() {
        return this.$store.getters['app/getAvailablePaymentMethods']();
      },
      showTrustpilot() {
        return this.$store.getters['pages/showTrustPilot'];
      },
      isPunchout() {
        return this.$store.getters['punchout/isPunchout'];
      },
      websiteSettings() {
        return this.$store.state.app.websiteSettings;
      }
    },
    methods: {
      openCookieModal() {
        window.dispatchEvent(new CustomEvent('cookie-settings'));
      }
    }
  };
</script>

<style lang="scss" scoped>
  .footer {
    background-color: var(--light);
    &__wrapper {
      border-top: 1px solid $border-color;
    }
    &__nav {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      padding: 3rem 0;
      &__block {
        @include media-breakpoint-down(sm) {
          margin-bottom: 2rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }
      &__certificates {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          align-items: center;
        }

        .image-link {
          display: block;
          width: 4rem;
          height: 4rem;
  
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      &__payment {
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
        & img {
          max-height: 1.2rem;
          width: 3rem;
          margin: 0 1rem 0.5rem 0;
          transition: $dur $ease;

          &:hover {
            transform: scale(1.2);
            transition: $dur $ease;
          }
        }
      }
      &__title {
        margin-bottom: 1rem;
        font-family: $font-semibold;
        font-size: 0.9rem;
        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }
      }
      justify-content: space-between;
      & .list-group > .list-group-item {
        padding: 0 0 0.5rem;
        border: 0;
      }
    }
    &__legals {
      padding: 3rem 0;
      flex-direction: row;
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        align-items: center;
      }
      &__nav {
        margin-bottom: 1rem;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }

      &__copyright {
        color: $gray-400;
        font-size: 0.75rem;
        @include media-breakpoint-up(lg) {
          font-size: 0.8rem;
        }
      }
      &__logo {
        display: flex;
        & img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
        a {
          max-width: 185px;
          width: 100%;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
          justify-content: center;
        }
      }
      .list-group {
        flex-direction: row;
        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
        & .list-group-item,
        a.list-group-item {
          border: 0;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            text-align: center;
          }
        }
      }
    }
    .list-group-item {
      font-size: 0.75rem;
      @include media-breakpoint-up(lg) {
        font-size: 0.8rem;
      }
      & a {
        color: $body-color;
      }
      &:first-child {
        @include media-breakpoint-up(md) {
          padding-left: 0;
        }
      }
    }
    .cookie-link {
      cursor: pointer;
    }

    .list-group-link {
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--primary);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
</style>
