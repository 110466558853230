<template>
  <CategorieThemeSwiper :overlineText="overlineText" :headlineText="headlineText" :categories="mgnlCategories" :variant="variant" />
</template>

<script>
import { mgnlToJson } from "../helpers/utils";
import CategorieThemeSwiper from '@/components/Categories/CategorieThemeSwiper.vue'

  export default {
    components: { CategorieThemeSwiper },
    props: {
      overlineText: {
        type: String,
        default: ''
      },
      headlineText: {
        type: String,
        default: ''
      },
      categories: {
        type: Object,
        default: []
      },
      variant: {
        type: String,
        default: 'primary'
      }
    },

    computed: {
      mgnlCategories() {
        return mgnlToJson(this.categories).map((val) => {
          return {
            title: val.title,
            products: val.products,
            image: {
              link: process.env.VUE_APP_MGNL_HOST + '/dam' + val.image['@path'],
              name: val.image['@name']
            }
          };
        });
      }
    }
  };
</script>
