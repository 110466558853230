<template>
  <div class="product-finder">
    <ins id="giftomatic-snippet" style="display:none;"></ins>
  </div>
</template>

<script>
  import { toHexColorCode } from '@/helpers/utils.js';

  export default {
    computed: {
      indexPage() {
        return this.$store.state.pages.indexPage;
      },
      activeGiftomatic() {
        return this.$store.getters['pages/activeGiftomatic'];
      },
    },
    mounted() {
      if (this.activeGiftomatic) {
        const searchEngine = document.createElement("script");
        searchEngine.setAttribute("type", "text/javascript");
        searchEngine.classList.add('giftomatic');
        searchEngine.innerHTML = `
          var gftmtc = gftmtc || [];
          gftmtc.push({
            type: "search",
            client: "CADOOZ",
            campaignId: 15052,
            id: "giftomatic-snippet",
            locale: "${this.$i18n.locale}",
            primaryColor: "${toHexColorCode(parseInt(this.indexPage.colors.primary))}",
            secondaryColor: "${toHexColorCode(parseInt(this.indexPage.colors.secondary))}"
          });
        `;

        const webSnippet = document.createElement("script");
        webSnippet.setAttribute("type", "text/javascript");
        webSnippet.setAttribute("src", "https://www.giftomatic.io/dist/websnippet.js")
        webSnippet.classList.add('giftomatic');
  
        document.head.appendChild(webSnippet);
        document.head.appendChild(searchEngine);
      } else {
        this.$router.push({ path: this.$routeHandler('/')});
      }
    },
    beforeUnmount() {
      if (this.activeGiftomatic) {
        const scripts = document.querySelectorAll('.giftomatic');
        scripts.forEach(el => el.remove());
        const wrapper = document.querySelector('#giftomatic-snippet .giftomatic-snippet-wrapper');
        wrapper?.remove();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-finder {
    padding: 2rem 0;
  }
</style>
