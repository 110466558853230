<template>
  <section 
    class="product-slider" 
    :style="bgColor?.length ? 'background-color: ' + bgColor + ';' : 'background-color: var(--transparent-light-lowest);'"
  >
    <div class="container-xl">
      <div class="row">
        <div class="col d-sm-flex justify-content-start product-swiper__headline" :class="fontColor === 'light' ? 'text-light' : 'text-dark'">
          <h2 class="mr-4 align-self-baseline swiper-start">{{ headline }}</h2>
          <h5 class="align-self-baseline mb-3" @click="closeOverlay" v-if="productLinkText">
            <router-link :to="{ path: $routeHandler(productLinkPath), query: productLinkQuery }" class="product-link" :class="{'product-link--light' : fontColor === 'light'}">
              {{ productLinkText }}
            </router-link>
          </h5>
        </div>
      </div>
    </div>
    <swiper :options="swiperOptions">
      <div class="swiper-slide" v-for="(item, index) in products" :key="index" @click="closeOverlay">
        <ProductItem :product="item"/>
      </div>
      <template v-if="products.length" v-slot:navigation>
        <DefaultButton 
          variant="primary" 
          size="sm" 
          type="button" 
          button-text="" 
          shadowed-button 
          icon-button 
          icon="arrow-left" 
          class="swiper-btn swiper-btn--prev"
          scale="1" 
          is-circle 
        />
        <DefaultButton 
          variant="primary" 
          size="sm" 
          type="button" 
          button-text="" 
          shadowed-button 
          icon-button icon="arrow-right" 
          class="swiper-btn swiper-btn--next"
          scale="1" 
          is-circle 
        />
      </template>
    </swiper>
  </section>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import ProductItem from './ProductItem.vue';

  export default {
    name: 'ProductSlider',
    components: {
      DefaultButton,
      ProductItem
    },
    props: {
      headline: {
        type: String,
        default: ''
      },
      productLinkText: {
        type: String,
        default: ''
      },
      productLinkPath: {
        type: String,
        default: ''
      },
      productLinkQuery: {
        type: [ Object, String ],
        default: () => {}
      },
      bgColor: {
        type: [String, Number]
      },
      products: {
        type: Array,
        default: () => []
      },
      closeOverlay: {
        type: Function,
        default: () => {}
      },
      fontColor: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        swiperOptions: {
          centerInsufficientSlides: true,
          loop: true,
          autoplay: {
            delay: 4000,
            pauseOnMouseEnter: true
          },
          centeredSlides: true,
          breakpoints: {
            '768': {
              centeredSlides: false
            }
          }
        }
      };
    }
  };
</script>

<style lang="scss" scoped>

  .product-slider {
    padding-top: 1.5rem;
    overflow: hidden;
    &__headline {
      flex-wrap: wrap;
    }
    .product-link {
      font-family: $font-semibold;
      color: var(--transparent-light-medium);
      transition: color .25s;
      &:hover {
        color: var(--primary);
        text-decoration: none;
      }
      &--light {
        color: var(--light);
        &:hover {
          color: var(--gray-300);
        }
      }
    }
    .swiper {
      padding-bottom: 2rem;
      padding-top: 1rem;
      width: 98.5vw;
      &:hover .swiper-btn {
        opacity: 1;
      }
      .swiper-slide {
        min-width: 11rem;
        max-width: 12rem;
        margin: 0 .5rem;
      }
      .swiper-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        @media (hover: hover) {
          transition: all 0.5s ease;
          opacity: 0;
          display: flex;
        }
        @media (max-width: 767px) and (hover: none) {
          display: none;
        }
        &--next {
          right: 1rem;
          @include media-breakpoint-up(lg) {
            right: 4rem;
          }
          @include media-breakpoint-up(xl) {
            right: calc(10%);
          }
          @media (min-width: 1700px) {
            right: calc(20%);
          }
          @media (min-width: 1900px) {
            right: calc(28%);
          }
        }
        &--prev {
          left: 1rem;
          @include media-breakpoint-up(lg) {
            left: 4rem;
          }
          @include media-breakpoint-up(xl) {
            left: calc(10%);
          }
          @media (min-width: 1700px) {
            left: calc(20%);
          }
          @media (min-width: 1900px) {
            left: calc(28%);
          }
        }
      }
    }
  }
</style>
