<template>
  <div role="group" class="form-group">
    <label class="label" v-if="label" :label-for="groupId">
      {{ label }}
    </label>
    <select
      v-model="selected"
      :disabled="disabled"
      class="form__select custom-select"
      :class="[{ 'readonly-style': disabled === true, 'is-invalid': state === false }]"
    >
      <option v-if="placeholder !== ''" value='1' class="select-placeholder">{{ placeholder }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <div v-if="name === hintLocation" class="special-hint">{{ hintText }}</div>
    <div v-if="state === false" class="invalid-feedback">{{ errorText }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: null,
        default: ''
      },
      options: {
        type: Array,
        default() {
          return [{ value: null, text: '' }]
        }
      },
      label: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      groupId: {
        type: String,
        default: ''
      },
      state: {
        type: Boolean,
        default: null
      },
      errorText: {
        type: String,
        default: ''
      },
      hintText: {
        type: String,
        default:''
      },
      hintLocation:{
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    computed: {
      selected: {
        get() {
          return this.modelValue || 1
        },
        set(value) {
          this.$emit('update:modelValue', value)
          this.$emit('inputed', value)
          this.$emit('changed', value)
        }
      },
    },

    methods: {
      updateProduct(value) {
        this.$emit('update:modelValue', value)
        this.$emit('inputed', value)
        this.$emit('changed', value)
      }
    },

    created() {
      if(this.options?.length === 1) {
        this.selected = this.options[0].value;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .form__select.custom-select.readonly-style {
    background-color: $input-disabled-bg;
    opacity: 1;
  }
  .label {
    font-size: $h5-font-size;
    color: var(--primary);
    font-weight: bold !important;
  }
  .special-hint {
    margin-top: 0.5rem;
    font-size: $font-size-md;
    color: $gray-400;
  }
  .select-placeholder {
    display: none;
  }
</style>
