<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-8 payment-hint-wrapper">
          <b-icon icon="credit-card" class="payment-hint-icon" font-scale="1.5" />
          <div class="payment-info-title">{{ $t('view.product.price.info.title') }}</div>
        </div>
        <div cols="4" class="col-4 text-right">
          <div class="badge badge-soft-dark detail-card_badge tooltip-container">
            {{ $t('view.product.price.info.badge') }}
            <span class="tooltip-text">
              <b>{{ $t('view.product.price.info.tooltip_title') }}</b>
              <br />
              <em>{{ $t('view.product.price.info.tooltip') }}</em>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    }
  };
</script>

<style lang="scss" scoped>
  .payment-hint-wrapper {
    position: relative;
    padding-left: 1.5rem;
  }
  .payment-hint-icon {
    position: absolute;
    left: 0;
    vertical-align: middle;
  }
  .paymenthint-title {
    font-size: $h6-font-size;
    font-family: $font-semibold, sans-serif;
  }
  .payment-info-title {
    padding-left: 1rem;
  }

  .tooltip-container .tooltip-text {
    visibility: hidden;
    width: min-content;
    background-color: rgb(41, 39, 39);
    font-size: 0.7rem;
    color: #fff;
    text-align: center;
    padding: 1rem;
    border-radius: 6px;
    position: absolute;
    top: -3rem;
    right: 0;
    z-index: 1000;
    line-height: 1.4;

    @include media-breakpoint-down(xs) {
      white-space: pre-wrap;
      width: 300px;
      right: -20px;
    }
  }

  .tooltip-container:hover .tooltip-text {
    visibility: visible;
  }
</style>
