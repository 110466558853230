<template>
  <section :id="id" class="list-group payment-list">
    <div class="list-group-item flex-column align-items-start">
      <slot>
        <DefaultHeadline is-headline heading-size="h3" :headline-text="headlineText" />
      </slot>
      <slot>
        <p class="subheader">{{ subHeadline }}</p>
      </slot>
    </div>

    <div v-for="listItem in usedVoucherAmount" :key="listItem.id" :disabled="disabled" class="list-group-item flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <span class="mb-1 title">{{ listItem.code }}</span>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <span class="mb-1">{{ $t('view.checkout.payment.voucher_list.amount_used') }}</span>
        <span>{{ $currency(listItem.usedValue) }}</span>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <span class="mb-1">{{ $t('view.checkout.payment.voucher_list.amount_rest') }}</span>
        <span>{{ $currency(listItem.remainingValue) }}</span>
      </div>
    </div>
    <div class="list-group-item flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <span class="mb-1 title">{{ $t('view.checkout.payment.voucher_list.amount_used_sum') }}</span>
        <span class="title">{{ $currency(usedCreditAmount) }}</span>
      </div>
    </div>
  </section>
</template>

<script>
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';

  export default {
    components: {
      DefaultHeadline
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      remainingBalance: {
        type: Number,
        default: 0
      },
      usedCreditAmount: {
        type: Number,
        default: 0
      },
      usedVoucherAmount: {
        type: Array,
        default: () => [
          {
            code: 'df54z65g#3g',
            remainingValue: 0,
            usedValue: 0
          }
        ]
      },
      headlineText: {
        type: String,
        default: ''
      },
      subHeadline: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: '#'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .list-group {
    margin-top: 2rem;
    margin-bottom: 2rem;
    & .list-group-item {
      padding: 1rem 0;
      margin: 0 1rem;
      border: none;
      border-bottom: 1px solid $list-group-border-color;
      &:last-child {
        border: none;
      }
      @include media-breakpoint-up(md) {
        padding: 1rem 0 1rem;
        margin: 0 1.5rem;
        &:first-child {
          padding: 1.5rem 0 1rem;
        }
        &:last-child {
          padding: 1rem 0 1.5rem;
        }
      }
    }
  }
  .title {
    font-family: $font-semibold;
  }
  .payment-list {
    border: 1px solid $list-group-border-color;
    border-radius: $border-radius;
    & .subheader {
      margin-bottom: 0.25rem;
    }
  }
</style>
