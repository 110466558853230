<template>
  <Loader v-if="showLoadingAnimation" />
  <div v-else>
    <Toast />
    
    <component :is="layout" v-if="isStatic">
      <template v-if="indexPage && indexPage.header" v-slot:header>
        <EditableArea :content="indexPage.header" :pageTemplateId="indexPage['mgnl:template']" />
      </template>

      <template v-if="currentPage && currentPage.before" v-slot:before>
        <div class="mb-0">
          <EditableArea :content="currentPage.before" />
        </div>
      </template>

      <template v-slot:default>
        <router-view />
      </template>

      <template v-if="currentPage && currentPage.after" v-slot:after>
        <div class="mt-0">
          <EditableArea :content="currentPage.after" />
        </div>
      </template>

      <template v-if="indexPage && indexPage.footer" v-slot:footer>
        <EditableArea :content="indexPage.footer" :pageTemplateId="indexPage['mgnl:template']" />
      </template>
    </component>

    <router-view v-else />

    <form v-if="isPunchout && punCheckoutData" ref="cxml_form" id="cxml_form" method="POST" :action="punCheckoutData.url" enctype="application/x-www-form-urlencoded">
      <input type="hidden" name="cXML-urlencoded" :value="punCheckoutData.cxml">
    </form>
  </div>
  
</template>

<script>
  import Toast from 'building-blocks/components/Toast.vue';
  import { EditableArea } from '@magnolia/vue-editor';
  import Default from '@/layouts/Default.vue';
  import Plain from '@/layouts/Plain.vue';
  import Loader from 'building-blocks/components/Loader.vue';
  import config from 'mgnl-building-blocks/magnolia.config';
  import useSession from '@/composables/useSession';
  import TestForm from '@/components/TestForm.vue';
  import { mapState } from 'vuex';

  export default {
    components: {
      Default,
      Plain,
      EditableArea,
      Toast,
      Loader,
      TestForm
    },
    data() {
      return {
        fallbackTitle: 'cadooz – rewarding excellence'
      };
    },

    provide() {
      return {
        config: config,
        templateAnnotations: { value: this.currentPage && this.currentPage.templateAnnotations },
        pageTemplateId: this.currentPage && this.currentPage['mgnl:template']
      };
    },

    setup() {
      useSession();
    },

    watch: {
      appLoaded() {
        this.init();
      },
      punCheckoutData(d) {
        if (d) {
          setTimeout(() => {
            this.$refs.cxml_form.submit();
          }, 0);
        }
      }
    },

    computed: {
      ...mapState({
        userLoggedIn: (state) => state.user.loggedIn,
        websiteSettings: (state) => state.app.websiteSettings,
        routes: (state) => state.pages.routes,
        indexPage: (state) => state.pages.indexPage,
        currentPage: (state) => state.pages.currentPage,
        appLoaded: (state) => state.app.appLoaded,
        showLoadingAnimation: (state) => state.app.showLoadingAnimation,
        punCheckoutData: (state) => state.punchout.checkout
      }),
      layout() {
        return this.$route.meta.layout || 'Default';
      },
      isStatic() {
        return this.$route.meta.static;
      },
      isPunchout() {
        return this.$store.getters['punchout/isPunchout']
      }
    },

    methods: {
      websiteTitle() {
        if (!this.websiteSettings) return;

        if (this.websiteSettings.website_title) {
          document.title = this.websiteSettings.website_title;
        } else if (this.websiteSettings.website_default_pagetitle) {
          document.title = this.websiteSettings.website_default_pagetitle;
        }
      },

      async init() {
        if (this.appLoaded) {
          this.websiteTitle();
          if(this.websiteSettings?.website_etracker_id) {
            this.$store.dispatch('etracker/addScript', this.websiteSettings.website_etracker_id)
          }
        }
      }
    },

    created() {
      document.title = this.fallbackTitle;
    }
  };
</script>

<style lang="scss">
  #cxml_form {
    position: absolute;
    top: -9000px;
    left: -9000px;
    pointer-events: none;
    display: none;
  }
</style>