<template>
  <section class="payment">
    <div class="container-xl mb-5">
      <div class="row">
        <div class="col-md-8 col-xl-7">
          <CheckoutNavigation />

          <!-- Redemption Shop Area ( BestChoice )-->
          <div v-if="isRedemptionShop">
            <DefaultCard
              v-if="postPaymentNeeded"
              no-padding
              :title="$t('view.checkout.payment.add_payment.headline')"
              :text="$t('view.checkout.payment.add_payment.text')"
              use-button
              :button-variant="buttonVariant"
              :button-text="$t('view.checkout.payment.add_payment.button')"
              scrollto="#payment-options"
              button-type="anchor"
              data-test="additional-payment-info"
            />
            <PaymentListGroup
              :headline-text="$t('view.checkout.payment.headline')"
              :sub-headline="$t('view.checkout.payment.voucher_list.text')"
              :used-credit-amount="totalUsedPrepaidAmount"
              :used-voucher-amount="paymentListGroupItems"
            />
            <PaymentOptionRadio
              v-if="postPaymentNeeded && availablePaymentRadioOptions"
              id="payment-options"
              :button-text="$t('global.voucher_form.button')"
              :headline-text="$t('view.checkout.payment.payment_option.headline')"
              :sub-headline="hintLimitedPaymentOptions"
              :payment-options="availablePaymentRadioOptions"
              :selected-payment-option-type="selectedPaymentOptionTypeId"
              @selection="handleOptionType"
            />
          </div>

          <!--- Every other Shop -->
          <div v-else>
            <PaymentOptionRadio
              v-if="availablePaymentRadioOptions"
              :button-text="$t('global.voucher_form.button')"
              :headline-text="$t('view.checkout.payment.add_payment.headline')"
              :payment-options="availablePaymentRadioOptions"
              :selected-payment-option-type="selectedPaymentOptionTypeId"
              @selection="handleOptionType"
            />
          </div>
      
        </div>
        <div class="col-md-4 col-xl-4 offset-xl-1">
          <div class="basket-summary__wrapper">
            <BasketSummary :button-text="$t('view.checkout.cart.summary.button.address')" @button-click="handleFormValidation" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import paymentTypes from '@/config/paymentTypes.js';
  import CheckoutNavigation from '@/components/CheckoutNavigation/CheckoutNavigation.vue';
  import DefaultCard from 'building-blocks/components/DefaultElements/DefaultCard.vue';
  import PaymentListGroup from '@/components/Payment/PaymentListGroup.vue';
  import PaymentOptionRadio from '@/components/Payment/PaymentOptionRadio.vue';
  import BasketSummary from '../../components/Basket/BasketSummary.vue';

  export default {
    components: {
      CheckoutNavigation,
      DefaultCard,
      PaymentListGroup,
      PaymentOptionRadio,
      BasketSummary
    },
    props: {
      buttonVariant: {
        type: String,
        default: 'primary'
      },
      showHintLimitedPaymentOptions: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        paymentTypes: paymentTypes,
        availablePaymentRadioOptions: null,
        selectedPaymentOptionTypeId: 2
      };
    },
    async created() {
      // retrieve the available payment type and create the options for the radio group
      const availablePaymentOptions = await this.$store.dispatch('basket/getAvailablePaymentOptions');

      if (availablePaymentOptions && availablePaymentOptions.length !== 0) {
        this.createSelectablePaymentOptions(availablePaymentOptions);

        // preselect the current post payment from the basket
        const postPayment = this.$store.getters['basket/getPostPaymentType'];
        if (postPayment) {
          this.selectedPaymentOptionTypeId = postPayment.payment_type_id;
        }
      }
    },
    mounted() {
      if (this.$route.params.err) {
        const errMsg = this.$t('view.checkout.payment.saferpay_fail.text');
        this.errorToast(errMsg);
      }
    },
    computed: {
      ...mapGetters({
        postPaymentSet: 'basket/containsSufficentPostPayment',
        postPaymentType: 'basket/getPostPaymentType',
        isRedemptionShop: 'app/isRedemptionShop'
      }),
      ...mapState({
        prepaidValue: (state) => state.basket.prepaidValue,
        basketGrossPrice: (state) => state.basket.grossPrice,
        missingAmountOfMoney: (state) => state.basket.missingAmountOfMoney
      }),

      hintLimitedPaymentOptions() {
        return this.showHintLimitedPaymentOptions ? this.$t('view.checkout.payment.payment_option.text') : '';
      },
      totalUsedPrepaidAmount() {
        let usedPrepaidAmount = this.prepaidValue;
        if (usedPrepaidAmount > this.basketGrossPrice) {
          usedPrepaidAmount = this.basketGrossPrice;
        }
        return usedPrepaidAmount;
      },
      paymentListGroupItems() {
        const prepaidPayments = this.$store.getters['basket/getPrepaidPayments'];
        const bestchoicePaymentType = this.paymentTypes.find((payment) => payment.name === 'bestchoice');
        let remainingBasketPrice = this.basketGrossPrice;
        const paymentListGroupItems = [];

        prepaidPayments.forEach((prepaidPayment) => {
          if (prepaidPayment.payment_type_id === bestchoicePaymentType.id) {
            const fullRedemption = remainingBasketPrice > prepaidPayment.value;
            const remainingVoucherValue = fullRedemption ? 0 : prepaidPayment.value - remainingBasketPrice;
            const usedVoucherValue = prepaidPayment.value - remainingVoucherValue;
            const paymentListGroupItem = {
              code: prepaidPayment.code,
              remainingValue: remainingVoucherValue,
              usedValue: usedVoucherValue
            };
            remainingBasketPrice -= usedVoucherValue;
            paymentListGroupItems.push(paymentListGroupItem);
          }
        });
        return paymentListGroupItems;
      },
      postPaymentNeeded() {
        return this.basketGrossPrice > this.prepaidValue;
      }
    },
    methods: {
      /**
       * creates the radio options for the DefaultInputRadioGroupCollapse component
       */
      createSelectablePaymentOptions(availablePaymentType) {
        const radioOptions = [];

        availablePaymentType.forEach((paymentTypes) => {
          const paymentType = this.paymentTypes.find(({ id }) => id === paymentTypes.id);
          if (paymentType) {
            const paymentRadioOption = {
              id: paymentType.id,
              title: paymentType.short_description,
              description: paymentType.description,
              icon_url: paymentType.icon,
              prepaid: paymentType.prepaid,
              saferpay: paymentType.saferpay
            };
            radioOptions.push(paymentRadioOption);
          }
        });
        this.availablePaymentRadioOptions = radioOptions;
      },
      handleOptionType(value) {
        this.selectedPaymentOptionTypeId = value;
      },
      async handleFormValidation() {
        let navigateToNextStep = false;
        let errorMsg = '';

        if (!this.postPaymentNeeded) {
          await this.deleteCurrentPostPaymentOptionFromBasket();
          navigateToNextStep = true;
        } else if (this.postPaymentNeeded && !this.postPaymentSet) {
          const checker = await this.checkPaymentMethods();
          navigateToNextStep = checker.navigateToNextStep;
          errorMsg = checker.errorMsg;
        } else {
          const checker = await this.checkSelectedPostPaymentType(navigateToNextStep, errorMsg);
          navigateToNextStep = checker.navigateToNextStep;
          errorMsg = checker.errorMsg;
        }

        this.handleNavigationToNextPage(navigateToNextStep, errorMsg);
      },
      // This method should check which paymentType is selected and according to type call setSaferpayToBasket or setPaymentToBasket 
      async checkPaymentMethods() {
        const bestChoicePaymentId = 2;
        const paymentType = this.paymentTypes.find(({id}) => id === this.selectedPaymentOptionTypeId);
        let navigateToNextStep = false;
        let errorMsg = '';

        if (this.selectedPaymentOptionTypeId === bestChoicePaymentId) {
          errorMsg = this.isRedemptionShop ? this.$t('view.checkout.payment.add_payment_voucher.text') : this.$t('view.checkout.payment.choose_payment_method.text');
        } else if (paymentType.saferpay) {
          navigateToNextStep = await this.setSaferpayToBasket();
          errorMsg = this.$t('view.checkout.payment.saferpay_fail.text');
        } else {
          navigateToNextStep = await this.setPaymentToBasket();
          errorMsg = this.$t('view.checkout.payment.add_payment.text');
        }

        return {navigateToNextStep, errorMsg};
      },
      async checkSelectedPostPaymentType(navigateToNextStep, errorMsg) {
        const postPaymentTypeChanged = this.checkIfSelectedPostPaymentTypeChanged();

        if (postPaymentTypeChanged) {
          await this.deleteCurrentPostPaymentOptionFromBasket();
          const checker = await this.checkPaymentMethods();
          navigateToNextStep = checker.navigateToNextStep;
          errorMsg = checker.errMsg;
        } else {
          navigateToNextStep = true;
        }
        return {navigateToNextStep, errorMsg};
      },
      async setSaferpayToBasket() {
        const params = {
          amount: this.missingAmountOfMoney,
          currency: this.$store.state.app.currency.code,
          payment_type_id: this.selectedPaymentOptionTypeId
        };
        await this.$store.dispatch('basket/addSaferpayPaymentToBasket', params);
        return this.missingAmountOfMoney === 0;
      },
      async setPaymentToBasket() {
        await this.$store.dispatch('basket/addBanktransferPaymentToBasket', this.missingAmountOfMoney);
        return this.missingAmountOfMoney === 0;
      },
      checkIfSelectedPostPaymentTypeChanged() {
        const postPayment = this.postPaymentType;
        const postPaymentType = postPayment.payment_type_id;
        return this.selectedPaymentOptionTypeId !== postPaymentType;
      },
      async deleteCurrentPostPaymentOptionFromBasket() {
        if (this.postPaymentType) {
          try {
            return await this.$store.dispatch('basket/removePaymentType', this.postPaymentType);
          } catch (error) {
            this.errorToast(error.message);
          }
        }
      },
      handleNavigationToNextPage(navigateToNextStep, errorMsg) {
        if (navigateToNextStep) {
          this.$router.push({ path: this.$routeHandler('/checkout/address') });
        } else if (errorMsg !== '') {
          this.errorToast(errorMsg);
        } else {
          this.errorToast(this.$t('view.error.general'));
        }
      },
      errorToast(message) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .payment {
    margin-top: 3rem;
    &__container {
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 1.5rem;
    }
  }
  .basket-summary__wrapper {
    @include media-breakpoint-up(md) {
      position: -webkit-sticky;
      position: sticky;
      top: 12rem;
    }
  }
</style>
