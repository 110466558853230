import { createApp } from 'vue';
import router from './router';
import store from './store';
import api from './helpers/apiHelpers.js';
import App from './App.vue';
import i18n from './plugins/i18n';
import veeValidate from './plugins/veeValidate';
import * as yup from 'yup';
import { routeHandler, image, currency, scrollTo, mgnlToJson, toHexColorCode, setColors, setFavicon, isAppleDevice } from '@/helpers/utils';
import { isMobile, isIE } from 'mobile-device-detect';
import Swiper from './components/Swiper';
import VueMatomo from 'vue-matomo';
import matomoConfig from './plugins/vue-matomo';

// VeeValidate config
veeValidate.configure({
  validateOnInput: true
});

const app = createApp(App);

import '@/assets/styles/main.scss';

app.use(i18n);
app.use(router);
app.use(store);
app.use(VueMatomo, matomoConfig);

app.config.globalProperties.$currency = currency;
app.config.globalProperties.$image = image;
app.config.globalProperties.$scrollTo = scrollTo;
app.config.globalProperties.$device = { isMobile, isIE };
app.config.globalProperties.$isAppleDevice = isAppleDevice;


app.config.globalProperties.$mgnlToJson = mgnlToJson;
store.$mgnlToJson = mgnlToJson;
store.$toHexColorCode = toHexColorCode;
app.config.globalProperties.$setColors = setColors;
store.$setColors = setColors;
app.config.globalProperties.$setFavicon = setFavicon;
store.$setFavicon = setFavicon;

app.config.globalProperties.$api = api;
store.$api = api;

app.config.globalProperties.$i18n = i18n;
store.$i18n = i18n;

app.config.globalProperties.$routeHandler = routeHandler;
store.$routeHandler = routeHandler;

app.component('VeeField', veeValidate.Field);
app.component('VeeForm', veeValidate.Form);
app.component('VeeErrorMessage', veeValidate.ErrorMessage);

app.component('Swiper', Swiper);

app.config.globalProperties.$yup = yup;
store.$yup = yup;

app.mount('#app');
