<template>
  <div class="basket-item">
    <div class="basket-item__image-container">
      <router-link
        :to="$routeHandler('/product-details/' + `${product.product_number}`)"
        tag="div"
        class="basket-item__image"
        :class="{ 'basket-item__image--voucher': !isReward }"
      >
        <img v-if="imageUrl" :src="imageUrl" :alt="product.name" class="product__image" />
      </router-link>
    </div>
    <div class="basket-item__info">
      <div class="product__name" :class="{'mb-1': itemPrice()}">
        <router-link v-if="!isAdditionalProduct" :to="$routeHandler('/product-details/' + `${product.product_number}`)" class="product__name__link"
          >{{ product.name }}<sup>{{ vatIndex }}</sup></router-link
        >
        <span v-else class="product__name__link--no-hover">{{ product.name }}<sup>{{ vatIndex }}</sup></span>
      </div>
      <div v-if="itemPrice()" class="product__price-wrap mb-2">
        <div class="product__price mb-1">{{ $currency(itemPrice()) }}</div>
        <div v-if="recommendedRetailPrice() > itemPrice()" class="product__old-price pl-3">{{ $currency(recommendedRetailPrice()) }}</div>
      </div>
      <div v-if="isEditable && !isAdditionalProduct" class="product__delete">
        <button class="btn btn-white delete-button" @click="removePosition(product.position)">{{ $t('global.remove') }}</button>
      </div>
    </div>
    <div class="basket-item__count">
      <select
        v-if="isEditable && !isAdditionalProduct"
        v-model="productQuantity"
        class="product__counter custom-select custom-select-sm"
        :disabled="isLoading || quantityOptions.length === 1"
        @change="updatePositionQuantity(product.position)"
      >
        <option v-for="option in quantityOptions" :key="option" :value="option">{{ option }}</option>
      </select>
      <span v-else>{{ product.quantity }}x</span>
    </div>
    <div class="basket-item__total">{{ $currency(isB2B ? product.net_price : product.gross_price) }}</div>
  </div>
</template>

<script>
  import * as createSelectOptions from '@/helpers/createQuantitySelectOptions.js';
  import vatIndexMap from '@/config/vatIndexMap.js';
  export default {
    props: {
      product: {
        type: Object,
        default: null
      },
      isEditable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        productQuantity: this.product.quantity
      };
    },
    computed: {
      isLoading() {
        return this.$store.state.app.isLoading;
      },
      isB2B() {
        return this.$store.state.app.isB2B;
      },
      imageUrl() {
        return this.isAdditionalProduct ? this.$store.getters['product/getBasketImageUrl'](this.product) : this.$store.getters['product/getCatalogImageUrl'](this.product.product_number);
      },
      isReward() {
        return this.$store.getters['product/getProductTypReward'](this.product.product_number);
      },
      isAdditionalProduct() {
        return !this.product.gross_price;
      },
      /**
       * Creates select options
       * first parameter defines how many select options are created
       * second parameter defines the initial selected option
       */
      quantityOptions() {
        return createSelectOptions.createOptions(this.$store.state.app.basketItemLimit, this.product.quantity);
      },
      vatIndex() {
        return vatIndexMap[this.product.vat];
      }
    },
    watch: {
      product(newValue, oldValue) {
        if(newValue.gross_price && newValue.quantity !== oldValue.quantity && newValue.variation_number === oldValue.variation_number){
          newValue.quantity > oldValue.quantity ? 
            this.eTrackerCommerce('insertToBasket', newValue.quantity - oldValue.quantity ) :
            this.eTrackerCommerce('removeFromBasket', oldValue.quantity - newValue.quantity);
        }
        this.productQuantity = this.product.quantity;
      }
    },

    methods: {
      /**
       * Triggers Basket Update with new Quantity of Position
       * @param {number} productPosition
       */
      async updatePositionQuantity(productPosition) {
        const updateData = {
          positionNumber: productPosition,
          quantity: parseInt(this.productQuantity)
        };
        this.$store.commit('app/SET_LOADINGSTATE', true);

        try {
          await this.$store.dispatch('basket/changePositionQuantity', updateData);
          this.$store.commit('app/SET_LOADINGSTATE', false);
        } catch (error) {
          this.$store.commit('app/SET_LOADINGSTATE', false);
          this.errorToast(error.message);
          this.productQuantity = this.product.quantity
        }
      },
      async removePosition(position) {
        this.$store.commit('app/SET_LOADINGSTATE', true);
        try {
          await this.$store.dispatch('basket/removePositionFromBasket', position);
          this.eTrackerCommerce("removeFromBasket")
          this.$store.commit('app/SET_LOADINGSTATE', false);
        } catch (error) {
          this.errorToast(error.message);
          this.$store.commit('app/SET_LOADINGSTATE', false);
        }
      },
      errorToast(message) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      },
      itemPrice(eTracker) {
        return (this.isB2B && !eTracker ? this.product.net_price : this.product.gross_price) / this.product.quantity;
      },
      recommendedRetailPrice(eTracker) {
        const product = this.$store.getters['product/getProduct'](this.product.product_number);
        return this.isB2B && !eTracker ? product?.net_recommended_retail_price : product?.recommended_retail_price;
      },
      eTrackerCommerce(eventName, quantity) {
        if(!this.$store.state.etracker.etrackerId) return;
        const categories = this.$store.getters['etracker/getProductHierarchy'](this.product.product_number);
        const price = this.product.tracking_value;
        const discount = this.product.discount_percantage ? price*(this.product.discount_percantage/100) : 0;
        this.$store.dispatch('etracker/etCommerce', {eventName, payload: {
          id: this.product.product_number,
          name: this.product.tracking_name,
          price: price.toFixed(2).toString(),
          discount: discount.toFixed(2).toString(),
          currency: 'EUR',
          category: categories,
          variants: this.isReward ? {} : {'var1': this.product.face_value.amount.toFixed(2).toString()}
        }, number: +quantity || +this.product.quantity});
      }
    },
  };
</script>

<style lang="scss" scoped>
  .basket-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 7.5rem;
    @include media-breakpoint-up(md) {
      height: 8.1875rem;
    }

    padding: 1rem;
    border-bottom: $border-width solid $border-color;
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
    &__image-container,
    &__image {
      width: 5rem;
      height: 3.2rem;
      position: relative;
      @include media-breakpoint-down(xs) {
        width: 3rem;
      }
    }
    &__image {
      z-index: 1;
      position: absolute;
      height: 100%;
      overflow: hidden;
      cursor: pointer;
      .product__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
        width: 5rem;
        object-fit: cover;
        @include media-breakpoint-down(xs) {
          width: 3rem;
        }
      }
      &--voucher {
        // height: 3.125rem;
        @include media-breakpoint-down(xs) {
          height: 1.875rem;
        }

        overflow: hidden;
        box-shadow: $box-shadow-sm;
        border-radius: 0.25rem;

        .product__image {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          width: 100%;
        }
      }
    }
    &__info {
      width: 50%;
      padding-left: 1.5rem;
      @include media-breakpoint-down(xs) {
        padding-left: 1rem;
        padding-right: 0.5rem;
        flex: auto;
      }
      .product {
        &__price-wrap {
          display: table;
        }
        &__price,
        &__old-price {
          display: table-cell;
        }
        &__old-price {
          color: $gray_400;
          text-decoration: line-through;
        }
        &__name,
        &__price {
          @extend %font-h5;
        }
        &__name {
          position: relative;
          font-family: $font-semibold;
          // height: calc(#{($h5-font-size + $line-height-base) / 1});
          overflow: hidden;
          &__link, &__link--no-hover {
            color: $body-color;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &__link {
            &:hover {
              color: $link-hover-color;
            }
          }
          // &::after {
          //   content: '';
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 1rem;
          //   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), #ffffff 100%, #ffffff);
          //   background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), #ffffff 100%, #ffffff);
          //   background-repeat: no-repeat;
          // }
        }
        &__delete {
          .delete-button {
            color: $gray-400;
            font-family: $font-semibold;
            padding: 0;
            @extend %font-h6;

            &:hover,
            &:active,
            &:focus {
              color: $link-hover-color;
            }
          }
        }
      }
    }
    &__count,
    .product__counter {
      width: 5rem;
      @include media-breakpoint-down(xs) {
        width: 4rem;
      }
    }
    &__total {
      display: none;
      text-align: right;
      max-width: 4.625rem;
      width: 100%;
      font-family: $font-semibold;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
</style>
