<template>
  <Footer 
    :logo="getImageUrl(logo)"
    :shopLinks="parseLinks(shopLinks)" 
    :serviceLinks="parseLinks(serviceLinks)" 
    :url="url"
    :tuvUrl="tuvUrl"
  />
</template>

<script>
  import Footer from '@/components/Footer/Footer.vue';
  import { mgnlToJson } from '../helpers/utils.js';

  export default {
    components: { Footer },
    props: {
      shopLinks: {
        type: Object,
        default: undefined
      },
      serviceLinks: {
        type: Object,
        default: undefined
      },
      logo: {
        type: Object,
        default: undefined
      },
      url: {
        type: String,
        default: 'https://www.cadooz.com/'
      },
      tuvUrl: {
        type: String,
        default: 'https://www.cadooz.com/fileadmin/user_upload/Unternehmen/cadooz_ISO-Zertifikat_DE.pdf'
      },
    },

    methods: {
      parseLinks(links) {
        return mgnlToJson(links)
          .filter((link) => link.show === 'true' || link.show)
          .map((link) => {
            if (link.type.field == 'link') return this.createLinkObject(link);

            if (link.type.field == 'url') return this.createUrlObject(link);
          });
      },

      getImageUrl(img) {
        if (!img) return;
        return process.env.VUE_APP_MGNL_HOST + '/dam' + img['@path'];
      },

      createUrlObject(link) {
        return {
          name: link.name,
          href: link.type.href,
          target: link.type.target === 'true' ? '_blank' : ''
        };
      },
      createLinkObject(link) {
        return {
          name: link.name,
          path: link.type.path ? this.createPath(link.type.path) : '',
          hash: link.type.additional.field === 'hash' ? this.createAnchorLink(link.type.additional.hash) : '',
          query: link.type.additional.field === 'query' ? this.createQuery(link.type.additional.query) : ''
        };
      },

      createPath(path) {
        const siteName = path.substr(1, path.substring(1).indexOf("/") + 1);
        return path.replace(siteName, '');
      },

      createAnchorLink(hash) {
        if (hash.trim() === '') return '';

        return hash.includes('#') ? hash : '#' + hash;
      },
      createQuery(query) {
        if (query.trim() === '') return '';

        try {
          const jsonStr = query
            .replace(/(\w+:)|(\w+ :)/g, function (s) {
              return '"' + s.substring(0, s.length - 1) + '":';
            })
            .replace(/\'/g, '"');
          return JSON.parse(jsonStr);
        } catch (error) {
          console.error(`Provided Query String "${query}" was not in the expected Format`);
          return '';
        }
      }
    }
  };
</script>
