<template>
  <nav ref="nav" class="navigation" @click.self="closeMenu()">
    <div class="navigation__wrap">
      <span class="navigation__close" @click="closeMenu()"></span>
      <router-link :to="$routeHandler(loggedIn ? '/welcome' : '/')" class="navigation__logo">
        <img :src="logo" />
      </router-link>
      <ul class="navigation__group navigation__list">
        <li v-for="(item, groupIndex) in groups.options" :key="groupIndex" class="navigation__item" @click="setFiltrationGroup(item)">
          <a class="navigation__link">{{ item.text }}</a>
        </li>
      </ul>

      <ul class="navigation__categories navigation__list">
        <li class="navigation__item" v-for="(item, catIndex) in mutatedCategories" :key="catIndex" @click="setFiltrationCategory(item)">
          <a class="navigation__link">{{ item.name }}</a>
        </li>
        <li>
          <a v-if="categories.length > catLength" @click="showMore" class="navigation__load-more">+ Mehr</a>
          <a v-if="categories.length === catLength" @click="showLess" class="navigation__load-more">- Weniger</a>
        </li>
      </ul>

      <DefaultDivider />

      <ul class="navigation__links navigation__list">
        <li class="navigation__item" v-for="(item, linkIndex) in links" :key="linkIndex">
          <router-link v-if="!item.href" class="navigation__link" :to="{ path: $routeHandler(item.path), query: item.query, hash: item.hash }">{{
            item.name
          }}</router-link>
          <a v-else class="navigation__link" :href="item.href" :target="item.target">{{ item.name }}</a>
        </li>
      </ul>

      <DefaultDivider />

      <div class="navigation__logout" @click="logout()">
        <b-icon icon="logout" />
        <span>{{this.$t('global.navigation_items.logout')}}</span>
      </div>
    </div>
  </nav>
</template>

<script>
  import DefaultDivider from 'building-blocks/components/DefaultElements/DefaultDivider.vue';
  import { mapGetters } from 'vuex';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import headerMixin from '@/plugins/mixins/headerMixin';

  export default {
    components: {
      DefaultDivider,
      BIcon
    },
    data() {
      return {
        catLength: 8
      };
    },
    mixins: [headerMixin],
    props: {
      logo: {
        type: String
      },
      links: {
        type: Object,
        default: {}
      },
      languageLogo: {
        type: String,
        default: require('@/assets/images/de.png')
      },
      loggedIn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        categories: 'filter/getCategories',
        composedQuery: 'filter/composedQuery'
      }),
      mutatedCategories() {
        const arr = [...this.categories];
        return arr.slice(0, this.catLength);
      },
      groups() {
        return {
          options: [
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_vouchers'),
              value: 'TVO'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_brand_products'),
              value: 'PHY'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_theme_vouchers'),
              value: 'UVO'
            }
          ]
        };
      }
    },
    mounted() {
      document.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          this.closeMenu();
        }
      });
    },
    methods: {
      closeMenu() {
        this.$emit('closeMenu');
      },
      showMore() {
        this.catLength = this.categories.length;
      },
      showLess() {
        this.catLength = 8;
      },
      setFiltrationGroup(item) {
        this.$store.commit('filter/SET_SEARCHINPUT', '');
        this.$store.commit('filter/SET_PRODUCTTYPE', [item]);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
        this.closeMenu();
      },
      setFiltrationCategory(item) {
        this.$store.commit('filter/SET_SEARCHINPUT', '');
        this.$store.commit('filter/SET_CATEGORY', item);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
        this.closeMenu();
      }
    },
  };
</script>

<style lang="scss" scoped>
  .navigation {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba($dark, 0.3);

    &__close {
      position: absolute;
      padding: 1rem;
      right: 3rem;
      top: 1.5rem;
      cursor: pointer;
      display: block;
      height: 20px;
      width: 20px;

      @include media-breakpoint-down(md) {
        top: 1rem;
      }

      &:hover {
        &::before,
        &::after {
          transition: $dur $ease;
          transform: translate(-50%, -50%) rotate(0);
        }
      }

      &::before,
      &::after {
        content: '';
        height: 2px;
        width: 20px;
        background-color: var(--primary);
        transition: $dur $ease;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        position: absolute;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &__wrap {
      position: relative;
      padding: 1.5rem 3rem 3rem;
      background-color: $light;
      height: 100vh;
      width: 30rem;
      overflow-y: auto;

      @include media-breakpoint-down(lg) {
        width: 23rem;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
        padding: 1rem 1rem 3rem;
      }
    }

    &__logo {
      display: block;
      margin-bottom: 2.5rem;
      max-width: 160px;
      width: 100%;

      @include media-breakpoint-down(md) {
        margin-bottom: 1.5rem;
      }
    }

    &__load-more {
      font-size: 1rem;
      font-weight: bold;
      color: var(--primary);
      cursor: pointer;
    }

    &__list {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }

    &__group {
      .navigation__item {
        margin-bottom: 1.5rem;
      }

      .navigation__link {
        font-size: 1.5rem;
        font-weight: bold;
        color: $dark;
        cursor: pointer;
      }
    }

    &__categories {
      .navigation__item {
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
          margin-bottom: 1.5rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .navigation__link {
        font-size: 1rem;
        color: $dark;
        cursor: pointer;
      }
    }

    &__links {
      .navigation__item {
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .navigation__link {
        font-size: 0.75rem;
        color: $dark;
      }
    }

    &__language,
    &__logout {
      display: flex;
      cursor: pointer;

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }

      span {
        font-weight: bold;
        font-size: 1rem;
        color: $dark;
      }
    }

    &__logout {
      align-items: center;

      svg {
        margin-right: .3rem;
      }
    }
  }
</style>
