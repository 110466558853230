<template>
  <PictureCardGroup
    :overline="overline"
    :headline="headline"
    :cardStories="mgnlStories"
  />
</template>

<script>
  import { mgnlToJson } from '../helpers/utils.js';
  import PictureCardGroup from '@/components/PictureCardGroup/PictureCardGroup.vue';

  export default {
    components: { PictureCardGroup },
    data() {
      return {
        newStoriesArray: []
      };
    },
    props: {
      overline: {
        type: String,
        default: ''
      },
      headline: {
        type: String,
        default: ''
      },
      stories: {
        type: Object,
        default: []
      }
    },

    computed: {
      mgnlStories() {
        return mgnlToJson(this.stories).map((val) => {
          return {
            title: val.title,
            text: val.text,
            image: {
              link: process.env.VUE_APP_MGNL_HOST + '/dam' + val.image['@path'],
              name: val.image['@name']
            }
          };
        });
      }
    }
  };
</script>
