export default [
  {
    id: 'SER',
    title: 'view.checkout.cart.summary.fees.service_fee'
  },
  {
    id: 'D_SF',
    title: 'view.checkout.cart.summary.fees.discount_service_fee'
  },
  {
    id: 'DEL',
    title: 'view.checkout.cart.summary.fees.delivery_fee'
  },
  {
    id: 'D_DF',
    title: 'view.checkout.cart.summary.fees.discount_delivery_fee'
  },
  {
    id: 'PLO',
    title: 'view.checkout.cart.summary.fees.logo_printing'
  },
  {
    id: 'D_LP',
    title: 'view.checkout.cart.summary.fees.discount_logo_printing'
  },
  {
    id: 'LS',
    title: 'view.checkout.cart.summary.fees.liability_surcharge'
  },
  {
    id: 'D_LS',
    title: 'view.checkout.cart.summary.fees.discount_liability_surcharge'
  },
  {
    id: 'HF',
    title: 'view.checkout.cart.summary.fees.handling_fee'
  },
  {
    id: 'D_HF',
    title: 'view.checkout.cart.summary.fees.discount_handling_fee'
  },
  {
    id: 'D_VV',
    title: 'view.checkout.cart.summary.fees.discount_voucher_value'
  },
  {
    id: 'PROV',
    title: 'view.checkout.cart.summary.fees.provision'
  },
  {
    id: 'MISC',
    title: 'view.checkout.cart.summary.fees.miscellaneous'
  }
];