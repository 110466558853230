import { ref } from "@vue/reactivity";
import { onMounted, onBeforeUnmount } from "@vue/runtime-core";
import { useStore } from "vuex";

const useSession = (() => {
  const store = useStore();
  if(store.state.app.mgnlMode) return;

  const intervalTimer = 30000; // set interval in ms
  const poll = ref(null);

  const startPolling = () => {
    poll.value = setInterval(() => {
      store.dispatch('user/checkUserSessionDuration')
    }, intervalTimer)
  };

  const stopPolling = () => {
    clearInterval(poll.value)
    poll.value = null
  };

  onMounted(() => startPolling());
  onBeforeUnmount(() => stopPolling());
})

export default useSession;
