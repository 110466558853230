<template>
  <div class="card mb-3">
    <img v-if="image" :src="image.link" :alt="image.name" class="card-img" />
    <div class="card-body">
      <h3 class="card-title">{{ title }}</h3>
      <div v-html="text"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      image: {
        type: Object,
        default: {}
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card-body {
    padding: 1rem 0;
    @include media-breakpoint-up(md) {
      padding: 1rem 0 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 2rem 0 6rem;
    }
    & .card-title {
      margin-bottom: 0.5rem;
      @extend %font-h3;
      @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
      }
    }
  }
</style>