<template>
  <div class="stage-voucher-balance__card mr-md-4">
    <div class="stage-voucher-balance__card__title">
      <div class="stage-voucher-balance__card__title__name">
        <h2 class="mb-0">{{ cardTitle }}</h2>
      </div>
      <div class="stage-voucher-balance__card__title__balance">
        <h2 class="mb-0">{{ $currency(prepaidValue) }}</h2>
      </div>
    </div>
    <div v-if="cardDesc" v-html="cardDesc" class="stage-voucher-balance__card__desc"></div>
    <div class="stage-voucher-balance__button text-right">
      <DefaultButton :variant="buttonColorVariant || 'primary'" :buttonText="cardCtaLabel" :link="cardCtaLink" :type="cardCtaType" />
    </div>
  </div>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

  export default {
    components: {
      DefaultButton
    },
    props: {
      cardCtaLabel: {
        type: String,
        default: ''
      },
      cardCtaType: {
        type: String,
        default: 'url'
      },
      cardCtaLink: {
        type: String,
        default: '#'
      },
      cardTitle: {
        type: String,
        default: ''
      },
      cardDesc: {
        type: String,
        default: ''
      },
      buttonColorVariant: {
        type: String,
        default: ''
      }
    },
    computed: {
      prepaidValue() {
        return this.$store.state.basket.prepaidValue;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .stage {
    overflow: hidden;
    padding: 1rem 0;
    z-index: 1;

    @include media-breakpoint-up(md) {
      padding: 4rem 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 6rem 0;
    }
    &__background {
      display: none;
    }
    &-voucher-balance {
      &__card {
        padding: 1rem;
        background: var(--light);
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
        @include media-breakpoint-up(md) {
          padding: 1.5rem;
        }
        @include media-breakpoint-up(xl) {
          padding: 2rem;
        }
        &__title {
          display: table;
          width: 100%;
          &__name,
          &__balance {
            display: table-cell;
            padding-bottom: 1rem;
            color: var(--dark);
          }
          &__balance {
            text-align: right;
          }
        }
        &__desc {
          color: var(--dark);
          @extend %font-md;
          border-top: 1px solid $gray-100;
          padding-top: 0.75rem;
        }
      }
      &__text {
        @extend %font-base;
      }
    }
  }
</style>
