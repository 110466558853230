import * as yup from 'yup';
export default {
  fields: [
    {
      label: 'Anrede',
      name: 'anrede',
      as: 'select',
      children: [
        {
          tag: 'option',
          value: 'herr',
          text: 'Herr',
        },
        {
          tag: 'option',
          value: 'frau',
          text: 'Frau',
        }
      ],
    },
    {
      label: 'Vorname',
      name: 'firstName',
      as: 'input',
      rules: yup.string().required()
    },
    {
      label: 'Nachname',
      name: 'lastName',
      as: 'input',
      rules: yup.string().required()
    },
    {
      label: 'Firma',
      name: 'company',
      as: 'input'
    },
    {
      label: 'Straße',
      name: 'street',
      as: 'input',
      rules: yup.string().required()
    },
    {
      label: 'Hausnummer',
      name: 'houseNumber',
      as: 'input',
      rules: yup.string().required()
    },
    {
      label: 'Zusatz',
      name: 'additive',
      as: 'input'
    },
    {
      label: 'PLZ',
      name: 'postCode',
      as: 'input',
      rules: yup.string().required()
    },
    {
      label: 'Ort',
      name: 'location',
      as: 'input',
      rules: yup.string().required()
    },
    {
      label: 'Land',
      name: 'country',
      as: 'select',
      children: [
        {
          tag: 'option',
          value: 'germany',
          text: 'Germany'
        },
        {
          tag: 'option',
          value: 'Austria',
          text: 'austria'
        }
      ],
      rules: yup.string().required()
    },
    {
      label: 'E-Mail-Adresse',
      name: 'email',
      as: 'input',
      rules: yup.string().email().required()
    },
    {
      label: 'E-Mail-Adresse (Wiederholung)',
      name: 'email2',
      as: 'input',
      rules: yup.string().email().required().oneOf([yup.ref('email')], 'email must be the same')
    }
  ]
}