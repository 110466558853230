<template>
    <component :is="svg" class="b-icon bi" />
</template>

<script>
export default {
    props: {
        icon: {
            type: String
        }
    },
    computed: {
        svg() {
            return require(`@/assets/svg/${this.icon}.svg?inline`).default
        }
    }
}
</script>

<style lang="scss" scoped>
    .b-icon.bi {
        width: 1em;
        height: 1em;
        font-size: 125%;
        vertical-align: text-bottom;
    }
</style>
