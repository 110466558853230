<template>
  <div>
    <div class="filter__body px-2">
      <div v-if="filterTags.length > 0 || sortOption !== 'default'" class="filter__tags mb-3">
        <div class="d-inline-block">
          <div class="filter__badge badge badge-soft-dark m-1 p-2" @click="resetSorting()" v-if="sortOption !== 'default'">
            {{ $t(sortingText.text) }}
            <b-icon icon="x" shift-v="2" />
          </div>

          <div v-for="tag in filterTags" :key="tag.text" class="filter__badge badge badge-soft-dark m-1 p-2" @click="removeFilterByTag(tag)">
            {{ tag.text }}
            <b-icon icon="x" shift-v="2" />
          </div>
        </div>
        <button v-if="filterTags.length > 0 || sortOption !== 'default'" class="filter__badge btn btn-light button-reset" @click="$emit('reset')">{{ $t('filter.reset') }}</button>
      </div>
      <div class="mb-3">
        <FilterCategories
          :title="filterCategory.title"
          :categories="categories"
          :active-category="activeCategory"
          @update="updateCategoryFilter"
        />
      </div>
      <div class="mb-3">
        <FilterBoxRadio
          :title="$t('filter.sort.title')"
          :options="sortingOptions.map(option => ({ text: $t(option.text), value: option.value }))"
          :active-filter="sortOption"
          @filterRadio="updateSorting"
        />
      </div>
      <div class="mb-3">
        <FilterBox
          :title="filterGroupsProductType.title"
          :options="filterGroupsProductType.options"
          :active-filter="productTypeFilter"
          @filterInput="updateProductTypeFilter"
        />
      </div>
      <div class="mb-3">
        <FilterBoxRange
          :title="filterGroupsPrice.title"
          :min-range="priceRangeMin"
          :max-range="priceRangeMax"
          :price-min-filter="priceMinFilter"
          :price-max-filter="priceMaxFilter"
          :discount-filter="discountFilter"
          @filterRangeInput="updatePriceFilter"
          @discountClick="updatetDiscountFilter"
        />
      </div>
      <div class="mb-3">
        <FilterBox
          :title="filterGroupsShippingMethod.title"
          :options="filterGroupsShippingMethod.options"
          :active-filter="shippingMethodFilter"
          @filterInput="updateShippingMethodFilter"
        />
      </div>
      <div class="mb-3">
        <FilterBox
          :title="filterGroupsRedemptionType.title"
          :options="filterGroupsRedemptionType.options"
          :active-filter="redemptionTypeFilter"
          @filterInput="updateRedemptionTypeFilter"
        />
      </div>
      <!-- <div v-if="showBrandFilter" class="mb-3">
        <FilterBrands :title="$t('filter.brands.headline')" :brands="brands" :active-brands="selectedBrands" @update="updateBrandFilter" />
      </div> -->
    </div>
    <div class="filter__footer p-2">
      <DefaultButton variant="primary" block @click="$emit('button-click')">
        {{ countedProducts }}
      </DefaultButton>
    </div>
  </div>
</template>

<script>
  import FilterCategories from './FilterCategories.vue';
  import FilterBox from './FilterBox.vue';
  import FilterBoxRadio from './FilterBoxRadio.vue';
  import FilterBoxRange from './FilterBoxRange.vue';
  import FilterBrands from './FilterBrands.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      FilterCategories,
      FilterBox,
      FilterBoxRadio,
      FilterBoxRange,
      FilterBrands,
      DefaultButton,
      BIcon
    },
    data() {
      return {
        selectedSorting: this.sortOption
      };
    },
    computed: {
      ...mapGetters({
        productCount: 'filter/getFilteredProductsCount',
        priceRangeMin: 'filter/getMinPriceOfAllProducts',
        priceRangeMax: 'filter/getMaxPriceOfAllProducts',
        filterTags: 'filter/getFilterTags',
        isSearchActive: 'filter/isSearchActive',
        brands: 'filter/getBrands',
        categories: 'filter/getCategories',
        composedQuery: 'filter/composedQuery',
        isRedemptionShop: 'app/isRedemptionShop',
        sortingOptions: 'product/getSortingOptions'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType,
        redemptionTypeFilter: (state) => state.filter.redemptionType,
        shippingMethodFilter: (state) => state.filter.shippingMethod,
        discountFilter: (state) => state.filter.discount,
        sortOption: (state) => state.filter.sortOption,
        showOnlyRecommendedProducts: (state) => state.filter.onlyRecommendations,
        searchInput: (state) => state.filter.searchInput,
        selectedBrands: (state) => state.filter.selectedBrands,
        category: (state) => state.filter.category,
        activeCategory: (state) => state.filter.activeCategory,
        priceMinFilter: (state) => state.filter.priceMin,
        priceMaxFilter: (state) => state.filter.priceMax,
        allProducts: (state) => state.product.catalogProducts 
      }),

      countedProducts() {
        return this.$t('filter.show_results', { product_count: this.productCount })
      },
      // showBrandFilter() {
      //   console.log(this.productTypeFilter);
      //   return this.productTypeFilter.length === 1 ? this.productTypeFilter[0].value !== 'TVO' : true
      // },
      filterCategory() {
        return {
          title: this.$t('filter.group.category.title')
        }
      },
      sortingText() {
        return this.sortingOptions.find(el => el.value === this.sortOption);
      },
      filterGroupsProductType() {
        const productTypes = [
          {
            groupId: 'productType',
            text: this.$t('filter.group.type.option_vouchers'),
            value: ['TVO', 'UVO']
          },
          {
            groupId: 'productType',
            text: this.$t('filter.group.type.option_brand_products'),
            value: 'PHY'
          }
        ];
        if(!this.isRedemptionShop) {
          productTypes.push({
            groupId: 'productType',
            text: this.$t('filter.group.type.option_theme_vouchers'),
            value: 'UVO'
          });
        }
        const filteredOptions = this.reduceFilters(productTypes, this.allProducts, 'type_group');
        return {
          title: this.$t('filter.group.type.title'),
          options: filteredOptions
        };
      },
      filterGroupsPrice() {
        return {
          title: this.$t('filter.group.price.title', { currency: this.$store.state.app.currency.code })
        };
      },
      filterGroupsShippingMethod() {
        const shippingMethods = [
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_post'),
            value: 0
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_mail'),
            value: 1
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_sms'),
            value: 2
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_csv'),
            value: 3
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_csv'),
            value: 4
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_pdf'),
            value: 7
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_csv'),
            value: 8
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_bulk'),
            value: 9
          }
        ];
        const filteredOptions = this.reduceFilters(shippingMethods, this.allProducts, 'delivery_options.id');
        return {
          title: this.$t('filter.group.shipping.title'),
          options: filteredOptions
        };
      },
      filterGroupsRedemptionType() {
        const redemptionType = [
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_online'),
            value: 'online_redeemable'
          },
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_store'),
            value: 'offline_redeemable'
          },
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_print'),
            value: 'printable_as_barcode'
          },
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_mobile'),
            value: 'usable_on_smartphone'
          },
        ]
        const filteredOptions = this.reduceFilters(redemptionType, this.allProducts, '', true);
        return {
          title: this.$t('filter.group.property.title'),
          options: filteredOptions
        };
      }
    },
    watch: {
      sortOption() {
        this.selectedSorting = this.sortOption;
      }
    },

    mounted() {
      this.selectedSorting = this.sortOption;
    },

    methods: {
      updateProductTypeFilter(filterArray) {
        this.$store.commit('filter/SET_PRODUCTTYPE', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateShippingMethodFilter(filterArray) {
        this.$store.commit('filter/SET_SHIPPINGMETHOD', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateRedemptionTypeFilter(filterArray) {
        this.$store.commit('filter/SET_REDEMPTIONTYPE', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updatetDiscountFilter(discountValue) {
        this.$store.commit('filter/SET_DISCOUNT', discountValue);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updatePriceFilter(priceObject) {
        this.$store.dispatch('filter/updatePriceFilter', priceObject);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateBrandFilter(selectedBrands) {
        this.$store.commit('filter/SET_SELECTED_BRANDS', selectedBrands);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateCategoryFilter(category) {
        this.$store.commit('filter/SET_CATEGORY', category);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateSorting(sortOption) {
        this.$store.commit('filter/SET_SORTOPTION', sortOption);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },

      resetSorting() {
        this.$store.commit('filter/SET_SORTOPTION', 'default');
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },

      removeFilterByTag(tag) {
        const groupId = tag.groupId;
        let oldFilterGroup;
        let newFilterGroup;

        switch (groupId) {
          case 'productType':
            oldFilterGroup = this.productTypeFilter;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag);
            this.$store.commit('filter/SET_PRODUCTTYPE', newFilterGroup);
            break;
          case 'redemptionType':
            oldFilterGroup = this.redemptionTypeFilter;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag);
            this.$store.commit('filter/SET_REDEMPTIONTYPE', newFilterGroup);
            break;
          case 'shippingMethod':
            oldFilterGroup = this.shippingMethodFilter;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag);
            this.$store.commit('filter/SET_SHIPPINGMETHOD', newFilterGroup);
            break;
          case 'discount':
            this.$store.commit('filter/SET_DISCOUNT', false);
            break;
          case 'price':
            this.$store.dispatch('filter/resetPriceFilter');
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'brand':
            oldFilterGroup = this.selectedBrands;
            newFilterGroup = oldFilterGroup.filter((item) => item !== tag.text);
            this.$store.commit('filter/SET_SELECTED_BRANDS', newFilterGroup);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
          case 'category':
            this.$store.commit('filter/SET_CATEGORY', null);
            this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
            break;
        }
      },

      reduceFilters(group, products, key, boolean) {
        const result = group.reduce((list, item) => {
          const keys = key.split('.');
          const allowed = products.some(product => {
            return boolean ? product[item.value] 
                  : Array.isArray(item.value) ? item.value.some(val => product[key] === val) 
                  : keys.length > 1 && Array.isArray(product[keys[0]]) ? product[keys[0]].some(val => val[keys[1]] === item.value)
                  : product[key] === item.value
          });
          if (allowed) {
            return [...list, item];
          } else {
            return list;
          }
        }, []);
        return result;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter__tags {
    padding: 0.5rem 0;

    .form-control {
      border: none;
      box-shadow: none;
      border-radius: none;
      padding: 0;
      transition: none;
    }
    .button-reset {
      padding: .5rem;
      border: none;
    }
  }
  
  .filter__badge {
    font-size: .8rem;
    border: 1px solid var(--primary);
  }

  .filter__body {
    padding-bottom: 1rem;
  }

  .filter__footer {
    background: white;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 120;
    button {
      @extend %font-h5;
    }
  }
</style>
