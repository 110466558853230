<template>
  <ul class="nav">
    <li v-for="(item, index) in nav" :key="index" class="nav__li" :class="{ 'active-item': item.active }">
      <span v-if="$route.name === item.name" class="nav__link">{{ $t(item.text) }}</span>
      <router-link v-else class="nav__link" :to="$routeHandler(item.to)">{{ $t(item.text) }}</router-link>
    </li>
  </ul>
</template>

<script>
import navConfig from "@/config/checkoutNavigation.js"

export default {
  data() {
    return {
      nav: []
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  created() {
    const { routeName } = this
    const processed = navConfig.map((el) => {
      const item = Object.assign({}, el)
      if (item.name === routeName) {
        item.active = true
      }
      return item
    })
    this.nav = processed
  }
}
</script>

<style lang="scss" scoped>
  $icon-spacer: 0.5rem;
  .nav {
    margin-bottom: 2rem;
    @extend %font-md;
    &__li {
      margin-right: $icon-spacer;
      &:after {
        content: '>';
        display: inline-block;
        padding-left: $icon-spacer;
        color: #999999;
      }
    }
    &__li:last-child {
      &:after {
        display: none;
      }
    }
    .active-item ~ li.nav__li {
      a {
        color: $gray-400;
        cursor: default;
        pointer-events: none;
      }
    }
  }
</style>
