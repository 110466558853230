import axios from '@/plugins/axios';

const userServices = {
  //Sets the Login Flag to true. Used to Login the User over the handshake
  handShakeLogin(handshakeId, isValidCoupon) {
    return axios
      .post('/website/handshake-login', {
        login_user: true,
        externalId: handshakeId,
        isValidCoupon: isValidCoupon
      })
      .then((resp) => resp.data);
  },
  //Generate handshake url for ACE
  handShakeGenerator(url) {
    return axios.post(`/website/handshake-url`, {
      url: url
    }).then((resp) => resp.data);
  },
  // Verify login
  verifyLogin(data) {
    return axios.post(`/login`, data).then((resp) => {
      const basket = resp.data.basket;
      if (basket == null) {
        console.error(resp); // missing error handling
      }
      return resp.data;
    });
  },
  verifyUserLogin(data) {
    return axios.post('/user-login', data).then((resp) => {
      const basket = resp.data.basket;
      if(basket == null) {
        console.error(resp);
      }
      return resp.data;
    })
  },
  // Check whether the user has the logged in status.
  checkLoginState() {
    return axios.get(`/user/has-logged-in`).then((resp) => resp.data);
  },

  //Endpoint to notify the backend that the user session is still active.
  //Used when the frontend used does not query any backend calls, but is still active in the shop.
  sendHeartbeat() {
    return axios.post(`/session/heartbeat`).then((resp) => resp.data);
  },

  // Request to get the remaining duration of the session (s)
  getSessionDuration() {
    return axios
      .get('session/duration')
      .then((resp) => resp.data.duration)
      .catch((err) => err);
  },

  //Endpoint to query the remaining session duration. Requires the "session-id" cookie.
  checkUserSessionDuration() {
    return axios.get(`/session/duration`).then((resp) => resp.data);
  },

  // Deletes the session provided via session-id header.
  deleteUserSession() {
    return axios.delete(`/session`).then((resp) => resp.data);
  },

  // Newsletter subscription
  async newsletterSubscribe(data) {
    const requestData  = {
      listId: data.listId,
      email: data.email,
      source: data.source,
      attributes: data.attributes
    }
  
    const result = axios.post(`/newsletter/subscribe`, {data: requestData }).then((resp) => resp.data);
    return result;
  }
};

export default userServices;
