<template>
  <div v-if="availableLanguages.length > 1 && sidebar" id="accordion" class="accordion card accordion-card mb-3" :class="isDropdownVisible ? 'not-collapsed' : 'collapsed'">
    <div class="" id="headingOne">
      <h5 class="mb-0 accordion-title">
        <a href="#" role="button" aria-haspopup="true" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" @click="toggleDropdown">
          <b-icon :icon="'flags/' + selectedLanguage" class="language_icon mr-1"></b-icon>{{ displayLanguageNames.of(selectedLanguage) }}
          <span class="card-header-btn__icon ml-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </span>
        </a>
      </h5>
    </div>

    <div :class="{ show: isDropdownVisible }" id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="accordion-list">
        
        <a v-for="lang in availableLanguages" v-show="selectedLanguage !== lang" :key="lang.id" :href="'javascript:void(0)'" class="dropdown-item" :class="{'active' : lang === selectedLanguage}" @click="toggleLanguage(lang)">
          <b-icon :icon="'flags/' + lang" class="mr-1" />{{ displayLanguageNames.of(lang) }}
        </a>
      </div>
    </div>
  </div>

  <div v-else-if="availableLanguages.length > 1" class="nav-link dropdown language-dropdown">
    <a
      id="languageDropdownMenuLink"
      href="#"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleDropdown"
    >
      <b-icon :icon="'flags/' + selectedLanguage" class="language_icon"></b-icon>
    </a>
    <div class="dropdown-menu dropdown-menu-right dropdown-mobile-view" :class="{ show: isDropdownVisible }" aria-labelledby="languageDropdownMenuLink">
      <a v-for="lang in availableLanguages" :key="lang.id" :href="'javascript:void(0)'" class="dropdown-item" :class="{'active' : lang === selectedLanguage}" @click="toggleLanguage(lang)">
        <b-icon :icon="'flags/' + lang" class="mr-1" />{{ displayLanguageNames.of(lang) }}
      </a>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    props: {
      totalSum: {
        type: Number,
        default: 0
      },
      sidebar: {
        type: Boolean
      }
    },
    data() {
      return {
        'ml-auto': false,
        'd-none': false,
        isDropdownVisible: false,
      };
    },
    computed: {
      displayLanguageNames() {
        return new Intl.DisplayNames([this.$store.state.translation.locale], { type: 'language' })
      },
      availableLanguages() {
        return this.$store.state.translation.locales;
      },
      selectedLanguage() {
        return this.$store.state.translation.locale;
      },
      rightAlign() {
        return {
          'ml-auto': this.totalSum === 0
        };
      },
      invisible() {
        return {
          'd-none': this.totalSum !== 0,
          'd-lg-flex': this.totalSum !== 0
        };
      }
    },
    methods: {
      toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
      },

      toggleLanguage(lang) {
        this.isDropdownVisible = !this.isDropdownVisible;
        this.$store.dispatch('translation/changeLanguage', lang);
      }
    }
  };
</script>


<style lang="scss" scoped>
  .dropdown-menu {
    border: 0;
    margin: 0;

    .dropdown-item {
      font-weight: normal;
      font-family: $font-regular;
      font-size: 80%;

      svg {
        border: 1px solid var(--gray-100);
      }
    }
  }

  .dropdown-item {
    display: block;
    padding: 10px;

    &.active {
      color: var(--primary) !important;
      background-color: var(--gray-100);
      pointer-events: none;
    }
  }

  .dropdown-mobile-view {
    @include media-breakpoint-down(sm) {
      position: fixed;
      margin: 11px 0px 0px;
      width: 100%;
      top: auto;
      border: none;
    }
  }

  .language-dropdown {
    border: 0 !important;
  }

  .language-dropdown a svg,
  .accordion .accordion-title a svg,
  .language-dropdown .dropdown-menu a svg {
    border-radius: 50%;
    border: 1px solid var(--gray-100);
  }

  .accordion {
    padding: 0 1rem;
    margin-bottom: 20px;

    &-list {
      padding: 10px 0;
    }

    &-list a {
      padding: 10px 1px;

      svg {
        border-radius: 50%;
        border: 1px solid var(--gray-100);
      }
    }

    &.not-collapsed {
      & .card-header-btn__icon {
        & svg {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>