import store from '@/store';
// =======================================================================
// State
// =======================================================================
const state = () => ({
  activePage: 1,
  itemsPerPage: 40,
  displayedProducts: []
})

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_ITEMS_PER_PAGE(state, count) {
    state.itemsPerPage = count;
  },
  SET_DISPLAYED_PRODUCTS(state, items) {
    state.displayedProducts = items;
  },
  SET_ACTIVE_PAGE(state, page) {
    state.activePage = page;
  }
}
// =======================================================================
// Actions
// =======================================================================
const actions = {
  async setPageItems(context) {
    const count = window.innerWidth <= 990 ? 24 : 40;
    context.commit('SET_ITEMS_PER_PAGE', count);
  },

  async setDisplayedProducts({ commit, state }, payload) {
    const start = state.itemsPerPage * (payload.activePage - 1);
    const end = state.itemsPerPage * (payload.activePage - 1) + state.itemsPerPage;
    const filteredItems = payload.products.length > 0 ? payload.products.slice(start, end) : payload.products.slice(start, end);
    commit('SET_ACTIVE_PAGE', payload.activePage);
    commit('SET_DISPLAYED_PRODUCTS', filteredItems);
  }
}
// =======================================================================
// Getters
// =======================================================================
const getters = {
  getItems: (state) => {
    return state.itemsPerPage;
  }
}


export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}