// Pages
import MgnlDefault from './mgnl-pages/MgnlDefault.vue';
import MgnlStatic from './mgnl-pages/MgnlStatic.vue';

// Layout
import MgnlHeader from './components/MgnlHeader.vue';
import MgnlFooter from './components/MgnlFooter.vue';

// Index
import MgnlAccordion from './components/MgnlAccordion.vue';
import MgnlInfoBox from './components/MgnlInfoBox';
import MgnlHowToComponent from './components/MgnlHowToComponent.vue';
import MgnlPictureCardGroup from './components/MgnlPictureCardGroup.vue';
import MgnlPromotionBanner from './components/MgnlPromotionBanner.vue';
import MgnlPromotionTeaser from './components/MgnlPromotionTeaser.vue';
import MgnlStageLogin from './components/Stage/MgnlStageLogin';
import MgnlStageLoginImage from './components/Stage/MgnlStageLoginImage';

// Welcome
import MgnlCategoriesBar from './components/MgnlCategoriesBar';
import MgnlCategorieThemeSwiper from './components/MgnlCategorieThemeSwiper';
import MgnlNewsletter from './components/MgnlNewsletter';
import MgnlProductSlider from './components/MgnlProductSlider';
import MgnlStageDefault from './components/Stage/MgnlStageDefault';
import MgnlWelcomeStage from './components/MgnlWelcomeStage';

// Global
import MgnlTextEditor from './components/MgnlTextEditor';

const config = {
  componentMappings: {
    // Pages
    'shop-lm:pages/index': MgnlDefault,
    'shop-lm:pages/default': MgnlDefault,
    'shop-lm:pages/static': MgnlStatic,

    // Layout
    'shop-lm:components/header': MgnlHeader,
    'shop-lm:components/footer': MgnlFooter,

    // Index
    'shop-lm:components/accordion': MgnlAccordion,
    'shop-lm:components/infoBox': MgnlInfoBox,
    'shop-lm:components/howToComponent': MgnlHowToComponent,
    'shop-lm:components/pictureCardGroup': MgnlPictureCardGroup,
    'shop-lm:components/stageLogin': MgnlStageLogin,
    'shop-lm:components/stageLoginImage': MgnlStageLoginImage,

    // Welcome
    'shop-lm:components/categoriesBar': MgnlCategoriesBar,
    'shop-lm:components/categorieThemeSwiper': MgnlCategorieThemeSwiper,
    'shop-lm:components/newsletter': MgnlNewsletter,
    'shop-lm:components/productSlider': MgnlProductSlider,
    'shop-lm:components/stageDefault': MgnlStageDefault,
    'shop-lm:components/welcomeStage': MgnlWelcomeStage,

    // Promotion Banner
    'shop-lm:components/promotionBanner': MgnlPromotionBanner,
    'shop-lm:components/promotionTeaser': MgnlPromotionTeaser,

    // Global
    'shop-lm:components/textEditor': MgnlTextEditor
  }
};

export default config;
