export default [
  {
    "id": "TVO",
    "title": "filter.group.type.option_vouchers"
  },
  {
    "id": "PHY",
    "title": "filter.group.type.option_brand_products"
  },
  {
    "id": "UVO",
    "title": "filter.group.type.option_theme_vouchers"
  },
]