<template>
  <div class="product-overview--filter-on-page">
    <section>
      <div class="container-xl">
        <h1 class="product-overview__title">{{computedHeadline}}</h1>
        <div class="row">
          <div class="col-lg-3">
            <div>
              <FilterGroup />
            </div>
          </div>
          <div class="col-lg-9">
            <div>
              <FilterStatusBar @reset="resetAllFilters" />
            </div>
            <div class="sidebar-filter__wrapper">
              <Sidebar id="filterSidebar" ref="filterSidebar" :title="$t('filter.menu_title')">
                <template #body="{ hideSidebar }">
                  <FilterMobile @reset="resetAllFilters" @button-click="hideFilterSidebar(hideSidebar)" />
                </template>
              </Sidebar>
              <DefaultButton
                variant="primary"
                button-text="Filter"
                :nested-badge="filterCount > 0"
                :badge-text="filterCount.toString()"
                shadowed-button
                class="floating-button"
                @click="showFilterSidebar"
              />
            </div>

            <div v-if="filteredProducts.length > 0" class="filter-results">
              <ProductItem v-for="product in displayProducts" 
                :key="product.id" :product="product" />
            </div>
            <div v-else>
              <div class="row d-flex align-content-center">
                <div class="col filter-results__empty-message">
                  <DefaultHeadline :headline-text="$t('filter.no_results')" heading-size="h5" is-headline is-centered />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <DefaultHeadline :headline-text="$t('global.product_slider.headline')" heading-size="h4" is-headline />
                </div>
              </div>
              <div class="filter-results">
                <ProductItem v-for="product in displayProducts" :key="product.id" :product="product" />
              </div>
            </div>
            
            <Pagination
              v-bind="pagination"
              :products="products"
              :activePage="activePage"
              @update="pagination = $event"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import StageDefault from '@/components/Stage/StageDefault.vue';
  import FilterGroup from '@/components/Filter/FilterGroup.vue';
  import FilterStatusBar from '@/components/Filter/FilterStatusBar.vue';
  import FilterMobile from '@/components/Filter/FilterMobile.vue';
  import Sidebar from 'building-blocks/components/Sidebar.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import ProductItem from '@/components/Product/ProductItem.vue';
  import Pagination from 'building-blocks/components/Pagination.vue';

  export default {
    components: {
      StageDefault,
      FilterGroup,
      FilterStatusBar,
      FilterMobile,
      Sidebar,
      DefaultButton,
      DefaultHeadline,
      ProductItem,
      Pagination
    },
    data() {
      return {
        pagination: { activePage: 1 },
      }
    },
    props: {
      recommendedProducts: {
        type: Array,
        default: []
      },
      filteredProducts: {
        type: Array,
        default: []
      },
      filterCount: {
        type: Number,
        default: 0
      },
      computedHeadline: {
        type: String,
        default: ''
      }
    },

    methods: {
      showFilterSidebar() {
        this.$emit('showFilterSidebar');
      },
      resetAllFilters() {
        this.$emit('resetAllFilters');
      },
      hideFilterSidebar(hideSidebar) {
        this.$emit('hideFilterSidebar', hideSidebar);
      }
    },

    computed: {
      products() {
        return this.filteredProducts.length > 0 ? this.filteredProducts : this.recommendedProducts
      },
      displayProducts() {
        return this.$store.state.pagination.displayedProducts;
      },
      activePage() {
        return this.pagination.activePage = this.$store.state.filter.page
      }
    }
  };
</script>

<style lang="scss" scoped>
  .product-overview--filter-on-page {
    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;
    }

    .sidebar-filter__wrapper {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
  .product-overview__title {
    padding: 2rem 0;
  }
</style>
