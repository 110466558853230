<template>
  <div class="search__results-wrap">
    <div class="search__results-inner">
      <div class="container-xl">
        <div class="search__results-top">
          <h2 class="search__results-title">Ihre Suche: "{{ searchInput }}"</h2>
          <div class="search__results-close-wrap">
            <span class="search__results-close" @click="clearSearch()"></span>
          </div>
        </div>
        <div class="search__results" v-if="!hasNoSearchResults">
          <div v-for="product in resultsPreview" :key="product.number" class="search__results-product">
            <ProductItem :product="product" />
          </div>
          <div v-if="searchResults.length > previewLimit" class="col-12 search__results-more text-center">
            <DefaultButton variant="primary" :button-text="$t('view.search.all_button_text')" @click="showAllResults" />
          </div>
        </div>
      </div>

      <ProductSlider
        v-if="hasNoSearchResults"
        :headline="$t('global.product_slider.headline')"
        :productLinkPath="'/product-overview'"
        :productLinkText="$t('global.product_slider.headline')"
        :productLinkQuery="{ recommended: true }"
        :products="allProducts"
        fontColor="dark"
      />
    </div>
  </div>
</template>

<script>
  import Fuse from 'fuse.js';
  import ProductItem from './ProductItem.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import ProductSlider from './ProductSlider.vue';

  export default {
    components: {
      ProductItem,
      DefaultButton,
      ProductSlider
    },
    data() {
      return {
        searchInput: '',
        searchResults: [],
        previewLimit: 4,
        innerWidth: 0,
        fuse: {},
        searchOptions: {
          keys: [
            {
              name: 'name',
              weight: 4
            },
            {
              name: 'description',
              weight: 1
            }
          ],
          minMatchCharLength: 2,
          includeScore: true,
          includeMatches: true,
          threshold: 0.3,
          ignoreLocation: true
        }
      };
    },
    props: {
      search: {
        type: String
      }
    },
    computed: {
      allProducts() {
        return this.$store.state.product.catalogProducts;
      },
      resultsPreview() {
        return this.searchResults.length > this.previewLimit ? this.searchResults.slice(0, this.previewLimit) : this.searchResults;
      },
      emptySearchInput() {
        return this.searchInput.trim() == '';
      },
      hasNoSearchResults() {
        return this.searchResults.length === 0 && !this.emptySearchInput;
      }
    },
    watch: {
      innerWidth(newWidth) {
        if (newWidth > 1200) {
          this.previewLimit = 4;
        } else if (newWidth > 992 && newWidth < 1200) {
          this.previewLimit = 3;
        } else if (newWidth < 992 && newWidth > 768) {
          this.previewLimit = 4;
        } else if (newWidth < 768 && newWidth > 576) {
          this.previewLimit = 3;
        } else {
          this.previewLimit = 2;
        }
      }
    },
    mounted() {
      this.searchInput = this.search;
      this.handleResults();
    },
    beforeUpdate() {
      this.searchInput = this.search;
      this.handleResults();
    },
    beforeMount() {
      window.addEventListener('resize', this.handleResize);

      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResults() {
        if (this.emptySearchInput) {
          this.searchResults = [];
        } else {
          const arr = new Fuse(this.allProducts, this.searchOptions).search(this.search);
          this.searchResults = arr.map((el) => el.item);
        }
      },
      showAllResults() {
        this.clearSearch();
        this.$store.commit('filter/SET_LOADED_PRODUCTS', this.searchResults);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: { search: this.searchInput } });
      },
      clearSearch() {
        this.$emit('clearSearch');
      },
      handleResize() {
        this.innerWidth = window.outerWidth;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .search__results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 2rem;

    @include media-breakpoint-down(xs) {
      padding-bottom: 1rem;
    }

    &__empty-message {
      padding: 4rem;
    }

    &-inner {
      background-color: #fff;
      width: 100%;
      max-width: 100%;
      border-top: 1px solid $gray-100;
      box-shadow: $box-shadow-sm;
    }

    &-wrap {
      position: absolute;
      top: 104px;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);

      @include media-breakpoint-down(md) {
        top: 95px;
      }

      @include media-breakpoint-down(sm) {
        top: 151px;
      }
    }

    &-top {
      padding: 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-close-wrap {
      display: flex;
      justify-content: center;
      position: relative;
    }

    &-title {
      margin-bottom: 0;
    }

    &-more {
      padding-top: 1.5rem;

      @include media-breakpoint-down(xs) {
        padding-top: 1rem;

        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &-close {
      padding: 1rem;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        top: 1rem;
      }

      &:hover {
        &::before,
        &::after {
          transition: $dur $ease;
          transform: translate(-50%, -50%) rotate(0);
        }
      }

      &::before,
      &::after {
        content: '';
        height: 2px;
        width: 20px;
        background-color: var(--primary);
        transition: $dur $ease;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        position: absolute;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .search__results > div.search__results-product {
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex-basis: calc(50% - 0.5rem);
    min-width: 0;
    @include media-breakpoint-down(xs) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(sm) {
      flex-basis: calc(100% / 3 - 2rem / 3);
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(md) {
      flex-basis: calc(25% - 0.75rem);
      margin-bottom: 2rem;
      &:nth-child(3n) {
        margin-right: 1rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      margin-right: 2rem;
      flex-basis: calc(100% / 3 - 4rem / 3);
      &:nth-child(4n) {
        margin-right: 2rem;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-up(xl) {
      flex-basis: calc(25% - 1.5rem);
      &:nth-child(3n) {
        margin-right: 2rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .search__results > div {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
</style>
