<template>
  <ProductSlider
    :headline="headline"
    :productLinkText="productLinkText"
    :productLinkPath="createPath(productLinkPath)"
    :productLinkQuery="createQuery(productLinkQuery)"
    :bgColor="toHexColorCode(parseInt(bgColor))"
    :products="getProducts(products)"
    :fontColor="fontColor"
  />
</template>

<script>
  import ProductSlider from '@/components/Product/ProductSlider.vue';
  import { mapGetters, mapState } from 'vuex';
  export default {
    components: { ProductSlider },
    props: {
      headline: {
        type: String,
        default: ''
      },
      productLinkText: {
        type: String,
        default: ''
      },
      productLinkPath: {
        type: String,
        default: '/product-overview'
      },
      productLinkQuery: {
        type: String,
        default: '{ recommended: false }'
      },
      bgColor: {
        type: [String, Number]
      },
      products: {
        type: String,
        default: 'recommendedProducts'
      },
      fontColor: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        recommendedProducts: 'product/getRecommendedProducts'
      }),
      ...mapState({
        allProducts: (state) => state.product.catalogProducts
      })
    },
    methods: {
      toHexColorCode(negDecimal) {
        const colorCodeInDec = 16777216 + negDecimal;
        const colorCodeInHex = colorCodeInDec.toString(16).padStart(6, '0');
        return `#${colorCodeInHex}`;
      },
      getProducts(mgnlProducts) {
        return mgnlProducts == 'recommendedProducts' ? this.recommendedProducts : this.allProducts;
      },
      createPath(path) {
        const siteName = path.substr(1, path.substring(1).indexOf("/") + 1);
        return path.replace(siteName, '');
      },
      createQuery(query) {
        if (query.trim() === '') return '';

        try {
          const jsonStr = query
            .replace(/(\w+:)|(\w+ :)/g, function (s) {
              return '"' + s.substring(0, s.length - 1) + '":';
            })
            .replace(/\'/g, '"');
          return JSON.parse(jsonStr);
        } catch (error) {
          console.error(`Provided Query String "${query}" was not in the expected Format`);
          return '';
        }
      }
    }
  };
</script>
