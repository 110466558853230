<template>
  <div class="card filterbox">
    <div class="card-header p-0">
      <button class="btn btn-light btn-block filterbox__header p-3" :class="{ 'not-collapsed': !isCollapsed }" @click="toogleCollapse">
        <span>{{ title }}</span>
        <span class="filterbox__icon"><b-icon icon="caret-down-fill" h-flip /></span>
      </button>
    </div>
    <div class="collapse" :class="{ show: !isCollapsed }">
      <div class="card-body py-0 px-3">
        <fieldset class="form-group">
          <div class="bv-no-focus-ring" role="group">
            <div class="input__wrapper">
              <input
                v-model="minValue"
                name="min"
                :min="minRange"
                :max="maxValue"
                type="number"
                placeholder="min"
                inputmode="numeric"
                pattern="[0-9]*"
                class="form-control"
                :class="{ invalid: invalidInput }"
                @input="handleInput"
                @blur="overrideInputValues"
              />
              <span class="p-3"> {{ $t('filter.range.to') }} </span>
              <input
                v-model="maxValue"
                name="max"
                :min="minValue"
                :max="maxRange"
                type="number"
                placeholder="max"
                inputmode="numeric"
                pattern="[0-9]*"
                class="form-control"
                :class="{ invalid: invalidInput }"
                @input="handleInput"
                @blur="overrideInputValues"
              />
            </div>
            <SliderTwoHandles
              :max-range="maxRange"
              :min-range="minRange"
              :price-min-filter="minValue.toString()"
              :price-max-filter="maxValue.toString()"
              @slided="updateSlideValues"
            />
            <hr v-if="isRedemptionShop" />
            <div class="custom-control custom-checkbox" v-if="isRedemptionShop">
              <input id="discount" v-model="discount" type="checkbox" class="custom-control-input" @change="handleDiscountFilterInput" />
              <label for="discount" class="custom-control-label">
                <span> {{ $t('filter.group.price.discount') }}</span>
              </label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SliderTwoHandles from '@/components/SliderTwoHandles.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      SliderTwoHandles,
      BIcon
    },
    props: {
      groupId: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      minRange: {
        type: String,
        default: ''
      },
      maxRange: {
        type: String,
        default: ''
      },
      priceMinFilter: {
        type: String,
        default: ''
      },
      priceMaxFilter: {
        type: String,
        default: ''
      },
      discountFilter: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        discount: this.discountFilter,
        minValue: this.priceMinFilter,
        maxValue: this.priceMaxFilter,
        invalidInput: false,
        isCollapsed: false
      };
    },
    computed: {
      ...mapGetters({
        isRedemptionShop: 'app/isRedemptionShop'
      }),
      allCharsValid() {
        const validateRegEx = /^\+?(0|[1-9]\d*)$/;
        const validMaxValue = validateRegEx.test(this.maxValue);
        const validMinValue = validateRegEx.test(this.minValue);
        return validMinValue && validMaxValue;
      },
      isMinLessOrEqualMax() {
        return parseInt(this.minValue) <= parseInt(this.maxValue);
      },
      areValuesInRange() {
        const isMaxInRange = parseInt(this.maxValue) <= parseInt(this.maxRange);
        const isMinInRange = parseInt(this.minValue) >= parseInt(this.minRange);
        return isMaxInRange && isMinInRange;
      }
    },

    watch: {
      discountFilter() {
        this.discount = this.discountFilter;
      },
      priceMinFilter() {
        this.minValue = this.priceMinFilter;
      },
      priceMaxFilter() {
        this.maxValue = this.priceMaxFilter;
      }
    },
    mounted() {
      this.discount = this.discountFilter;
      this.minValue = this.priceMinFilter;
      this.maxValue = this.priceMaxFilter;
    },

    methods: {
      handleDiscountFilterInput() {
        this.$emit('discountClick', this.discount);
      },
      toogleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      },

      /**
       * Debounced Input Emit of the Active FilterObject
       */
      updateFilter() {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.allCharsValid && this.isMinLessOrEqualMax && this.areValuesInRange) {
            this.$emit('filterRangeInput', { min: this.minValue.toString(), max: this.maxValue.toString() });
          }
        }, 200);
      },

      handleInput() {
        if (this.allCharsValid && this.isMinLessOrEqualMax && this.areValuesInRange) {
          this.invalidInput = false;
          this.updateFilter();
        } else {
          this.invalidInput = true;
        }
      },

      setValuesInRange(inputName) {
        if (inputName === 'min') {
          if (this.minValue > this.maxRange) {
            this.minValue = this.maxValue;
          } else if (this.minValue > this.maxValue) {
            this.maxValue = this.minValue;
          }
        } else if (inputName === 'max') {
          if (this.minValue < this.minRange) {
            this.minValue = this.minRange;
          } else if (this.maxValue < this.minRange) {
            this.maxValue = this.minValue;
          } else {
            this.minValue = this.maxValue;
          }
        }
      },

      overrideInputValues(event) {
        const inputName = event.target.name;

        if (!this.allCharsValid) {
          if (inputName === 'min') {
            this.minValue = this.minRange
          } else {
            this.maxValue = this.maxRange
          }
          this.handleInput();
        }

        if (!this.isMinLessOrEqualMax) {
          this.setValuesInRange(inputName);
          this.handleInput();
        }

        if (!this.areValuesInRange) {
          if (inputName === 'min') {
            this.minValue = this.minRange;
            if (parseInt(this.maxValue) < parseInt(this.minRange)) {
              this.maxValue = this.minValue;
            }
          }
          if (inputName === 'min') {
            this.minValue = this.minRange
          } else {
            this.maxValue = this.maxRange
          }
          this.handleInput();
        }
      },

      updateSlideValues(newValuesObj) {
        this.minValue = newValuesObj.newMin;
        this.maxValue = newValuesObj.newMax;
        this.handleInput();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filterbox {
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;
    .card-body {
      @extend %font-md;
      input {
        @extend %font-base;
      }
    }
  }

  .input__wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .slider__wrapper {
    display: flex;
    flex-direction: column;
  }

  .invalid:focus {
    border-color: #c31b19;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(195, 27, 25, 0.25);
    caret-color: #c31b19;
  }

  // hiding the spinners from the price input

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
