<template>
  <EditablePage v-if="content" :content="content" :config="config" :templateAnnotations="content.templateAnnotations" />
</template>

<script>
  import { EditablePage } from '@magnolia/vue-editor';
  import Plain from '@/layouts/Plain.vue';
  import ErrorPage from '@/pages/error.vue';
  import config from 'mgnl-building-blocks/magnolia.config';
  import { mapState } from 'vuex';

  export default {
    name: 'PageLoader',

    components: {
      EditablePage,
      ErrorPage,
      Plain
    },
    data() {
      return {
        config
      };
    },
    computed: {
      ...mapState({
        content: (state) => state.pages.currentPage
      })
    }
  };
</script>
