<template>
  <section class="login">
    <div class="container-xl">
      <div class="login__wrap row">
        <div class="col-md-12 col-lg-6">
          <div class="card login__form">
            <div class="loading" v-if="loader">
              <div v-for="(item, index) in 3" :key="index"></div>
            </div>
            
            <NewPasswordForm v-if="!showSuccessForm && !loader" @formSubmit="formSubmit" class="card__form" />

            <form v-else-if="!loader" class="card__form text-center">
              <b-icon class="mb-2" style="width: 80px; height: 80px;" :icon="'forgot-password-icon'" />
              <h1 class="card-title mb-3">{{ $t('global.new_password_success_form.title') }}</h1>
              <div class="card-subtitle">{{ $t('global.new_password_success_form.subtitle') }}</div>

              <DefaultButton 
                class="mt-5"
                block
                variant="light"
                :button-text="$t('global.reset_password_success_form.button')"
                @click="toHomePage"
                data-test="password-forgot-success-input-button"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="login__background"></div>
  </section>
</template>

<script>
import NewPasswordForm from '../components/NewPasswordForm.vue';
import BIcon from 'building-blocks/components/BIcon.vue';
import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

export default {
  components: {
    BIcon,
    DefaultButton,
    NewPasswordForm
  },

  data() {
    return {
      showSuccessForm: false,
      loader: false,
    }
  },

  methods: {
    toHomePage() {
      this.$router.push(this.$routeHandler('/'));
    },
    formSubmit() {
      this.showSuccessForm = true;
      this.setLoader();
    },
    setLoader() {
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    },
  },

  mounted() {
    this.setLoader();
  },
}
</script>

<style lang="scss" scoped>
  .login {
    position: relative;
    overflow: hidden;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-down(xs) {
      padding: 1rem 0;
    }

    &__background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: rgb(197, 245, 251);
    }

    &__image {
      position: absolute;
      bottom: -3rem;
      right: -3rem;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .container-xl {
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }
  .login__wrap {
    color: white;

    @include media-breakpoint-down(md) {
      max-width: 500px;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }

    .card-title {
      font-family: $font-semibold;
      margin-bottom: 2rem;
    }

    .card-subtitle {
      margin-bottom: 1rem;
    }

    & .login__form {
      border-radius: 10px;
      z-index: 2;
      background: var(--primary);
      padding: 2rem;
      width: 100%;

      min-height: 500px;
      display: flex;
      justify-content: center;
      
      @include media-breakpoint-up(md) {
        align-items: center;
      }

      @media screen and (max-width: 410px) {
        padding: 1rem;
      }

      & .card {
        border-radius: $border-radius;

        &__form {
          color: var(--light);
        }

        &-body {
          @media screen and (max-width: 410px) {
            padding: 1rem;
          }
        }
      }
    }

    .loading {
      display: flex;
      justify-content: center;

      div {
        width: 1rem;
        height: 1rem;
        margin: 2rem 0.3rem;
        background: var(--light);
        border-radius: 50%;
        animation: 0.9s bounce infinite alternate;

        &:nth-child(2) {
          animation-delay: 0.3s;
        }

        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }

    @keyframes bounce {
      to {
        opacity: 0.3;
        transform: translate3d(0, -1rem, 0);
      }
    }
  }
</style>