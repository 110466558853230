import axios from '@/plugins/axios';

const punchoutServices = {
  punchoutCheckout() {
    return axios
      .post('/punchout/checkout')
      .then((resp) => resp.data);
  },

  punchoutCancel() {
    return axios
      .post('/punchout/cancel')
      .then((resp) => resp.data);
  }
};

export default punchoutServices;
