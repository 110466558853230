// =======================================================================
// State
// =======================================================================
const state = () => ({
  checkout: undefined
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_CHECKOUTDATA(state, data) {
    state.checkout = data;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  async checkout(context, cancel) {
    let punCheckout;
    try {
      punCheckout = (cancel ? await this.$api.punchoutCancel() : await this.$api.punchoutCheckout()) || {};
    } catch (error) {
      return false;
    }
    if(punCheckout && punCheckout.url) {
      context.commit('SET_CHECKOUTDATA', punCheckout);
      await this.$api.deleteUserSession();
      return true;
    } else {
      return false;
    }
  }
};

// =======================================================================
// Getters
// =======================================================================
const getters = {
  isPunchout(_state, _getters, rootState) {
    return rootState.app.websiteSettings && rootState.app.websiteSettings.website_type_id === "PUN";
  },

  isPunchoutInspect(_state, _getters, rootState) {
    return rootState.app.websiteSettings && rootState.app.websiteSettings.punchout_session && rootState.app.websiteSettings.punchout_session.operation === 'inspect';
  },

  isPunchoutEdit(_state, _getters, rootState) {
    return rootState.app.websiteSettings && rootState.app.websiteSettings.punchout_session && rootState.app.websiteSettings.punchout_session.operation === 'edit';
  },

  getCheckoutData(state) {
    return state.checkout
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
