<template>
  <div>
    <CookieBanner
      v-if="showBanner"
      @openModal="showModal = true"
      @acceptAll="onAcceptAll"
      @removeAll="onRemoveAll"
      :style="showBanner === true ? 'top: 0' : 'top: -200px'"
    />
    <CookieModal v-if="showModal" @closeModal="showModal = false" @savePref="onSavePreferences" @acceptAll="onAcceptAll" @removeAll="onRemoveAll" />
  </div>
</template>

<script>
  import CookieBanner from './CookieBanner.vue';
  import CookieModal from './CookieModal.vue';

  export default {
    components: {
      CookieBanner,
      CookieModal
    },
    data() {
      return {
        showBanner: false,
        showModal: false
      };
    },
    mounted() {
      setTimeout(() => {
        let pref = localStorage.getItem('cookie-acceptance');
        if (pref) {
          this.showBanner = false;
          pref = JSON.parse(pref);
          if (pref.includes('matomo')) this.enableMatomo();
        } else if (!this.$store.state.app.mgnlMode) {
          this.showBanner = true;
        }
      }, 700);

      window.addEventListener('cookie-settings', () => (this.showModal = true));
    },
    methods: {
      onAcceptAll() {
        this.enableMatomo();
        localStorage.setItem('cookie-acceptance', JSON.stringify(['cadooz', 'matomo']));
        this.showBanner = false;
      },
      onRemoveAll() {
        this.disableMatomo();
        localStorage.setItem('cookie-acceptance', JSON.stringify(['cadooz']));
        this.showBanner = false;
      },
      onSavePreferences(preferences) {
        if (preferences.includes('matomo')) {
          this.enableMatomo();
        }
        localStorage.setItem('cookie-acceptance', JSON.stringify(preferences));
        this.showBanner = false;
      },
      enableMatomo() {
        this.$matomo && this.$matomo.rememberConsentGiven();
      },
      disableMatomo() {
        this.$matomo && this.$matomo.forgetConsentGiven();
      }
    }
  };
</script>
