<template>
  <div class="amount">
    <div class="detail-card">
      <!-- Rewards Section -->
      <div v-if="isReward && isAvailable">
        <span v-if="hasDiscount" class="badge badge-secondary amount--badge">{{ $t('view.product.price.uvp', { discount_percentage: product.displayed_discount }) }}</span>
        <div class="row no-gutters mt-1">
          <div class="col-12">
            <span class="amount__price"> {{ $currency(firstVarSellingPrice) }}</span>
            <span v-if="hasDiscount" class="amount__old-price">{{ $currency(retailPrice) }}</span> <span v-if="hasDiscount" class="amount__old-text">
              {{ $t('view.product.price.retail_price') }}
            </span>
          </div>
          <p v-if="!isB2B" class="amount__tax-hint amount__tax-hint--small">{{ $t('view.product.price.tax_and_delivery') }}</p>
        </div>
      </div>
      <div v-if="isReward && !isAvailable" class="amount__price amount__price--not-available">
        {{ $currency(firstVarSellingPrice) }}
      </div>

      <!-- Voucher Section -->
      <div v-if="!isReward">
        <h5 v-if="product?.external_product_value_type_id !== 'HID' || product?.variations.length > 1" class="detail-card__title mb-3">{{ $t('view.product.price.select') }}</h5>
        <fieldset v-if="product?.external_product_value_type_id !== 'HID' || product?.variations.length > 1" class="form-group amount__variations" @change="handleSelection">
          <div role="group" class="bv-no-focus-ring">
            <div
              v-for="productVariation in product?.variations"
              :key="productVariation.number"
              class="cdo-radio-bts btn-group-toggle btn-group btn-group-md bv-no-focus-ring"
            >
              <label class="btn btn-soft-primary btn-md amount__box" :class="{ active: productVariation.number === selected }" data-test="product-variant-btn">
                <input v-model="selected" :value="productVariation.number" type="radio" />
                <span>{{ $currency(productVariation.face_value.amount, productVariation.face_value.currency.short_symbol || productVariation.face_value.currency.symbol) }}</span>
              </label>
            </div>
          </div>
        </fieldset>

        <div v-if="hasDiscount" class="badge badge-secondary amount--badge">{{ `-${product.displayed_discount}%` }}</div>
        <div v-if="product.discount_text && !hasDiscount" class="badge badge-secondary amount--badge">{{ `${product.discount_text}` }}</div>
        <div class="amount--container" v-if="selectedVariationObj">
          <div class="amount--container-wrap">
            <div class="amount__price">
              {{ $currency(isB2B ? selectedVariationObj.net_selling_price : selectedVariationObj.selling_price) }}
            </div>
            <div class="amount--container--hints" :class="{'tax-hint-only': selectedVariationSaving === 0}">
              <span class="amount__tax-hint" v-if="hasDiscount && selectedVariationSaving > 0"> {{ $t('view.checkout.cart.summary.saving') }}: <span class="amount__tax-hint--num">{{ $currency(selectedVariationSaving) }}</span></span>
            </div>
          </div>
          <span v-if="!isB2B" class="amount__tax-hint amount__tax-hint--small">{{ $t('view.product.price.tax') }}</span>
        </div>
        <div class="amount__price--container" v-else></div>
      </div>
      <hr v-if="isRedemptionShop "/>
      <ProductDetailPaymentHint v-if="isRedemptionShop" />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import ProductDetailPaymentHint from './ProductDetailPaymentHint.vue';

  export default {
    components: {
      ProductDetailPaymentHint
    },
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      },
      selectedVariation: {
        type: String,
        default: ''
      },
      isReward: {
        type: Boolean,
        default: false
      },
      isAvailable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selected: this.selectedVariation
      };
    },
    computed: {
      ...mapGetters({
        isRedemptionShop: 'app/isRedemptionShop'
      }),
      ...mapState({
        isB2B: (state) => state.app.isB2B
      }),
      selectedVariationObj() {
        return this.product?.variations?.find(variation => variation.number === this.selected) || null;
      },
      selectedVariationSaving() {
        return this.isB2B ? this.selectedVariationObj.net_savings : this.selectedVariationObj.savings;
      },
      hasDiscount() {        
        return this.product.displayed_discount && this.product.displayed_discount > 0;
      },
      retailPrice() {
        return this.isB2B ? this.product.net_recommended_retail_price : this.product.recommended_retail_price;
      },
      firstVarSellingPrice() {
        return this.isB2B ? this.product.variations[0].net_selling_price : this.product.variations[0].selling_price; // as Rewards always just have 1 Variation, this is the Rewards Selling Price as well
      }
    },
    watch: {
      selectedVariation() {
        this.selected = this.selectedVariation;
      }
    },
    methods: {
      handleSelection() {
        this.$emit('select', this.selected);
      }
    }
  };
</script>

<style lang="scss">
  .amount {
    position: relative;
    &--container {
      &--hints {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .tax-hint-only {
        justify-content: flex-end
      }
      &-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: baseline;

        .amount__tax-hint {
          font-size: .9rem !important;
        }
      }
    }
    .amount__tax-hint--small {
      font-size: .7rem
    }
    &__price {
      @extend %font-h1;
      font-family: $font-semibold;
      color: var(--secondary);
      white-space: nowrap;
      margin-right: 1rem;
      &--not-available {
        @extend %font-h1;
        font-family: $font-regular;
        color: $gray-800;
      }
    }
    &__old-price,
    &__old-text,
    &__tax-hint {
      @extend %font-h6;
      color: $gray-300;
      font-family: $font-regular;
    }
    &__old-price {
      text-decoration: line-through;
    }
    &__tax-hint {
      text-align: right;
      margin: 0px;
      &-only {
        justify-content: flex-end;
      }
      &--num {
        color: var(--secondary);
        font-weight: bold;
      }
    }
    &__variations {
      margin-left: -0.25rem;
    }

    &--badge {
      position: absolute;
      right: -10px;
      top: -25px;
      font-size: 1rem;
      padding: 1rem;

      @media (max-width: 768px) {
        padding: .5rem;
        top: -15px;
      }
    }

    &__box {
      background-color: $white;
      border: 1px solid var(--primary-lightest);
    }
  }
  .cdo-radio-bts {
    .btn {
      min-width: 65px;
    }
  }
  .cdo-radio-bts.btn-group-toggle.btn-group.btn-group-md {
    display: inline;
  }
  .cdo-radio-bts label.btn.btn-soft-primary.btn-md {
    margin: 0.25rem;
    border-radius: $border-radius;
    font-family: $font-semibold;
    color: var(--primary);
    transition: $dur $ease;
    &.active,
    :active {
      color: var(--light);
      background-color: var(--primary);
      border-color: var(--primary);
    }
    &:hover {
      background: var(--primary-bright);
      color: var(--light);
    }
    .with-margin {
      margin-bottom: 1rem;
    }
  }
</style>
