<template>
  <section class="welcome" :class="!showProducts ? 'welcome--no-products' : ''">
    <div class="container-xl">
      <div class="welcome__content-wrap row">
        <div class="welcome__content col-md-6" :class="fontColor === 'dark' ? 'text-dark' : 'text-light'">
          <h1 class="welcome__title">{{ headline }}</h1>
          <p class="welcome__desc" v-html="description"></p>
          <DefaultButton
            v-if="buttonText"
            :button-text="buttonText"
            :link="buttonLink"
            :type="buttonType"
            :iconButton="buttonIcon"
            icon="cart3"
            :variant="buttonColorVariant || 'primary'"
          />
        </div>
        <div class="welcome__image-wrap col-6" v-if="image.name">
          <img class="welcome__image" :src="image.link" :alt="image.name">
        </div>
      </div>
      <div class="welcome__products-wrap" v-if="showProducts">
        <h2>{{ productsHeadline }}</h2>
        <div
          ref="swiper"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in highlightProducts" :key="index"
            >
              <ProductItem :product="item" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="welcome__background" :style="'background-color: ' + bgColor + ''"></div>
  </section>
</template>

<script>
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import ProductItem from '@/components/Product/ProductItem.vue';
  import Swiper from 'swiper';
  import { Pagination, Autoplay } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';

  export default {
    name: 'WelcomeStage',
    components: { DefaultButton, ProductItem },
    props: {
      bgColor: {
        type: [String, Number]
      },
      image: {
        type: Object,
        default: {}
      },
      headline: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      buttonType: {
        type: String,
        default: 'default'
      },
      buttonLink: {
        type: String,
        default: '#'
      },
      buttonIcon: {
        type: Boolean,
        default: false
      },
      showProducts: {
        type: [Boolean, Array],
        default: false
      },
      productsHeadline: {
        type: String,
        default: ''
      },
      fontColor: {
        type: String,
        default: ''
      },
      buttonColorVariant: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        swiper: undefined,
        swiperOptions: {
          modules: [Pagination, Autoplay],
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          speed: 600,
          loop: true,
          autoplay: {
            delay: 4000,
            pauseOnMouseEnter: true
          },
          pagination: {
            el: '.welcome .swiper-pagination',
            type: 'bullets'
          }
        }
      };
    },
    computed: {
      highlightProducts() {
        if (Array.isArray(this.showProducts)) {
          const filteredProducts = this.allProducts
            .filter((product) => this.showProducts.includes(product.number))
            .map((product) => {
              const index = this.showProducts.indexOf(product.number);
              return { ... product, order: index };
            })
            .sort((a, b) => a.order - b.order);

          return filteredProducts.length == 5 ? filteredProducts : this.$store.state.filter.highlightProducts;
        } else {
          return this.$store.state.filter.highlightProducts;
        }
      },
      allProducts() {
        return this.$store.state.product.catalogProducts;
      }
    },

    mounted () {
      this.initSwiper();
      window.addEventListener('resize', this.initSwiper);
    },

    methods: {
      initSwiper() {
        const isMobile = window.innerWidth <= 700;
        if(!isMobile && this.swiper) {
          this.swiper?.destroy();
          this.swiper = undefined;
        } else if(isMobile && !this.swiper && this.highlightProducts.length > 1) {
          this.swiper = new Swiper(this.$refs.swiper, this.swiperOptions);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.welcome {
  position: relative;
  min-height: 475px;

  &__image-wrap {
    position: relative;
    z-index: 1;
    max-height: 375px;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__image {
    height: 100%;
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }

  &--no-products {
    display: flex;
    align-items: center;
    min-height: 300px;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-down(sm) {
      padding: 2rem 1rem;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__title,
  &__desc {
    margin-bottom: 1rem;
  }

  &__desc {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }

  .swiper {
    padding: .5rem 0 0 .5rem;

    @media (min-width: 700px) {
      padding: .5rem 0 .5rem .5rem;
      .swiper-wrapper {
        justify-content: center;
        // flex-wrap: wrap;
        margin: -1rem -.5rem;
        .swiper-slide {
          padding: 1rem .5rem;
          max-width: 20%;
        }
      }
    }
    @media (min-width: 320px){
      .swiper-slide {
          padding: 1rem .5rem;
        }
    }
  }

  &__products-wrap {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: $box-shadow-sm;
    padding: 1.25rem;
    overflow: hidden;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    max-height: 475px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .swiper-pagination {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
</style>
