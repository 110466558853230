<template>
  <div class="product-teaser">
    <div class="detail-card">
      <div class="product-description__headline mb-3">{{ $t('view.product.description') }}</div>
      <div class="product-teaser__text" v-html="truncateText(description, 300)" />
      <div class="product-teaser__link">
        <a @click="$scrollTo('#description', 100)" href="javascript:void(0)">{{ $t('view.product.description_link') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      description: {
        type: String,
        default: ''
      }
    },
    methods: {
      truncateText(text, length) {
        if (text) {
          return text.slice(0, length) + ' ' + '...';
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .product-teaser {
    &__link,
    &__text {
      @extend %font-md;
    }
  }
</style>
