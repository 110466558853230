<template>
  <section id="howto" class="how-to-comp container-xl">
    <div class="how-to-comp__headline">
      <p class="overline" v-if="overline">{{ overline }}</p>
      <h1 v-if="headline">{{ headline }}</h1>
    </div>
    <div class="cards row">
      <img class="arrow arrow--1" :src="$image('assets/images/HowToArrow.png')" alt="arrow" />
      <img class="arrow arrow--2" :src="$image('assets/images/HowToArrow.png')" alt="arrow" />
      <div v-for="(card, i) in cards" :key="card.title" class="card col-lg-4 mt-lg-0" :class="{'mt-5 mt-md-4' : i > 0}">
        <img v-if="card.image" :src="card.image.link" :alt="card.image.name" class="card-img" />
        <div class="p-2 p-lg-3 mt-4 mt-lg-5">
          <h4 class="card-title mb-3">{{ card.title }}</h4>
          <p v-html="card.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      overline: {
        type: String,
        default: ''
      },
      headline: {
        type: String,
        default: ''
      },
      cards: {
        type: Array,
        default: []
      }
    }
  };
</script>

<style lang="scss" scoped>
  .how-to-comp {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &__headline {
      margin-bottom: 3rem;
    }

    .cards {
      position: relative;

      .arrow {
        display: none;
      }

      .card{
        text-align: center;

        .card-img{
          width: 3.5rem;
          height: auto;
          margin: 0 auto;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .how-to-comp__headline {
        margin-bottom: 4rem;
      }

      .cards {
        max-width: 363px;
        margin: 0 auto;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: 5rem;
      margin-bottom: 5rem;

      .how-to-comp__headline {
        margin-bottom: 7rem;
      }

      .cards {
        .arrow {
          position: absolute;
          display: block;
          width: 13.75rem;
          height: auto;
          z-index: 2;

          &--1 {
            top: -5%;
            left: 23%;
          }

          &--2 {
            top: 25%;
            left: 57%;
            transform: scaleY(-1);
          }
        }

        .card {
          .card-img{
            width: 5.125rem;
          }

          .card-title {
            font-size: $h3-font-size;
          }
        }
      }
    }
  }
</style>
