import store from '@/store';
import i18n from '@/plugins/i18n';
import apiErrors from '@/config/apiErrors';

const $t = i18n.global.t;

export default function handleError(error) {
  // If the error doesn`t have a response Object we show a generic Toast
  if (error.response == null || error.response.status === 500) {
    return error.response;
  }

  // Create Custom Error Object
  const customErrorResponse = createCustomErrorResponse(error.response);
  const customErrorObject = {
    ...error,
    response: customErrorResponse,
    message: customErrorResponse.data.message,
    displayInComponent: customErrorResponse.data.displayInComponent
  };

  // Show additional Message if existing
  if (customErrorResponse.data.additionalMessage) showAdditionalMessage(customErrorResponse);

  return customErrorObject;
}

/* ##################################################
           Helper Functions
   ##################################################
*/

// Creates a Custom Error Response from API-Errors Config
function createCustomErrorResponse(response) {
  const errorResponse = response;
  const notAuthorized = errorResponse.status === 403;
  const gatewayTimeout = errorResponse.status === 504;

  if (errorResponse.data) {
    const customData = getErrorObject(errorResponse.data.error_code);

    if (customData) {
      const errorLabelName = createErrorLabelName(errorResponse);
      const parameter = createParameterObject(errorResponse, store.state.app.currency.symbol);
      customData.messageKey = customData.messageKey.replace('${errorLabelName}', errorLabelName);
      customData.message = $t(customData.messageKey, parameter);
      Object.assign(errorResponse.data, customData);
      return errorResponse;
    }
  }

  if (notAuthorized) {
    const unauthorizedError = getErrorObject('BFFE016');
    unauthorizedError.message = $t(unauthorizedError.messageKey);
    Object.assign(errorResponse.data, unauthorizedError);
    return errorResponse;
  }

  if (gatewayTimeout) {
    const timeoutError = getErrorObject('TIMEOUT');
    timeoutError.message = $t(timeoutError.messageKey);
    Object.assign(errorResponse.data, timeoutError);
    return errorResponse;
  }

  // Fallback to Generic Error
  const genericError = getErrorObject('BFFE100');
  genericError.message = $t(genericError.messageKey);
  Object.assign(errorResponse.data, genericError);
  return errorResponse;
}

// Gets the matching ErrorObject from API-Errors Config
function getErrorObject(errorCode) {
  return apiErrors.find((el) => el.error_code === errorCode);
}

// Creates Label from Request URL and Request Method to match Translation Key
function createErrorLabelName(errorResponse) {
  const url = errorResponse.config.url;
  const requestMethod = errorResponse.config ? errorResponse.config.method : '';
  return `${url.toLowerCase()}.${requestMethod.toLowerCase()}`;
}

// Create Object containing all additional Error Parameter from Response
function createParameterObject(errorResponse, currency) {
  const paramObject = { trace_id: errorResponse.data.trace_id, currency: currency };
  const errorFields = errorResponse.data.error_fields;

  if (errorFields) {
    Object.assign(paramObject, errorFields);
    return paramObject;
  } else {
    return paramObject;
  }
}

// Triggers an additional Error Message in Form of a Toast or Alert based on API-Error Configuration
function showAdditionalMessage(errorResponse) {
  const messageType = errorResponse.data.additionalMessageType.toLowerCase().trim();
  const parameter = createParameterObject(errorResponse, store.state.app.currency.symbol);
  const message = $t(errorResponse.data.additionalMessageKey, parameter);
  const colorVariant = errorResponse.data.additionalMessageVariant;

  switch (messageType) {
    case 'toast':
      showToast({ message: message, variant: colorVariant, status: errorResponse.status });
      break;
    case 'alert':
      showAlert({ message: message, variant: colorVariant });
      break;
    default:
      console.log('Message Type not found');
  }
}

function showToast({ message, variant, status }) {
  const toastData = {
    title: $t('api-errors.toast-title'),
    message: message,
    colorVariant: variant,
    duration: 7
  };
  if(status !== 500) store.commit('app/SET_TOAST', toastData, { root: true });
}

function showAlert({ message, variant }) {
  const alertData = {
    show: true,
    text: message,
    colorVariant: variant
  };
  store.commit('app/SET_ALERT', alertData, { root: true });
}
