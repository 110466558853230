export default [
  {
    id: 2,
    name: 'bestchoice',
    title: 'view.checkout.payment.add_voucher.headline',
    short_description: 'view.checkout.payment.add_voucher.headline',
    description: 'view.checkout.payment.add_voucher.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/bestChoice-new.svg',
    prepaid: true,
    saferpay: false
  },
  {
    id: 3,
    name: 'my_cadooz',
    title: 'Konto',
    short_description: '',
    description: '',
    icon: '',
    prepaid: true,
    saferpay: false
  },
  {
    id: 4,
    name: 'creditcard',
    title: 'Kreditkarte',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 5,
    name: 'banktransfer',
    title: 'view.checkout.payment.prepay.headline',
    short_description: 'view.checkout.payment.prepay.description',
    description: 'view.checkout.payment.prepay.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/vorkasse.svg',
    prepaid: false,
    saferpay: false
  },
  {
    id: 6,
    name: 'debitadvice',
    title: 'Lastschrift',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 7,
    name: 'bill',
    title: 'Rechnung',
    short_description: '',
    description: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 9,
    name: 'cash_on_delivery',
    title: 'Nachnahme',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 14,
    name: 'salesstar',
    title: 'SalesStar',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 20,
    name: 'employee_sale',
    title: 'Mitarbeitereinkauf',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 21,
    name: 'cadooz_card',
    title: 'Cadooz Card',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: false
  },
  {
    id: 101,
    name: 'mastercard',
    title: 'view.checkout.payment.mastercard.headline',
    short_description: 'view.checkout.payment.mastercard.description',
    description: 'view.checkout.payment.mastercard.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/mastercard.svg',
    prepaid: false,
    saferpay: true
  },
  {
    id: 102,
    name: 'visa',
    title: 'view.checkout.payment.visa.headline',
    short_description: 'view.checkout.payment.visa.description',
    description: 'view.checkout.payment.visa.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/visa.svg',
    prepaid: false,
    saferpay: true
  },
  {
    id: 103,
    name: 'amex',
    title: 'view.checkout.payment.amex.headline',
    short_description: 'view.checkout.payment.amex.description',
    description: 'view.checkout.payment.amex.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/amex.svg',
    prepaid: false,
    saferpay: true
  },
  {
    id: 104,
    name: 'sofortueberweisung',
    title: 'Sofortüberweisung',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: true
  },
  {
    id: 105,
    name: 'paypal',
    title: 'view.checkout.payment.paypal.headline',
    short_description: 'view.checkout.payment.paypal.description',
    description: 'view.checkout.payment.paypal.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/paypal.svg',
    prepaid: false,
    saferpay: true
  },
  {
    id: 106,
    name: 'diners_club',
    title: 'DinersClub',
    short_description: '',
    description: '',
    icon: '',
    prepaid: false,
    saferpay: true
  },
  {
    id: 107,
    name: 'applepay',
    title: 'view.checkout.payment.applepay.headline',
    short_description: 'view.checkout.payment.applepay.description',
    description: 'view.checkout.payment.applepay.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/applepay.svg',
    prepaid: false,
    saferpay: true
  },
  {
    id: 108,
    name: 'googlepay',
    title: 'view.checkout.payment.googlepay.headline',
    short_description: 'view.checkout.payment.googlepay.description',
    description: 'view.checkout.payment.googlepay.text',
    icon: 'https://einloesen.cadooz.com/images/shop/icons/paymenttype/googlepay.svg',
    prepaid: false,
    saferpay: true
  }
]