import paymentTypes from '@/config/paymentTypes.js';

// =======================================================================
// State
// =======================================================================
const state = () => ({
  appLoaded: false,
  isLoading: false,
  showLoadingAnimation: true,
  websiteSettings: null,
  shopUrl: "bestchoice.einloesen.de",
  mgnlPath: "bestchoice.einloesen.de",
  devMode: process.env.NODE_ENV === 'development',
  stagingMode: process.env.NODE_ENV === 'staging',
  dockerMode: process.env.NODE_ENV === 'docker',
  mgnlMode: process.env.NODE_ENV === 'mgnl',
  handshakeData: null,
  isHandshake: false,
  isOpenShop: false,
  isB2B: false,
  prefilledVoucherCode: null,
  toastConfig: null,
  footerImgs: {
    tuevLogo: 'assets/images/tuev_logo.png',
    etrackerLogo: 'assets/images/etracker_logo.png',
    trustpilotLogo: 'assets/images/trustpilot_logo.svg'
  },
  alert: {
    show: false,
    text: '',
    colorVariant: 'primary'
  },
  currency: {
    code: 'EUR',
    symbol: '€'
  },
  categoryIcon: 'catIconDefault',
  headerLoggedIn: false,
  basketItemLimit: null,
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_WEBSITESETTINGS(state, settings) {
    state.websiteSettings = settings;
  },
  SET_SHOP_URL(state, url) {
    state.shopUrl = url;
  },
  SET_MGNL_PATH(state, path) {
    state.mgnlPath = path;
  },
  SET_BASKET_ITEM_LIMIT(state, limit) {
    limit !== null ? state.basketItemLimit = limit : state.basketItemLimit = 999;
  },
  SET_LOADINGSTATE(state, loadingState) {
    state.isLoading = loadingState;
  },
  SET_ALERT(state, payload) {
    state.alert = payload;
  },
  SET_PREFILLED_VOUCHERCODE(state, code) {
    state.prefilledVoucherCode = code;
  },
  SET_TOAST(state, config) {
    state.toastConfig = config;
  },
  SET_APPLOADED(state, payload) {
    state.appLoaded = payload;
  },
  SET_SHOWLOADINGANIMATION(state, payload) {
    state.showLoadingAnimation = payload;
  },
  SET_IS_HANDSHAKE(state, payload) {
    state.isHandshake = payload;
  },
  SET_HANDSHAKE_DATA(state, payload) {
    state.handshakeData = payload;
  },
  SET_IS_OPEN_SHOP(state, payload) {
    state.isOpenShop = payload;
  },
  SET_IS_B2B(state, payload) {
    state.isB2B = payload;
  },
  SET_HEADER_LOGGEDIN(state, payload) {
    state.headerLoggedIn = payload;
  },
  SET_CURRENCY(state, payload) {
    state.currency = payload;
  },
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  /**
   * @param {*} context
   * @throws ApiError
   */
  async initShop(context, {sessionId, language}) {
    if (context.state.websiteSettings === null) {
      context.commit('SET_SHOP_URL', context.getters.getShopUrl);
      
      const websiteSettings = await context.dispatch('getWebsiteSettings', {sessionId, language});
      context.commit('SET_WEBSITESETTINGS', websiteSettings);
      context.commit('SET_MGNL_PATH', context.getters.getMgnlPath);
      context.commit('SET_BASKET_ITEM_LIMIT', websiteSettings.maximum_basket_item_count);
      context.commit('SET_IS_B2B', websiteSettings.website_type_id === 'PUN');
      context.commit('SET_CURRENCY', context.getters.getCurrency);

      if (websiteSettings.session_info.logged_in) {
        context.dispatch('user/logIn', null, { root: true });
        context.commit('SET_HEADER_LOGGEDIN', true);
      } else {
        context.dispatch('user/logOut', null, { root: true });
        context.commit('SET_HEADER_LOGGEDIN', false);
      }
      return websiteSettings;
    }
  },

  async getWebsiteSettings({ state }, {sessionId, language}) {
    const response = await this.$api.getWebsiteSettings({url: state.shopUrl, sessionId, language});
    return response.settings;
  },

  async decryptAndPrefillVoucherCode(context, encryptedCode) {
    const payload = {
      encrypted_code: encryptedCode
    };
    const response = await this.$api.decryptVoucherCode(payload);
    const decryptedCode = response.voucher.decrypted_code;
    context.commit('SET_PREFILLED_VOUCHERCODE', decryptedCode);
  },

  async verifyHandShake(context, handshakeQueryParam) {
    const shopUrl = context.getters.getShopUrl;
    const referrer = window.frames.top.document.referrer;
    const { expired_request, externalId, coupon, isValidCoupon, product_number } = await this.$api.verifyHandShake({ url: shopUrl, query: handshakeQueryParam, referrer});
    const data = { valid: expired_request == false, externalId, coupon, isValidCoupon, product_number };
    context.commit('SET_HANDSHAKE_DATA', data);
    return data;
  },

  async handleHandshakeLogin(context) {
    const handshakeId = context.state.handShakeId;
    const response = await context.dispatch('user/handShakeLogin', handshakeId, { root: true });
    context.commit('SET_HEADER_LOGGEDIN', true);
    return response.logged_in;
  },

  loadingAnimation(context, param) {
    context.commit('SET_SHOWLOADINGANIMATION', param);
  }
};

// =======================================================================
// Getters
// =======================================================================
const getters = {
  getShopUrl: (state) => {
    let shopUrl;

    if (state.mgnlMode) {
      // extract the url from within the magnolia iFrame
      shopUrl = window.location.pathname.split('/')[1].split('.html')[0];
    } else if (state.dockerMode || state.devMode) {
      let cookieShopUrl = document.cookie
        .split("; ")
        .find((row) => row.startsWith("shop_url="))
        ?.split("=")[1];

      if(!cookieShopUrl) {
        cookieShopUrl = state.shopUrl;
        document.cookie = `shop_url=${cookieShopUrl}; SameSite=None; Secure`;
      }

      shopUrl = cookieShopUrl;
    } else {
      shopUrl = window.location.hostname;
    }

    return shopUrl;
  },
  getMgnlPath: (state) => {
    let mgnlPath = state.websiteSettings.magnolia_path;

    if (state.mgnlMode) {
      // extract the magnolia-path from within the magnolia iFrame
      mgnlPath = window.location.pathname.split('/')[1].split('.html')[0];
    } else if (state.dockerMode || state.devMode) { 
      let cookieMgnlPath = document.cookie
        .split("; ")
        .find((row) => row.startsWith("mgnl_path="))
        ?.split("=")[1];
      if(!cookieMgnlPath) {
        cookieMgnlPath = state.mgnlPath;
        document.cookie = `mgnl_path=${cookieMgnlPath}; SameSite=None; Secure`;
      }
      mgnlPath = cookieMgnlPath;
    }

    return mgnlPath?.toLowerCase();
  },
  getDefaultCatalogId: (state) => {
    if (state.websiteSettings) {
      return state.websiteSettings.default_catalog_id;
    }
  },
  getCurrency: (state) => {
    if (state.websiteSettings) {
      return {
        code: state.websiteSettings.website_currency_symbol,
        symbol: state.websiteSettings.website_currency_short_symbol != '' ? state.websiteSettings.website_currency_short_symbol : state.websiteSettings.website_currency_symbol
      };
    }
  },

  getRiskIdentId: (state) => {
    return state.websiteSettings.riskident_snippetid;
  },

  /**
   * Loads, translates and returns all available payment methods.
   * @returns Array
   */
  getAvailablePaymentMethods: (state, getters, rootState, rootGetters) => (i18n) => {
    if (!state.websiteSettings) {
      return [];
    }
    const availablePaymentTypes = state.websiteSettings.payment_types;
    return paymentTypes.filter((payment) => availablePaymentTypes.find((pay) => pay.id === payment.id));
  },
  /**
   * Workaround for category mapping. Several URLs have similar categories, but different category Ids.
   * This workaround maps the category ids to the default ones of einloesen.
   * In this way, we only need icons for the default categories.
   *
   * Attention!  if there are new categories with similar labels, they must be entered into the arrays so that
   * the corresponding icons can be assigned to them.
   */
  getCategoryIcon: (state, getters) => (catId) => {
    const categoryMap = [
      [5, 1488], // Accessoires/Mode/Fashion
      [6], //Auto/Mobility
      [332, 1563], //Beauty/Wellnes/Lifestyle
      [9], //Books/Music
      [689], //Charity
      [297, 1628], //Eat/Drink/Cook
      [8, 1568], //Hobby/Travel
      [14, 1573], //Multimedia/Technique
      [339], //Toys/Baby
      [15], //Storts/Lifestyle
      [13093], //Streaming/Gaming
      [10, 1558] //Living/House/Garden
    ];
    const catNum = categoryMap.find((item) => item.find((e) => e === catId));
    let catIcon;
    try {
      require('@/assets/svg/catIcon' + catNum[0] + '.svg?inline');
      catIcon = 'catIcon' + catNum[0];
    } catch (e) {
      catIcon = state.categoryIcon;
    }
    return catIcon;
  },

  isRedemptionShop(state) {
    return state.websiteSettings.website_type_id === "RED" ?? false
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
