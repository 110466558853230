import { loadNavigation, loadPageContent, removeCurrentLanguage } from 'mgnl-building-blocks/helpers/mgnlApiHelper';

// =======================================================================
// State
// =======================================================================
const state = () => ({
  routes: [],
  indexPage: null,
  currentPage: null,
  productPage: null,
  favicon: null,
  additionalOptions: {
    filterVariant: 'on-page-filter',
    addToCartVariant: null,
    minimalMode: null,
    trustPilot: null,
    surveyMonkey: null,
    giftomatic: null,
    newsletterList: null,
    newsletterCheckout: null,
    checkoutGTCLink: null,
    checkoutGTCLinkLabel: null
  }
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_ROUTES(state, data) {
    state.routes = data;
  },
  SET_INDEX_PAGE(state, data) {
    state.indexPage = data;
  },
  SET_PRODUCT_PAGE(state, data) {
    state.productPage = data;
  },
  SET_ADDITIONAL_OPTIONS(state, data) {
    // state.additionalOptions.filterVariant = data.filters;
    state.additionalOptions.addToCartVariant = data.addToCart;
    state.additionalOptions.minimalMode = data.minMode;
    state.additionalOptions.trustPilot = data.trustPilot;
    state.additionalOptions.surveyMonkey = data.surveyMonkey;
    state.additionalOptions.giftomatic = data.giftomatic;
    state.additionalOptions.newsletterList = data.newsletterList;
    state.additionalOptions.newsletterCheckout = data.newsletterCheckout;
    state.additionalOptions.checkoutGTCLink = data.checkoutGTCLink;
    state.additionalOptions.checkoutGTCLinkLabel = data.checkoutGTCLinkLabel;
    state.favicon = data.favicon;
  },
  SET_CURRENT_PAGE(state, content) {
    state.currentPage = content;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  async initNav(context, lang) {
    const content = await loadNavigation('/' + context.rootState.app.mgnlPath, lang);
    if (!content) return false;
    const routes = ['/'];
    parsePaths(content);

    function parsePaths(parent) {
      parent['@nodes'] && parent['@nodes'].forEach((node) => {
        routes.push(parent[node]['@path'].replace('/' + context.rootState.app.mgnlPath, ''));
        if (parent[node]['@nodes'].length) {
          parsePaths(parent[node]);
        }
      });
    }
    context.commit('SET_ROUTES', routes);
    return true;
  },

  async loadPage(context, {path, lang}) {
    let content;

    if (context.rootState.app.mgnlMode || context.state.routes.includes(removeCurrentLanguage(path, lang))) {
      content = await loadPageContent(path, '/' + context.rootState.app.mgnlPath, lang);
    }

    if(!context.state.indexPage) {
      const indexContent = content && content['mgnl:template'] === 'shop-lm:pages/index' ? content : await loadPageContent('/', '/' + context.rootState.app.mgnlPath, lang);
      if (!indexContent) return;

      context.commit('SET_ADDITIONAL_OPTIONS', indexContent);

      this.$setFavicon(indexContent.favicon);
      this.$setColors(this.$toHexColorCode(parseInt(indexContent.colors.primary)), this.$toHexColorCode(parseInt(indexContent.colors.secondary)));

      context.commit('SET_INDEX_PAGE', indexContent);
    }

    if(!context.state.productPage && context.state.routes.includes('/product-detail')) {
      const productContent = content && content['mgnl:template'] === 'shop-lm:pages/productDetail' ? content : await loadPageContent('/product-detail', '/' + context.rootState.app.mgnlPath, lang);
      if (!productContent) return;

      context.commit('SET_PRODUCT_PAGE', productContent);
    }

    return content;
  }
};

// =======================================================================
// Getters
// =======================================================================

const getters = {
  minimalMode: (state) => {
    return state.additionalOptions.minimalMode === 'active';
  },
  showTrustPilot: (state) => {
    const trustpilot = state.additionalOptions.trustPilot;
    if (trustpilot === null || trustpilot === undefined) {
      return false;
    }
    if (!Array.isArray(trustpilot)) {
      return false;
    }
    return state.additionalOptions.trustPilot.length === 1;
  },
  activeGiftomatic: (state) => {
    const giftomatic = state.additionalOptions.giftomatic;
    if (giftomatic === null || giftomatic === undefined) {
      return false;
    }
    if (!Array.isArray(giftomatic)) {
      return false;
    }
    return state.additionalOptions.giftomatic.length === 1;
  },
  newsletterCheckout: (state) => {
    const newsletterCheckout = state.additionalOptions.newsletterCheckout;
    if (newsletterCheckout === null || newsletterCheckout === undefined) {
      return false;
    }
    if (!Array.isArray(newsletterCheckout)) {
      return false;
    }
    return state.additionalOptions.newsletterCheckout.length === 1;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
