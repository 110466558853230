<template>
  <HowToComponent
    :overline="overline"
    :headline="headline"
    :cards="mgnlCards"
  />
</template>

<script>
  import HowToComponent from '@/components/HowToComponent/HowToComponent.vue';

  export default {
    components: { HowToComponent },
    data() {
      return {};
    },
    props: {
      overline: {
        type: String,
        default: ''
      },
      headline: {
        type: String,
        default: ''
      },
      card1: {
        type: Object,
        default: []
      },
      card2: {
        type: Object,
        default: []
      },
      card3: {
        type: Object,
        default: []
      }
    },

    computed: {
      mgnlCards() {
        const cards = [this.card1, this.card2, this.card3];
        return cards.map((card) => {
          return {
            title: card.title,
            text: card.text,
            image: {
              link: process.env.VUE_APP_MGNL_HOST + '/dam' + card.image['@path'],
              name: card.image['@name']
            }
          };
        });
      }
    }
  };
</script>
