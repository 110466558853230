<template>
  <div class="product-survey mb-4">
    <div>
      <h5 class="product-survey__text mb-3" v-html="$t('view.product.survey.text', { brandname: `<b>${product.name_short}</b>?` })" />
      <div role="group" class="bv-no-focus-ring">
        <div v-for="option of surveyOptions" :key="option.text" class="mb-4">
          <div class="custom-control custom-control-inline custom-checkbox">
            <input 
              :id="option.text"
              type="checkbox" 
              class="custom-control-input" 
              :checked="modelValue === option.value"
              :value="option.value"
              @click="$emit('update:modelValue', option.value)"
            />
            <label :for="option.text" class="custom-control-label checked">
              <span>
                {{ option.text }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      },
      modelValue: {
        type: Number,
        default: null
      },
      surveyOptions: {
        type: Array,
        default: null
      },
    }
  }
</script>

<style lang="scss" scoped>
  .product-survey {
    &__text {
      line-height: 30px;
    }
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 50%;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
    border: 3px solid white;
    border-radius: 50%;
  }
  .custom-control-label::after {
    top: 0.05rem;
    left: -1.95rem;
    width: 1.4rem;
    height: 1.4rem;
  }
</style>